/* eslint-disable max-params */
import { Injectable } from '@angular/core';
import { TranslateService } from "@ngx-translate/core";
import { NgbModal, NgbModalOptions, NgbModalRef } from "@ng-bootstrap/ng-bootstrap";
import { ConfirmationModalComponent } from "../shared/components/confirmation-modal/confirmation-modal.component";
import { Dialog } from '../models/dialog';
import { SelectionModelComponent } from '../shared/components/selection-model/selection-model.component';
import { ApproveRequestModalComponent } from '../lumen-integration/approve-request-modal/approve-request-modal.component';

@Injectable({
  providedIn: 'root'
})
export class DialogService {

  ngbModelOptions: NgbModalOptions = {
    backdrop: 'static',
    size: 'lg',
    centered: true,
    animation: true
  };

  constructor(private translate: TranslateService, private ngbModal: NgbModal) {
  }

  public showConfirmDialog(dialog: Dialog): Promise<boolean> {
    dialog.ngbModelOptions = { ...this.ngbModelOptions, ...dialog.ngbModelOptions, modalDialogClass: 'confirm-modal-dialog' };
    const modalRef: NgbModalRef = this.ngbModal.open(ConfirmationModalComponent, dialog.ngbModelOptions);
    return this.executeDialog(dialog, modalRef);
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public showSelectionDialog(dialog: Dialog): Promise<any> {
    dialog.ngbModelOptions = { ...this.ngbModelOptions, ...dialog.ngbModelOptions };
    const modalRef: NgbModalRef = this.ngbModal.open(SelectionModelComponent, dialog.ngbModelOptions);
    modalRef.componentInstance.modalConfig.options = dialog.options;
    return this.executeDialog(dialog, modalRef);
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public showApproveRequestDialog(dialog: Dialog): Promise<any> {
    dialog.ngbModelOptions = { ...this.ngbModelOptions, ...dialog.ngbModelOptions };
    const modalRef: NgbModalRef = this.ngbModal.open(ApproveRequestModalComponent, dialog.ngbModelOptions);
    return this.executeDialog(dialog, modalRef);
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any, max-params
  private async executeDialog(dialog: Dialog, modalRef: NgbModalRef, isGoToContentArea?: boolean, allowToEditQuestion?: boolean, diableOriginalEdit?: boolean, cancelNote?: boolean) {
    const translatedTitle = await this.translate.get(dialog.title.translationKey, dialog.title.translateArgs).toPromise();
    const component = modalRef.componentInstance;
    if (dialog.note !== undefined) {
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      const translatedNote = await this.translate.get(dialog.note!.translationKey, dialog.note!.translateArgs).toPromise();
      component.modalConfig.note = translatedNote;
    }
    if (dialog.button !== undefined) {
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      const translatedNote = await this.translate.get(dialog.button!.translationKey, dialog.button!.translateArgs).toPromise();
      component.modalConfig.confirmBtn = translatedNote;
    }
    if (dialog.text2 !== undefined) {
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      const translatedNote = await this.translate.get(dialog.text2!.translationKey, dialog.text2!.translateArgs).toPromise();
      component.modalConfig.message2 = translatedNote;
    }
    component.modalConfig.type = dialog.type;
    component.modalConfig.message = translatedTitle;
    component.modalConfig.selectedArchetype = dialog.selectedArchetype;
    component.allowtoShowContent = isGoToContentArea;
    component.allowToEditQuestion = allowToEditQuestion;
    component.diableOriginalEdit = diableOriginalEdit;
    component.modalConfig.archetypeOptions = dialog.archetypeOptions;
    component.cancelNote = cancelNote;
    component.modalConfig.isRejectRequestModal = dialog.modalType;
    return new Promise<boolean>((resolve) => {
      component.confirmStatus.subscribe((status: boolean) => {
        modalRef.close();
        resolve(status);
      });
    });

  }

}
