<nav class="navbar sticky-top header">
  <div class="container-fluid">
    <a class="navbar-brand p-0" href="#">
      <img src="../../assets/images/logo.svg" *ngIf="!isDarkThemeEnable">
      <img src="../../assets/images/logo-dark.svg" *ngIf="isDarkThemeEnable">
    </a>

    <div class="d-flex align-items-center">
      <span class="user-pic">
        <img [src]="userPic">
      </span>
      <span class="ms-2 user-name">{{userName}}</span>
    </div>
  </div>
</nav>