import { Component, Output, EventEmitter } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Archetype } from 'src/app/models/project-list-card';

@Component({
  selector: 'app-selection-model',
  templateUrl: './selection-model.component.html',
  styleUrls: ['./selection-model.component.scss']
})
export class SelectionModelComponent {

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  modalConfig: any = {
    type: 'singleSelect',
    message: 'selectionModal.text',
    note: 'selectionModal.note',
    archetypeOptions: [],
    selectedArchetype: '',
    confirmBtn: 'selectionModal.confirmButton',
    cancelBtn: 'selectionModal.cancelButton',
  };

  selectedOptions!: string;

  @Output() confirmStatus = new EventEmitter();

  constructor(private translate: TranslateService) { }

  ngOnInit() {
    this.selectedOptions = this.modalConfig.selectedArchetype;
  }

  sendConfirmStatus(): void {
    this.confirmStatus.emit(this.selectedOptions);
  }

  sendCancelStatus(): void {
    this.confirmStatus.emit(false);
  }

  selectOptions(option: Archetype): void {
    this.selectedOptions = option.archetypeId;
  }
}
