<div class="modal-header d-flex align-items-center justify-content-between">
  <span class="heading-2" *ngIf="marksMode !== MarksMode.EDIT_MARKS">
    Confirm Marks
  </span>
  <span class="heading-2" *ngIf="marksMode === MarksMode.EDIT_MARKS">
    Edit Marks
  </span>
  <svg-icon (click)="closeModal()" class="cursor-pointer" src="../../../../assets/images/test_case_close.svg">
  </svg-icon>
</div>

<div class="modal-body">
  <div>
    <div>
      <p class="big-bold-assistive-text">Customize Project Marks</p>
      <p class="body-sub-text">You can edit the marks of the project till learners attempt the exercise.</p>
    </div>
    <div class="mt-24">
      <div class="marks-container">
        <div class="row px-24 py-3 g-0">
          <div class="col-10 big-bold-assistive-text">Title</div>
          <div class="col-2 justify-content-center big-bold-assistive-text">Marks</div>
        </div>
      </div>
      <div class="marks-bottom-container">
        <scrollbar class="scrollbar">
          <div class="add-scroll">
            <div>
              <div class="row g-0 project-list" *ngFor="let project of localSelectedProjects">
                <div class="col-9 pl-24">{{project.title}}</div>
                <div class="col-3 pr-24">
                  <div class="form-group mb-0">
                    <input type="number" class="form-control marks-input" [(ngModel)]="project.maxMarks"
                      (ngModelChange)="calculateSumOfMarks(); onInputChange()">
                  </div>
                </div>
              </div>
            </div>
          </div>
        </scrollbar>
      </div>
    </div>
  </div>
  <div class="text-end">
    <div class="total-marks body-text  mt-3" *ngIf="marksMode !== MarksMode.EDIT_MARKS">
      Total Marks <span class="heading-2">{{totalMarks}}</span>
    </div>
  </div>
  <div class="text-end">
    <div class="total-marks body-text mt-3" *ngIf="marksMode === MarksMode.EDIT_MARKS">
      Total Exercise Marks <span class="heading-2">{{totalMarks}}</span>
    </div>
  </div>
</div>


<div class="modal-footer d-flex justify-content-end">
  <button class="btn btn-secondary-grey mr-24" (click)="closeModal()">Cancel</button>
  <button class="btn btn-primary ml-24" (click)="saveExercise()" *ngIf="marksMode !== MarksMode.EDIT_MARKS">Save & Create
    Exercise</button>
  <button class="btn btn-primary ml-24" (click)="updateMarks()" *ngIf="marksMode === MarksMode.EDIT_MARKS"
    [disabled]="!changesMade">Save
    Marks</button>
</div>