import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ExerciseBuilderRoutingModule } from './exercise-builder-routing.module';
import { ExerciseBuilderComponent } from './exercise-builder.component';
import { ExerciseSetupComponent } from './exercise-setup/exercise-setup.component';
import { ExerciseProjectsComponent } from './exercise-projects/exercise-projects.component';
import { WrapperModule } from 'src/app/shared/modules/wrapper/wrapper.module';
import { CkEditorSharedModule } from "../../shared/modules/ck-editor/ck-editor.module";
import { ProjectListCardModule } from 'src/app/shared/modules/project-list-card/project-list-card.module';
import { ProjectSelectionComponent } from './project-selection/project-selection.component';
import { DateTimePickerModule } from "../../shared/modules/date-time-picker/date-time-picker.module";
import { ExerciseBuilderService } from './exercise-builder.service';

@NgModule({
  declarations: [
    ExerciseBuilderComponent,
    ExerciseSetupComponent,
    ExerciseProjectsComponent,
    ProjectSelectionComponent,
  ],
  imports: [
    CommonModule,
    ExerciseBuilderRoutingModule,
    WrapperModule,
    CkEditorSharedModule,
    ProjectListCardModule,
    WrapperModule,
    DateTimePickerModule
  ],
  providers: [ExerciseBuilderService]
})
export class ExerciseBuilderModule { }
