import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subject, distinctUntilChanged, filter, takeUntil } from 'rxjs';
import { ExerciseBuilderService } from './exercise-builder.service';
import { ExerciseBuilderModes } from 'src/app/enums/exerciseBuilderModes';
import { ExerciseDetails } from 'src/app/models/exerciseInterface';

export enum ExerciseTabModes {
  EXERCISE_SETUP = 'exercise-setup',
  EXERCISE_PROJECTS = 'exercise-projects',
  PROJECT_SELECTION = 'project-selection'
}

@Component({
  selector: 'app-exercise-builder',
  templateUrl: './exercise-builder.component.html',
  styleUrls: ['./exercise-builder.component.scss']
})
export class ExerciseBuilderComponent {
  ExerciseTabModes = ExerciseTabModes;
  activatedTab!: ExerciseTabModes;
  createMode!: boolean;
  exerciseType: string | undefined | null;
  subjectToUnsubscribe = new Subject;
  isLayoutReady = false;
  constructor(private activatedRoute: ActivatedRoute, private router: Router, public exerciseBuilderService: ExerciseBuilderService) { }
  ngOnInit() {
    //Check the current mode of operation. Create or Edit mode based on route parameters.
    this.detectCreateEditMode();
    //Setting the default tab to EXERCISE_SETUP
    this.tabSwitch(ExerciseTabModes.EXERCISE_SETUP);
    this.setupQueryParamActiveTabListener();
    this.exerciseType = this.activatedRoute.snapshot.queryParamMap.get('excerciseType');
    const redirectUrl = this.activatedRoute.snapshot.queryParams['redirectBackTo'];
    if (redirectUrl) {
      this.exerciseBuilderService.redirectBack = redirectUrl;
    }
  }

  translationKey(): string {
    if (this.createMode) {
      if (this.exerciseType === 'quiz') {
        return 'exerciseBuilder.createExerciseQuiz';
      } else if (this.exerciseType === 'assignment') {
        return 'exerciseBuilder.createExerciseAssignment';
      } else if (this.exerciseType === 'practice') {
        return 'exerciseBuilder.createExercisePractice';
      }
    }
    return 'exerciseBuilder.editExercise';
  }

  /**
  * This will fetch the exercise id from route param and if it is create the
  * create mode would be enabled else the edit mode would be enabled.
  */
  detectCreateEditMode() {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const exerciseId: string = this.activatedRoute.snapshot.paramMap.get('exerciseId')!;
    this.createMode = exerciseId === 'create' ? (
      this.exerciseBuilderService.exerciseBuilderMode = ExerciseBuilderModes.CREATE,
      this.isLayoutReady = true,
      true) : (
      this.exerciseBuilderService.setExerciseId(exerciseId),
      this.exerciseBuilderService.exerciseBuilderMode = ExerciseBuilderModes.EDIT,
      this.storeExerciseDetails(exerciseId),
      false);
  }

  async storeExerciseDetails(exerciseId: string) {
    const getExercisePayload = {
      exerciseId: exerciseId
    };
    this.exerciseBuilderService.getExerciseDetails(getExercisePayload)
      .pipe(takeUntil(this.subjectToUnsubscribe))
      .subscribe((data: ExerciseDetails) => {
        this.exerciseBuilderService.exerciseDetails = data;
        const submissionCountUnSubscribe = this.exerciseBuilderService.getSubmissionCount(getExercisePayload).subscribe((data: { submissions: number }) => {
          this.exerciseBuilderService.exerciseDetails.submissions = data.submissions;
          this.exerciseBuilderService.publishExerciseDetailsState();
          this.isLayoutReady = true;
          submissionCountUnSubscribe.unsubscribe();
        });
      });
  }
  tabSwitch(activeTabMode: ExerciseTabModes): void {
    if (this.activatedTab === activeTabMode) {
      return;
    }
    this.activatedTab = activeTabMode;
    this.router.navigate([], { relativeTo: this.activatedRoute, queryParams: { exerciseManipulationTab: this.activatedTab }, queryParamsHandling: 'merge' });
  }

  /**
* This will setup a listener for the query param - 'exerciseManipulationTab' and switch the tab automatically to maintain the state.
*/
  private setupQueryParamActiveTabListener() {
    this.activatedRoute.queryParams.pipe(
      takeUntil(this.subjectToUnsubscribe),
      distinctUntilChanged(),
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      filter((param: any) => param.exerciseManipulationTab)
    ).subscribe((activeTabParam: { exerciseManipulationTab: ExerciseTabModes; }) => {
      if (this.activatedTab !== activeTabParam.exerciseManipulationTab) {
        this.tabSwitch(activeTabParam.exerciseManipulationTab);
      }
    });
  }

  ngOnDestroy() {
    this.subjectToUnsubscribe.complete();
    this.router.navigate([], { queryParams: { exerciseManipulationTab: null }, queryParamsHandling: 'merge', replaceUrl: true });
  }
}
