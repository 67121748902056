<div class="add-scroll d-flex pl-24 try-project-container">
  <div class="left-content">
    <div class="left-content-scroll">
      <div class="mb-16">
        <p class="body-bold-assistive-text">
          <ng-template skeletonUi [skeletonUiType]="SkeletonLoaderType.SkeletonRectangleComponent"
            [skeletonUiWidth]="'7vw'"></ng-template>
        </p>
      </div>
      <div class="mb-16">
        <p>
          <ng-template skeletonUi [skeletonUiType]="SkeletonLoaderType.SkeletonRectangleComponent"
            [skeletonUiWidth]="'7vw'" [skeletonUiStyles]="{'margin-right': '0.3vw'}"></ng-template>
          <ng-template skeletonUi [skeletonUiType]="SkeletonLoaderType.SkeletonRectangleComponent"
            [skeletonUiWidth]="'3vw'" [skeletonUiStyles]="{'margin-right': '0.3vw'}"></ng-template>
          <ng-template skeletonUi [skeletonUiType]="SkeletonLoaderType.SkeletonRectangleComponent"
            [skeletonUiWidth]="'5vw'" [skeletonUiStyles]="{'margin-right': '0.3vw'}"></ng-template>
        </p>
        <p>
          <ng-template skeletonUi [skeletonUiType]="SkeletonLoaderType.SkeletonRectangleComponent"
            [skeletonUiWidth]="'7vw'" [skeletonUiStyles]="{'margin-right': '0.3vw'}"></ng-template>
          <ng-template skeletonUi [skeletonUiType]="SkeletonLoaderType.SkeletonRectangleComponent"
            [skeletonUiWidth]="'3vw'" [skeletonUiStyles]="{'margin-right': '0.3vw'}"></ng-template>
          <ng-template skeletonUi [skeletonUiType]="SkeletonLoaderType.SkeletonRectangleComponent"
            [skeletonUiWidth]="'5vw'" [skeletonUiStyles]="{'margin-right': '0.3vw'}"></ng-template>
        </p>
        <p>
          <ng-template skeletonUi [skeletonUiType]="SkeletonLoaderType.SkeletonRectangleComponent"
            [skeletonUiWidth]="'7vw'" [skeletonUiStyles]="{'margin-right': '0.3vw'}"></ng-template>
          <ng-template skeletonUi [skeletonUiType]="SkeletonLoaderType.SkeletonRectangleComponent"
            [skeletonUiWidth]="'3vw'" [skeletonUiStyles]="{'margin-right': '0.3vw'}"></ng-template>
          <ng-template skeletonUi [skeletonUiType]="SkeletonLoaderType.SkeletonRectangleComponent"
            [skeletonUiWidth]="'5vw'" [skeletonUiStyles]="{'margin-right': '0.3vw'}"></ng-template>
        </p>
      </div>
      <div class="row 16">
        <div class="col-6">
          <p class="body-sub-text">
            Exercise Total:
            <span>
              <ng-template skeletonUi [skeletonUiType]="SkeletonLoaderType.SkeletonRectangleComponent"
                [skeletonUiWidth]="'1vw'"></ng-template>
            </span>
          </p>
        </div>
        <div class="col-6">
          <p class="body-sub-text">
            Pass Mark:
            <span>
              <ng-template skeletonUi [skeletonUiType]="SkeletonLoaderType.SkeletonRectangleComponent"
                [skeletonUiWidth]="'1vw'"></ng-template>
            </span>
          </p>
        </div>
      </div>
      <!-- Accordian Having Projects -->
      <div class="accordion project-player-accordian projects-accordion mt-16 mb-16">
        <div class="accordion-item project-player-accordian">
          <h2 class="accordion-header">
            <button
              class="accordion-button d-flex align-items-center justify-content-between heading-2 project-player-accordian-button"
              type="button">
              Projects
              <span class="accordion-toggle-icon cursor-pointer ms-1" data-bs-target="#panelsStayOpen-collapse"
                aria-expanded="true" aria-controls="panelsStayOpen-collapse"></span>
            </button>
          </h2>
          <div id="panelsStayOpen-collapse" class="accordion-collapse collapse show"
            aria-labelledby="panelsStayOpen-heading">

            <div class="accordion-body p-16">
              <p class="pb-2">
                <ng-template skeletonUi [skeletonUiType]="SkeletonLoaderType.SkeletonRectangleComponent"
                  [skeletonUiWidth]="'5vw'" [skeletonUiStyles]="{'margin-right': '0.3vw'}"></ng-template>
                <ng-template skeletonUi [skeletonUiType]="SkeletonLoaderType.SkeletonRectangleComponent"
                  [skeletonUiWidth]="'3vw'" [skeletonUiStyles]="{'margin-right': '0.3vw'}"></ng-template>
                <ng-template skeletonUi [skeletonUiType]="SkeletonLoaderType.SkeletonRectangleComponent"
                  [skeletonUiWidth]="'5vw'" [skeletonUiStyles]="{'margin-right': '0.3vw'}"></ng-template>
              </p>
              <p class="pb-2">
                <ng-template skeletonUi [skeletonUiType]="SkeletonLoaderType.SkeletonRectangleComponent"
                  [skeletonUiWidth]="'5vw'" [skeletonUiStyles]="{'margin-right': '0.3vw'}"></ng-template>
                <ng-template skeletonUi [skeletonUiType]="SkeletonLoaderType.SkeletonRectangleComponent"
                  [skeletonUiWidth]="'3vw'" [skeletonUiStyles]="{'margin-right': '0.3vw'}"></ng-template>
                <ng-template skeletonUi [skeletonUiType]="SkeletonLoaderType.SkeletonRectangleComponent"
                  [skeletonUiWidth]="'5vw'" [skeletonUiStyles]="{'margin-right': '0.3vw'}"></ng-template>
              </p>
              <p class="pb-2">
                <ng-template skeletonUi [skeletonUiType]="SkeletonLoaderType.SkeletonRectangleComponent"
                  [skeletonUiWidth]="'5vw'" [skeletonUiStyles]="{'margin-right': '0.3vw'}"></ng-template>
                <ng-template skeletonUi [skeletonUiType]="SkeletonLoaderType.SkeletonRectangleComponent"
                  [skeletonUiWidth]="'3vw'" [skeletonUiStyles]="{'margin-right': '0.3vw'}"></ng-template>
                <ng-template skeletonUi [skeletonUiType]="SkeletonLoaderType.SkeletonRectangleComponent"
                  [skeletonUiWidth]="'5vw'" [skeletonUiStyles]="{'margin-right': '0.3vw'}"></ng-template>
              </p>
              <p class="pb-2">
                <ng-template skeletonUi [skeletonUiType]="SkeletonLoaderType.SkeletonRectangleComponent"
                  [skeletonUiWidth]="'5vw'" [skeletonUiStyles]="{'margin-right': '0.3vw'}"></ng-template>
                <ng-template skeletonUi [skeletonUiType]="SkeletonLoaderType.SkeletonRectangleComponent"
                  [skeletonUiWidth]="'3vw'" [skeletonUiStyles]="{'margin-right': '0.3vw'}"></ng-template>
                <ng-template skeletonUi [skeletonUiType]="SkeletonLoaderType.SkeletonRectangleComponent"
                  [skeletonUiWidth]="'5vw'" [skeletonUiStyles]="{'margin-right': '0.3vw'}"></ng-template>
              </p>
            </div>

          </div>
        </div>
      </div>
      <!-- Get Direcxtory Details      -->
      <div class="accordion project-player-accordian lang-file">
        <div class="accordion-item project-player-accordian">
          <h2 class="accordion-header" id="panelsStayOpen-headingOne">
            <button
              class="accordion-button d-flex align-items-center justify-content-between heading-2  project-player-accordian-button"
              type="button" translate="projectBank.projectPlayer.languageFileHeading">
              Language & Files
              <span class="accordion-toggle-icon cursor-pointer ms-1" data-bs-target="#panelsStayOpen-collapseOne"
                aria-expanded="true" aria-controls="panelsStayOpen-collapseOne">
              </span>
            </button>
          </h2>
          <div id="panelsStayOpen-collapseOne" class="accordion-collapse collapse show"
            aria-labelledby="panelsStayOpen-headingOne">
            <div class="accordion-body px-2">
              <p class="pb-2">
                <ng-template skeletonUi [skeletonUiType]="SkeletonLoaderType.SkeletonRectangleComponent"
                  [skeletonUiWidth]="'5vw'" [skeletonUiStyles]="{'margin-right': '0.3vw'}"></ng-template>
                <ng-template skeletonUi [skeletonUiType]="SkeletonLoaderType.SkeletonRectangleComponent"
                  [skeletonUiWidth]="'3vw'" [skeletonUiStyles]="{'margin-right': '0.3vw'}"></ng-template>
                <ng-template skeletonUi [skeletonUiType]="SkeletonLoaderType.SkeletonRectangleComponent"
                  [skeletonUiWidth]="'5vw'" [skeletonUiStyles]="{'margin-right': '0.3vw'}"></ng-template>
              </p>
              <p class="pb-2">
                <ng-template skeletonUi [skeletonUiType]="SkeletonLoaderType.SkeletonRectangleComponent"
                  [skeletonUiWidth]="'5vw'" [skeletonUiStyles]="{'margin-right': '0.3vw'}"></ng-template>
                <ng-template skeletonUi [skeletonUiType]="SkeletonLoaderType.SkeletonRectangleComponent"
                  [skeletonUiWidth]="'3vw'" [skeletonUiStyles]="{'margin-right': '0.3vw'}"></ng-template>
                <ng-template skeletonUi [skeletonUiType]="SkeletonLoaderType.SkeletonRectangleComponent"
                  [skeletonUiWidth]="'5vw'" [skeletonUiStyles]="{'margin-right': '0.3vw'}"></ng-template>
              </p>
              <p class="pb-2">
                <ng-template skeletonUi [skeletonUiType]="SkeletonLoaderType.SkeletonRectangleComponent"
                  [skeletonUiWidth]="'5vw'" [skeletonUiStyles]="{'margin-right': '0.3vw'}"></ng-template>
                <ng-template skeletonUi [skeletonUiType]="SkeletonLoaderType.SkeletonRectangleComponent"
                  [skeletonUiWidth]="'3vw'" [skeletonUiStyles]="{'margin-right': '0.3vw'}"></ng-template>
                <ng-template skeletonUi [skeletonUiType]="SkeletonLoaderType.SkeletonRectangleComponent"
                  [skeletonUiWidth]="'5vw'" [skeletonUiStyles]="{'margin-right': '0.3vw'}"></ng-template>
              </p>
              <p class="pb-2">
                <ng-template skeletonUi [skeletonUiType]="SkeletonLoaderType.SkeletonRectangleComponent"
                  [skeletonUiWidth]="'5vw'" [skeletonUiStyles]="{'margin-right': '0.3vw'}"></ng-template>
                <ng-template skeletonUi [skeletonUiType]="SkeletonLoaderType.SkeletonRectangleComponent"
                  [skeletonUiWidth]="'3vw'" [skeletonUiStyles]="{'margin-right': '0.3vw'}"></ng-template>
                <ng-template skeletonUi [skeletonUiType]="SkeletonLoaderType.SkeletonRectangleComponent"
                  [skeletonUiWidth]="'5vw'" [skeletonUiStyles]="{'margin-right': '0.3vw'}"></ng-template>
              </p>

            </div>
          </div>
        </div>
      </div>
      <!-- instructions -->
      <div class="project-clear-instructions">

        <div class="px-16 pt-16 d-flex">
          <img src="../../../../../assets/images/pop-up.svg" class="me-2" alt="">
          <p class="body-bold-assistive-text-orange cursor-pointer">Project Details</p>
        </div>
        <div class="project-guide p-16">
          <nav class="mb-16">
            <p class="pb-2 pt-2">
              <ng-template skeletonUi [skeletonUiType]="SkeletonLoaderType.SkeletonRectangleComponent"
                [skeletonUiWidth]="'5vw'" [skeletonUiStyles]="{'margin-right': '0.3vw'}"></ng-template>
              <ng-template skeletonUi [skeletonUiType]="SkeletonLoaderType.SkeletonRectangleComponent"
                [skeletonUiWidth]="'3vw'" [skeletonUiStyles]="{'margin-right': '0.3vw'}"></ng-template>
              <ng-template skeletonUi [skeletonUiType]="SkeletonLoaderType.SkeletonRectangleComponent"
                [skeletonUiWidth]="'5vw'" [skeletonUiStyles]="{'margin-right': '0.3vw'}"></ng-template>
            </p>
            <p class="pb-2">
              <ng-template skeletonUi [skeletonUiType]="SkeletonLoaderType.SkeletonRectangleComponent"
                [skeletonUiWidth]="'5vw'" [skeletonUiStyles]="{'margin-right': '0.3vw'}"></ng-template>
              <ng-template skeletonUi [skeletonUiType]="SkeletonLoaderType.SkeletonRectangleComponent"
                [skeletonUiWidth]="'3vw'" [skeletonUiStyles]="{'margin-right': '0.3vw'}"></ng-template>
              <ng-template skeletonUi [skeletonUiType]="SkeletonLoaderType.SkeletonRectangleComponent"
                [skeletonUiWidth]="'5vw'" [skeletonUiStyles]="{'margin-right': '0.3vw'}"></ng-template>
            </p>
            <p class="pb-2">
              <ng-template skeletonUi [skeletonUiType]="SkeletonLoaderType.SkeletonRectangleComponent"
                [skeletonUiWidth]="'5vw'" [skeletonUiStyles]="{'margin-right': '0.3vw'}"></ng-template>
              <ng-template skeletonUi [skeletonUiType]="SkeletonLoaderType.SkeletonRectangleComponent"
                [skeletonUiWidth]="'3vw'" [skeletonUiStyles]="{'margin-right': '0.3vw'}"></ng-template>
              <ng-template skeletonUi [skeletonUiType]="SkeletonLoaderType.SkeletonRectangleComponent"
                [skeletonUiWidth]="'5vw'" [skeletonUiStyles]="{'margin-right': '0.3vw'}"></ng-template>
            </p>
          </nav>
          <section id="statement" class="mb-16">
            <h4 class="big-bold-assistive-text mb-16">Statement</h4>
            <div class="body-text"></div>
          </section>
        </div>

      </div>
    </div>
  </div>
  <div class="right-content col-9 abc border-right-0">
    <div class="row">
      <div class="heading-1 d-flex align-items-center col-6">
        <P>
          <ng-template skeletonUi [skeletonUiType]="SkeletonLoaderType.SkeletonRectangleComponent"
            [skeletonUiWidth]="'7vw'"></ng-template>
        </P><span class="test ml-16">
          <ng-template skeletonUi [skeletonUiType]="SkeletonLoaderType.SkeletonRectangleComponent"
            [skeletonUiWidth]="'2vw'"></ng-template><span>
            <ng-template skeletonUi [skeletonUiType]="SkeletonLoaderType.SkeletonRectangleComponent"
              [skeletonUiWidth]="'2vw'"></ng-template>
          </span>
        </span>
      </div>
      <div class="col-6 d-flex justify-content-end ">
        <div class="d-flex cursor-pointer ">
          <img clas="pt-2 pe-2" src="../../../../../assets/images/viewSolution.svg" alt="">
          <p class="pt-2 ps-2 body-bold-assistive-text-orange">View</p>
        </div>
        <div class="dropdown flat-btn ml-16 output-dropdown">
          <span class="dropdown-toggle cursor-pointer d-flex" data-bs-toggle="dropdown" aria-expanded="false">
            <img class="me-1" src="../../../../assets/images/output.svg">
            <p translate="projectBank.projectPlayer.tryProject.output"></p>Output
          </span>
        </div>
      </div>
    </div>
    <div class="pt-16">
      <div class="fgh">
        <div>
          <ng-template skeletonUi [skeletonUiType]="SkeletonLoaderType.SkeletonRectangleComponent"
            [skeletonUiWidth]="'17vw'"
            [skeletonUiStyles]="{'margin-right': '1vw','margin-top':'3vh','margin-bottom':'3vh','margin-left':'2vh'}">
          </ng-template>
          <ng-template skeletonUi [skeletonUiType]="SkeletonLoaderType.SkeletonRectangleComponent"
            [skeletonUiWidth]="'2vw'"
            [skeletonUiStyles]="{'margin-right': '3vw','margin-top':'3vh','margin-bottom':'3vh'}"></ng-template>
        </div>
      </div>
      <div class="xcv pl-24">
        <ng-template skeletonUi [skeletonUiType]="SkeletonLoaderType.SkeletonRectangleComponent"
          [skeletonUiWidth]="'17vw'"
          [skeletonUiStyles]="{'margin-right': '1vw','margin-top':'3vh','margin-bottom':'1vh'}"></ng-template>
        <ng-template skeletonUi [skeletonUiType]="SkeletonLoaderType.SkeletonRectangleComponent"
          [skeletonUiWidth]="'15vw'"
          [skeletonUiStyles]="{'margin-right': '1vw','margin-top':'3vh','margin-bottom':'1vh'}"></ng-template>
        <ng-template skeletonUi [skeletonUiType]="SkeletonLoaderType.SkeletonRectangleComponent"
          [skeletonUiWidth]="'4vw'"
          [skeletonUiStyles]="{'margin-right': '1vw','margin-top':'3vh','margin-bottom':'1vh'}"></ng-template>
        <ng-template skeletonUi [skeletonUiType]="SkeletonLoaderType.SkeletonRectangleComponent"
          [skeletonUiWidth]="'13vw'"
          [skeletonUiStyles]="{'margin-right': '1vw','margin-top':'3vh','margin-bottom':'1vh'}"></ng-template>

        <div>
          <ng-template skeletonUi [skeletonUiType]="SkeletonLoaderType.SkeletonRectangleComponent"
            [skeletonUiWidth]="'7vw'" [skeletonUiStyles]="{'margin-right': '1vw','margin-bottom':'1vh'}"></ng-template>
          <ng-template skeletonUi [skeletonUiType]="SkeletonLoaderType.SkeletonRectangleComponent"
            [skeletonUiWidth]="'18vw'" [skeletonUiStyles]="{'margin-right': '1vw','margin-bottom':'1vh'}"></ng-template>
          <ng-template skeletonUi [skeletonUiType]="SkeletonLoaderType.SkeletonRectangleComponent"
            [skeletonUiWidth]="'13vw'" [skeletonUiStyles]="{'margin-right': '1vw','margin-bottom':'1vh'}"></ng-template>
          <ng-template skeletonUi [skeletonUiType]="SkeletonLoaderType.SkeletonRectangleComponent"
            [skeletonUiWidth]="'12vw'" [skeletonUiStyles]="{'margin-right': '1vw','margin-bottom':'1vh'}"></ng-template>
        </div>
        <div>
          <ng-template skeletonUi [skeletonUiType]="SkeletonLoaderType.SkeletonRectangleComponent"
            [skeletonUiWidth]="'19vw'" [skeletonUiStyles]="{'margin-right': '1vw','margin-bottom':'1vh'}"></ng-template>
          <ng-template skeletonUi [skeletonUiType]="SkeletonLoaderType.SkeletonRectangleComponent"
            [skeletonUiWidth]="'8vw'" [skeletonUiStyles]="{'margin-right': '1vw','margin-bottom':'1vh'}"></ng-template>
          <ng-template skeletonUi [skeletonUiType]="SkeletonLoaderType.SkeletonRectangleComponent"
            [skeletonUiWidth]="'12vw'" [skeletonUiStyles]="{'margin-right': '1vw','margin-bottom':'1vh'}"></ng-template>
          <ng-template skeletonUi [skeletonUiType]="SkeletonLoaderType.SkeletonRectangleComponent"
            [skeletonUiWidth]="'6vw'" [skeletonUiStyles]="{'margin-right': '1vw','margin-bottom':'1vh'}"></ng-template>
        </div>
        <div>
          <ng-template skeletonUi [skeletonUiType]="SkeletonLoaderType.SkeletonRectangleComponent"
            [skeletonUiWidth]="'13vw'" [skeletonUiStyles]="{'margin-right': '1vw','margin-bottom':'1vh'}"></ng-template>
          <ng-template skeletonUi [skeletonUiType]="SkeletonLoaderType.SkeletonRectangleComponent"
            [skeletonUiWidth]="'7vw'" [skeletonUiStyles]="{'margin-right': '1vw','margin-bottom':'1vh'}"></ng-template>
          <ng-template skeletonUi [skeletonUiType]="SkeletonLoaderType.SkeletonRectangleComponent"
            [skeletonUiWidth]="'17vw'" [skeletonUiStyles]="{'margin-right': '1vw','margin-bottom':'1vh'}"></ng-template>
          <ng-template skeletonUi [skeletonUiType]="SkeletonLoaderType.SkeletonRectangleComponent"
            [skeletonUiWidth]="'8vw'" [skeletonUiStyles]="{'margin-right': '1vw','margin-bottom':'1vh'}"></ng-template>
        </div>
        <div>
          <ng-template skeletonUi [skeletonUiType]="SkeletonLoaderType.SkeletonRectangleComponent"
            [skeletonUiWidth]="'17vw'" [skeletonUiStyles]="{'margin-right': '1vw','margin-bottom':'1vh'}"></ng-template>
          <ng-template skeletonUi [skeletonUiType]="SkeletonLoaderType.SkeletonRectangleComponent"
            [skeletonUiWidth]="'9vw'" [skeletonUiStyles]="{'margin-right': '1vw','margin-bottom':'1vh'}"></ng-template>
          <ng-template skeletonUi [skeletonUiType]="SkeletonLoaderType.SkeletonRectangleComponent"
            [skeletonUiWidth]="'10vw'" [skeletonUiStyles]="{'margin-right': '1vw','margin-bottom':'1vh'}"></ng-template>
          <ng-template skeletonUi [skeletonUiType]="SkeletonLoaderType.SkeletonRectangleComponent"
            [skeletonUiWidth]="'13vw'" [skeletonUiStyles]="{'margin-right': '1vw','margin-bottom':'1vh'}"></ng-template>
        </div>
        <div>
          <ng-template skeletonUi [skeletonUiType]="SkeletonLoaderType.SkeletonRectangleComponent"
            [skeletonUiWidth]="'17vw'" [skeletonUiStyles]="{'margin-right': '1vw','margin-bottom':'1vh'}"></ng-template>
          <ng-template skeletonUi [skeletonUiType]="SkeletonLoaderType.SkeletonRectangleComponent"
            [skeletonUiWidth]="'2vw'" [skeletonUiStyles]="{'margin-right': '1vw','margin-bottom':'1vh'}"></ng-template>
          <ng-template skeletonUi [skeletonUiType]="SkeletonLoaderType.SkeletonRectangleComponent"
            [skeletonUiWidth]="'2vw'" [skeletonUiStyles]="{'margin-right': '1vw','margin-bottom':'1vh'}"></ng-template>
          <ng-template skeletonUi [skeletonUiType]="SkeletonLoaderType.SkeletonRectangleComponent"
            [skeletonUiWidth]="'13vw'" [skeletonUiStyles]="{'margin-right': '1vw','margin-bottom':'1vh'}"></ng-template>
          <ng-template skeletonUi [skeletonUiType]="SkeletonLoaderType.SkeletonRectangleComponent"
            [skeletonUiWidth]="'9vw'" [skeletonUiStyles]="{'margin-right': '1vw','margin-bottom':'1vh'}"></ng-template>
        </div>
      </div>
    </div>


  </div>
  <div class="col-3 third-component">
    <!-- Test Case Accordian -->
    <div class="mb-16">
      <div class="accordion">
        <div class="accordion-item project-player-accordian">
          <h2 class="accordion-header">
            <button
              class="accordion-button d-flex align-items-center justify-content-between heading-2  project-player-accordian-button"
              type="button">Test Cases
              <span class="accordion-toggle-icon cursor-pointer ms-1" data-bs-target="#uniqueAccordionCollapse1"
                aria-expanded="true" aria-controls="uniqueAccordionCollapse1">
              </span>
            </button>
          </h2>
          <div id="uniqueAccordionCollapse1" class="accordion-collapse collapse"
            aria-labelledby="uniqueAccordionHeading1">

            <div class="accordion-body test-case-accordian p-0">

            </div>

          </div>
        </div>
      </div>
    </div>
    <!-- Rubric Accordian -->
    <div class="mb-16">
      <div class="accordion project-player-accordian lang-file">
        <div class="accordion-item project-player-accordian">
          <h2 class="accordion-header">
            <button
              class="accordion-button d-flex align-items-center justify-content-between heading-2  project-player-accordian-button"
              type="button">Rubric
              <span class="accordion-toggle-icon cursor-pointer ms-1" data-bs-target="#uniqueAccordionCollapse2"
                aria-expanded="true" aria-controls="uniqueAccordionCollapse2">
              </span>
            </button>
          </h2>
          <div id="uniqueAccordionCollapse2" class="accordion-collapse collapse"
            aria-labelledby="uniqueAccordionHeading2">
            <div class="accordion-body p-0">
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Total Marks & Feedback -->
    <div class="accordion">
      <div class="accordion-item project-player-accordian">
        <h2 class="accordion-header">
          <button
            class="accordion-button d-flex align-items-center justify-content-between heading-2  project-player-accordian-button"
            type="button">Total Marks & Feedback
            <span class="accordion-toggle-icon cursor-pointer ms-1" data-bs-target="#uniqueAccordionCollapse3"
              aria-expanded="true" aria-controls="uniqueAccordionCollapse3">
            </span>
          </button>
        </h2>
        <div id="uniqueAccordionCollapse3" class="accordion-collapse collapse show"
          aria-labelledby="uniqueAccordionHeading3">

          <div class="accordion-body total-mark-accordian p-0">

            <div class="py-3">
              <div class="px-3">
                <p class="pb-2">
                  <ng-template skeletonUi [skeletonUiType]="SkeletonLoaderType.SkeletonRectangleComponent"
                    [skeletonUiWidth]="'5vw'" [skeletonUiStyles]="{'margin-right': '0.3vw'}"></ng-template>
                  <ng-template skeletonUi [skeletonUiType]="SkeletonLoaderType.SkeletonRectangleComponent"
                    [skeletonUiWidth]="'3vw'" [skeletonUiStyles]="{'margin-right': '0.3vw'}"></ng-template>
                  <ng-template skeletonUi [skeletonUiType]="SkeletonLoaderType.SkeletonRectangleComponent"
                    [skeletonUiWidth]="'5vw'" [skeletonUiStyles]="{'margin-right': '0.3vw'}"></ng-template>
                </p>
                <p class="pb-2">
                  <ng-template skeletonUi [skeletonUiType]="SkeletonLoaderType.SkeletonRectangleComponent"
                    [skeletonUiWidth]="'5vw'" [skeletonUiStyles]="{'margin-right': '0.3vw'}"></ng-template>
                  <ng-template skeletonUi [skeletonUiType]="SkeletonLoaderType.SkeletonRectangleComponent"
                    [skeletonUiWidth]="'3vw'" [skeletonUiStyles]="{'margin-right': '0.3vw'}"></ng-template>
                  <ng-template skeletonUi [skeletonUiType]="SkeletonLoaderType.SkeletonRectangleComponent"
                    [skeletonUiWidth]="'5vw'" [skeletonUiStyles]="{'margin-right': '0.3vw'}"></ng-template>
                </p>
                <p class="pb-2">
                  <ng-template skeletonUi [skeletonUiType]="SkeletonLoaderType.SkeletonRectangleComponent"
                    [skeletonUiWidth]="'5vw'" [skeletonUiStyles]="{'margin-right': '0.3vw'}"></ng-template>
                  <ng-template skeletonUi [skeletonUiType]="SkeletonLoaderType.SkeletonRectangleComponent"
                    [skeletonUiWidth]="'3vw'" [skeletonUiStyles]="{'margin-right': '0.3vw'}"></ng-template>
                  <ng-template skeletonUi [skeletonUiType]="SkeletonLoaderType.SkeletonRectangleComponent"
                    [skeletonUiWidth]="'5vw'" [skeletonUiStyles]="{'margin-right': '0.3vw'}"></ng-template>
                </p>
                <p class="pb-2">
                  <ng-template skeletonUi [skeletonUiType]="SkeletonLoaderType.SkeletonRectangleComponent"
                    [skeletonUiWidth]="'5vw'" [skeletonUiStyles]="{'margin-right': '0.3vw'}"></ng-template>
                  <ng-template skeletonUi [skeletonUiType]="SkeletonLoaderType.SkeletonRectangleComponent"
                    [skeletonUiWidth]="'3vw'" [skeletonUiStyles]="{'margin-right': '0.3vw'}"></ng-template>
                  <ng-template skeletonUi [skeletonUiType]="SkeletonLoaderType.SkeletonRectangleComponent"
                    [skeletonUiWidth]="'5vw'" [skeletonUiStyles]="{'margin-right': '0.3vw'}"></ng-template>
                </p>
                <div class="px-16 pt-16 d-flex justify-content-center align-items-center">
                  <button type="button" class="btn btn-secondary-orange" disabled>Submit Marks &
                    Feedback</button>
                </div>
              </div>
            </div>

          </div>

        </div>
      </div>
    </div>

  </div>

</div>
<div class="d-flex justify-content-end footerBtn">
  <button type="button" class="btn btn-secondary-grey mx-4 px-5" disabled>Exit Evaluation</button>
  <button type="button" class="btn btn-primary" disabled>Complete Exercise Evaluation</button>
</div>