export enum RoutingParams {
  DASHBOARD = 'dashboard',
  EXERCISE = 'exercise',
  PROJECT_BANK = 'project-bank',
  LEARNER = 'learner',
  VIEW_PROJECT = 'view-project',
  MANIPULATE_PROJECT = 'manipulate-project',
  TRY_PROJECT = 'try-project',
  VIEW_EXERCISE = 'view-exercise',
  MANIPULATE_EXERCISE = "manipulate-exercise",
  LEARNER_ATTEMPT = 'learner-attempt',
  EVALUATION = 'evaluation',
  SELFPRACTICE = 'selfpractice',
  LUMEN_INTEGRATION = 'lumen-integration',
  EVALUATE_ATTEMPT = 'evaluate-attempt',
  SELFPRACTISE = 'selfpractise',
  EVALUATE_SUMMARY = 'evaluate-summary',
  LUMEN = 'lumen'
}