import { Component, EventEmitter, Output } from '@angular/core';
import { ProjectPlayerService } from '../../modules/player/project-player.service';
import { ProjectPlayerModes, content } from 'src/app/enums/PlayerModes';
import { AddContent, IRenameDirectory } from 'src/app/models/projectInterface';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { CommonService, ErrorType } from 'src/app/services/common.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-directory-creation',
  templateUrl: './directory-creation.component.html',
  styleUrls: ['./directory-creation.component.scss']
})
export class DirectoryCreationComponent {
  name = '';
  @Output() addDirectory = new EventEmitter;
  projectPlayerService!: ProjectPlayerService;
  kind!: string;
  contentMode = content.CREATE;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  selectedDirectory!: any;
  selectedArchType!: string;
  mode!: ProjectPlayerModes;
  @Output() editDirectory = new EventEmitter;
  unsubscribeAddFileOrDirectory$!: Subscription;
  validationUnSubscription$!: Subscription;
  unsubscribeRenameFileNameOrDirectoryName$!: Subscription;
  title!: string;

  constructor(public activeModal: NgbActiveModal, public commonService: CommonService) { }

  ngOnInit() {
    this.title = `${this.contentMode} ${this.kind} `;
  }

  ngAfterViewInit() {
    if (this.contentMode === content.EDIT) {
      this.name = this.selectedDirectory.name;
    }
  }

  validationListener() {
    this.validationUnSubscription$ = this.projectPlayerService.listenValidationError().subscribe((payload: { message: string }) => {
      if (['Error create directory: directory with Same Name Exist.', 'Error create file: file with Same Name Exist.'].includes(payload.message)) {
        this.commonService.showToast('please enter different Title', ErrorType.ERROR);
      }
      this.unSubscribeListeners();
    });
  }

  saveContent() {
    this.validationListener();
    if (this.contentMode === content.CREATE) {
      this.createContent();
    }
    else {
      this.editContent();
    }
  }

  createContent() {
    const newFileOrDirectory = {
      kind: this.kind,
      name: this.name,
      path: '',
      isSystemGenerated: false
    };
    newFileOrDirectory.path = `${this.selectedDirectory.path}/${newFileOrDirectory.name}`;
    const obj: AddContent = {
      kind: newFileOrDirectory.kind,
      path: newFileOrDirectory.path,
      archetypeId: this.selectedArchType,
      [this.mode !== ProjectPlayerModes.PRACTISE ? 'projectId' : 'practiceId']: this.projectPlayerService.projectId,
      ...(this.projectPlayerService.exerciseIdTry && { exerciseId: this.projectPlayerService.exerciseIdTry }),
    };
    this.unsubscribeAddFileOrDirectory$ = this.projectPlayerService.addFileOrDirectory(obj).subscribe(() => {
      this.addDirectory.emit(newFileOrDirectory);
      this.unSubscribeListeners();
    });
  }

  editContent() {
    const updateFileOrDirectory = {
      name: this.name,
      path: ''
    };
    updateFileOrDirectory.path = this.selectedDirectory.path;
    const obj: IRenameDirectory = {
      path: updateFileOrDirectory.path,
      archetypeId: this.selectedArchType,
      newName: this.name,
      ...(this.projectPlayerService.exerciseIdTry && { exerciseId: this.projectPlayerService.exerciseIdTry }),
      [this.mode !== ProjectPlayerModes.PRACTISE ? 'projectId' : 'practiceId']: this.projectPlayerService.projectId
    };
    this.unsubscribeRenameFileNameOrDirectoryName$ = this.projectPlayerService.renameFileFromTree(obj).subscribe(() => {
      this.editDirectory.emit(updateFileOrDirectory);
      this.unSubscribeListeners();
    });
  }

  unSubscribeListeners() {
    if (this.unsubscribeAddFileOrDirectory$) {
      this.unsubscribeAddFileOrDirectory$.unsubscribe();
    }
    if (this.unsubscribeRenameFileNameOrDirectoryName$) {
      this.unsubscribeRenameFileNameOrDirectoryName$.unsubscribe();
    }
    this.validationUnSubscription$.unsubscribe();
  }

}
