import { Component, ElementRef } from '@angular/core';
import { SkeletonComponent } from 'src/app/base-class/skeleton-class';

@Component({
  selector: 'app-modal-skeleton',
  templateUrl: './modal-skeleton.component.html',
  styleUrls: ['./modal-skeleton.component.scss']
})
export class ModalSkeletonComponent extends SkeletonComponent {
  constructor(override host: ElementRef<HTMLElement>) {
    super(host);
  }
  ngOnInit() {
    this.setStylesAndClass();
  }

}
