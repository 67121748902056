<div class="content-container p-24">

  <div class="d-flex align-items-center justify-content-between">
    <div class="page-title pb-2 mb-0">
      Programming Lab
    </div>
    <div class="d-flex align-items-center">
      <button *ngIf="userRole === userRoles.FACULTY" type="button" class="btn btn-secondary-orange-md"
        [routerLink]="['/selfpractise']" queryParamsHandling="preserve">Start
        Self-Practice
      </button>
      <button class="btn btn-primary-md ml-24" (click)="createNewExerciseModal()">
        Create New
      </button>
    </div>
  </div>

  <div class="tab-section mt-24">
    <ul class="nav nav-tabs" id="myTab" role="tablist">
      <li class="nav-item" role="presentation">
        <button class="nav-link active" id="assignment-tab" data-bs-toggle="tab" data-bs-target="#assignment-tab-pane"
          type="button" role="tab" aria-controls="assignment-tab-pane" aria-selected="true"
          (click)="getExerciseTypeList(ExerciseType.ASSIGNMENT)">Assignment/IA</button>
      </li>
      <li class="nav-item" role="presentation">
        <button class="nav-link" id="quiz-tab" data-bs-toggle="tab" data-bs-target="#quiz-tab-pane" type="button"
          role="tab" aria-controls="quiz-tab-pane" aria-selected="false"
          (click)="getExerciseTypeList(ExerciseType.QUIZ)">Quiz/TEE</button>
      </li>
      <li class="nav-item" role="presentation">
        <button class="nav-link" id="learner-tab" data-bs-toggle="tab" data-bs-target="#learner-tab-pane" type="button"
          role="tab" aria-controls="learner-tab-pane" aria-selected="false"
          (click)="getExerciseTypeList(ExerciseType.PRACTICE)"> Assigned
          Practice</button>
      </li>
    </ul>
    <ng-template #tabContent>
      <div class="row my-24">
        <div class="col-6">
          <div class="input-group search-field">
            <input type="text" class="form-control inputField" placeholder="Search..." aria-label="Search..."
              aria-describedby="search-btn" [(ngModel)]="searchString">
            <span class="clear-button" *ngIf="searchString!==''">
              <svg-icon (click)="clearSearch()" src="../../../assets/images/self-practice/cross.svg">
              </svg-icon>
            </span>
            <button class="btn search-btn" type="button" id="search-btn" (click)="searchExercise()">
              <img src="../assets/images/search.svg">
            </button>
          </div>
        </div>
        <div class="col-6">

          <div class="action-container d-flex align-items-center justify-content-end">
            <!--  <div class="records">
                              Records per page
                            </div> -->
            <ng-select class="sort-ng-select mx-24" placeholder="Sort" [items]="sortOptions" bindLabel="name"
              bindValue="value" [multiple]="false" [searchable]="false" (change)="setSortOption()"
              [(ngModel)]="selectSortOption" (clear)="clearSort()">
            </ng-select>

            <div class="filter-dropdown mr-24">
              <div class="dropdown">
                <div class="dropdown-toggle dropdown-filter-toggle" id="dropdownMenuClickableInside"
                  data-bs-toggle="dropdown" data-bs-auto-close="outside" aria-expanded="false">
                  <span *ngIf="filterOptionSelected">
                    <svg-icon src="../../../assets/images/red_Dot.svg"></svg-icon>
                  </span>
                  Filter
                </div>
                <ul class="dropdown-menu" aria-labelledby="dropdownMenuClickableInside">
                  <li class="dropdown-submenu" *ngFor="let data of  allFilters"><a
                      class="dropdown-item dropdown-toggle body-sub-text">{{data.title | titlecase}}</a>
                    <ul class="dropdown-menu first-level-menu">
                      <li>
                        <a class="dropdown-item body-sub-text">
                          <div class="form-check filter-select" *ngFor="let item of data.titleValue">
                            <input class="form-check-input" type="checkbox" [id]="item.name"
                              (click)="onSelectFilter($any($event.target).checked,item.value, data.title)">
                            <label class="form-check-label" [for]="item.name">
                              {{ item.name }}
                            </label>
                          </div>
                        </a>
                      </li>
                    </ul>
                  </li>
                </ul>
              </div>
            </div>
            <!-- <ul class="filter-dropdown mr-24">
              <li class="nav-item dropdown">
                <a class="nav-link dropdown-toggle dropdown-filter-toggle" id="navbarDropdownMenuLink"
                  data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                  <span *ngIf="filterOptionSelected"><svg-icon src="../../../assets/images/red_Dot.svg"></svg-icon></span>
                  Filter
                </a>
                <ul class="dropdown-menu first-level-menu" aria-labelledby="navbarDropdownMenuLink">
                  <li class="dropdown-submenu" *ngFor="let data of  allFilters"><a
                      class="dropdown-item dropdown-toggle body-sub-text">{{data.title | titlecase}}</a>
                    <ul class="dropdown-menu">
                      <li><a class="dropdown-item body-sub-text">
                          <div class="form-check filter-select" *ngFor="let item of data.titleValue">
                            <input class="form-check-input" type="checkbox" [id]="item.name"
                              (click)="onSelectFilter($any($event.target).checked,item.value, data.title)">
                            <label class="form-check-label" [for]="item.name">
                              {{ item.name }}
                            </label>
                          </div>
                        </a></li>
                    </ul>
                  </li>
                </ul>
              </li>
            </ul> -->
            <div class="body-text">
              Total number of records: <span>{{totalExercise}}</span>
            </div>

          </div>
        </div>
      </div>
      <scrollbar class="scrollbar" appInfiniteScroll (scrolled)="onScrollDown()">
        <div class="add-scroll">
          <div class="project-bank-list">
            <ng-container *ngIf="!loadingSkeleton; else loadSkeleton">
              <ng-container *ngIf="exerciseList.length>0; else show">
                <div *ngFor="let exerciseListCardItem of exerciseList">
                  <app-exercise-list-card [exerciseListCardItem]="exerciseListCardItem"
                    (clonedExerciseEmitter)="appendClonedExerciseToList($event)"
                    (deletedExercise)="removeExercise($event)" [displayMode]="ExerciseListCardModes.LISTING">
                  </app-exercise-list-card>
                </div>
              </ng-container>
              <ng-template #show>
                <div class="no-exercise pt-24">
                  <svg-icon src="../../../assets/images/no-exercise.svg"></svg-icon>
                </div>
                <div>
                  <p class="no-exercise heading-1">Looks Like there are no exercise</p>
                  <p class="no-exercise heading-1">as per selected filters</p>
                </div>
              </ng-template>
            </ng-container>
            <ng-template #loadSkeleton>
              <ng-template skeletonUi [skeletonUiType]="SkeletonLoaderType.ProjectListCardSkeletonComponent"
                [skeletonUiRepeat]="5">
              </ng-template>
            </ng-template>
          </div>
        </div>
      </scrollbar>
    </ng-template>
    <div class="tab-content" id="myTabContent">
      <ng-container [ngTemplateOutlet]="tabContent"></ng-container>
    </div>
  </div>
</div>