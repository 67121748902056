/* eslint-disable @typescript-eslint/no-explicit-any */
import { Directive, Input, ViewContainerRef } from '@angular/core';
import { ProjectListCardSkeletonComponent } from '../shared/components/skeleton-ui/project-list-card-skeleton/project-list-card-skeleton.component';
import { SkeletonLoaderType } from '../enums/skeletonui';
import { SkeletonRectangleComponent } from '../shared/components/skeleton-ui/skeleton-rectangle/skeleton-rectangle.component';
import { ConsoleSkeletonComponent } from '../shared/components/skeleton-ui/console-skeleton/console-skeleton.component';
import { ModalSkeletonComponent } from '../shared/components/skeleton-ui/modal-skeleton/modal-skeleton.component';
import { EvaluationSkeletonComponent } from '../shared/components/skeleton-ui/evaluation-skeleton/evaluation-skeleton.component';
import { OverlaySkeletonComponent } from '../shared/components/skeleton-ui/overlay-skeleton/overlay-skeleton.component';
import { FileEditorSkeletonComponent } from '../shared/components/skeleton-ui/file-editor-skeleton/file-editor-skeleton.component';
import { ViewExerciseSkeletonComponent } from '../shared/components/skeleton-ui/view-exercise-skeleton/view-exercise-skeleton.component';

@Directive({
  selector: '[skeletonUi]'
})
export class SkeletonUiLoaderDirective {

  @Input('skeletonUiType') type!: string;
  @Input('skeletonUiRepeat') size = 1;
  @Input('skeletonUiWidth') width!: string;
  @Input('skeletonUiHeight') height!: string;
  @Input('skeletonUiClassName') className!: string;
  @Input('skeletonUiStyles') styles!: Record<string, string>;

  constructor(private vcr: ViewContainerRef) {

  }
  ngOnInit() {
    this.renderSkeleton();
  }
  renderSkeleton() {
    const customTheme = {
      ...(this.width && { width: this.width === 'random' ? `${(Math.random() * 90) + 30}%` : this.width }),
      ... (this.height && { height: this.height }),
      ...(this.className && { className: this.className }),
      ...(this.styles && { styles: this.styles })
    };
    const componentToGenerate: any = this.referenceToSkeletonComponent(this.type);
    if (componentToGenerate) {
      Array.from({ length: this.size }).forEach(() => {
        const ref: any = this.vcr.createComponent(componentToGenerate);

        Object.assign(ref.instance, customTheme);
      });
    }

  }
  referenceToSkeletonComponent(type: string) {
    switch (type) {
      case SkeletonLoaderType.ProjectListCardSkeletonComponent:
        return ProjectListCardSkeletonComponent;
      case SkeletonLoaderType.SkeletonRectangleComponent:
        return SkeletonRectangleComponent;
      case SkeletonLoaderType.ConsoleSkeletonComponent:
        return ConsoleSkeletonComponent;
      case SkeletonLoaderType.ModalSkeletonComponent:
        return ModalSkeletonComponent;
      case SkeletonLoaderType.EvaluationSkeletonComponent:
        return EvaluationSkeletonComponent;
      case SkeletonLoaderType.OverlaySkeletonComponent:
        return OverlaySkeletonComponent;
      case SkeletonLoaderType.FileEditorSkeletonComponent:
        return FileEditorSkeletonComponent;
      case SkeletonLoaderType.ViewExerciseSkeletonComponent:
        return ViewExerciseSkeletonComponent;
      default:
        return SkeletonRectangleComponent;
    }
  }
}
