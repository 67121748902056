import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ProjectBankRoutingModule } from './projectbank-routing.module';
import { ProjectBankComponent } from './project-bank/projectbank.component';
import { ViewProjectComponent } from './viewproject/viewproject.component';
import { ProjectModule } from './project/project.module';
import { ProjectBankService } from './project-bank.service';
import { CkEditorSharedModule } from 'src/app/shared/modules/ck-editor/ck-editor.module';
import { WrapperModule } from '../shared/modules/wrapper/wrapper.module';
import { PlayerModule } from "../shared/modules/player/player.module";
import { NgSelectModule } from '@ng-select/ng-select';
import { ProjectListCardModule } from '../shared/modules/project-list-card/project-list-card.module';
import { TryProjectModule } from '../shared/modules/try-project/try-project.module';

@NgModule({
  declarations: [
    ProjectBankComponent,
    ViewProjectComponent,
  ],
  providers: [ProjectBankService],
  imports: [
    CommonModule,
    ProjectBankRoutingModule,
    ProjectModule,
    TryProjectModule,
    CkEditorSharedModule,
    WrapperModule,
    PlayerModule,
    NgSelectModule,
    ProjectListCardModule
  ]
})
export class ProjectBankModule { }
