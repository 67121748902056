/* eslint-disable @typescript-eslint/no-empty-function */
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Archetype, DifficultyLevel, ProjectListCardItem } from 'src/app/models/project-list-card';
import { DialogService } from 'src/app/services/dialog.service';
import { ProjectBankService } from 'src/app/projectbank/project-bank.service';
import { deleteProj } from 'src/app/models/projectInterface';
import { DialogTypes } from 'src/app/enums/dialog';
import { Dialog } from 'src/app/models/dialog';
import { ProjectListCardModes } from 'src/app/enums/projectListCardMode';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { PreviewModalComponent } from 'src/app/exercise/modals/preview-modal/preview-modal.component';
import { RemoveProject } from 'src/app/models/exerciseInterface';
import { ExerciseBuilderService } from 'src/app/exercise/exercise-builder/exercise-builder.service';
import { take } from 'rxjs';

@Component({
  selector: 'app-projectlistcard',
  templateUrl: './projectlistcard.component.html',
  styleUrls: ['./projectlistcard.component.scss']
})
export class ProjectListCardComponent {
  formattedArchetypes: string[] = [];
  DifficultyLevel = DifficultyLevel;
  ProjectListCardModes = ProjectListCardModes;
  archeTypes!: Archetype[];
  filteredValues: string[] = [];
  remainingValuesCount!: number;
  countOfExercises!: number;
  @Input() projectListCardItem!: ProjectListCardItem;
  @Input() displayMode!: ProjectListCardModes;
  @Output() deletedProj = new EventEmitter;
  @Output() clonedProjectEmitter = new EventEmitter<ProjectListCardItem>();
  @Output() selected = new EventEmitter;

  // eslint-disable-next-line max-params
  constructor(private translationService: TranslateService, private dialogService: DialogService, private activatedRoute: ActivatedRoute, private router: Router, private projectBankService: ProjectBankService, private NgbModal: NgbModal, private exerciseBuilderService: ExerciseBuilderService) { }
  ngOnInit() {
    this.filteredLangAndArchtypes();
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    this.countOfExercises = this.projectListCardItem.countOfExercises!;
  }

  filteredLangAndArchtypes() {
    this.formattedArchetypes = this.formatUniqueArchetypes(this.projectListCardItem.archetypes);
    const valuesArray = this.formattedArchetypes[0].split(' , ');
    const finalArr = valuesArray.slice(0, 3);
    this.filteredValues = [finalArr.join('  ,  ')];
    this.remainingValuesCount = valuesArray.length - finalArr.length;
  }

  private formatUniqueArchetypes(archetypes: Archetype[]): string[] {
    if (archetypes.length === 0) {
      return ["No archetypes"];
    }
    const formattedArchetypes = archetypes.map((archetype) => {
      return `${archetype.language} > ${archetype.archetypeId}`;
    });
    return [formattedArchetypes.join('  ,  ')];
  }

  modelRef!: NgbModalRef;
  async deleteModal() {
    let message!: string;
    let note!: string;
    const obj: deleteProj = {
      projectId: this.projectListCardItem.projectId
    };
    this.translationService.get('projectBank.deleteProject', { type: DialogTypes.ERROR }).subscribe((translation) => {
      message = translation;
    }).unsubscribe();
    this.translationService.get('confirmModal.note', { itemName: this.projectListCardItem.title }).subscribe((translation) => {
      note = translation;
    }).unsubscribe();
    const dialog: Dialog = { title: { translationKey: message }, note: { translationKey: note } };
    const confirmation = await this.dialogService.showConfirmDialog(dialog);
    if (confirmation) {
      this.projectBankService.deleteProj(obj).subscribe(() => {
        this.deletedProj.emit(this.projectListCardItem.projectId);
      });
    }
  }

  tryProject() {
    this.archeTypes = this.projectListCardItem.archetypes;
    this.projectBankService.archTypes = this.archeTypes;
    const exerciseId = this.activatedRoute.snapshot.paramMap.get('exerciseId');
    //try project from view-exercise details screen
    if (this.displayMode === ProjectListCardModes.VIEW_EXERCISE_DETAILS) {
      this.router.navigate(['../../' + this.projectListCardItem.projectId + '/try-project'], { relativeTo: this.activatedRoute, queryParams: { exerciseId: exerciseId, tryProjectFrom: 'view_exercise' }, queryParamsHandling: 'merge' });
      return;
    }
    // try project from exercise-projects screen
    else if (this.displayMode === ProjectListCardModes.DRAG_DROP) {
      this.router.navigate(['../../' + this.projectListCardItem.projectId + '/try-project'], { relativeTo: this.activatedRoute, queryParams: { exerciseId: exerciseId, tryProjectFrom: 'exercise_projects' }, queryParamsHandling: 'merge' });
      return;
    }
    //try project from project bank
    this.router.navigate(['./' + this.projectListCardItem.projectId + '/try-project'], { relativeTo: this.activatedRoute, queryParams: { tryProjectFrom: 'project_bank' }, queryParamsHandling: 'merge' });
  }

  cloneProject() {
    this.cloneListeners();
    this.projectBankService.cloneProject(this.projectListCardItem.projectId).pipe(take(1)).subscribe((data) => {
      // eslint-disable-next-line no-console
      console.log(data);
    });
  }

  PreviewModel() {
    this.modelRef = this.NgbModal.open(PreviewModalComponent, { windowClass: 'createNewExercise-modal' });
    this.modelRef.componentInstance.projectBankService = this.projectBankService;
    this.modelRef.componentInstance.projectId = this.projectListCardItem.projectId;
    this.modelRef.componentInstance.closedStatus.subscribe((projectId: string) => {
      if (projectId) {
        this.selected.emit(projectId);
        this.modelRef.close();
      }
    });
  }

  cloneListeners() {
    const cloneMetaDataUnSubscribe = this.projectBankService.clonedProjectMetadataCreated().subscribe((clonedProjectPayload: ProjectListCardItem) => {
      this.clonedProjectEmitter.emit(clonedProjectPayload);
      cloneMetaDataUnSubscribe.unsubscribe();
    });
    this.projectBankService.projectCloneFailure().subscribe(() => { });
    this.projectBankService.metadataCreationFailure().subscribe(() => { });
    this.projectBankService.oldProjectPathNotFound().subscribe(() => { });
  }

  async removeProjectModal() {
    let message!: string;
    let note!: string;
    let button!: string;
    const removeProjectData: RemoveProject = {
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      exerciseId: this.activatedRoute.snapshot.paramMap.get('exerciseId')!,
      projects: [{
        projectId: this.projectListCardItem.projectId,
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        maxMarks: this.projectListCardItem.maxMarks!
      }],
    };
    this.translationService.get('exerciseBuilder.removeProject', { type: DialogTypes.ERROR }).subscribe((translation) => {
      message = translation;
    }).unsubscribe();
    this.translationService.get('confirmModal.note', { itemName: this.projectListCardItem.title }).subscribe((translation) => {
      note = translation;
    }).unsubscribe();
    this.translationService.get('exerciseBuilder.confirmButton').subscribe((translation) => {
      button = translation;
    }).unsubscribe();
    const dialog: Dialog = { title: { translationKey: message }, note: { translationKey: note }, button: { translationKey: button } };
    const confirmation = await this.dialogService.showConfirmDialog(dialog);
    if (confirmation) {
      this.exerciseBuilderService.removeProject(removeProjectData).subscribe(() => {
        this.deletedProj.emit(this.projectListCardItem.projectId);
      });
    }
  }

  editProject(mode = 'projectList') {
    const exerciseId = this.activatedRoute.snapshot.paramMap.get('exerciseId');
    const projectId = this.projectListCardItem.projectId;
    if (exerciseId) {
      if (mode === 'viewExerciseEdit') {
        this.router.navigate(['../../../project-bank/' + projectId + '/manipulate-project'], { relativeTo: this.activatedRoute, queryParams: { exerciseId: exerciseId, contentRoot: 'view_exercise' }, queryParamsHandling: 'merge' });
      }
      else {
        this.router.navigate(['../../../project-bank/' + projectId + '/manipulate-project'], { relativeTo: this.activatedRoute, queryParams: { exerciseId: exerciseId, contentRoot: 'exercise_projects' }, queryParamsHandling: 'merge' });
      }
    }
    else {
      this.router.navigate(['./' + projectId + '/manipulate-project'], { relativeTo: this.activatedRoute, queryParamsHandling: 'merge' });
    }
  }

}