<div class="content-container p-24">
  <div class="view-exercise-container">
    <div class="d-flex align-items-center justify-content-between mb-24">
      <div class="d-flex align-items-center">
        <ng-template skeletonUi [skeletonUiType]="SkeletonLoaderType.SkeletonRectangleComponent"
          [skeletonUiWidth]="'1vw'">
        </ng-template>
        <span class="page-title text-break pb-2 ms-2 mb-0">
          <ng-template skeletonUi [skeletonUiType]="SkeletonLoaderType.SkeletonRectangleComponent"
            [skeletonUiWidth]="'7vw'">
          </ng-template>
        </span>
      </div>
    </div>
    <div class="project-detail d-flex align-items-center p-2 mb-24">
      <div class="project-details">
        <h3 class="heading-3 mb-1">
          <ng-template skeletonUi [skeletonUiType]="SkeletonLoaderType.SkeletonRectangleComponent"
            [skeletonUiWidth]="'20vw'">
          </ng-template>
          <ng-template skeletonUi [skeletonUiType]="SkeletonLoaderType.SkeletonRectangleComponent"
            [skeletonUiWidth]="'7vw'" [skeletonUiStyles]="{'margin-left': '2vw'}">
          </ng-template>
        </h3>
        <div class="body-sub-text mb-16">
          <ng-template skeletonUi [skeletonUiType]="SkeletonLoaderType.SkeletonRectangleComponent"
            [skeletonUiWidth]="'12vw'" [skeletonUiStyles]="{'margin-right': '1vw'}" [skeletonUiRepeat]="3">
          </ng-template>
          <ng-template skeletonUi [skeletonUiType]="SkeletonLoaderType.SkeletonRectangleComponent"
            [skeletonUiWidth]="'2vw'" [skeletonUiStyles]="{'float': 'right','position':'relative', 'right':'-8.5vw' }">
          </ng-template>
        </div>
        <div class="d-flex align-items-center">
          <ng-template skeletonUi [skeletonUiType]="SkeletonLoaderType.SkeletonRectangleComponent"
            [skeletonUiWidth]="'3vw'">
          </ng-template>
          <ng-template skeletonUi [skeletonUiType]="SkeletonLoaderType.SkeletonRectangleComponent"
            [skeletonUiWidth]="'5vw'" [skeletonUiStyles]="{'margin-left': '1vw' }">
          </ng-template>
          <ng-template skeletonUi [skeletonUiType]="SkeletonLoaderType.SkeletonRectangleComponent"
            [skeletonUiWidth]="'10vw'" [skeletonUiStyles]="{'margin-left': '1vw' }">
          </ng-template>
          <ng-template skeletonUi [skeletonUiType]="SkeletonLoaderType.SkeletonRectangleComponent"
            [skeletonUiWidth]="'7vw'" [skeletonUiStyles]="{'margin-left': '1vw'}" [skeletonUiRepeat]="4">
          </ng-template>
        </div>
      </div>

    </div>
  </div>


  <div class="d-flex align-items-center justify-content-between mb-24">
    <div class="d-flex align-items-center">
      <ng-template skeletonUi [skeletonUiType]="SkeletonLoaderType.SkeletonRectangleComponent"
        [skeletonUiWidth]="'7vw'">
      </ng-template>
    </div>
  </div>

  <div class="projectlist-check">
    <div class="project-detail d-flex align-items-center p-2 mb-24">
      <div class="project-details">
        <h3 class="heading-3 mb-1">
          <ng-template skeletonUi [skeletonUiType]="SkeletonLoaderType.SkeletonRectangleComponent"
            [skeletonUiWidth]="'20vw'">
          </ng-template>
          <ng-template skeletonUi [skeletonUiType]="SkeletonLoaderType.SkeletonRectangleComponent"
            [skeletonUiWidth]="'7vw'" [skeletonUiStyles]="{'margin-left': '2vw'}">
          </ng-template>
        </h3>
        <div class="body-sub-text mb-16">
          <ng-template skeletonUi [skeletonUiType]="SkeletonLoaderType.SkeletonRectangleComponent"
            [skeletonUiWidth]="'12vw'" [skeletonUiStyles]="{'margin-right': '1vw'}" [skeletonUiRepeat]="3">
          </ng-template>
          <ng-template skeletonUi [skeletonUiType]="SkeletonLoaderType.SkeletonRectangleComponent"
            [skeletonUiWidth]="'2vw'" [skeletonUiStyles]="{'float': 'right','position':'relative', 'right':'-8.5vw' }">
          </ng-template>
          <!-- <ngx-skeleton-loader count="3"
        [theme]="{ 'border-radius': '0','width':'12vw','margin-right':'1vw' }"></ngx-skeleton-loader> -->
          <!-- <ngx-skeleton-loader [theme]="{ 'border-radius': '0','width':'2vw', 'float': 'right' }"></ngx-skeleton-loader> -->
        </div>
        <div class="d-flex align-items-center">
          <div class="d-flex align-items-center">

            <ng-template skeletonUi [skeletonUiType]="SkeletonLoaderType.SkeletonRectangleComponent"
              [skeletonUiWidth]="'3vw'">
            </ng-template>
            <ng-template skeletonUi [skeletonUiType]="SkeletonLoaderType.SkeletonRectangleComponent"
              [skeletonUiWidth]="'5vw'" [skeletonUiStyles]="{'margin-left': '1vw' }">
            </ng-template>
            <ng-template skeletonUi [skeletonUiType]="SkeletonLoaderType.SkeletonRectangleComponent"
              [skeletonUiWidth]="'10vw'" [skeletonUiStyles]="{'margin-left': '1vw' }">
            </ng-template>
            <ng-template skeletonUi [skeletonUiType]="SkeletonLoaderType.SkeletonRectangleComponent"
              [skeletonUiWidth]="'7vw'" [skeletonUiStyles]="{'margin-left': '1vw'}" [skeletonUiRepeat]="4">
            </ng-template>

            <!-- <ngx-skeleton-loader [theme]="{ 'border-radius' : '0' ,'width':'3vw' }"></ngx-skeleton-loader> -->
            <!-- <ngx-skeleton-loader
          [theme]="{ 'border-radius' : '0' ,'width':'5vw', 'margin-left': '1vw' }"></ngx-skeleton-loader> -->
            <!-- <ngx-skeleton-loader
          [theme]="{ 'border-radius' : '0' ,'width':'10vw', 'margin-left': '1vw' }"></ngx-skeleton-loader> -->
            <!-- <ngx-skeleton-loader count="4"
          [theme]="{ 'border-radius' : '0' ,'width':'7vw', 'margin-left': '1vw' }"></ngx-skeleton-loader> -->
            <!-- <ngx-skeleton-loader [theme]="{ 'border-radius' : '0' ,'width':'7vw', 'float': 'right'}"></ngx-skeleton-loader> -->

          </div>
        </div>
      </div>
      <div class="try-project pr-24">

        <ng-template skeletonUi [skeletonUiType]="SkeletonLoaderType.SkeletonRectangleComponent"
          [skeletonUiWidth]="'7vw'" [skeletonUiStyles]="{'position':'relative','top': '3vh' }">
        </ng-template>
      </div>
    </div>
  </div>

</div>