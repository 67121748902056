/* eslint-disable @typescript-eslint/no-explicit-any */
import { Injectable } from "@angular/core";
import { Router } from "@angular/router";

@Injectable({
  providedIn: "root"
})
export class ScrollService {
  constructor(private router: Router) { }
  scrollToElementById(id: string) {
    const element: any = this.__getElementById(id);
    this.scrollToElement(element);
  }

  private __getElementById(id: string) {
    //console.log("element id : ", id);
    // const element = <HTMLElement>document.querySelector(`#${id}`);
    const element = document.getElementById(id);
    return element;
  }

  scrollToElement(element: any) {
    element.scrollIntoView({ behavior: "smooth" });
  }
}