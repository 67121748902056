import { Component } from '@angular/core';
import { ProjectService } from '../project.service';
import { ProjectPlayerModes } from 'src/app/enums/PlayerModes';
import { ProjectDetails } from 'src/app/models/projectInterface';
import { Router } from '@angular/router';
import { SkeletonLoaderType } from 'src/app/enums/skeletonui';

@Component({
  selector: 'app-console',
  templateUrl: './console.component.html',
  styleUrls: ['./console.component.scss']
})
export class ConsoleComponent {
  projectId!: string;
  ProjectPlayerModes = ProjectPlayerModes;
  SkeletonLoaderType = SkeletonLoaderType;
  archTypes!: { language: string, archetypeId: string, label: string, targetFileName: string, sourceFileName: string }[];
  projectDetails!: ProjectDetails;
  constructor(public projectService: ProjectService, private router: Router) { }
  ngOnInit() {
    this.storeProjectDetails();
  }

  private storeProjectDetails() {
    this.pojectDetailListner();
    //If the mode is create and we don't have a projectId we will redirect to the setup component.
    if (this.projectService.projectMode === ProjectPlayerModes.CREATE && !this.projectId) {
      this.router.navigate([], { queryParams: { projectManipulationTab: 'setup' }, queryParamsHandling: 'merge', skipLocationChange: true });
    }
  }

  private pojectDetailListner() {
    this.projectService.listenToProjectDetailsState().subscribe(() => {
      this.projectId = this.projectService.projectId;
      this.archTypes = this.projectService.archetypes;
      this.projectDetails = this.projectService.projectDetails;
    });
  }
}
