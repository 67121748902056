/* eslint-disable max-lines-per-function */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-console */
import { Component, Input } from '@angular/core';
import { ProjectPlayerService } from '../../modules/player/project-player.service';
import { ProjectDetails, SetupInterface } from 'src/app/models/projectInterface';
import { ActivatedRoute, Router } from '@angular/router';
import { ProjectPlayerModes, Themes } from 'src/app/enums/PlayerModes';
import { LayoutModes } from 'src/app/enums/layoutModes';
import { Archetype } from 'src/app/models/project-list-card';
import { LocationStrategy } from '@angular/common';
import { ProjectService } from 'src/app/projectbank/project/project.service';
import { ExerciseTabModes } from 'src/app/exercise/exercise-builder/exercise-builder.component';
import { StorageService } from 'src/app/services/storage.service';
import { StorageKey } from 'src/app/enums/storageKey';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { AddProjects } from 'src/app/models/exerciseInterface';
import { Subject, takeUntil } from 'rxjs';
import { CommonService, ErrorType } from 'src/app/services/common.service';

@Component({
  selector: 'app-project-player',
  templateUrl: './project-player.component.html',
  styleUrls: ['./project-player.component.scss']
})
export class ProjectPlayerComponent {
  @Input() projectId!: string;
  @Input() mode!: ProjectPlayerModes;
  @Input() archetypes!: Archetype[];
  @Input() projectDetails!: ProjectDetails;
  @Input() projectTitle !: string;
  defaultTime = new Date();
  lastSavedTime = new Date();
  title!: string;
  ProjectPlayerModes = ProjectPlayerModes;
  public treeData!: any | null;
  fileContent!: string;
  layout = LayoutModes;
  uploading = false;
  uploaded = false;
  selectedStatus = false;
  disableTestCase = false;
  initFile: File | null = null;
  unSubscribe = new Subject<void>();
  isNotTry = true;
  autoSaveInterval!: any;
  // eslint-disable-next-line max-params
  constructor(private activatedRoute: ActivatedRoute, private router: Router, public projectService: ProjectService, public projectPlayerService: ProjectPlayerService, private location: LocationStrategy, private storageService: StorageService, private modal: NgbModal, private commonService: CommonService) { }
  modalRef!: NgbModalRef;
  ngOnInit() {
    console.log('Loggin player', this.projectId, this.mode, this.archetypes, this.projectDetails, this.projectTitle);
    this.isNotTry = this.mode === ProjectPlayerModes.TRY ? false : true;
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const projectId: string = this.activatedRoute.snapshot.paramMap.get('projectId')!;
    this.projectPlayerService.projectId = this.projectId || projectId;
    this.projectPlayerService.archetypes = this.archetypes;
    if (this.projectPlayerService.archetypes[0].language === 'DBMS') {
      this.disableTestCase = true;
    }
    console.log(this.archetypes, "arch");
    this.projectPlayerService.mode = this.mode;
    this.title = this.projectDetails ? this.projectDetails.title! : this.projectTitle!;
    if (this.mode !== ProjectPlayerModes.PRACTISE) {
      this.projectPlayerService.projectDetails = this.projectDetails;
    }
    this.storageService.set(StorageKey.Theme, this.selectedStatus);
    const exercisesLinked = this.projectDetails.linkedExercises?.length || 0;
    const exerciseId: string = this.activatedRoute.snapshot.queryParams['exerciseId'];
    if (exerciseId && (this.mode === ProjectPlayerModes.TRY || (this.mode === ProjectPlayerModes.EDIT && exercisesLinked > 0))) {
      this.projectPlayerService.exerciseIdTry = exerciseId;
    }
    if (!exerciseId) {
      this.projectPlayerService.exerciseIdTry = undefined;
    }
  }

  ngAfterViewInit() {
    if (this.mode === ProjectPlayerModes.PRACTISE) {
      this.autoSave();
    }
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    this.projectPlayerService.listenDeleteFileContent().pipe(takeUntil(this.unSubscribe)).subscribe(() => {
      this.uploaded = false;
      this.uploading = false;
      if (this.initFile) {
        const message = this.commonService.translateText('projectBank.projectPlayer.sqlFileDeleted', { itemName: this.initFile.name });
        this.commonService.showToast(message, ErrorType.SUCESS);
      }
    });
    this.projectPlayerService.listenValidationError().pipe(takeUntil(this.unSubscribe)).subscribe(() => {
      this.uploaded = false;
      this.uploading = false;
    });
  }

  autoSave() {
    this.autoSaveInterval = setInterval(async () => {
      await this.projectPlayerService.autoSave();
    }, 10000);
  }
  async saveAndNavigate() {
    const savedFiles = await this.projectPlayerService.saveAllFiles();
    if (savedFiles) {
      if (this.disableTestCase) {
        this.projectPlayerService.clearTab();
        this.projectPlayerService.clearCustomInputData();
        const exerciseId = this.activatedRoute.snapshot.queryParams['exerciseId'];
        if (this.activatedRoute.snapshot.queryParams['contentRoot'] === 'exercise_projects') {
          //after edit redirect to exercise-projects screen
          this.router.navigate(['/exercise/' + exerciseId + '/manipulate-exercise'], { queryParamsHandling: 'merge', queryParams: { exerciseManipulationTab: ExerciseTabModes.EXERCISE_PROJECTS, contentRoot: null, exerciseId: null, projectManipulationTab: null } });
        }
        else if (this.activatedRoute.snapshot.queryParams['contentRoot'] === 'view_exercise') {
          //after edit redirect to view exercise details
          this.router.navigate(['/exercise/' + exerciseId + '/view-exercise'], { queryParamsHandling: 'merge', queryParams: { contentRoot: null, exerciseId: null, projectManipulationTab: null } });
        }
        else if (this.activatedRoute.snapshot.queryParams['contentRoot'] === 'try_project') {
          // after edit redirect to tryproject screen
          this.router.navigate(['/exercise/' + this.projectService.projectDetails.projectId + '/try-project'], { queryParams: { contentRoot: null, projectManipulationTab: null }, queryParamsHandling: 'merge' });
        }
        else if (this.activatedRoute.snapshot.queryParams['exerciseId'] && !this.activatedRoute.snapshot.queryParams['contentRoot']) {
          const exerciseId = this.activatedRoute.snapshot.queryParams['exerciseId'];
          //store the project in the exercise
          const addProjectPayload: AddProjects = {
            exerciseId,
            projects: [
              {
                projectId: this.projectService.projectId,
                maxMarks: 20
              }
            ],
          };
          this.projectService.addProjectToExercise(addProjectPayload).subscribe(() => {
            this.router.navigate(['/exercise/' + exerciseId + '/manipulate-exercise'], { queryParamsHandling: 'merge', queryParams: { exerciseManipulationTab: ExerciseTabModes.EXERCISE_PROJECTS, exerciseId: null, projectManipulationTab: null } });
          });
        }
        else if (this.activatedRoute.snapshot.queryParams['tryProjectFrom'] === 'project_bank') {
          this.router.navigate(['./' + this.projectService.projectDetails.projectId + '/try-project'], { queryParamsHandling: 'merge', queryParams: { projectManipulationTab: null } });
        }
        else {
          //On successful save redirect to project bank.
          this.router.navigate(['/project-bank'], { queryParamsHandling: 'merge', queryParams: { projectManipulationTab: null } });
        }
        return;

        // this.router.navigate(['/project-bank'], { queryParamsHandling: 'merge', queryParams: { projectManipulationTab: null } });
        // return;
      }
      this.projectPlayerService.clearTab();
      this.projectPlayerService.clearCustomInputData();
      this.router.navigate([], { relativeTo: this.activatedRoute, queryParams: { projectManipulationTab: 'rubrics' }, queryParamsHandling: 'merge' });
    }
  }

  navigatePractice() {
    this.projectPlayerService.saveAllFiles();
    this.router.navigate(['/selfpractise']);
  }

  saveAsDraft() {
    const obj: SetupInterface = {
      projectId: this.projectDetails.projectId,
      title: this.projectDetails.title!,
      difficultyLevel: this.projectDetails.difficultyLevel!,
      archetypes: this.projectDetails.archetypes!,
      problemStatement: this.projectDetails.problemStatement!,
      instructions: this.projectDetails.instructions!,
      example: this.projectDetails.example!,
      isDraft: true
    };
    this.projectService.updateSetupForm(obj).subscribe(async () => {
      const savedFiles = await this.projectPlayerService.saveAllFiles();
      if (savedFiles) {
        this.projectPlayerService.clearCustomInputData();
        if (this.activatedRoute.snapshot.queryParams['exerciseId']) {
          const exerciseId = this.activatedRoute.snapshot.queryParams['exerciseId'];
          this.router.navigate(['/exercise/' + exerciseId + '/manipulate-exercise'], { queryParamsHandling: 'merge', queryParams: { exerciseManipulationTab: ExerciseTabModes.EXERCISE_PROJECTS, exerciseId: null } });
        }
        else {
          this.router.navigate(['../../../project-bank'], { relativeTo: this.activatedRoute, queryParamsHandling: 'merge' });
        }
      }
    });
  }

  async savePractise() {
    const savedFiles = await this.projectPlayerService.saveAllFiles();
    console.log(savedFiles);
    console.log("current time", new Date());

  }

  cancelProject() {
    this.saveAsDraft();
  }

  toggleTheme(theme: string) {
    this.selectedStatus = theme === 'dark' ? true : false;
    const selectedTheme = this.selectedStatus ? Themes.DARK : Themes.LIGHT;
    this.projectPlayerService.triggerThemeChange(selectedTheme);
    document.documentElement.setAttribute('data-theme', theme);
    this.storageService.set(StorageKey.Theme, this.selectedStatus);
  }

  outputLayout(layout: LayoutModes) {
    this.projectPlayerService.layout = layout;
  }

  exitTrial() {
    const exerciseId = this.activatedRoute.snapshot.queryParams['exerciseId'];

    if (this.activatedRoute.snapshot.queryParams['tryProjectFrom'] === 'view_exercise') {
      //exit trial and go back to view exercise details
      this.router.navigate(['/exercise/' + exerciseId + '/view-exercise'], { queryParamsHandling: 'merge', queryParams: { tryProjectFrom: null, exerciseId: null } });
    }
    else if (this.activatedRoute.snapshot.queryParams['tryProjectFrom'] === 'exercise_projects') {
      //exit trail and go back to exercise-projects
      this.router.navigate(['/exercise/' + exerciseId + '/manipulate-exercise'], { queryParamsHandling: 'merge', queryParams: { exerciseManipulationTab: ExerciseTabModes.EXERCISE_PROJECTS, tryProjectFrom: null, exerciseId: null } });
    }
    else {
      //exit trail and go back to project bak
      this.router.navigate(['../../../project-bank'], { queryParams: { tryProjectFrom: null }, queryParamsHandling: 'merge' });
    }
  }

  editProject() {
    //edit project from try-project screen when coming from view-exercise details or exercise projects screen (exerciseId is there)
    if (this.activatedRoute.snapshot.queryParams['exerciseId']) {
      this.router.navigate(['/project-bank/' + this.projectId + '/manipulate-project'], { relativeTo: this.activatedRoute, queryParams: { contentRoot: 'try_project' }, queryParamsHandling: 'merge' });
      return;
    }
    //edit project from try-project screen when coming from project bank
    this.router.navigate(['/project-bank/' + this.projectId + '/manipulate-project'], { relativeTo: this.activatedRoute, queryParamsHandling: 'merge' });
  }

  ngOnDestroy() {
    this.projectPlayerService.tabs = [];
    this.toggleTheme('light');
    this.projectPlayerService.customInput = {};
    this.unSubscribe.next();
    this.unSubscribe.unsubscribe();
    clearInterval(this.autoSaveInterval);
    //this.projectPlayerService.exerciseIdTry = undefined;
  }

  uploadInitSql() {
    this.uploading = true;
    this.uploaded = false;
    this.projectPlayerService.initSqlUploader.next(this.initFile);
    this.projectPlayerService.initFileUploaded$.subscribe(() => {
      this.uploading = false;
      this.uploaded = true;
    });
  }

  onFileSelected(event: any) {
    // Retrieve the selected file
    this.initFile = event.target.files[0] as File;
    if (this.initFile) {
      this.uploadInitSql();
    }
  }

  async deleteSqlFile() {
    this.projectPlayerService.deleteSqlTrigger();
  }

}
