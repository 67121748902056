<!-- Main Container -->
<div class=" container-fluid delete-modal-style py-32">
  <!-- Image -->
  <div class="pb-24">
    <svg-icon src="../../../assets/images/passed.svg"></svg-icon>
  </div>
  <!-- First Warning Line -->
  <p class="heading-1 pb-24" [innerHtml]="modalConfig.message"></p>
  <!-- Second Warning Line -->
  <div class="pb-24">
    <p class="big-bold-assistive-text required pb-2" [innerHtml]="modalConfig.message2"></p>
    <!-- date-time-picker -->
    <div>
      <!-- <input type="date" id="date" name="selectDate" [(ngModel)]="selectedDate"> -->
      <app-date-time-picker [restrictSelectionBeforeCurrentDate]="true" (dateTimeEmitter)="dateTimeEmitter($event)"
        [maxStartDate]="currentDate" [defaultDisplayValue]="selectedDate"
        [enableCancelButton]="true"></app-date-time-picker>
    </div>
  </div>
  <!-- Note -->
  <div class="pb-16">
    <span class="big-bold-assistive-text">Note:</span> <span class="body-text ps-1"
      [innerHtml]="modalConfig.note"></span>
  </div>
  <!-- Buttons -->
  <div class="row pt-16 justify-content-center">
    <button class=" btn btn-secondary-grey mr-24" [translate]="modalConfig.cancelBtn"
      (click)="sendCancelStatus()"></button>
    <button class="btn btn-primary" [translate]="modalConfig.confirmBtn" (click)="sendConfirmStatus()"></button>
  </div>
</div>