<div class="project-guide p-16" *ngIf="mode !== ProjectPlayerModes.EDIT">
  <div class="flex-container mb-16 cursor-pointer " (click)="openModal()">
    <span class="text">
      <span class="image">
        <svg-icon src="../../../../assets/images/pop-up.svg"></svg-icon>
      </span>
      <span class="big-bold-assistive-text project-text">Project Details</span>
    </span>
  </div>
  <nav class="mb-16">
    <ul class="p-16">
      <li class="mb-16"><a class="text-button" (click)="show(statement)"
          translate="projectBank.projectPlayer.tryProject.statement" *ngIf="projectDetails.problemStatement"></a>
      </li>
      <li class="mb-16"><a class="text-button" (click)="show(instructions)"
          translate="projectBank.projectPlayer.tryProject.instructions" *ngIf="projectDetails.instructions"></a>
      </li>
      <li><a class="text-button" (click)="show(example)" translate="projectBank.projectPlayer.tryProject.example"
          *ngIf="projectDetails.example"></a>
      </li>
    </ul>
  </nav>
  <section id="statement" #statement class="mb-16">
    <h4 class="big-bold-assistive-text mb-16" translate="projectBank.projectPlayer.tryProject.statement"
      *ngIf="projectDetails.problemStatement">
    </h4>
    <div class="body-text" [innerHTML]="projectDetails.problemStatement">
    </div>
  </section>
  <section id="instructions" #instructions class="mb-16">
    <h4 class="big-bold-assistive-text mb-16" translate="projectBank.projectPlayer.tryProject.instructions"
      *ngIf="projectDetails.instructions">
    </h4>
    <div class="body-text" [innerHTML]="projectDetails.instructions">
    </div>
  </section>
  <section id="example" #example class="mb-16">
    <h4 class="big-bold-assistive-text mb-16" translate="projectBank.projectPlayer.tryProject.example"
      *ngIf="projectDetails.example">
    </h4>
    <div class="body-text" [innerHTML]="projectDetails.example">
    </div>
  </section>
</div>