/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { Component, EventEmitter, Output } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { CustomInputData } from 'src/app/models/projectInterface';
import { ProjectPlayerService } from 'src/app/shared/modules/player/project-player.service';

@Component({
  selector: 'app-custom-input',
  templateUrl: './custom-input.component.html',
  styleUrls: ['./custom-input.component.scss']
})
export class CustomInputComponent {
  @Output() closeCustom = new EventEmitter;
  customInputForm!: FormGroup;
  projectPlayerService!: ProjectPlayerService;
  customInputData!: CustomInputData;
  constructor(private custom: FormBuilder, public activeModal: NgbActiveModal) { }
  ngOnInit() {
    this.createForm();
    if (this.projectPlayerService?.customInput) {
      this.customInputForm.patchValue(this.projectPlayerService.customInput);
    }
  }

  createForm() {
    this.customInputForm = this.custom.group({
      consoleInput: new FormControl('', [Validators.required]),
      commandInput: new FormControl('', [Validators.required])
    });
  }
  isSaveDisabled(): boolean {
    const consoleInput = this.customInputForm.get('consoleInput')!.value;
    const commandInput = this.customInputForm.get('commandInput')!.value;
    return !(consoleInput.length > 0 || commandInput.length > 0);
  }
  submit() {
    if (this.projectPlayerService) {
      this.projectPlayerService.customInput = this.customInputForm.value;
      this.closeCustom.emit();
    }
  }

  cancel() {
    this.customInputForm.reset();
    this.closeCustom.emit();
  }

}
