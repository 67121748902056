import { Component } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Router } from '@angular/router';

@Component({
  selector: 'app-create-new-exercise',
  templateUrl: './create-new-exercise.component.html',
  styleUrls: ['./create-new-exercise.component.scss']
})
export class CreateNewExerciseComponent {
  selectedExerciseType = '';
  constructor(private NgbActiveModal: NgbActiveModal, private router: Router) { }
  get activeModal() {
    return this.NgbActiveModal;
  }
  createExercise() {
    if(this.selectedExerciseType){
      this.activeModal.dismiss();
      this.router.navigate(['/exercise/create/manipulate-exercise'], {
        queryParams: { exerciseManipulationTab: 'exercise-setup', excerciseType: this.selectedExerciseType }
      });
    }
  }
  updateSelectedOption(option: string) {
    this.selectedExerciseType = option;
  }
}
