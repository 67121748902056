import { Component } from '@angular/core';
import { LeftMenuItem, leftMenuItems } from './leftmenuitems.config';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { filter } from 'rxjs';
import { StorageService } from 'src/app/services/storage.service';
import { StorageKey } from 'src/app/enums/storageKey';

@Component({
  selector: 'app-leftmenu',
  templateUrl: './leftmenu.component.html',
  styleUrls: ['./leftmenu.component.scss']
})
export class LeftMenuComponent {
  leftMenuItems = leftMenuItems;
  activeMenuItem !: LeftMenuItem;
  constructor(private router: Router, private activatedRoute: ActivatedRoute, private storageService: StorageService) { }
  ngOnInit() {
    this.navigationEndListener();
    this.routeToDefaultMenuItem();
    // eslint-disable-next-line no-extra-boolean-cast
    if (!!this.activatedRoute.snapshot.queryParams['redirectBackTo']) {
      this.storageService.set(StorageKey.RedirectBackTo, this.activatedRoute.snapshot.queryParams['redirectBackTo']);
    }
  }

  /**
   * @param menuItemToSwitchTo This will navigate to the clicked menu item.
   */
  switchMenu(menuItemToSwitchTo: LeftMenuItem) {
    if (menuItemToSwitchTo.title === 'lumen') {
      const redirectBackToLumenUrl = this.storageService.get(StorageKey.RedirectBackTo);
      // eslint-disable-next-line no-extra-boolean-cast
      if (!!redirectBackToLumenUrl) {
        location.replace(`${redirectBackToLumenUrl}`);
        return;
      }
    }
    this.activeMenuItem = menuItemToSwitchTo;
    this.router.navigate([menuItemToSwitchTo.route], { relativeTo: this.activatedRoute, queryParamsHandling: 'merge', queryParams: { contentRoot: null, exerciseId: null, tryProjectFrom: null } });
  }

  /**
   * Will check the active key in left menu config and route to it by default, if no menuItem is already active..
   */
  private routeToDefaultMenuItem() {
    const defaultRoute = leftMenuItems.filter((leftMenuItem: LeftMenuItem) => leftMenuItem.active)[0].route;
    this.activateLeftMenuItems();
    if (this.activeMenuItem) {
      return;
    }
    this.router.navigate([defaultRoute], { relativeTo: this.activatedRoute, queryParamsHandling: 'merge' });
  }

  /**
   * This will listen to the Navigation end events. Based on that can trigger different functions as required.
   */
  private navigationEndListener() {
    this.router.events.pipe(filter((event) => event instanceof NavigationEnd)).subscribe(() => {
      this.activateLeftMenuItems();
    });
  }

  /**
   * This will apply the active class to the left menu items based on the route if it finds any active route.
   */
  activateLeftMenuItems(): void {
    leftMenuItems.forEach((menuItem: LeftMenuItem) => {
      //Checking the url to check which menu item is currently active. Then apply the active class to it.
      if (this.router.url.includes(`/${menuItem.route}`) || this.router.url.includes(`/${menuItem.id}`)) {
        menuItem.active = true;
        this.activeMenuItem = menuItem;
      }
      else {
        menuItem.active = false;
      }
    });
  }
}
