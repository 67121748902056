<div class="content-container">
  <div class="p-24">
    <div class="page-title mb-0" *ngIf="!exerciseBuilderService.selectionMode" [translate]="translationKey()"></div>
    <div class="page-title mb-0" *ngIf="exerciseBuilderService.selectionMode"
      translate="exerciseBuilder.selectFromProjectBank"></div>
  </div>

  <ng-container>
    <div class="px-24" *ngIf="!exerciseBuilderService.selectionMode">
      <ul class="nav nav-tabs create-project-tabs" id="myTab" role="tablist">
        <li class="nav-item" role="presentation">
          <button class="nav-link" id="exercise-setup-tab" data-bs-toggle="tab" data-bs-target="#exercise-setup"
            type="button" role="tab" aria-controls="exercise-setup" aria-selected="false"
            [ngClass]="{'active': activatedTab === 'exercise-setup'}"
            (click)="tabSwitch(ExerciseTabModes.EXERCISE_SETUP)" translate="exercise.excerciseSetupName"></button>
        </li>
        <li class="nav-item" role="presentation">
          <button class="nav-link" id="exercise-projects-tab" data-bs-toggle="tab" data-bs-target="#exercise-projects"
            type="button" role="tab" aria-controls="exercise-projects" aria-selected="false"
            [ngClass]="{'active': activatedTab === 'exercise-projects'}"
            (click)="tabSwitch(ExerciseTabModes.EXERCISE_PROJECTS)" translate="exercise.excerciseProjectsName"></button>
        </li>
      </ul>
    </div>

    <!-- Tab panes  -->
    <div class="tab-content">
      <div class="tab-pane " id="exercise-setup" role="tabpanel" aria-labelledby="exercise-setup-tab"
        [ngClass]="{'active': activatedTab === ExerciseTabModes.EXERCISE_SETUP}">
        <app-exercise-setup *ngIf="(activatedTab === ExerciseTabModes.EXERCISE_SETUP) && isLayoutReady">
        </app-exercise-setup>
      </div>
      <div class="tab-pane " id="exercise-projects" role="tabpanel" aria-labelledby="exercise-projects-tab"
        [ngClass]="{'active': activatedTab === ExerciseTabModes.EXERCISE_PROJECTS}">
        <app-exercise-projects *ngIf="activatedTab === ExerciseTabModes.EXERCISE_PROJECTS"></app-exercise-projects>
      </div>
      <div class="tab-pane" id="project-selection" role="tabpanel" aria-labelledby="project-selection-tab"
        [ngClass]="{'active': activatedTab ===  ExerciseTabModes.PROJECT_SELECTION}">
        <app-project-selection *ngIf="activatedTab ===  ExerciseTabModes.PROJECT_SELECTION"></app-project-selection>
      </div>
    </div>
  </ng-container>

</div>

<router-outlet></router-outlet>