<div class="d-flex px-16">
  <div class="left-content ">
    <div>
      <div class="accordion test-cases mb-24" id="accordionPanelsStayOpenExample2">
        <div class="accordion-item">
          <h2 class="accordion-header" id="panelsStayOpen-headingTwo">
            <button
              class="accordion-button d-flex align-items-center justify-content-between heading-2 project-player-accordian-button"
              type="button">
              <span class="d-flex" translate="projectBank.projectPlayer.testCasesHeading">
                Language & File
                <img class="ms-1" src="../../../../../assets/images/info.svg" alt="">
              </span>
              <span class="accordion-toggle-icon cursor-pointer ms-1" data-bs-target="#panelsStayOpen-collapseTwo"
                aria-expanded="true" aria-controls="panelsStayOpen-collapseTwo">
              </span>
            </button>
          </h2>
          <div id="panelsStayOpen-collapseTwo" class="accordion-collapse collapse show"
            aria-labelledby="panelsStayOpen-headingTwo">
            <div class="accordion-body p-16">
              <ng-template skeletonUi [skeletonUiType]="SkeletonLoaderType.SkeletonRectangleComponent"
                [skeletonUiWidth]="'9vw'" [skeletonUiStyles]="{'margin-right': '1vw','margin-top':'3vh'}"></ng-template>
              <ng-template skeletonUi [skeletonUiType]="SkeletonLoaderType.SkeletonRectangleComponent"
                [skeletonUiWidth]="'3vw'" [skeletonUiStyles]="{'margin-right': '1vw'}"></ng-template>
              <ng-template skeletonUi [skeletonUiType]="SkeletonLoaderType.SkeletonRectangleComponent"
                [skeletonUiWidth]="'1vw'"></ng-template>
              <div>
                <ng-template skeletonUi [skeletonUiType]="SkeletonLoaderType.SkeletonRectangleComponent"
                  [skeletonUiWidth]="'10vw'" [skeletonUiStyles]="{'margin-right': '1vw','margin-top':'3vh'}">
                </ng-template>
                <ng-template skeletonUi [skeletonUiType]="SkeletonLoaderType.SkeletonRectangleComponent"
                  [skeletonUiWidth]="'3vw'" [skeletonUiStyles]="{'margin-right': '1vw'}"></ng-template>
                <ng-template skeletonUi [skeletonUiType]="SkeletonLoaderType.SkeletonRectangleComponent"
                  [skeletonUiWidth]="'1vw'"></ng-template>

              </div>
              <div>
                <ng-template skeletonUi [skeletonUiType]="SkeletonLoaderType.SkeletonRectangleComponent"
                  [skeletonUiWidth]="'10vw'" [skeletonUiStyles]="{'margin-right': '1vw','margin-top':'3vh'}">
                </ng-template>
                <ng-template skeletonUi [skeletonUiType]="SkeletonLoaderType.SkeletonRectangleComponent"
                  [skeletonUiWidth]="'3vw'" [skeletonUiStyles]="{'margin-right': '1vw'}"></ng-template>
                <ng-template skeletonUi [skeletonUiType]="SkeletonLoaderType.SkeletonRectangleComponent"
                  [skeletonUiWidth]="'1vw'"></ng-template>

              </div>
              <div>
                <ng-template skeletonUi [skeletonUiType]="SkeletonLoaderType.SkeletonRectangleComponent"
                  [skeletonUiWidth]="'10vw'" [skeletonUiStyles]="{'margin-right': '1vw','margin-top':'3vh'}">
                </ng-template>
                <ng-template skeletonUi [skeletonUiType]="SkeletonLoaderType.SkeletonRectangleComponent"
                  [skeletonUiWidth]="'3vw'" [skeletonUiStyles]="{'margin-right': '1vw'}"></ng-template>
                <ng-template skeletonUi [skeletonUiType]="SkeletonLoaderType.SkeletonRectangleComponent"
                  [skeletonUiWidth]="'1vw'"></ng-template>

              </div>
              <hr>
            </div>
          </div>
        </div>
      </div>

      <div class="accordion test-cases mb-24" id="accordionPanelsStayOpenExample2">
        <div class="accordion-item">
          <h2 class="accordion-header" id="panelsStayOpen-headingTwo">
            <button
              class="accordion-button d-flex align-items-center justify-content-between heading-2 project-player-accordian-button"
              type="button">
              <span class="d-flex" translate="projectBank.projectPlayer.testCasesHeading">
                Test Cases
                <img class="ms-1" src="../../../../../assets/images/info.svg" alt="">
              </span>
              <span class="accordion-toggle-icon cursor-pointer ms-1" data-bs-target="#panelsStayOpen-collapseTwo"
                aria-expanded="true" aria-controls="panelsStayOpen-collapseTwo">
              </span>
            </button>
          </h2>
          <div id="panelsStayOpen-collapseTwo" class="accordion-collapse collapse show"
            aria-labelledby="panelsStayOpen-headingTwo">
            <div class="accordion-body p-16">
              <ng-template skeletonUi [skeletonUiType]="SkeletonLoaderType.SkeletonRectangleComponent"
                [skeletonUiWidth]="'8vw'" [skeletonUiStyles]="{'margin-right': '1vw'}"></ng-template>
              <ng-template skeletonUi [skeletonUiType]="SkeletonLoaderType.SkeletonRectangleComponent"
                [skeletonUiWidth]="'1vw'" [skeletonUiStyles]="{'margin-right': '1vw'}"></ng-template>
              <ng-template skeletonUi [skeletonUiType]="SkeletonLoaderType.SkeletonRectangleComponent"
                [skeletonUiWidth]="'1vw'"></ng-template>
              <div>
                <ng-template skeletonUi [skeletonUiType]="SkeletonLoaderType.SkeletonRectangleComponent"
                  [skeletonUiWidth]="'8vw'" [skeletonUiStyles]="{'margin-right': '1vw','margin-top':'1vh'}">
                </ng-template>
                <ng-template skeletonUi [skeletonUiType]="SkeletonLoaderType.SkeletonRectangleComponent"
                  [skeletonUiWidth]="'1vw'" [skeletonUiStyles]="{'margin-right': '1vw'}"></ng-template>
                <ng-template skeletonUi [skeletonUiType]="SkeletonLoaderType.SkeletonRectangleComponent"
                  [skeletonUiWidth]="'1vw'"></ng-template>
              </div>
              <div>
                <ng-template skeletonUi [skeletonUiType]="SkeletonLoaderType.SkeletonRectangleComponent"
                  [skeletonUiWidth]="'8vw'" [skeletonUiStyles]="{'margin-right': '1vw','margin-top':'1vh'}">
                </ng-template>
                <ng-template skeletonUi [skeletonUiType]="SkeletonLoaderType.SkeletonRectangleComponent"
                  [skeletonUiWidth]="'1vw'" [skeletonUiStyles]="{'margin-right': '1vw'}"></ng-template>
              </div>
              <div>
                <ng-template skeletonUi [skeletonUiType]="SkeletonLoaderType.SkeletonRectangleComponent"
                  [skeletonUiWidth]="'8vw'" [skeletonUiStyles]="{'margin-right': '1vw','margin-top':'1vh'}">
                </ng-template>
                <ng-template skeletonUi [skeletonUiType]="SkeletonLoaderType.SkeletonRectangleComponent"
                  [skeletonUiWidth]="'1vw'" [skeletonUiStyles]="{'margin-right': '1vw'}"></ng-template>
              </div>
              <div>
                <ng-template skeletonUi [skeletonUiType]="SkeletonLoaderType.SkeletonRectangleComponent"
                  [skeletonUiWidth]="'8vw'" [skeletonUiStyles]="{'margin-right': '1vw','margin-top':'1vh'}">
                </ng-template>
                <ng-template skeletonUi [skeletonUiType]="SkeletonLoaderType.SkeletonRectangleComponent"
                  [skeletonUiWidth]="'1vw'" [skeletonUiStyles]="{'margin-right': '1vw'}"></ng-template>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- Right Content -->
  <div class="container-fluid pt-24">
    <div class="row pt-2">
      <div class="col-9 pl-24">
        <div class="left-container pl-24">
          <div>
            <ng-template skeletonUi [skeletonUiType]="SkeletonLoaderType.SkeletonRectangleComponent"
              [skeletonUiWidth]="'17vw'"
              [skeletonUiStyles]="{'margin-right': '1vw','margin-top':'3vh','margin-bottom':'3vh'}"></ng-template>
            <ng-template skeletonUi [skeletonUiType]="SkeletonLoaderType.SkeletonRectangleComponent"
              [skeletonUiWidth]="'2vw'"
              [skeletonUiStyles]="{'margin-right': '3vw','margin-top':'3vh','margin-bottom':'3vh'}"></ng-template>
          </div>
        </div>
        <div class="left-lower-container pl-24">
          <ng-template skeletonUi [skeletonUiType]="SkeletonLoaderType.SkeletonRectangleComponent"
            [skeletonUiWidth]="'17vw'"
            [skeletonUiStyles]="{'margin-right': '1vw','margin-top':'3vh','margin-bottom':'1vh'}"></ng-template>
          <ng-template skeletonUi [skeletonUiType]="SkeletonLoaderType.SkeletonRectangleComponent"
            [skeletonUiWidth]="'15vw'"
            [skeletonUiStyles]="{'margin-right': '1vw','margin-top':'3vh','margin-bottom':'1vh'}"></ng-template>
          <ng-template skeletonUi [skeletonUiType]="SkeletonLoaderType.SkeletonRectangleComponent"
            [skeletonUiWidth]="'4vw'"
            [skeletonUiStyles]="{'margin-right': '1vw','margin-top':'3vh','margin-bottom':'1vh'}"></ng-template>
          <ng-template skeletonUi [skeletonUiType]="SkeletonLoaderType.SkeletonRectangleComponent"
            [skeletonUiWidth]="'13vw'"
            [skeletonUiStyles]="{'margin-right': '1vw','margin-top':'3vh','margin-bottom':'1vh'}"></ng-template>

          <div>
            <ng-template skeletonUi [skeletonUiType]="SkeletonLoaderType.SkeletonRectangleComponent"
              [skeletonUiWidth]="'7vw'" [skeletonUiStyles]="{'margin-right': '1vw','margin-bottom':'1vh'}">
            </ng-template>
            <ng-template skeletonUi [skeletonUiType]="SkeletonLoaderType.SkeletonRectangleComponent"
              [skeletonUiWidth]="'18vw'" [skeletonUiStyles]="{'margin-right': '1vw','margin-bottom':'1vh'}">
            </ng-template>
            <ng-template skeletonUi [skeletonUiType]="SkeletonLoaderType.SkeletonRectangleComponent"
              [skeletonUiWidth]="'13vw'" [skeletonUiStyles]="{'margin-right': '1vw','margin-bottom':'1vh'}">
            </ng-template>
            <ng-template skeletonUi [skeletonUiType]="SkeletonLoaderType.SkeletonRectangleComponent"
              [skeletonUiWidth]="'12vw'" [skeletonUiStyles]="{'margin-right': '1vw','margin-bottom':'1vh'}">
            </ng-template>
          </div>
          <div>
            <ng-template skeletonUi [skeletonUiType]="SkeletonLoaderType.SkeletonRectangleComponent"
              [skeletonUiWidth]="'19vw'" [skeletonUiStyles]="{'margin-right': '1vw','margin-bottom':'1vh'}">
            </ng-template>
            <ng-template skeletonUi [skeletonUiType]="SkeletonLoaderType.SkeletonRectangleComponent"
              [skeletonUiWidth]="'8vw'" [skeletonUiStyles]="{'margin-right': '1vw','margin-bottom':'1vh'}">
            </ng-template>
            <ng-template skeletonUi [skeletonUiType]="SkeletonLoaderType.SkeletonRectangleComponent"
              [skeletonUiWidth]="'12vw'" [skeletonUiStyles]="{'margin-right': '1vw','margin-bottom':'1vh'}">
            </ng-template>
            <ng-template skeletonUi [skeletonUiType]="SkeletonLoaderType.SkeletonRectangleComponent"
              [skeletonUiWidth]="'6vw'" [skeletonUiStyles]="{'margin-right': '1vw','margin-bottom':'1vh'}">
            </ng-template>
          </div>
          <div>
            <ng-template skeletonUi [skeletonUiType]="SkeletonLoaderType.SkeletonRectangleComponent"
              [skeletonUiWidth]="'13vw'" [skeletonUiStyles]="{'margin-right': '1vw','margin-bottom':'1vh'}">
            </ng-template>
            <ng-template skeletonUi [skeletonUiType]="SkeletonLoaderType.SkeletonRectangleComponent"
              [skeletonUiWidth]="'7vw'" [skeletonUiStyles]="{'margin-right': '1vw','margin-bottom':'1vh'}">
            </ng-template>
            <ng-template skeletonUi [skeletonUiType]="SkeletonLoaderType.SkeletonRectangleComponent"
              [skeletonUiWidth]="'17vw'" [skeletonUiStyles]="{'margin-right': '1vw','margin-bottom':'1vh'}">
            </ng-template>
            <ng-template skeletonUi [skeletonUiType]="SkeletonLoaderType.SkeletonRectangleComponent"
              [skeletonUiWidth]="'8vw'" [skeletonUiStyles]="{'margin-right': '1vw','margin-bottom':'1vh'}">
            </ng-template>
          </div>
          <div>
            <ng-template skeletonUi [skeletonUiType]="SkeletonLoaderType.SkeletonRectangleComponent"
              [skeletonUiWidth]="'17vw'" [skeletonUiStyles]="{'margin-right': '1vw','margin-bottom':'1vh'}">
            </ng-template>
            <ng-template skeletonUi [skeletonUiType]="SkeletonLoaderType.SkeletonRectangleComponent"
              [skeletonUiWidth]="'9vw'" [skeletonUiStyles]="{'margin-right': '1vw','margin-bottom':'1vh'}">
            </ng-template>
            <ng-template skeletonUi [skeletonUiType]="SkeletonLoaderType.SkeletonRectangleComponent"
              [skeletonUiWidth]="'10vw'" [skeletonUiStyles]="{'margin-right': '1vw','margin-bottom':'1vh'}">
            </ng-template>
            <ng-template skeletonUi [skeletonUiType]="SkeletonLoaderType.SkeletonRectangleComponent"
              [skeletonUiWidth]="'13vw'" [skeletonUiStyles]="{'margin-right': '1vw','margin-bottom':'1vh'}">
            </ng-template>
          </div>
          <div>
            <ng-template skeletonUi [skeletonUiType]="SkeletonLoaderType.SkeletonRectangleComponent"
              [skeletonUiWidth]="'17vw'" [skeletonUiStyles]="{'margin-right': '1vw','margin-bottom':'1vh'}">
            </ng-template>
            <ng-template skeletonUi [skeletonUiType]="SkeletonLoaderType.SkeletonRectangleComponent"
              [skeletonUiWidth]="'2vw'" [skeletonUiStyles]="{'margin-right': '1vw','margin-bottom':'1vh'}">
            </ng-template>
            <ng-template skeletonUi [skeletonUiType]="SkeletonLoaderType.SkeletonRectangleComponent"
              [skeletonUiWidth]="'2vw'" [skeletonUiStyles]="{'margin-right': '1vw','margin-bottom':'1vh'}">
            </ng-template>
            <ng-template skeletonUi [skeletonUiType]="SkeletonLoaderType.SkeletonRectangleComponent"
              [skeletonUiWidth]="'13vw'" [skeletonUiStyles]="{'margin-right': '1vw','margin-bottom':'1vh'}">
            </ng-template>
            <ng-template skeletonUi [skeletonUiType]="SkeletonLoaderType.SkeletonRectangleComponent"
              [skeletonUiWidth]="'9vw'" [skeletonUiStyles]="{'margin-right': '1vw','margin-bottom':'1vh'}">
            </ng-template>
          </div>
        </div>
      </div>
      <div class="col-3">
        <div class="left-container ps-2">
          <ng-template skeletonUi [skeletonUiType]="SkeletonLoaderType.SkeletonRectangleComponent"
            [skeletonUiWidth]="'10vw'"
            [skeletonUiStyles]="{'margin-right': '1vw','margin-top':'3vh','margin-bottom':'3vh'}"></ng-template>

        </div>
        <div class="left-lower-container">
          <ng-template skeletonUi [skeletonUiType]="SkeletonLoaderType.SkeletonRectangleComponent"
            [skeletonUiWidth]="'10vw'"
            [skeletonUiStyles]="{'margin-right': '1vw','margin-top':'3vh','margin-bottom':'3vh'}"></ng-template>
          <ng-template skeletonUi [skeletonUiType]="SkeletonLoaderType.SkeletonRectangleComponent"
            [skeletonUiWidth]="'2vw'"
            [skeletonUiStyles]="{'margin-right': '1vw','margin-top':'3vh','margin-bottom':'3vh'}"></ng-template>
          <ng-template skeletonUi [skeletonUiType]="SkeletonLoaderType.SkeletonRectangleComponent"
            [skeletonUiWidth]="'2vw'"
            [skeletonUiStyles]="{'margin-right': '1vw','margin-top':'3vh','margin-bottom':'3vh'}"></ng-template>
        </div>
      </div>




    </div>
    <div class="d-flex justify-content-end footerBtn pe-0">
      <button type="button" class="btn btn-secondary-grey" disabled>Cancel</button>
      <button type="button" class="btn btn-secondary-orange mx-4" disabled>Save As Draft</button>
      <button type="button" class="btn btn-primary" disabled>Save & Continue</button>
    </div>
  </div>