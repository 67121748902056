<div class="project-detail d-flex align-items-center p-24 mb-24">
  <div class="project-details pr-24">
    <h3 class="heading-3 text-break mb-1">
      {{projectListCardItem.title | titlecase}}
    </h3>
    <div class="body-sub-text text-break mb-16">
      <span>Added to {{projectListCardItem.countOfExercises}} exercises | </span>
      <!-- <span>Available in 5 live exercises | </span> -->
      <span>Authored by {{projectListCardItem.userName}}</span>
    </div>
    <div class="d-flex align-items-center project-card-footer">
      <div class="d-flex align-items-center">
        <span class="body-sub-text">Level:</span><span
          class="d-inline-flex align-items-center justify-content-center level ms-1"
          [ngClass]="{'level-easy' :projectListCardItem.difficultyLevel == DifficultyLevel.EASY  , 'level-difficult':projectListCardItem.difficultyLevel == DifficultyLevel.DIFFICULT,'level-medium':projectListCardItem.difficultyLevel == DifficultyLevel.MEDIUM }">
          {{projectListCardItem.difficultyLevel | titlecase}}
        </span>
      </div>
      <div class="body-sub-text text-nowrap"
        *ngIf="displayMode === ProjectListCardModes.DRAG_DROP || displayMode === ProjectListCardModes.VIEW_EXERCISE_DETAILS">
        Marks:<span class="ff-semibold lh-16 ms-1">
          {{projectListCardItem.maxMarks}}</span>
      </div>
      <div class="body-sub-text lang-arch d-flex align-items-center">
        <span class="text-nowrap">
          Language & Archetype:
        </span>
        <span class="ff-semibold lh-16 ms-1" *ngFor="let language of filteredValues">
          {{language | titlecase}}</span>
        <span class="remaining-count ms-1" *ngIf="remainingValuesCount">+{{remainingValuesCount}}</span>
      </div>
      <div class="body-sub-text text-nowrap" *ngIf="displayMode !== ProjectListCardModes.DRAG_DROP">
        Created:
        <span class="ff-semibold lh-16 ms-1">
          {{ projectListCardItem.createdAt | dateFormat}} </span>
      </div>
      <div class="body-sub-text text-nowrap" *ngIf="displayMode !== ProjectListCardModes.DRAG_DROP">
        Edited:<span class="ff-semibold lh-16 ms-1">
          {{ projectListCardItem.updatedAt | dateFormat }}</span>
      </div>
    </div>
  </div>
  <div class="try-project pl-24">
    <div class="dropdown text-end mb-16"
      *ngIf="displayMode !== ProjectListCardModes.SELECTION && displayMode !== ProjectListCardModes.VIEW_EXERCISE_DETAILS">
      <img class="dropdown-toggle cursor-pointer" data-bs-toggle="dropdown" aria-expanded="false"
        src="../../assets/images/ellipsis-menu.svg">
      <ul class="dropdown-menu">
        <li>
          <a class="dropdown-item cursor-pointer" (click)="editProject()"><img
              src="../../../../../assets/images/ellipsis-edit.svg">
            Edit
          </a>
        </li>
        <li>
          <a class="dropdown-item cursor-pointer" (click)="cloneProject()"
            *ngIf="!projectListCardItem.isDraft && displayMode !== ProjectListCardModes.DRAG_DROP"><img
              src="../../../../../assets/images/ellipsis-clone.svg">
            Clone
          </a>
        </li>
        <li *ngIf="displayMode !== ProjectListCardModes.DRAG_DROP">
          <a class="dropdown-item cursor-pointer" (click)="deleteModal()" [class.disabled]="countOfExercises > 0"><img
              src="../../../../../assets/images/ellipsis-delete.svg">
            Delete
          </a>
        </li>
        <li *ngIf="displayMode === ProjectListCardModes.DRAG_DROP">
          <a class="dropdown-item cursor-pointer" (click)="removeProjectModal()"><img
              src="../../../../../assets/images/ellipsis-delete.svg">
            Remove
          </a>
        </li>
      </ul>
    </div>
    <div class="dropdown text-end mb-3" *ngIf="displayMode === ProjectListCardModes.VIEW_EXERCISE_DETAILS">
      <img class="dropdown-toggle cursor-pointer" data-bs-toggle="dropdown" aria-expanded="false"
        src="../../assets/images/ellipsis-menu.svg">
      <ul class="dropdown-menu">
        <li>
          <a class="dropdown-item cursor-pointer" (click)="editProject('viewExerciseEdit')">
            <img src="../../../../../assets/images/ellipsis-edit.svg">
            Edit Project
          </a>
        </li>
        <li>
          <a class="dropdown-item cursor-pointer" (click)="removeProjectModal()"><img
              src="../../../../../assets/images/ellipsis-delete.svg">
            Remove Project
          </a>
        </li>
      </ul>
    </div>
    <p class="primary-text cursor-pointer" (click)="tryProject()"
      *ngIf="(!projectListCardItem.isDraft) && ( displayMode === ProjectListCardModes.LISTING || displayMode === ProjectListCardModes.DRAG_DROP || displayMode === ProjectListCardModes.VIEW_EXERCISE_DETAILS)">
      Try Project</p>
    <!-- <p class="primary-text cursor-pointer"
      *ngIf="(!projectListCardItem.isDraft) && ( displayMode === ProjectListCardModes.SELECTION)">
      Preview</p> -->
    <p class="primary-text cursor-pointer" *ngIf="displayMode === ProjectListCardModes.SELECTION"
      (click)="PreviewModel()">
      Preview <img class="ms-1" src="../../../../assets/images/edit-marks.svg"></p>
  </div>
</div>