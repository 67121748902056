/* eslint-disable @typescript-eslint/no-explicit-any */
import { ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, Output, ViewChild, ViewEncapsulation } from '@angular/core';
import { DatePickerDirective, IDatePickerDirectiveConfig } from 'ng2-date-picker';
import * as dayjs from 'dayjs';
import { ScrollService } from 'src/app/services/scroll.service';

type MeridiemType = 'am' | 'pm';
type TimeFormats = 'hours' | 'minutes';

@Component({
  selector: 'app-date-time-picker',
  templateUrl: './date-time-picker.component.html',
  styleUrls: ['./date-time-picker.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class DateTimePickerComponent {
  @ViewChild('date-time-widget') dateTimeWidget!: ElementRef;
  config: IDatePickerDirectiveConfig = {
    format: 'DD MMM YYYY, hh.mm A',
    hours12Format: 'hh',
    disableKeypress: true,
    inputElementContainer: this.dateTimeWidget,
  };
  current = dayjs();

  dateValueExists: any;
  @Input() disabled = false;

  @Input() restrictSelectionBeforeCurrentDate = false;
  // elementData: any;
  // courseId: any;

  @Input() set maxStartDate(date: dayjs.Dayjs | undefined) {
    if (date) {
      let min = date;
      if (this.restrictSelectionBeforeCurrentDate) {
        if (date < this.current) {
          min = this.current;
        }
      }
      this.config = {
        ...this.config,
        min,
        max: undefined
      };
    }
  }

  @Input() set maxEndDate(date: dayjs.Dayjs | undefined) {
    if (date) {
      if (!dayjs.isDayjs(date)) {
        date = dayjs(date);
      }
      let min = undefined;
      if (this.restrictSelectionBeforeCurrentDate) {
        min = dayjs();
      }
      this.config = {
        ...this.config,
        max: date,
        min
      };
    }
  }

  @Input() dateFormat = 'DD MMM YYYY, hh.mm A';

  @Input() placeholderFormat = 'dd/mon/yyyy hh:mm';

  @Input()
  set defaultDisplayValue(date: dayjs.Dayjs | string | undefined) {
    if (date && !dayjs.isDayjs(date)) {
      date = dayjs(date);
    }
    if (dayjs.isDayjs(date)) {
      if (date) {
        this.dateValueExists = true;
        this.displayDate = date.clone();
        if (this.dateDirectivePicker) {
          this.dateDirectivePicker.datePicker.selected[0] = date;
        }
        this.timePicker.hours = Number(date.format('hh'));
        this.timePicker.minutes = Number(date.format('mm'));
        this.timePicker.meridiem = date.format('a');
        this.dateDirectivePicker.datePicker.api.moveCalendarTo(date);
      } else {
        //this.displayDate = null;
      }
    }
  }

  @Input() highlightError = false;

  @Input() enableCancelButton = false;

  @Input() enableSaveButton = true;

  @Input() displayTimePicker = true;

  @ViewChild('dateDirectivePicker') dateDirectivePicker!: DatePickerDirective;

  @ViewChild('dateTimeDisplay') dateTimeDisplay!: ElementRef;

  @ViewChild('timeSelector') timeSelector!: ElementRef;

  @Output() dateTimeEmitter = new EventEmitter();

  displayDate!: dayjs.Dayjs | null;

  timePicker: {
    hours: number,
    minutes: number,
    meridiem: string,
  } = {
      hours: 0,
      minutes: 0,
      meridiem: 'am',
    };

  todayDate: Date = new Date();
  mounted = false;
  constructor(private cdr: ChangeDetectorRef, private scrollService: ScrollService) { }

  initializeDateComponents() {
    const now = dayjs();
    if (this.restrictSelectionBeforeCurrentDate && !this.maxStartDate) {
      this.config = {
        ...this.config,
        min: now
      };
    }
    this.dateDirectivePicker.datePicker.selected = [now];
    this.timePicker.hours = Number(now.format('hh'));
    this.timePicker.minutes = Number(now.format('mm'));
    this.timePicker.meridiem = now.format('a');
    this.mounted = true;
    if (this.displayDate) {
      this.dateDirectivePicker.datePicker.selected[0] = this.displayDate.clone();
      this.timePicker.hours = Number(this.displayDate.format('hh'));
      this.timePicker.minutes = Number(this.displayDate.format('mm'));
      this.timePicker.meridiem = this.displayDate.format('a');
    }
  }

  async ngOnInit() {
    if (this.dateValueExists) {
      this.initializeDateComponents();
    }
  }

  ngAfterViewInit(): void {
    this.initializeDateComponents();
    this.cdr.detectChanges();
  }

  getSelectedDate(): dayjs.Dayjs {
    return this.dateDirectivePicker?.datePicker?.selected?.[0];
  }

  get formattedDate(): string {
    let selectedDate = this.getSelectedDate();
    if (selectedDate !== undefined) {
      selectedDate = selectedDate.set('hour', this.timePicker.hours).set('minute', this.timePicker.minutes);
      if (this.timePicker.meridiem === 'am') {
        if (selectedDate.hour() >= 12) {
          selectedDate = selectedDate.subtract(12, 'hour');
        }
      } else if (selectedDate.hour() < 12) {
        selectedDate = selectedDate.add(12, 'hour');
      }
    }
    if (dayjs.isDayjs(selectedDate)) {
      return selectedDate?.format(this.config.format);
    }
    return '';
  }

  changeDate(): void {
    const selectedDate = this.getSelectedDate();
    if (this.mounted) {
      selectedDate.set('hours', this.timePicker.hours);
      selectedDate.set('minutes', this.timePicker.minutes);
      this.timePicker.meridiem = selectedDate.format('a') === 'am' ? 'am' : 'pm';
      if (!this.enableSaveButton) {
        this.saveDate();
      }
    }
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  scrollToElement(element: any) {
    this.scrollService.scrollToElement(element);
  }
  changeTime(event: Event, min: number, max: number): void {
    event.stopPropagation();
    const eventTarget = <HTMLInputElement>event.target;
    const targetName: TimeFormats = eventTarget.name as TimeFormats;
    this.updateTime({
      name: targetName,
      value: Number(eventTarget.value)
    }, min, max);
  }

  updateTime({
    name,
    value
  }: {
    name: TimeFormats,
    value: number
  }, min: number, max: number, event?: Event) {
    event?.stopPropagation();
    const selectedDate = this.getSelectedDate();
    if (value > max) {
      value = max;
    }
    if (value < min) {
      value = min;
    }
    this.timePicker[name] = value;
    if (name === 'hours') {
      if (selectedDate.format('a') === 'am') {
        if (Number(value) !== 12) {
          selectedDate.set(name, +value);
        } else {
          selectedDate.set(name, 0);
        }
      } else if (Number(value) !== 12) {
        selectedDate.set(name, +value + 12);
      } else {
        selectedDate.set(name, 12);
      }
    } else {
      selectedDate.set(name, +value);
    }
  }

  changeMeridiem(event: Event, meridiem: MeridiemType): void {
    event.stopPropagation();
    this.timePicker.meridiem = meridiem;
  }

  saveDate(): void {
    let selectedDate = this.getSelectedDate();
    selectedDate = selectedDate.set('hour', this.timePicker.hours).set('minute', this.timePicker.minutes);
    this.displayDate = selectedDate;
    if (this.timePicker.meridiem === 'am') {
      if (selectedDate.hour() >= 12) {
        this.displayDate = this.displayDate.subtract(12, 'hour');
      }
    } else if (selectedDate.hour() < 12) {
      this.displayDate = this.displayDate.add(12, 'hour');
    }
    this.dateDirectivePicker.datePicker.api.close();
    this.dateTimeEmitter.emit(this.displayDate.toISOString());
  }

  cancelDate(event: Event): void {
    event.stopPropagation();
    this.dateDirectivePicker.datePicker.api.close();
  }

  opened() {
    const calendarWrapper = document.querySelector('.date-time-widget');
    const timeSelector = document.createElement('div');
    timeSelector.style.display = 'block';
    timeSelector.id = 'timeSelector';
    if (calendarWrapper) {
      calendarWrapper.appendChild(this.timeSelector.nativeElement);

    }
    setTimeout(() => {
      this.dateDirectivePicker.datePicker.calendarContainer.nativeElement.children[0].appendChild(this.timeSelector.nativeElement);
    }, 0);
    const calendarOverlay = document.querySelector('.cdk-overlay-container');
    calendarOverlay?.addEventListener('click', ($event) => {
      $event.stopPropagation();
    });
  }

}
