import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ToastrModule, ToastContainerDirective } from 'ngx-toastr';
import { ToastComponent } from '../../components/toast/toast.component';

@NgModule({
  declarations: [
    ToastComponent
  ],
  imports: [
    CommonModule,
    ToastrModule.forRoot({
      positionClass: 'toast-top-center',
      closeButton: true
    }),
    ToastContainerDirective
  ],
  exports: [
    ToastComponent
  ]
})
export class ToastrSharedModule { }
