import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { LayoutRoutingModule } from './layout-routing.module';
import { LayoutComponent } from './layout.component';
import { HeaderComponent } from './header/header.component';
import { LeftNavigationComponent } from './leftnavigation/leftnavigation.component';
import { ContentComponent } from './content/content.component';
import { LeftMenuComponent } from './leftnavigation/leftmenu/leftmenu.component';
import { DashboardModule } from '../dashboard/dashboard.module';
import { ExerciseModule } from '../exercise/exercise.module';
import { ProjectBankModule } from '../projectbank/projectbank.module';
import { WrapperModule } from '../shared/modules/wrapper/wrapper.module';

@NgModule({
  declarations: [
    LayoutComponent,
    HeaderComponent,
    LeftNavigationComponent,
    ContentComponent,
    LeftMenuComponent
  ],
  imports: [
    CommonModule,
    LayoutRoutingModule,
    DashboardModule,
    ExerciseModule,
    ProjectBankModule,
    WrapperModule
  ]
})
export class LayoutModule { }