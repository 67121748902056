<div class="add-scroll d-flex px-24" [ngClass]="{'try-project-container': mode !== ProjectPlayerModes.EDIT }">

  <div class="left-content">
    <scrollbar class="scrollbar">
      <div class="left-content-scroll">
        <div class="accordion project-player-accordian lang-file" id="accordionPanelsStayOpenExample1">
          <div class="accordion-item project-player-accordian">
            <h2 class="accordion-header" id="panelsStayOpen-headingOne">
              <button
                class="accordion-button d-flex align-items-center justify-content-between heading-2  project-player-accordian-button"
                type="button" translate="projectBank.projectPlayer.languageFileHeading">
                <span class="accordion-toggle-icon cursor-pointer ms-1" [ngClass]="{'collapsed':!isNotTry}"
                  data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapseOne" [attr.aria-expanded]="isNotTry"
                  aria-controls="panelsStayOpen-collapseOne">
                </span>
              </button>
            </h2>
            <div id="panelsStayOpen-collapseOne" class="accordion-collapse collapse " [ngClass]="{'show':isNotTry}"
              aria-labelledby="panelsStayOpen-headingOne">
              <div class="accordion-body p-0">
                <app-file-explorer [mode]="mode">
                </app-file-explorer>
              </div>
            </div>
          </div>
        </div>
        <ng-container *ngIf="mode !== ProjectPlayerModes.TRY">
          <div
            *ngIf="['MYSQL_LARGE_DATASET','ORACLE_LARGE_DATASET', 'PLSQL_LARGE_DATASET'].includes(projectPlayerService.selectedArchetype)"
            class="accordion  project-player-accordian test-cases mb-24" id="accordionPanelsStayOpenExample2">
            <div class="accordion-item">
              <h2 class="accordion-header" id="panelsStayOpen-headingTwo">
                <button
                  class="accordion-button d-flex align-items-center justify-content-between heading-2 project-player-accordian-button"
                  type="button">
                  <span translate="projectBank.projectPlayer.initSql">
                    <svg-icon class="ms-1" src="../../../../assets/images/projectPlayer/i_icon.svg"></svg-icon>
                  </span>
                  <span class="accordion-toggle-icon cursor-pointer ms-1" data-bs-toggle="collapse"
                    data-bs-target="#panelsStayOpen-collapseTwo" aria-expanded="true"
                    aria-controls="panelsStayOpen-collapseTwo">
                  </span>
                </button>
              </h2>
              <div id="panelsStayOpen-collapseTwo" class="accordion-collapse collapse show"
                aria-labelledby="panelsStayOpen-headingTwo">
                <div class="accordion-body">
                  <ng-container *ngIf="!uploading && !uploaded; else uploadingOrUploaded">
                    <div class="file-upload-wrapper text-center">
                      <svg-icon src="../../../../assets/images/upload.svg"></svg-icon>
                      <span class="big-bold-assistive-text project-text ms-1"
                        translate="projectBank.projectPlayer.uploadSql"></span>
                      <input class="file-input" accept=".sql" type="file" (change)="onFileSelected($event)">
                    </div>
                  </ng-container>
                </div>
              </div>
            </div>
          </div>
        </ng-container>

        <div class="accordion  project-player-accordian test-cases mb-24" id="accordionPanelsStayOpenExample2"
          *ngIf="mode === ProjectPlayerModes.EDIT && !disableTestCase">
          <div class="accordion-item">
            <h2 class="accordion-header" id="panelsStayOpen-headingTwo">
              <button
                class="accordion-button d-flex align-items-center justify-content-between heading-2 project-player-accordian-button"
                type="button">
                <span translate="projectBank.projectPlayer.testCasesHeading">
                  <svg-icon class="ms-1" src="../../../../assets/images/projectPlayer/i_icon.svg"></svg-icon>
                </span>
                <span class="accordion-toggle-icon cursor-pointer ms-1" data-bs-toggle="collapse"
                  data-bs-target="#panelsStayOpen-collapseTwo" aria-expanded="true"
                  aria-controls="panelsStayOpen-collapseTwo">
                </span>
              </button>
            </h2>
            <div id="panelsStayOpen-collapseTwo" class="accordion-collapse collapse show"
              aria-labelledby="panelsStayOpen-headingTwo">
              <div class="accordion-body py-0">
                <app-test-cases></app-test-cases>
              </div>
            </div>
          </div>
        </div>


        <div class="project-clear-instructions" *ngIf="mode !== ProjectPlayerModes.PRACTISE">
          <scrollbar class="scrollbar">
            <app-project-details [mode]="mode"></app-project-details>
          </scrollbar>
        </div>
      </div>
    </scrollbar>

  </div>

  <div class="right-content">
    <div class="try-project-header d-flex justify-content-between" *ngIf="mode !== ProjectPlayerModes.EDIT">

      <!-- <div class="heading-1 d-flex align-items-center">
        {{title}}<span class="mark-card big-bold-assistive-text ml-16">1<span> Mark</span></span>
      </div>
      <div> -->
      <div class="heading-1 d-flex align-items-center">
        {{title}}
        <span class="mark-card big-bold-assistive-text ms-3" *ngIf="mode !== ProjectPlayerModes.PRACTISE">
          1<span> Mark</span>
        </span>
      </div>
      <div class="d-flex">
        <div *ngIf="mode === ProjectPlayerModes.PRACTISE" class="last-saved d-inline-flex flex-column mr-24">
          <div class="d-flex align-items-center justify-content-end cursor-pointer " (click)="savePractise()">
            <img src="../../../../assets/images/projectPlayer/save.svg"><span class="big-bold-assistive-text ms-2"
              translate="projectBank.projectPlayer.tryProject.save"></span>
          </div>
          <p class="m-0">
            Last saved at {{(projectPlayerService.getLastSavedStatus(this.projectId) | date:'h:mm a')||
            (defaultTime | date :'h:mm a')}}
          </p>
        </div>
        <div class="dropdown">
          <span class="dropdown-toggle flat-btn theme-dropdown align-items-center cursor-pointer d-flex"
            data-bs-toggle="dropdown" aria-expanded="false">
            <img class="me-1" src="../../../../assets/images/theme.svg">
            <p translate="projectBank.projectPlayer.tryProject.theme"></p>
          </span>

          <ul class="dropdown-menu">
            <li>
              <a class="dropdown-item" (click)="toggleTheme('light')"
                [ngClass]="selectedStatus ? 'theme-not-selected' : 'theme-selected'">
                <svg-icon class="theme-selected-icon pe-2" src="../../../../assets/images/tick.svg"></svg-icon>
                <svg-icon class="me-2" src="../../../../assets/images/projectPlayer/light_theme_icon.svg"></svg-icon>
                <p translate="projectBank.projectPlayer.tryProject.light"></p>
              </a>
            </li>
            <li>
              <a class="dropdown-item" (click)="toggleTheme('dark')"
                [ngClass]="selectedStatus ? 'theme-selected' : 'theme-not-selected'">
                <svg-icon class="theme-selected-icon pe-2" src="../../../../assets/images/tick.svg"></svg-icon>
                <svg-icon class="me-2" src="../../../../assets/images/projectPlayer/dark_theme_icon.svg"></svg-icon>
                <p translate="projectBank.projectPlayer.tryProject.dark"></p>
              </a>
            </li>
            <!-- <li>
              <a class="dropdown-item">
                <svg-icon class="theme-selected-icon pe-2" src="../../../../assets/images/tick.svg"></svg-icon>
                <svg-icon class="me-2" src="../../../../assets/images/projectPlayer/highContrast_theme_icon.svg">
                </svg-icon>
                <p translate="projectBank.projectPlayer.tryProject.highContrast"></p>
              </a>
            </li> -->
          </ul>
        </div>

        <div class="dropdown ms-3">
          <span class="dropdown-toggle flat-btn output-dropdown cursor-pointer d-flex" data-bs-toggle="dropdown"
            aria-expanded="false">
            <img class="me-1" src="../../../../assets/images/output.svg">
            <p translate="projectBank.projectPlayer.tryProject.output"></p>
          </span>
          <ul class="dropdown-menu">
            <li>
              <a class="dropdown-item" (click)="outputLayout(layout.SIDE)"
                [ngClass]="{'selected-layout': projectPlayerService.layout == layout.SIDE}">
                <svg-icon class="selected-layout-icon pe-2" src="../../../../assets/images/tick.svg"></svg-icon>
                <svg-icon class="me-2" src="../../../../assets/images/projectPlayer/side_panel_icon.svg"></svg-icon>
                <p translate="projectBank.projectPlayer.tryProject.sidePanel"></p>
              </a>
            </li>
            <li>
              <a class="dropdown-item" (click)="outputLayout(layout.DOWN)"
                [ngClass]="{'selected-layout': projectPlayerService.layout == layout.DOWN}">
                <svg-icon class="selected-layout-icon pe-2" src="../../../../assets/images/tick.svg"></svg-icon>
                <svg-icon class="me-2" src="../../../../assets/images/projectPlayer/down_panel_icon.svg"></svg-icon>
                <p translate="projectBank.projectPlayer.tryProject.downPanel"></p>
              </a>
            </li>
            <li>
              <a class="dropdown-item" (click)="outputLayout(layout.PICTURE)"
                [ngClass]="{'selected-layout': projectPlayerService.layout == layout.PICTURE}">
                <svg-icon class="selected-layout-icon pe-2" src="../../../../assets/images/tick.svg"></svg-icon>
                <svg-icon class="me-2" src="../../../../assets/images/projectPlayer/picture_in_picture_icon.svg">
                </svg-icon>
                <p translate="projectBank.projectPlayer.tryProject.pictureInPicture"></p>
              </a>
            </li>
            <li>
              <a class="dropdown-item" (click)="outputLayout(layout.NONE)"
                [ngClass]="{'selected-layout': projectPlayerService.layout == layout.NONE}">
                <svg-icon class="selected-layout-icon pe-2" src="../../../../assets/images/tick.svg"></svg-icon>
                <svg-icon class="me-2" src="../../../../assets/images/projectPlayer/none_icon.svg"></svg-icon>
                <p translate="projectBank.projectPlayer.tryProject.none"></p>
              </a>
            </li>
          </ul>
        </div>

      </div>

    </div>
    <app-file-editor [mode]="mode"></app-file-editor>

    <div class="d-flex justify-content-end footerBtn" *ngIf="mode === ProjectPlayerModes.EDIT">
      <button type="button" class="btn btn-secondary-grey" translate="projectBank.projectPlayer.cancel"
        (click)="cancelProject()" [disabled]="!projectService.isSaveAsDraftOrCancelEnable"></button>
      <button type="button" class="btn btn-secondary-orange mx-4" translate="projectBank.projectPlayer.saveAsDraft"
        (click)="saveAsDraft()" [disabled]="!projectService.isSaveAsDraftOrCancelEnable"></button>
      <button type="button" class="btn btn-primary" (click)="saveAndNavigate()"
        translate="projectBank.projectPlayer.saveContinue"></button>
    </div>

    <div class="d-flex justify-content-end footerBtn" *ngIf="mode === ProjectPlayerModes.TRY">
      <button type="button" class="btn btn-secondary-orange mx-4" (click)="editProject()"
        translate="projectBank.projectPlayer.tryProject.editProject"></button>
      <button type="button" class="btn btn-primary" (click)="exitTrial()"
        translate="projectBank.projectPlayer.tryProject.exitTrial"></button>
    </div>

    <div class="d-flex justify-content-end footerBtn modal-footer" *ngIf="mode === ProjectPlayerModes.PRACTISE">
      <button type="button" class="btn btn-secondary-grey m-0" (click)="navigatePractice()">Exit Practice
      </button>
      <button type="button" class="btn btn-primary ml-24 m-0" (click)="navigatePractice()">Save & Exit
      </button>
    </div>
  </div>

</div>

<ng-template #uploadingOrUploaded>
  <div class="file-uploadng-wrapper text-center" *ngIf="uploading">
    <svg-icon src="../../../../assets/images/uploading.svg"></svg-icon>
    <span class="big-bold-assistive-text body-sub-text ms-1" translate="projectBank.projectPlayer.uploading"></span>
  </div>

  <div *ngIf="!uploading && uploaded" class="d-flex align-items-center justify-content-between">
    <span class="big-body-text-2 align-items-center" *ngIf="initFile">{{initFile.name}}</span>
    <svg-icon class="ms-1 align-items-center" src="../../../../assets/images/delete-Icon.svg" (click)="deleteSqlFile()">
    </svg-icon>
  </div>
</ng-template>