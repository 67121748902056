<div class="content-container">
  <div class="p-24">
    <div class="page-title mb-0" [translate]="createMode? 'projectBank.createProject': 'projectBank.editProject'">
    </div>
  </div>

  <ng-container>

    <div class="px-24">
      <ul class="nav nav-tabs create-project-tabs" id="myTab" role="tablist">
        <li class="nav-item" role="presentation">
          <button class="nav-link" id="setup-tab" data-bs-toggle="tab" data-bs-target="#setup" type="button" role="tab"
            aria-controls="setup" aria-selected="false" translate="projectBank.setupName"
            [ngClass]="{'active': activatedTab === 'setup'}" (click)="tabSwitch(TabModes.SETUP)"></button>
        </li>
        <li class="nav-item" role="presentation">
          <button class="nav-link" id="console-tab" data-bs-toggle="tab" data-bs-target="#console" type="button"
            role="tab" aria-controls="console" aria-selected="false" translate="projectBank.consoleName"
            [ngClass]="{'active': activatedTab === 'console'}" [disabled]="!projectService.projectId"
            (click)="tabSwitch(TabModes.CONSOLE)"></button>
        </li>
        <li class="nav-item" role="presentation" *ngIf="!concealRubric">
          <button class="nav-link" id="rubric-tab" data-bs-toggle="tab" data-bs-target="#rubric" type="button"
            role="tab" aria-controls="rubric" aria-selected="false" translate="projectBank.rubricName"
            [ngClass]="{'active': activatedTab === 'rubrics'}" [disabled]="!projectService.projectId"
            (click)="tabSwitch(TabModes.RUBRICS)"></button>
        </li>
        <!-- <li class="nav-item" role="presentation">
          <button class="nav-link" id="try-tab" data-bs-toggle="tab" data-bs-target="#try" type="button" role="tab"
            aria-controls="try" aria-selected="false" (click)="tabSwitch(TabModes.TRY)">try</button>
        </li> -->
      </ul>
    </div>

    <!-- Tab panes -->
    <div class="tab-content">
      <div class="tab-pane " id="setup" role="tabpanel" aria-labelledby="setup-tab"
        [ngClass]="{'active': activatedTab === TabModes.SETUP}">
        <app-setup (hideRubric)="maskRubric($event)" (projectModeChange)="changeProjectPlayerMode($event)" *ngIf="activatedTab === TabModes.SETUP">
        </app-setup>
      </div>
      <div class="tab-pane " id="console" role="tabpanel" aria-labelledby="console-tab"
        [ngClass]="{'active': activatedTab === TabModes.CONSOLE}">
        <app-console *ngIf="activatedTab === TabModes.CONSOLE"></app-console>
      </div>
      <div class="tab-pane" id="rubric" role="tabpanel" aria-labelledby="rubric-tab"
        [ngClass]="{'active': activatedTab === TabModes.RUBRICS}">
        <app-rubrics *ngIf="activatedTab === TabModes.RUBRICS"></app-rubrics>
      </div>
      <!-- <div class="tab-pane" id="try" role="tabpanel" aria-labelledby="try-tab"
        [ngClass]="{'active': activatedTab === TabModes.TRY}">
        <app-try *ngIf="activatedTab === TabModes.TRY"></app-try>
      </div> -->
    </div>
  </ng-container>


</div>