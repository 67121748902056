import { Component, EventEmitter, Output } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import * as dayjs from 'dayjs';

@Component({
  selector: 'app-approve-request-modal',
  templateUrl: './approve-request-modal.component.html',
  styleUrls: ['./approve-request-modal.component.scss']
})
export class ApproveRequestModalComponent {

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  modalConfig = {
    message: 'approveRequestModal.text',
    message2: 'approveRequestModal.text2',
    confirmBtn: 'approveRequestModal.confirmButton',
    cancelBtn: 'approveRequestModal.cancelButton',
    note: 'approveRequestModal.note',
  };

  selectedDate!: string;
  currentDate = dayjs();

  @Output() confirmStatus = new EventEmitter();

  constructor(private translate: TranslateService,public activeModal: NgbActiveModal) {

  }
 
  public async dateTimeEmitter(event: string): Promise<void> {
    this.selectedDate = event;
  }

  sendConfirmStatus(): void {
    this.confirmStatus.emit(this.selectedDate);
  }

  sendCancelStatus(): void {
    this.confirmStatus.emit(false);
  }
}

