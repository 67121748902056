export enum ExerciseEventsKey {
  GET_EXERCISE_LIST = "get_exercise_list",
  EXERCISE_LIST = "exercise_list",
  CREATE_EXERCISE = "create_exercise",
  EXERCISE_CREATED = "exercise_created",
  GET_PROJECT_SELECTION_LIST = "get_project_selection_list",
  PROJECT_SELECTION_LIST = "project_selection_list",
  ADD_PROJECTS = "add_projects",
  PROJECTS_ADDED = "projects_added",
  PROJECT_LIST = "project_list",
  UPDATE_EXERCISE = "update_exercise",
  EXERCISE_METADATA_UPDATED = "exercise_metadata_updated",
  GET_EXERCISE_DETAILS = "get_exercise_details",
  EXERCISE_DEATILS = "exercise_details",
  REMOVE_PROJECTS = "remove_projects",
  PROJECTS_REMOVED = "projects_removed",
  EDIT_MARKS = "edit_marks",
  MARKS_EDITED = "marks_edited",
  REORDER_PROJECTS = "reorder_projects",
  PROJECTS_REORDERED = "projects_reordered",
  DELETE_EXERCISE = "delete_exercise",
  EXERCISE_DELETED = "exercise_deleted",
  CLONE_EXERCISE = "clone_exercise",
  EXERCISE_CLONED = "exercise_cloned",
  GET_NO_OF_SUBMISSIONS = "get_no_of_submissions",
  NO_OF_SUBMISSIONS = "no_of_submissions"
}