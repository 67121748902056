import { NgModule, Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'getkeys'
})
export class GetkeysPipe implements PipeTransform {

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  transform(value: any): string[] {
    return Object.keys(value);
  }

}
@NgModule({ declarations: [GetkeysPipe], exports: [GetkeysPipe] }) export class GetkeysPipeModule { }