import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ProjectBankComponent } from './project-bank/projectbank.component';
import { ViewProjectComponent } from './viewproject/viewproject.component';
import { RoutingParams } from '../enums/routingParameters';

const routes: Routes = [
  {
    path: '',
    component: ProjectBankComponent,
  },
  {
    path: ':projectId/' + RoutingParams.VIEW_PROJECT,
    component: ViewProjectComponent,
  },
  {
    path: ':projectId/' + RoutingParams.MANIPULATE_PROJECT,
    loadChildren: () => import('./project/project.module').then((module) => module.ProjectModule),
  },
  {
    path: ':projectId/' + RoutingParams.TRY_PROJECT,
    loadChildren: () => import('./../shared/modules/try-project/try-project.module').then((module) => module.TryProjectModule),
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ProjectBankRoutingModule { }
