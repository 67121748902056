import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Subject, takeUntil } from 'rxjs';
import { IexercutedQueries, IinitErorr } from 'src/app/models/projectInterface';
import { CommonService, ErrorType } from 'src/app/services/common.service';
import { ProjectPlayerService } from 'src/app/shared/modules/player/project-player.service';

@Component({
  selector: 'app-sql-file-uploader',
  templateUrl: './sql-file-uploader.component.html',
  styleUrls: ['./sql-file-uploader.component.scss']
})
export class SqlFileUploaderComponent {
  @Output() delete = new EventEmitter<boolean>();
  @Input() fileUploaderWidth = '0%';
  dataInsertedWidth = '0%';
  datainsertedQueries = 0;
  queryexercutedSubscription = new Subject<void>();
  initSqlFileExecutedSubscription = new Subject<void>();
  errorHandler = new Subject<void>();
  @Input() initFile: File | null = null;

  constructor(public projectPlayerService: ProjectPlayerService, private commonService: CommonService) { }
  ngOnInit() {
    this.fileuploadListeners();
  }

  deleteSqlFile() {
    this.delete.emit(true);
  }

  fileuploadListeners() {
    this.projectPlayerService.listenQueryExecuted().pipe(takeUntil(this.queryexercutedSubscription)).subscribe((exercutedQueries: IexercutedQueries) => {
      if (exercutedQueries.no_rows_affected === exercutedQueries.total_no_of_queries) {
        this.dataInsertedWidth = '90%';
        this.queryexercutedSubscription.next();
        this.queryexercutedSubscription.complete();
      } else {
        this.datainsertedQueries += exercutedQueries.no_rows_affected;
        const progressWidth = Math.round((this.datainsertedQueries / exercutedQueries.total_no_of_queries) * 100);
        if (!!progressWidth || progressWidth === 0) {
          const width = progressWidth >= 90 ? 90 : progressWidth;
          this.dataInsertedWidth = width.toString() + '%';
        }
      }
    });
    this.projectPlayerService.listenInitSqlFileExecuted().pipe(takeUntil(this.initSqlFileExecutedSubscription)).subscribe(() => {
      this.dataInsertedWidth = '100%';
      this.projectPlayerService.initFileUploaded.next();
      if (this.initFile) {
        const message = this.commonService.translateText('projectBank.projectPlayer.successUpload', { itemName: this.initFile.name });
        this.commonService.showToast(message, ErrorType.SUCESS);
      }
      this.delete.emit(false);
      this.queryexercutedSubscription.next();
      this.queryexercutedSubscription.complete();
      this.dataInsertedWidth = '0%';
      this.datainsertedQueries = 0;
      this.fileUploaderWidth = '0%';
      this.errorHandler.next();
      this.errorHandler.complete();
      this.initSqlFileExecutedSubscription.next();
      this.initSqlFileExecutedSubscription.complete();
    });
    this.projectPlayerService.listenValidationError().pipe(takeUntil(this.errorHandler)).subscribe(async (res: IinitErorr) => {
      if (res.errId === 'ERROR_EXECUTING_QUERY') {
        this.queryexercutedSubscription.next();
        this.queryexercutedSubscription.complete();
        this.dataInsertedWidth = '0%';
        this.datainsertedQueries = 0;
        this.fileUploaderWidth = '0%';
        this.initSqlFileExecutedSubscription.next();
        this.initSqlFileExecutedSubscription.complete();
        this.errorHandler.next();
        this.errorHandler.complete();
      }
    });
  }

  ngOnDestroy() {
    this.queryexercutedSubscription.next();
    this.queryexercutedSubscription.complete();
    this.initSqlFileExecutedSubscription.next();
    this.initSqlFileExecutedSubscription.complete();
    this.errorHandler.next();
    this.errorHandler.complete();
  }
}
