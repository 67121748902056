/* eslint-disable no-console */
import { Component, Input } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Archetype } from 'src/app/models/project-list-card';
import { ProjectPlayerService } from '../../modules/player/project-player.service';
import { LocationStrategy } from '@angular/common';
import { LayoutModes } from 'src/app/enums/layoutModes';
import { ProjectPlayerModes, Themes } from 'src/app/enums/PlayerModes';
import { ExercisePlayerService } from '../../modules/player/exercise-player.service';
import { IAttemptData, IAttemptProjectDetails, ISubmitProject } from 'src/app/models/attemptInterface';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';
import { DialogService } from 'src/app/services/dialog.service';
import { DialogTypes } from 'src/app/enums/dialog';
import { Dialog } from 'src/app/models/dialog';
import { urlpayload } from 'src/app/models/projectInterface';
import { CommonService, ErrorType } from 'src/app/services/common.service';

@Component({
  selector: 'app-exercise-player',
  templateUrl: './exercise-player.component.html',
  styleUrls: ['./exercise-player.component.scss'],
  providers: [{
    provide: ProjectPlayerService,
    useClass: ExercisePlayerService
  }]
})
export class ExercisePlayerComponent {
  @Input() mode!: ProjectPlayerModes;
  @Input() archetypes!: Archetype[];
  @Input() attemptDataDetails!: IAttemptData;
  @Input() exerciseId!: string;
  @Input() redirectBackUrl!: string;
  exercisePlayerModes = ProjectPlayerModes;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public treeData!: any | null;
  currentProject!: IAttemptProjectDetails;
  fileContent!: string;
  isPreSubmissionScreen = false;
  layout = LayoutModes;
  selectedStatus = false;
  isProjectChangeRequired = false;
  currentProjectIndex = 0;
  defaultTime = new Date();
  toastMessage!: string;
  toastTitle!: string;
  currentTheme!: string;
  autoSaveInterval!: any;

  // eslint-disable-next-line max-params
  constructor(private modal: NgbModal, private activatedRoute: ActivatedRoute, private router: Router, public projectPlayerService: ProjectPlayerService, private location: LocationStrategy, private toastService: ToastrService, private translationService: TranslateService, private dialogService: DialogService, private commonService: CommonService) { }
  modalRef!: NgbModalRef;

  ngOnInit() {
    this.projectPlayerService.exerciseId = this.exerciseId;
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const projectId: string = this.activatedRoute.snapshot.paramMap.get('projectId')!;
    this.projectPlayerService.projectId = this.attemptDataDetails.projects[0].projectId || projectId;
    this.projectPlayerService.archetypes = this.attemptDataDetails.projects[0].archetypes;
    this.projectPlayerService.mode = this.mode;
    this.projectPlayerService.projectDetails = this.attemptDataDetails.projects[0];
    this.projectPlayerService.attemptId = this.attemptDataDetails.attemptId;
    this.currentProject = this.attemptDataDetails.projects[0];
    this.projectPlayerService.timeLimitInSeconds = this.attemptDataDetails.timeLeft || 1800;
    this.projectPlayerService.attemptData = this.attemptDataDetails;
    this.isPreSubmissionScreen = this.activatedRoute.snapshot.queryParamMap.get('isPreSubmit') === 'true';
    this.setProjectStatus();
    this.projectPlayerService.redirectBack = this.redirectBackUrl;
  }

  setProjectStatus() {
    this.attemptDataDetails.projects.forEach((project: IAttemptProjectDetails) => {
      const isProjectAnswered = ["graded", "submitted"].includes(project.status as string);
      this.projectPlayerService.setAnswerStatus(project.projectId, isProjectAnswered);
      this.projectPlayerService.setMarkedForReview(project.projectId, project.isMarkedForReview);
    });
  }

  ngAfterViewInit() {
    this.autoSave();
    if (this.attemptDataDetails.exerciseKind !== 'practice') {
      this.projectPlayerService.startCountdown();
    }
  }

  autoSave() {
    this.autoSaveInterval = setInterval(async () => {
      const savedFiles = await this.projectPlayerService.autoSave();
      if (savedFiles) {
        this.projectPlayerService.updateProjectArchetypesMap(this.currentProject.projectId, this.projectPlayerService.selectedArchetype);
        if (this.projectPlayerService.getSubmitState()) {
          const updateProjectUnSubscribe = this.projectPlayerService.updateProjectStatus().subscribe(() => {
            this.projectPlayerService.setAnswerStatus(this.currentProject.projectId, false);
            this.projectPlayerService.setSubmitState(false);
            updateProjectUnSubscribe.unsubscribe();
          });
        }
      }
    }, 10000);
  }

  async submitCurrentProjectAndSwitchProject(project: IAttemptProjectDetails, projectIndex?: number, projectChange = true, isSubmitted = false) {
    if (projectIndex || projectIndex === 0) {
      this.currentProjectIndex = projectIndex;
    }
    if (this.currentProject.archetypes[0].archetypeId === 'JAVA17_SPRINGBOOT') {
      this.killPort();
    }
    this.isProjectChangeRequired = projectChange;
    if (this.projectPlayerService.getSubmitState()) {
      const savedFiles = await this.projectPlayerService.saveAllFiles();
      if (savedFiles) {
        this.projectPlayerService.updateProjectArchetypesMap(this.currentProject.projectId, this.projectPlayerService.selectedArchetype);
        if (!isSubmitted) {
          this.setMessageToast(project);
          return;
        }
        this.submitProject(project);
      }
    } else {
      if (isSubmitted) {
        this.submitProject(project, false);
        return;
      }
      this.setMessageToast(project, false);
    }
  }

  submitProject(project: IAttemptProjectDetails, toast = true) {
    const updateProjectUnSubscribe = this.projectPlayerService.updateProjectStatus('submitted').subscribe(() => {
      this.projectPlayerService.setAnswerStatus(this.currentProject.projectId, true);
      this.setMessageToast(project, toast);
      updateProjectUnSubscribe.unsubscribe();
    });
  }

  setMessageToast(project: IAttemptProjectDetails, toast = true) {
    if (toast) {
      this.projectPlayerService.setSubmitState(false);
      this.translationService.get('toastMessages.saveProject.message', { projectTitle: this.currentProject.title }).subscribe((translation) => {
        this.toastMessage = translation;
      }).unsubscribe();
      this.translationService.get('toastMessages.saveProject.title').subscribe((translation) => {
        this.toastTitle = translation;
      }).unsubscribe();
      this.commonService.showToast(this.toastMessage, ErrorType.SUCESS, undefined, this.toastTitle);
      this.projectPlayerService.clearTab();
      if (this.isProjectChangeRequired) {
        this.loadProjectSwitchDependencies(project);
      }
      return;
    }
    this.projectPlayerService.clearTab();
    this.loadProjectSwitchDependencies(project);
  }

  loadProjectSwitchDependencies(project: IAttemptProjectDetails) {
    this.isProjectChangeRequired = false;
    this.projectPlayerService.projectDetails = project;
    this.currentProject = project;
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const projectId: string = this.activatedRoute.snapshot.paramMap.get('projectId')!;
    this.projectPlayerService.projectId = project.projectId || projectId;
    this.projectPlayerService.archetypes = project.archetypes;
    this.projectPlayerService.triggerProjectChange(project);
    this.defaultTime = new Date();
  }

  killPort() {
    if (!this.projectPlayerService.storedUrlToken) {
      return;
    }
    const payload: urlpayload = {
      urlToken: this.projectPlayerService.storedUrlToken
    };
    this.projectPlayerService.kill_port(payload);
  }

  async saveFile() {
    if (this.projectPlayerService.getSubmitState()) {
      const savedFiles = await this.projectPlayerService.saveAllFiles();
      if (savedFiles) {
        this.projectPlayerService.updateProjectArchetypesMap(this.currentProject.projectId, this.projectPlayerService.selectedArchetype);
        const updateProjectUnSubscribe = this.projectPlayerService.updateProjectStatus().subscribe(() => {
          this.projectPlayerService.setAnswerStatus(this.currentProject.projectId, false);
          updateProjectUnSubscribe.unsubscribe();
        });
        this.projectPlayerService.setSubmitState(false);
        //SHOW PROJECT SAVED SUCCESS TOAST MESSAGE
        this.translationService.get('toastMessages.saveProject.message', { projectTitle: this.currentProject.title }).subscribe((translation) => {
          this.toastMessage = translation;
        }).unsubscribe();
        this.translationService.get('toastMessages.saveProject.title').subscribe((translation) => {
          this.toastTitle = translation;
        }).unsubscribe();
        this.commonService.showToast(this.toastMessage, ErrorType.SUCESS, undefined, this.toastTitle);
      }
    }
  }

  toggleTheme(theme: string) {
    this.currentTheme = theme;
    this.selectedStatus = theme === 'dark' ? true : false;
    const selectedTheme = this.selectedStatus ? Themes.DARK : Themes.LIGHT;
    this.projectPlayerService.triggerThemeChange(selectedTheme);
    document.documentElement.setAttribute('data-theme', theme);
  }

  outputLayout(layout: LayoutModes) {
    this.projectPlayerService.layout = layout;
  }

  ngOnDestroy() {
    this.projectPlayerService.tabs = [];
    this.toggleTheme('light');
    clearInterval(this.autoSaveInterval);
  }

  navigateToPreSubmission() {
    this.isPreSubmissionScreen = true;
    this.router.navigate([], { relativeTo: this.activatedRoute, queryParams: { isPreSubmit: true }, queryParamsHandling: 'merge' });
  }

  backToTest(event: { shift: boolean, projectIndex: number }) {
    this.isPreSubmissionScreen = event.shift;
    this.router.navigate([], { relativeTo: this.activatedRoute, queryParams: { isPreSubmit: false }, queryParamsHandling: 'merge' });
    if (event.projectIndex && event.projectIndex >= 0) {
      this.submitCurrentProjectAndSwitchProject(this.attemptDataDetails.projects[event.projectIndex], event.projectIndex);
    }
  }

  toggledThemeStatus(theme: string) {
    this.toggleTheme(theme);
  }

  markForReview(projectId: string) {
    const reviewed = this.projectPlayerService.getMarkedForReview(projectId);
    const obj = {
      attemptId: this.projectPlayerService.attemptId,
      projectId: this.projectPlayerService.projectId,
      isMarkedForReview: !reviewed,
    };
    this.projectPlayerService.projectMarkedForReview(obj).subscribe(() => {
      this.projectPlayerService.setMarkedForReview(projectId, obj.isMarkedForReview);
    });
  }

  navigateProject(direction: 'next' | 'previous', isSubmitted = false) {
    if (direction === 'next' && this.currentProjectIndex < this.attemptDataDetails.projects.length - 1) {
      this.currentProjectIndex++;
    } else if (direction === 'previous' && this.currentProjectIndex > 0) {
      this.currentProjectIndex--;
    }
    this.submitCurrentProjectAndSwitchProject(this.attemptDataDetails.projects[this.currentProjectIndex], undefined, undefined, isSubmitted);
  }

  async submitProjectModal() {
    let message!: string;
    let note!: string;
    let options = [];
    this.translationService.get('selectionModal.text', { projectIndex: this.currentProjectIndex + 1 }).subscribe((translation) => {
      message = translation;
    }).unsubscribe();
    this.translationService.get('selectionModal.note').subscribe((translation) => {
      note = translation;
    }).unsubscribe();
    //options = this.projectPlayerService.archetypes;
    const currentProjectId = this.currentProject.projectId;
    const archetypesForCurrentProject = this.projectPlayerService.projectArchetypesMap.get(currentProjectId) || [];
    options = this.projectPlayerService.archetypes.filter(archetype => archetypesForCurrentProject.includes(archetype.archetypeId));
    console.log(options);

    if (options.length > 1) {
      const dialog: Dialog = { title: { translationKey: message }, note: { translationKey: note }, type: DialogTypes.SINGLESELECT, archetypeOptions: options, selectedArchetype: this.projectPlayerService.selectedArchetype, };
      const confirmation = await this.dialogService.showSelectionDialog(dialog);
      if (confirmation) {
        this.submitAndNavigateToNextEvent(confirmation);
      }
    }
    else if (options.length === 1) {
      this.submitAndNavigateToNextEvent(options[0].archetypeId);
    }
    else {
      this.submitAndNavigateToNextEvent(this.projectPlayerService.selectedArchetype);
    }
  }

  submitAndNavigateToNextEvent(archetypeId: string) {
    const submitProjectData: ISubmitProject = {
      exerciseId: this.attemptDataDetails.exerciseId,
      attemptId: this.projectPlayerService.attemptId,
      projectId: this.projectPlayerService.projectId,
      archetypeId: archetypeId,
    };
    const unSubscribeSubmitProject = this.projectPlayerService.submitProject(submitProjectData).subscribe(() => {
      if (this.currentProjectIndex === this.attemptDataDetails.projects.length - 1) {
        this.submitCurrentProjectAndSwitchProject(this.attemptDataDetails.projects[this.currentProjectIndex], this.currentProjectIndex, false, true);
        this.navigateToPreSubmission();
      }
      else {
        this.navigateProject('next', true);
      }
      unSubscribeSubmitProject.unsubscribe();
    });
  }

}
