import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AngularSvgIconModule } from 'angular-svg-icon';
import { ScrollbarModule } from '../scrollbar/scrollbar.module';
import { NG_EVENT_PLUGINS } from '@tinkoff/ng-event-plugins';
import { InfiniteScrollComponent } from '../../components/infinite-scroll/infinite-scroll.component';
import { DraggableDirective } from 'src/app/directives/draggable.directive';
import { InfiniteScrollDirective } from 'src/app/directives/infinite-scroll.directive';
import { SkeletonUiModule } from '../skeleton-ui/skeleton-ui.module';
import { DragDropArrayDirective } from 'src/app/directives/drag-drop-array.directive';
import { ToastrSharedModule } from '../toastr/toastr-shared.module';
import { NgSelectModule } from '@ng-select/ng-select';
import { CopyClipboardDirective } from 'src/app/directives/copy-clipboard.directive';

@NgModule({
  declarations: [InfiniteScrollComponent,
    DraggableDirective,
    InfiniteScrollDirective,
    DragDropArrayDirective,
    CopyClipboardDirective
  ],
  imports: [
    CommonModule,
    TranslateModule,
    ReactiveFormsModule,
    AngularSvgIconModule,
    ScrollbarModule,
    SkeletonUiModule,
    FormsModule,
    ToastrSharedModule,
    NgSelectModule,
  ],
  exports: [
    TranslateModule,
    ReactiveFormsModule,
    AngularSvgIconModule,
    ScrollbarModule,
    InfiniteScrollComponent,
    DraggableDirective,
    InfiniteScrollDirective,
    SkeletonUiModule,
    DragDropArrayDirective,
    FormsModule,
    ToastrSharedModule,
    NgSelectModule,
    CopyClipboardDirective
  ],
  providers: [NG_EVENT_PLUGINS]
})
export class WrapperModule { }
