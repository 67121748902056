import { Directive, ElementRef, HostBinding, HostListener, Renderer2 } from '@angular/core';

@Directive({
  selector: '[appDraggable]'
})
export class DraggableDirective {
  private isDragging = false;
  private initialX = 0;
  private initialY = 0;

  constructor(private renderer: Renderer2, private elementRef: ElementRef) { }
  @HostBinding('class') elementClass = 'draggable-window';

  @HostListener('mousedown', ['$event'])
  onMouseDown(event: MouseEvent) {
    this.isDragging = true;
    this.initialX = event.clientX - this.elementRef.nativeElement.offsetLeft;
    this.initialY = event.clientY - this.elementRef.nativeElement.offsetTop;
  }

  @HostListener('document:mousemove', ['$event'])
  onMouseMove(event: MouseEvent) {
    if (!this.isDragging) { return; }

    const newX = event.clientX - this.initialX;
    const newY = event.clientY - this.initialY;

    this.renderer.setStyle(this.elementRef.nativeElement, 'left', `${newX}px`);
    this.renderer.setStyle(this.elementRef.nativeElement, 'top', `${newY}px`);

  }

  @HostListener('document:mouseup')
  onMouseUp() {
    this.isDragging = false;
  }
}
