/* eslint-disable @typescript-eslint/no-explicit-any */
import { Component, EventEmitter, HostBinding, Input, OnChanges, OnInit, Output } from '@angular/core';
import * as Editor from '@edunxtv2/unextlabs-ckeditor/ckeditor';
import { distinctUntilChanged } from 'rxjs';
import { CommonUtils } from 'src/app/utils/common-utils';
import { MyUploadAdapter } from './adapter';
import { CkEditorService } from 'src/app/services/ck-editor.service';
import { CommonService } from 'src/app/services/common.service';

@Component({
  selector: 'app-ck-editor',
  templateUrl: './ck-editor.component.html',
  styleUrls: ['./ck-editor.component.scss'],
})
export class CkEditorComponent implements OnInit, OnChanges {
  @Input() ckeditorFormControl!: any;
  @Input() characterCountLimit = 0;
  @Input() lineCountLimit = 0;
  @Input() config: any = {};
  @Input() readonly = false;
  @Output() inputText = new EventEmitter<any>();
  public Editor = Editor;
  @Input() inline = false;
  @Input() ckheight: any;
  @Input() data!: any;

  @HostBinding('style.--ck-height') height: any;
  public initialText = '';

  constructor(private commonUtils: CommonUtils, private commonService: CommonService, private ckEditorService: CkEditorService) { }

  ngOnInit(): void {
    this.height = this.ckheight;
    this.checkCharacterLineCount();
    this.config.removePlugins = ['Title'];
  }

  onReady(editor: any): void {
    editor.plugins.get('FileRepository').createUploadAdapter = (loader: any) => {
      return new MyUploadAdapter(loader, this.ckEditorService, this.commonService);
    };
  }

  ngOnChanges(): void {
    this.checkCharacterLineCount();
  }

  private checkCharacterLineCount(): void {
    let lineCount = 0;
    if (
      this.ckeditorFormControl &&
      (this.lineCountLimit > 0 || this.characterCountLimit > 0)
    ) {
      this.ckeditorFormControl.valueChanges
        .pipe(distinctUntilChanged())
        .subscribe((val: string) => {
          if (val) {
            const lines = val.split(/\r*<p>/);
            lineCount = lines.length - 1;
            const plainText = this.commonUtils.removeHTML(val);
            if (
              (lineCount > this.lineCountLimit && this.lineCountLimit > 0) ||
              (plainText.length > this.characterCountLimit &&
                this.characterCountLimit > 0)
            ) {
              this.ckeditorFormControl.setValue(this.initialText, {
                emitEvent: false,
              });
              this.ckeditorFormControl.updateValueAndValidity();
            } else {
              this.initialText = val;
            }
          }
        });
    }
  }

}