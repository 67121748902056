<div class="inner-container">
  <scrollbar class="scrollbar">
    <form [formGroup]="exerciseSetup" class="px-24 mt-24 add-scroll ">
      <div class="form-group">
        <label class="form-label required" translate="exercise.excerciseSetup.title"></label>
        <input class="form-control" formControlName="title"
          placeholder="eg-Resolving Java NullPointerException: Quick Fixes and Tips">
      </div>
      <div class="form-group">
        <label class="form-label" translate="exercise.excerciseSetup.description"></label>
        <app-ck-editor [ckeditorFormControl]="exerciseSetup.controls['description']"></app-ck-editor>
      </div>
      <div class="form-group">
        <label class="form-label" translate="exercise.excerciseSetup.instruction"></label>
        <app-ck-editor [ckeditorFormControl]="exerciseSetup.controls['instruction']"></app-ck-editor>
      </div>
      <div id="elId1" #target></div>
      <!-- Practice Validity -->
      <div *ngIf="exerciseTypeUrl === 'practice'" class="accordion">
        <div class="accordion-item">
          <h2 class="accordion-header">
            <button class="accordion-button  collapsed big-bold-assistive-text  project-player-accordian-button"
              type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapseValidity"
              aria-expanded="false" aria-controls="panelsStayOpen-collapseValidity"
              translate="exercise.excerciseSetup.idealTime">
            </button>
          </h2>
          <div id="panelsStayOpen-collapseValidity" class="accordion-collapse "
            aria-labelledby="panelsStayOpen-headingValidity">
            <div class="accordion-body">
              <div>
                <div class="row">
                  <div class="col-3 body-text" translate="exercise.excerciseSetup.idealTimeRequiredToComplete">
                  </div>
                  <div class="col-3">
                    <div class="row ml-16">
                      <div class="col body-text">
                        <span class="d-flex align-items-center"><input type="number" class="form-control"
                            formControlName="practiceIdealHours">
                          <span class=" ms-2" translate="exercise.excerciseSetup.hours"></span></span>
                      </div>
                      <div class="col body-text ">
                        <span class="d-flex align-items-center"><input type="number" class="form-control"
                            formControlName="practiceIdealMins"><span class="ms-2 text-nowrap"
                            translate="exercise.excerciseSetup.mins"></span></span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

      </div>
      <div *ngIf="exerciseTypeUrl !== 'practice'" class="container-fluid pb-16">
        <div class="row row-container mt-24">
          <div class="col-6 col-content">
            <!-- Content -->
            <div class="accordion">
              <div class="accordion-item">
                <h2 class="accordion-header">
                  <button class="accordion-button collapsed big-bold-assistive-text " type="button"
                    data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapseContent" aria-expanded="false"
                    aria-controls="panelsStayOpen-collapse1">
                    <span translate="exercise.excerciseSetup.content">

                    </span>
                  </button>
                </h2>
                <div id="panelsStayOpen-collapseContent" class="accordion-collapse collapse"
                  aria-labelledby="panelsStayOpen-headingContent">
                  <div class="accordion-body">
                    <div class="row content-status">
                      <div class="col body-bold-assistive-text" translate="exercise.excerciseSetup.contentStatus">
                      </div>
                      <div class="col">
                        <div class="d-flex flex-wrap justify-content-sm-start justify-content-md-end">
                          <div class="form-check form-check-inline mb-0">
                            <label class="form-check-label custom-radio">
                              <input class="form-check-input" type="radio" formControlName="contentStatus"
                                [value]="ContentStatus.MANDATORY" />
                              <span class="body-text" translate="exercise.excerciseSetup.mandatory"></span>
                            </label>
                          </div>
                          <div class="form-check form-check-inline m-0">
                            <label class="form-check-label  custom-radio">
                              <input class="form-check-input custom-radio" type="radio" formControlName="contentStatus"
                                [value]="ContentStatus.OPTIONAL" />
                              <span class="body-text" translate="exercise.excerciseSetup.optional"></span>
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- Validity -->
            <div class="accordion mt-32">
              <div class="accordion-item">
                <h2 class="accordion-header">

                  <button class="accordion-button collapsed big-bold-assistive-text" type="button"
                    data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapseValidity" aria-expanded="false"
                    aria-controls="panelsStayOpen-collapseValidity">
                    <span class="required" translate="exercise.excerciseSetup.valdityandTimeLimit">

                    </span>
                  </button>
                </h2>
                <ng-container formGroupName="duration">
                  <div id="panelsStayOpen-collapseValidity" class="accordion-collapse collapse"
                    aria-labelledby="panelsStayOpen-headingValidity">
                    <div class="accordion-body">
                      <div>
                        <div class="container-fluid">
                          <div class="row mb-24 mt-16">
                            <p class="body-bold-assistive-text" translate="exercise.excerciseSetup.validity"></p>
                          </div>
                          <div class="row mb-24">
                            <div class="col-6 body-text required" translate="exercise.excerciseSetup.startDateTime">
                            </div>
                            <div class="col-6 body-text date-position">
                              <app-date-time-picker [disabled]="isDisable" [restrictSelectionBeforeCurrentDate]="true"
                                (dateTimeEmitter)="dateTimeEmitter($event, 'startDate')" [maxStartDate]="currentDate"
                                [maxEndDate]="exerciseSetup.controls['duration'].value.endDate"
                                [defaultDisplayValue]="exerciseSetup.controls['duration'].value.startDate"
                                [enableCancelButton]="true"></app-date-time-picker>
                            </div>
                          </div>
                          <div class="row mb-32">
                            <div class="col body-text required" translate="exercise.excerciseSetup.endDateTime">
                            </div>
                            <div class="col date-position">
                              <app-date-time-picker [restrictSelectionBeforeCurrentDate]="true"
                                (dateTimeEmitter)="dateTimeEmitter($event, 'endDate')"
                                [maxStartDate]="exerciseSetup.controls['duration'].value.startDate"
                                [defaultDisplayValue]="exerciseSetup.controls['duration'].value.endDate"
                                [enableCancelButton]="true"></app-date-time-picker>
                            </div>
                          </div>
                        </div>
                      </div>
                      <hr class="mb-24" />
                      <div class="row content-status mb-24">
                        <div class="col body-bold-assistive-text" translate="exercise.excerciseSetup.timeLimit">
                        </div>
                        <div class="col">
                          <div class="d-flex flex-wrap justify-content-sm-start justify-content-md-end">
                            <div class="form-check form-check-inline mb-0 inline-form-components">
                              <label class="form-check-label custom-radio">
                                <input class="form-check-input" type="radio" value="Required"
                                  formControlName="timeLimit" />
                                <span class="body-text" translate="exercise.excerciseSetup.required"></span>
                              </label>
                            </div>
                            <div class="form-check form-check-inline m-0 inline-form-components">
                              <label class="form-check-label custom-radio">
                                <input class="form-check-input" type="radio" formControlName="timeLimit"
                                  value="Not required" />
                                <span class="body-text" translate="exercise.excerciseSetup.notRequired"></span>
                              </label>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="row mb-16" *ngIf="durationLimitExercise">
                        <div class="col-6 body-text"
                          translate="exercise.excerciseSetup.durationLimitforCompletingExercise">
                        </div>
                        <div class="col-6">
                          <div class="row ml-16">
                            <div class="col body-text">
                              <span class="d-flex align-items-center"><input type="number" class="form-control" min="0"
                                  max="23" formControlName="durationHours">
                                <span class="ms-2 text-nowrap" translate="exercise.excerciseSetup.hours"></span></span>
                            </div>
                            <div class="col body-text ">
                              <span class="d-flex align-items-center"><input type="number" class="form-control" min="0"
                                  max="59" formControlName="durationMins"><span class="ms-2 text-nowrap"
                                  translate="exercise.excerciseSetup.mins"></span></span>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-6 body-text" translate="exercise.excerciseSetup.idealTimeRequiredToComplete">
                        </div>
                        <div class="col-6">
                          <div class="row ml-16">
                            <div class="col body-text">
                              <span class="d-flex align-items-center"><input type="number" class="form-control" min="0"
                                  max="23" formControlName="idealHours">
                                <span class="ms-2 text-nowrap" translate="exercise.excerciseSetup.hours"></span></span>
                            </div>
                            <div class="col body-text ">
                              <span class="d-flex align-items-center"><input type="number" class="form-control" min="0"
                                  max="59" formControlName="idealMins"><span class="ms-2 text-nowrap"
                                  translate="exercise.excerciseSetup.mins"></span></span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </ng-container>
              </div>
            </div>
            <!-- Feedback -->
            <div class="accordion mt-32">
              <div class="accordion-item">
                <h2 class="accordion-header">
                  <button class="accordion-button collapsed big-bold-assistive-text" type="button"
                    data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapseFeedback" aria-expanded="false"
                    aria-controls="panelsStayOpen-collapseFeedback"
                    translate="exercise.excerciseSetup.feedbackSettings">
                  </button>
                </h2>
                <div id="panelsStayOpen-collapseFeedback" class="accordion-collapse collapse body-bold-assistive-text"
                  aria-labelledby="panelsStayOpen-headingFeedback">
                  <div class="accordion-body">
                    <div class="row mt-16 mb-24">
                      <div class="col" translate="exercise.excerciseSetup.rangeBasedFeedback">
                      </div>
                      <div class=" col d-flex justify-content-end">
                        <div class="form-check form-switch  form-switch-green ">
                          <input class="form-check-input" type="checkbox" role="switch" formControlName="allowFeedback"
                            (change)="enableFormField($event,'range')" />
                        </div>
                      </div>
                    </div>
                    <div *ngIf="isRangeDisplay">
                      <div formArrayName="feedbackDetails">
                        <label class="body-text error-message" translate="exercise.excerciseSetup.rangeDoesNotOverlap"
                          *ngIf="isValidRange|| isCompareRangeValid"></label>
                        <ng-container *ngFor="let feedbackControl of feedbackArr?.controls; let i=index"
                          [formGroupName]="i">
                          <div class="row mb-16">
                            <p class="col body-text">{{ "exercise.excerciseSetup.range" |
                              translate}}{{i+1}}
                            </p>
                            <span *ngIf="i>0" class="col d-flex justify-content-end" style="cursor: pointer;"
                              (click)="deleteOption(i)">
                              <svg-icon src="../../../../assets/images/exerciseBuilder/cross.svg"></svg-icon>
                            </span>
                          </div>
                          <div class="row align-items-center mb-16">
                            <span class="w-100 d-flex align-items-center">
                              <span class="svg-rangevector">
                                <svg-icon src="../../../../assets/images/exerciseBuilder/range-vector.svg"></svg-icon>
                              </span>

                              <span class="d-flex align-items-center ml-16"
                                translate="exercise.excerciseSetup.startRange">
                                <input type="number" [value]="feedbackControl.value.startRange"
                                  formControlName="startRange" class="form-control input-range ml-16"
                                  (keyup)="validateUniqueValue()">
                              </span>
                            </span>
                            <label translate="exercise.excerciseSetup.startRangeErrorMsg"
                              class=" error-message form-label required"
                              *ngIf="feedbackArr.controls[i]?.get('startRange')?.errors?.['min']"></label>
                            <label translate="exercise.excerciseSetup.startRangeExceedErrorMsg"
                              class="error-message form-label required"
                              *ngIf="feedbackArr.controls[i]?.get('startRange')?.errors?.['max']"></label>
                          </div>
                          <div class="row align-items-center mb-16">
                            <span class="w-100 d-flex align-items-center">
                              <span class="svg-rangevector">
                                <svg-icon src="../../../../assets/images/exerciseBuilder/range-vector.svg"></svg-icon>
                              </span>
                              <span class="d-flex align-items-center ml-16"
                                translate="exercise.excerciseSetup.endRange">
                                <input type="number" [value]="feedbackControl.value.endRange" formControlName="endRange"
                                  class="form-control input-range ml-16" (keyup)="validateUniqueValue()">
                              </span>
                            </span>
                            <label translate="questionType.generalSetting.endRangeErrorMsg"
                              class="mr8 error-message form-label required"
                              *ngIf="feedbackArr.controls[i]?.get('endRange')?.errors?.['min']"></label>
                            <label translate="questionType.generalSetting.endRangeErrorExceedMsg"
                              class="mr8 error-message form-label required"
                              *ngIf="feedbackArr.controls[i]?.get('endRange')?.errors?.['max']"></label>
                            <br>
                          </div>
                          <div class="row align-items-center mb-24 g-0">
                            <span class="w-100 d-flex align-items-center">
                              <span class="svg-rangevector">
                                <svg-icon src="../../../../assets/images/exerciseBuilder/range-vector.svg"></svg-icon>
                              </span>
                              <span class="ml-16" translate="exercise.excerciseSetup.feedback">
                              </span>
                              <textarea class="form-control w-auto flex-fill ml-16" rows="3"
                                placeholder="{{ 'exercise.excerciseSetup.typeHere' | translate }}"
                                formControlName="feedback"></textarea>
                            </span>

                          </div>
                        </ng-container>
                        <span class="d-flex align-items-center newTestCase " (click)="addNewOption()">
                          <span>
                            <svg-icon src="../../../../assets/images/exerciseBuilder/add.svg"></svg-icon>
                          </span>
                          <span class="primary-text ms-2" translate="exercise.excerciseSetup.addNewRange"></span>
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

          </div>
          <div class="col-6 col-content ">

            <!-- Plagiarism -->
            <div class="accordion">
              <div class="accordion-item">
                <h2 class="accordion-header">
                  <button class="accordion-button collapsed big-bold-assistive-text " type="button"
                    data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapsePlagiarism" aria-expanded="false"
                    aria-controls="panelsStayOpen-collapsePlagiarism" translate="exercise.excerciseSetup.plagiarism">
                  </button>
                </h2>
                <div id="panelsStayOpen-collapsePlagiarism" class="accordion-collapse collapse mt-3"
                  aria-labelledby="panelsStayOpen-Plagiarism">
                  <div class="accordion-body">
                    <div class="row align-items-center">
                      <div class="col body-bold-assistive-text" translate="exercise.excerciseSetup.enablePlagiarism">
                      </div>
                      <div class="col d-flex justify-content-end">
                        <div class="form-check form-switch  form-switch-green mb-0">
                          <input class="form-check-input" type="checkbox" role="switch" id="flexSwitchCheckChecked"
                            formControlName="allowPlagiarism" (change)="enableFormField($event,'plagiarism')" />
                        </div>
                      </div>
                    </div>
                    <div *ngIf="isPlagiarismDisplay">
                      <div class="row mt-24 mb-16">
                        <div class="col body-text" translate="exercise.excerciseSetup.showPlagiarismscoretolearner">
                        </div>
                        <div class="col-3">
                          <div class="d-flex flex-wrap justify-content-sm-start justify-content-md-end">
                            <div class="form-check form-check-inline">
                              <label class="form-check-label form-check form-checkbox-orange ">
                                <input class="form-check-input " type="checkbox" formControlName="plagiarismReport"
                                  [checked]="exerciseSetup.controls['plagiarismReport'].value" />
                              </label>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- Grading -->
            <div *ngIf="exerciseTypeUrl  !== 'assignment'" class="accordion mt-32" id="accordionPanelsStayOpenGrading">
              <div class="accordion-item">
                <h2 class="accordion-header" id="panelsStayOpen-headingGrading">
                  <button class="accordion-button collapsed big-bold-assistive-text" type="button"
                    data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapseGrading" aria-expanded="false"
                    aria-controls="panelsStayOpen-collapseGrading"
                    translate="exercise.excerciseSetup.attemptandGradingSettings">
                  </button>
                </h2>
                <div id="panelsStayOpen-collapseGrading" class="accordion-collapse collapse mt-16"
                  aria-labelledby="panelsStayOpen-headingGrading">
                  <div class="accordion-body">
                    <div class="">
                      <div class="container-fluid">
                        <div class="row align-items-center">
                          <div class="col ps-0 body-bold-assistive-text"
                            translate="exercise.excerciseSetup.multipleAttempts"></div>
                          <div class="col">
                            <div class="d-flex justify-content-end">
                              <div class="form-check form-switch  form-switch-green ">
                                <input class="form-check-input" type="checkbox" role="switch"
                                  id="flexSwitchCheckChecked" formControlName="allowMultipleAttempts"
                                  (change)="enableFormField($event,'multipleAttempt')" />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div>
                        <div *ngIf="isMultipleAttemptDisplay">
                          <div class="container-fluid">
                            <div class="row mb-16">
                              <div class="col ps-0 body-text"
                                translate="exercise.excerciseSetup.numberOfAttemptsAllowed">
                              </div>
                              <div class="col">
                                <div class="d-flex flex-wrap justify-content-sm-start justify-content-md-end">
                                  <div class="form-check form-check-inline">
                                    <label class="form-check-label custom-radio">
                                      <input class="form-check-input" type="radio" value="Limited"
                                        (change)="changeAttempts()" formControlName="attempts" />
                                      <span class="body-text" translate="exercise.excerciseSetup.limited"></span>
                                    </label>
                                  </div>
                                  <div class="form-check form-check-inline">
                                    <label class="form-check-label custom-radio">
                                      <input class="form-check-input" type="radio" value="unLimited"
                                        (change)="changeAttempts()" formControlName="attempts" />
                                      <span class="body-text" translate="exercise.excerciseSetup.unlimited"></span>
                                    </label>
                                  </div>
                                </div>
                              </div>
                            </div>

                            <div class="row mb-24" *ngIf="limitedAttempts">
                              <div class="col-8 body-text">
                                <span>
                                  <span class="svg-rangevector">
                                    <svg-icon src="../../../../assets/images/exerciseBuilder/range-vector.svg">
                                    </svg-icon>
                                  </span>
                                  <span class="ml-16"
                                    translate="exercise.excerciseSetup.howmanyTimescanLearnerAttempt"></span>
                                </span>
                              </div>
                              <div class="col-4 ">
                                <input type="number" class="form-control" formControlName="maxAttempts" min="-1" />
                                <!-- <div class="quantity__btn quantity__btn--up">
                                  <svg-icon color="#ff6600" src="../../../../assets/images/exerciseBuilder/uparrow.svg">
                                  </svg-icon>
                                </div>

                                <div class="quantity__btn quantity__btn--down">
                                  <svg-icon color="#ff6600"
                                    src="../../../../assets/images/exerciseBuilder/downarrow.svg">
                                  </svg-icon>
                                </div> -->
                              </div>

                            </div>
                            <p class="body-text mb-24" translate="exercise.excerciseSetup.overallGradeCalculation">
                            </p>
                            <div class="mb-16">
                              <div class="form-check form-check-inline">
                                <label class="form-check-label custom-radio">
                                  <input class="form-check-input body-text" type="radio" formControlName="overallGrade"
                                    [value]="OverallGradeCalculation.MAXIMUM_OF_ALL_ATTEMPTS" />
                                  <span class="body-text" translate="exercise.excerciseSetup.maximumOfAllAttempts">
                                  </span>
                                </label>
                              </div>
                              <div class="form-check form-check-inline">
                                <label class="form-check-label custom-radio">
                                  <input class="form-check-input body-text" type="radio" formControlName="overallGrade"
                                    [value]="OverallGradeCalculation.MINIMUM_OF_ALL_ATTEMPTS" />
                                  <span class="body-text" translate="exercise.excerciseSetup.minimumOfAllAttempts">
                                  </span>
                                </label>
                              </div>
                              <div class="form-check form-check-inline">
                                <label class="form-check-label custom-radio">
                                  <input class="form-check-input body-text" type="radio" formControlName="overallGrade"
                                    [value]="OverallGradeCalculation.AVERAGE_OF_ALL_ATTEMPTS" />
                                  <span class="body-text" translate="exercise.excerciseSetup.averageOfAllAttempts">
                                  </span>
                                </label>
                              </div>
                            </div>

                            <div class="form-check form-check-inline">
                              <label class="form-check-label custom-radio">
                                <input class="form-check-input" type="radio" formControlName="overallGrade"
                                  [value]="OverallGradeCalculation.FIRST_ATTEMPT" />
                                <span class="body-text" translate="exercise.excerciseSetup.firstAttempt"></span>
                              </label>
                            </div>
                            <div class="form-check form-check-inline">
                              <label class="form-check-label custom-radio">
                                <input class="form-check-input" type="radio" formControlName="overallGrade"
                                  [value]="OverallGradeCalculation.LAST_ATTEMPT" />
                                <span class="body-text" translate="exercise.excerciseSetup.lastAttempt"> </span>
                              </label>
                            </div>

                            <div class="container-fluid">
                              <!-- <div class="row mb-16">   // will be using this next sprint (report)
                                  <div class="col ps-0 body-text"
                                    translate="exercise.excerciseSetup.nextAttemptdependsontheLearnerPercentage">
                                  </div>
                                  <div class="col">
                                    <div class="d-flex flex-wrap justify-content-sm-start justify-content-md-end">
                                      <div class="form-check form-check-inline">
                                        <label class="form-check-label custom-radio">
                                          <input class="form-check-input body-text" type="radio" formControlName="nextAttempts"
                                            value="Yes" />
                                          <span class="body-text" translate="exercise.excerciseSetup.yes"></span>
                                        </label>
                                      </div>
                                      <div class="form-check form-check-inline">
                                        <label class="form-check-label custom-radio">
                                          <input class="form-check-input body-text" type="radio" formControlName="nextAttempts"
                                            value="No" />
                                          <span class="body-text" translate="exercise.excerciseSetup.no"></span>
                                        </label>
                                      </div>
                                    </div>
                                  </div>
                                </div> -->
                              <!-- <div class="row mb-4">
                                  <div class="col-8 body-text">
                                    <span class="me-3"><svg-icon
                                        src="../../../../assets/images/exerciseBuilder/range-vector.svg"></svg-icon>
                                    </span>
                                    <span
                                      translate="exercise.excerciseSetup.minimumPercentageforReleasingNextAttempt"></span>
                                  </div>
                                  <div class="col-4">
                                    <input type="text" class="form-control" placeholder="0%">
                                  </div>
                                </div> -->
                              <!-- <div class="row mb-4">
                                  <div  class="col-8 body-text">
                                    <span class="me-3"> <svg-icon
                                        src="../../../../assets/images/exerciseBuilder/range-vector.svg"></svg-icon>
                                    </span>
                                    <span
                                      translate="exercise.excerciseSetup.maximumPercentageforReleasingNextAttempt"></span>
                                  </div>
                                  <div class="col-4">
                                    <input type="text" class="form-control" placeholder="100%">
                                  </div>
                                </div> -->
                            </div>
                          </div>
                        </div>
                      </div>

                      <hr class="mt-32 mb-32" />
                      <p class="body-bold-assistive-text mb-24" translate="exercise.excerciseSetup.gradingType"></p>
                      <div class="form-check form-check-inline" [ngClass]="{'disabled': disableGradable}">
                        <label class="form-check-label custom-radio">
                          <input class="form-check-input" type="radio" formControlName="gradingType"
                            [value]="GradingType.GRADABLE" />
                          <span class="body-text" translate="exercise.excerciseSetup.gradable"> </span>
                        </label>
                      </div>
                      <div class="form-check form-check-inline" [ngClass]="{'disabled': disableGradingType}">
                        <label class="form-check-label custom-radio">
                          <input class="form-check-input" type="radio" formControlName="gradingType"
                            [value]="GradingType.NON_GRADABLE_WITH_MARKS" />
                          <span class="body-text" translate="exercise.excerciseSetup.nonGradable"></span>
                        </label>
                      </div>
                      <div class="form-check form-check-inline">
                        <label class="form-check-label custom-radio">
                          <input class="form-check-input" type="radio" formControlName="gradingType"
                            [value]="GradingType.NON_GRADABLE_WITHOUT_MARKS" />
                          <span class="body-text" translate="exercise.excerciseSetup.nonGradablewoMarks"> </span>
                        </label>
                      </div>
                    </div>
                    <hr class="mt-32 mb-32" />
                    <div class="container-fluid">
                      <div class="row mb-24">
                        <div class="col body-bold-assistive-text ps-0 mb-24">Passing Criteria</div>
                        <div class="col">
                          <div class="d-flex justify-content-end">
                            <div class="form-check form-switch form-switch-green">
                              <input class="form-check-input" type="checkbox" formControlName="enablePassingCriteria"
                                (change)="enableFormField($event,'passingCriteria')" />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="row" *ngIf="isPassingCriteria">
                        <div class="col-9 body-text px-0"
                          translate="exercise.excerciseSetup.percentagethatlearnersshouldreceivetopassExercise">
                        </div>
                        <div class="col-3">
                          <input type="text" class="form-control" formControlName="percentagePassing"
                            placeholder="0%" />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <!-- AssignmentGrading -->
            <div *ngIf="exerciseTypeUrl  === 'assignment'" class="accordion mt-32" id="accordionPanelsStayOpenGrading">
              <div class="accordion-item">
                <h2 class="accordion-header" id="panelsStayOpen-headingGrading">
                  <button class="accordion-button collapsed big-bold-assistive-text" type="button"
                    data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapseGrading" aria-expanded="false"
                    aria-controls="panelsStayOpen-collapseGrading" translate="exercise.excerciseSetup.GradingSettings">
                  </button>
                </h2>
                <div id="panelsStayOpen-collapseGrading" class="accordion-collapse collapse mt-3"
                  aria-labelledby="panelsStayOpen-headingGrading">
                  <div class="accordion-body">
                    <p class="body-bold-assistive-text mb-24" translate="exercise.excerciseSetup.gradingType"></p>
                    <div class="form-check form-check-inline">
                      <label class="form-check-label custom-radio">
                        <input class="form-check-input" type="radio" formControlName="gradingType"
                          [value]="GradingType.GRADABLE" />
                        <span class="body-text" translate="exercise.excerciseSetup.gradable"> </span>
                      </label>
                    </div>
                    <div class="form-check form-check-inline">
                      <label class="form-check-label custom-radio">
                        <input class="form-check-input" type="radio" formControlName="gradingType"
                          [value]="GradingType.NON_GRADABLE_WITH_MARKS" />
                        <span class="body-text" translate="exercise.excerciseSetup.nonGradable"></span>
                      </label>
                    </div>
                    <div class="form-check form-check-inline">
                      <label class="form-check-label custom-radio">
                        <input class="form-check-input" type="radio" formControlName="gradingType"
                          [value]="GradingType.NON_GRADABLE_WITHOUT_MARKS" />
                        <span class="body-text" translate="exercise.excerciseSetup.nonGradablewoMarks"> </span>
                      </label>
                    </div>
                    <hr class="mt-32 mb-32" />
                    <div class="container-fluid">
                      <div class="row mb-24">
                        <div class="col body-bold-assistive-text ps-0 mb-24">Passing Criteria</div>
                        <div class="col">
                          <div class="d-flex justify-content-end">
                            <div class="form-check form-switch form-switch-green">
                              <input class="form-check-input" type="checkbox" formControlName="enablePassingCriteria"
                                (change)="enableFormField($event,'passingCriteria')" />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="row" *ngIf="isPassingCriteria">
                        <div class="col-9 body-text px-0"
                          translate="exercise.excerciseSetup.percentagethatlearnersshouldreceivetopassExercise">
                        </div>
                        <div class="col-3">
                          <input type="text" class="form-control" formControlName="percentagePassing"
                            placeholder="0%" />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </form>
  </scrollbar>
  <div class="d-flex justify-content-end footerBtn">
    <button type="button" class="btn btn-secondary-grey" [ngClass]="{'mx-4':status === exerciseStatus.ATTACHED}"
      translate="exercise.excerciseSetup.cancel" (click)="cancelExercise()">
    </button>
    <button type="button" class="btn btn-secondary-orange mx-4" *ngIf="status !== exerciseStatus.ATTACHED"
      (click)="submitHandler(true)" [disabled]="!exerciseSetup.valid || isValidRange|| isCompareRangeValid"
      translate="exercise.excerciseSetup.saveAsDraft">
    </button>
    <button type="button" class="btn btn-primary"
      [disabled]="!exerciseSetup.valid || isValidRange|| isCompareRangeValid" (click)="submitHandler()"
      translate="exercise.excerciseSetup.save&continue">
    </button>
  </div>
</div>