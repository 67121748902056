/* eslint-disable max-lines-per-function */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { NgSelectConfig } from '@ng-select/ng-select';
import { TranslateService } from '@ngx-translate/core';
import { ProjectDetails, SetupInterface } from 'src/app/models/projectInterface';
import { ProjectService } from '../project.service';
import { ActivatedRoute, Router } from '@angular/router';
import { ProjectPlayerModes } from 'src/app/enums/PlayerModes';
import { RoutingParams } from 'src/app/enums/routingParameters';
import { Configuration } from 'src/app/models/project-list-card';
import { ExerciseTabModes } from 'src/app/exercise/exercise-builder/exercise-builder.component';
import { SkeletonLoaderType } from 'src/app/enums/skeletonui';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-setup',
  templateUrl: './setup.component.html',
  styleUrls: ['./setup.component.scss']
})
export class SetupComponent implements OnInit {
  @Output() projectModeChange = new EventEmitter<ProjectPlayerModes>();
  @Output() hideRubric = new EventEmitter();
  overlay = false;
  SkeletonLoaderType = SkeletonLoaderType;
  problemStatementPlaceholder = "Add Problem Statement";
  instructionPlaceholder = "Add Instructions ";
  examplePlaceholder = "Add Example";
  projectPlayerModes = ProjectPlayerModes;
  level: { name: string; }[] = [];
  languages: { name: string; value: string; }[] = [];
  archetypes: { name: string; value: string; }[] = [];
  versionConfig: { language: string, versions: { langId: string, label: string, archetypes: { targetFileName: string, sourceFileName: string, label: string, archetypeId: string }[] }[] }[] = [];
  filteredVersionArray: any = [];
  filteredArchId: string[] = [];
  mappedVersion: {
    label: string,
    langId: string,
    archetypes: [
      {
        label: string,
        archetypeId: string
      }
    ]
  }[] = [];
  setUpForm!: FormGroup;
  selectedTESTLanguages: any = [];
  savedArchetypes: { language: string, archetypeId: string, label: string, targetFileName: string, sourceFileName: string }[] = [];
  // eslint-disable-next-line max-params
  constructor(private config: NgSelectConfig, private fb: FormBuilder, private toastService: ToastrService, private translationService: TranslateService, public projectService: ProjectService, private activatedRoute: ActivatedRoute, private router: Router) { }

  ngOnInit(): void {
    this.getConfig();
    this.formInitialize();
    this.projectMetaDataCreatedListener();
  }

  async getConfig() {
    let levels: string[] = [];
    this.projectService.getConfig().subscribe((data: Configuration) => {
      levels = data.level;
      if (data.languages) {
        this.versionConfig = data.languages;
        this.languages = this.versionConfig.map((lang: { language: string, versions: { langId: string, label: string, archetypes: { targetFileName: string, sourceFileName: string, label: string, archetypeId: string }[] }[] }) => {
          return { name: lang.language, value: lang.language };
        });
        this.languages.unshift({ name: "Clear All", value: "Clear All" });
      }
      // console.log(this.languages,"languages");
      this.level = levels.map(level => ({ name: level }));
      this.editForm();
    });
  }

  formInitialize() {
    this.setUpForm = this.fb.group({
      title: new FormControl('', Validators.required),
      problemStatement: new FormControl('', Validators.required),
      instruction: new FormControl(''),
      example: new FormControl(''),
      // eslint-disable-next-line no-sparse-arrays
      selectedLevel: [, Validators.required],
      selectedLang: new FormControl('', Validators.required),
      selectedArch: new FormControl('', Validators.required),
    });
  }
  removeLanguage(value: string): void {
    const currentValues = this.setUpForm.controls['selectedLang'].value as string[];
    const index = currentValues.indexOf(value);
    if (index !== -1) {
      const updatedValues = currentValues.filter(lang => lang !== value);
      this.setUpForm.controls['selectedLang'].setValue(updatedValues);
    }
  }
  keepOnlyDbms(): void {
    if (this.setUpForm.controls['selectedLang'].value.includes('DBMS')) {
      this.setUpForm.controls['selectedLang'].setValue(['DBMS']);
    }
  }
  onLangSelectChange(event: any, field: string) {
    const selectedOption = event;
    if (!selectedOption) {
      return;
    }
    if (Array.isArray(selectedOption)) {
      selectedOption.forEach((element: any) => {
        if (element.name === "Clear All" || event.length === 0) {
          this.setUpForm.controls[field].setValue([]);
          this.setUpForm.controls['selectedArch'].setValue([]);
          if (field === 'selectedLang') {
            this.selectedTESTLanguages = [];
          }
        }
      });
    }
    const dbmsSelectedLang: boolean = this.selectedTESTLanguages.some((element: any) => element.name === "DBMS");
    const isDbmsSelected: boolean = event.some((element: any) => element.name === "DBMS");
    if ((dbmsSelectedLang && event.length > 0) && isDbmsSelected) {
      if (event.length >= 2) {
        this.keepOnlyDbms();
        this.showErrorToast("Cannot select other languages if DBMS is selected");
        return;
      }
    }
    if ((isDbmsSelected && !dbmsSelectedLang) && this.selectedTESTLanguages.length > 0) {
      this.removeLanguage('DBMS');
      this.showErrorToast("Cannot select DBMS if other languages are selected");
      return;
    }
    this.selectedTESTLanguages = event;
    this.filteredVersionArray = [];
    this.filteredArchId = [];
    //making archetype dropdown array based on selections in language dropdown
    const fieldValue = this.setUpForm.controls[field].value;
    if (Array.isArray(fieldValue) && this.versionConfig) {
      fieldValue.forEach((language: string) => {
        this.versionConfig.forEach(
          (languagePayload: { language: string, versions: { langId: string, label: string, archetypes: { targetFileName: string, sourceFileName: string, label: string, archetypeId: string }[] }[] }) => {
            if (language === languagePayload.language && languagePayload.versions) { // Add a check for languagePayload.versions
              this.filteredVersionArray.push(...languagePayload.versions);
              languagePayload.versions.forEach((version: { langId: string, label: string, archetypes: { targetFileName: string, sourceFileName: string, label: string, archetypeId: string }[] }) => {
                if (version.archetypes) { // Add a check for version.archetypes
                  this.filteredArchId.push(...version.archetypes.flatMap(archetype => archetype.archetypeId));
                }
              });
            }
          });
      });
    }
    const selectedArchFieldValue = this.setUpForm.controls['selectedArch'].value;
    if (Array.isArray(selectedArchFieldValue) && Array.isArray(this.filteredArchId)) {
      const newSelectedArch = selectedArchFieldValue.filter((arch: string) => this.filteredArchId.includes(arch));
      this.setUpForm.controls['selectedArch'].patchValue(newSelectedArch);
      // console.log(newSelectedArch,"new");
      this.restructureLanguageAndArchetypes();
    }
    event.stopPropagation();
  }

  showErrorToast(message: string): void {
    this.toastService.error(message, '', {
      positionClass: 'toast-top-center',
      closeButton: true,
      timeOut: 5000,
      extendedTimeOut: 5000,
      tapToDismiss: false
    });

  }

  get setUpformInfo() {
    return this.setUpForm.controls;
  }

  validationListener() {
    const validationUnSubscription = this.projectService.validationEventsError().subscribe((res: { errId: string }) => {
      if (res.errId === 'AUTHORIZATION_ERROR') {
        this.toastService.error('Only Author can edit the Project');
      }
      validationUnSubscription.unsubscribe;
    });
  }

  async saveSetupForm(saveAsDraftSetup = false) {
    this.overlay = true;
    this.validationListener();
    this.restructureLanguageAndArchetypes();
    const obj: SetupInterface = {
      title: this.setUpForm.value.title,
      difficultyLevel: this.setUpForm.value.selectedLevel,
      archetypes: this.savedArchetypes,
      problemStatement: this.setUpForm.value.problemStatement,
      instructions: this.setUpForm.value.instruction,
      example: this.setUpForm.value.example,
      isDraft: saveAsDraftSetup
    };
    if (this.activatedRoute.snapshot.queryParams['contentRoot']) {
      obj.exerciseId = this.activatedRoute.snapshot.queryParams['exerciseId'];
    }
    if (this.projectService.projectMode === ProjectPlayerModes.EDIT) {
      obj.projectId = this.projectService.projectId;
      this.projectService.updateSetupForm(obj).subscribe(() => {
        this.storeSetupFormToProjectDetails(obj);
        if (saveAsDraftSetup) {
          if (this.activatedRoute.snapshot.queryParams['exerciseId']) {
            const exerciseId = this.activatedRoute.snapshot.queryParams['exerciseId'];
            this.router.navigate(['/exercise/' + exerciseId + '/manipulate-exercise'], { queryParamsHandling: 'merge', queryParams: { exerciseManipulationTab: ExerciseTabModes.EXERCISE_PROJECTS, exerciseId: null } });
          }
          else {
            this.router.navigate(['../../../project-bank']);
          }
        }
        else {
          this.router.navigate([], { relativeTo: this.activatedRoute, queryParams: { projectManipulationTab: 'console' }, queryParamsHandling: 'merge' });
        }
      });

    }
    else {
      this.projectService.saveSetupForm(obj).subscribe((savedProjectData: { projectId: string }) => {
        obj.projectId = savedProjectData.projectId;
        this.storeSetupFormToProjectDetails(obj);
        this.projectModeChange.emit(ProjectPlayerModes.EDIT);
        if (saveAsDraftSetup) {
          if (this.activatedRoute.snapshot.queryParams['exerciseId']) {
            const exerciseId = this.activatedRoute.snapshot.queryParams['exerciseId'];
            this.router.navigate(['/exercise/' + exerciseId + '/manipulate-exercise'], { queryParamsHandling: 'merge', queryParams: { exerciseManipulationTab: ExerciseTabModes.EXERCISE_PROJECTS, exerciseId: null } });
          }
          else {
            this.router.navigate(['../../../project-bank'], { relativeTo: this.activatedRoute });
          }
        } else {
          this.router.navigate(['../../' + savedProjectData.projectId + '/' + RoutingParams.MANIPULATE_PROJECT], { relativeTo: this.activatedRoute, queryParams: { projectManipulationTab: 'console' }, queryParamsHandling: 'merge' });
        }
      });
    }
  }

  restructureLanguageAndArchetypes() {
    const selectedArchetypeIdArray = this.setUpForm.controls['selectedArch'].value;
    if (!selectedArchetypeIdArray || !Array.isArray(selectedArchetypeIdArray)) {
      return; // Exit the function if selectedArchetypeIdArray is not valid
    }
    const archetypePayLoad: { language: string, archetypeId: string, label: string, targetFileName: string, sourceFileName: string }[] = [];
    const archetypePayLoadWithLangId: { language: string, archetypeId: string, label: string, targetFileName: string, sourceFileName: string }[] = [];
    if (Array.isArray(this.versionConfig)) {
      this.versionConfig.forEach((languageData: { language: string, versions: { langId: string, label: string, archetypes: { targetFileName: string, sourceFileName: string, label: string, archetypeId: string }[] }[] }) => {
        const { language, versions } = languageData;
        if (Array.isArray(versions)) {
          versions.forEach((version: { langId: string, label: string, archetypes: { targetFileName: string, sourceFileName: string, label: string, archetypeId: string }[] }) => {
            const { archetypes } = version;
            if (Array.isArray(archetypes)) {
              archetypes.forEach((archetype: { targetFileName: string, sourceFileName: string, label: string, archetypeId: string }) => {
                if (selectedArchetypeIdArray.includes(archetype.archetypeId)) {
                  archetypePayLoad.push({
                    language: language, ...archetype
                  });
                  archetypePayLoadWithLangId.push({
                    language: version.langId, ...archetype
                  });
                }
              });
            }
          });
        }
      });
      this.projectService.archetypes = archetypePayLoadWithLangId;
      this.savedArchetypes = archetypePayLoad;
    }
  }

  storeSetupFormToProjectDetails(setupPayload: SetupInterface) {
    const projectDetailsPayload =
    {
      projectId: setupPayload.projectId,
      title: setupPayload.title,
      difficultyLevel: setupPayload.difficultyLevel,
      archetypes: setupPayload.archetypes,
      problemStatement: setupPayload.problemStatement,
      instructions: setupPayload.instructions,
      example: setupPayload.example
    };
    if (!this.projectService.projectDetails) {
      this.projectService.projectDetails = projectDetailsPayload;
      this.projectService.publishProjectDetailsState();
    }
    else {
      this.projectService.projectDetails = Object.assign(this.projectService.projectDetails, projectDetailsPayload);
      this.projectService.publishProjectDetailsState();
    }
  }

  projectMetaDataCreatedListener() {
    this.projectService.projectMetaDataCreated().subscribe((projectMetaDataPayload: { projectId: string }) => {
      this.projectService.projectId = projectMetaDataPayload.projectId;
    });
  }

  updateSetupFormFailure() {
    this.projectService.updateSetupFormFailure().subscribe(() => {
      // eslint-disable-next-line no-console
      console.log("update failed");
    });
  }

  editForm() {
    if (this.projectService.projectMode === ProjectPlayerModes.EDIT) {
      this.projectService.listenToProjectDetailsState().subscribe(() => {
        this.patchSetupForm(this.projectService.projectDetails);
        this.updateSetupFormFailure();
      });

    }
  }

  patchSetupForm(data: ProjectDetails) {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const archetype = data.archetypes!.map((item: { language: string, archetypeId: string }) => item.archetypeId);
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const language = data.archetypes!.map((item: { language: string, archetypeId: string }) => item.language);
    this.setUpForm.patchValue({
      title: data.title,
      problemStatement: data.problemStatement,
      instruction: data.instructions,
      example: data.example || '',
      selectedLevel: data.difficultyLevel,
      selectedLang: language,
      selectedArch: archetype,
    });
    const modifiedLanguage = language.map(item => ({ name: item, value: item }));
    this.onLangSelectChange(modifiedLanguage, "selectedLang");
    this.restructureLanguageAndArchetypes();
  }

  cancelFromSetup() {
    if (this.activatedRoute.snapshot.queryParams['exerciseId']) {
      const exerciseId = this.activatedRoute.snapshot.queryParams['exerciseId'];
      this.router.navigate(['/exercise/' + exerciseId + '/manipulate-exercise'], { queryParamsHandling: 'merge', queryParams: { exerciseManipulationTab: ExerciseTabModes.EXERCISE_PROJECTS, exerciseId: null } });
    }
    else {
      this.router.navigate(['/project-bank'], { queryParamsHandling: 'merge' });
    }
  }
  ngOnDestroy() {
    const disr = this.selectedTESTLanguages.some((element: any) => element.name === "DBMS");
    this.hideRubric.emit(disr);
  }
}
