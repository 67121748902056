import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Subject, takeUntil } from 'rxjs';
import { StorageKey } from 'src/app/enums/storageKey';
import { SocketService } from 'src/app/services/socket.service';
import { StorageService } from 'src/app/services/storage.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent {
  isDarkThemeEnable = false;
  userName!: string;
  unSubscribe = new Subject<void>();
  userPic!: string;
  constructor(public socketService: SocketService, private storageService: StorageService, private translationService: TranslateService,) {
  }
  ngOnInit() {
    this.storageService.listen(StorageKey.Theme).pipe(takeUntil(this.unSubscribe)).subscribe((data) => {
      this.isDarkThemeEnable = data;
    });
    this.getUserName();

    const userProfilePicFetchUrl = environment.lumenapiBaseUrl + '/api/courseservice/content-store/unext-labs/profile-pic?' + `userId=${this.storageService.get(StorageKey.UserId)}`;
    fetch(userProfilePicFetchUrl, {
      method: 'GET',
      headers: {
        'organizationId': `${this.storageService.get(StorageKey.OrganizationId)}`,
      }
    })
      .then((response) => {
        if (response.ok) {
          return response.json();
        }
        this.userPic = '../../assets/images/dummy-user.png';
        throw new Error('Something went wrong');
      })
      .then((response) => this.userPic = response.url)
      .catch((error) => {
        // eslint-disable-next-line no-console
        console.log('User pic error', error);
      });

  }

  private getUserName() {
    try {
      this.userName = this.storageService.get(StorageKey.UserName);
    }
    catch {
      this.translationService.get('header.userName').subscribe((translation) => {
        this.userName = translation;
      }).unsubscribe();
    }
  }

  ngOnDestroy() {
    this.unSubscribe.next();
    this.unSubscribe.unsubscribe();
  }

}
