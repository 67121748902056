import { APP_INITIALIZER, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LayoutModule } from './layout/layout.module';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { AngularSvgIconModule } from 'angular-svg-icon';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { ToastrSharedModule } from './shared/modules/toastr/toastr-shared.module';
import { ConfirmationModalComponent } from './shared/components/confirmation-modal/confirmation-modal.component';
import { initApp } from './utils/application-initializer';

export function translateHttpLoaderFactory(http: HttpClient): TranslateHttpLoader {
  return new TranslateHttpLoader(http, "./assets/i18n/");
}

@NgModule({
  declarations: [
    AppComponent,
    ConfirmationModalComponent,

  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    LayoutModule,
    HttpClientModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: translateHttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    AngularSvgIconModule.forRoot(),
    ToastrSharedModule
  ],
  providers: [HttpClient,
    {
      provide: APP_INITIALIZER,
      useFactory: initApp,
      multi: true,
    },
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
