<div class="container px-0">
  <div class="row">
    <div class="px-2 my-3">
      <p class="test-heading">White Box</p>
    </div>
    <div class="px-2 d-inline-flex mb-16">
      <div class="col-10 d-flex p-0 align-items-center body-sub-text"
        translate="projectBank.projectPlayer.userWhiteBoxTestCase">
      </div>
      <div class="col-2">
        <div class="form-check form-switch form-switch-green">
          <input class="form-check-input cursor-pointer" type="checkbox" role="switch" id="flexSwitchCheckDefault"
            [checked]="isUserWhiteBoxTestCaseEnabled" (change)="onEventChangeUserWhiteBox($event)">
        </div>
      </div>
    </div>
  </div>
  <div class="row mb-16">
    <div class="px-2 d-inline-flex">
      <div class="col-10 d-flex p-0 align-items-center body-sub-text"
        translate="projectBank.projectPlayer.evaluatorWhiteBoxTestCase">
      </div>
      <div class="col-2">
        <div class="form-check form-switch form-switch-green">
          <input class="form-check-input cursor-pointer" type="checkbox" role="switch" id="flexSwitchCheckDefault"
            [checked]="isEvaluatorWhiteBoxTestCaseEnabled" (change)="onEventChangeEvalWhiteBox($event)">
        </div>
      </div>
    </div>
  </div>
  <!-- BLACK BOX USER MODEL -->
  <div class="row">
    <div class="px-2 mb-16">
      <p class="test-heading"> Black Box</p>
    </div>
    <div class="d-inline-flex px-2 mb-16">
      <div class="col d-flex p-0 align-items-center test-cases-primary-text  mouse-pointer"
        translate="projectBank.projectPlayer.userBlackBoxTestCase" (click)="blackBox('User')">
        <svg-icon class="pl-16" src="../../../../../assets/images/projectPlayer/popup_icon.svg"></svg-icon>
      </div>
      <div class="number-of-test">
        <p class="total-test">{{usercount}} added</p>
      </div>
    </div>
  </div>
  <!-- EVALUATOR BOX MODEL -->
  <div class="row mb-16">
    <div class="d-inline-flex px-2">
      <div class="col d-flex p-0 align-items-center test-cases-primary-text  mouse-pointer"
        translate="projectBank.projectPlayer.evaluatorBlackBoxTestCase" (click)="blackBox('Evaluator')">
        <svg-icon class="pl-16" src="../../../../../assets/images/projectPlayer/popup_icon.svg"></svg-icon>
      </div>
      <div class="number-of-test">
        <p class="total-test">{{evalcount}} added</p>
      </div>
    </div>
  </div>
</div>