<!-- Heading Of The Dialog Box -->
<div class="modal-header">
  <p class="heading-2"> {{title}}
  </p>
  <span class="close-icon cursor-pointer" (click)="activeModal.dismiss()">
    <svg-icon src="../../../../../assets/images/test_case_close.svg"></svg-icon>
  </span>
</div>
<div class="modal-body pb-0 ">
  <label class="tile-content">Title</label>
  <div class="title-insertion">
    <input type="text" class="form-control" [(ngModel)]="name">
  </div>
</div>

<div class="modal-footer">
  <button class="btn btn-secondary-grey" translate="projectBank.projectPlayer.customModal.cancel"
    (click)="activeModal.dismiss()"></button>
  <button class="btn btn-primary ml-24" translate="projectBank.projectPlayer.customModal.save"
    (click)="saveContent()"></button>
</div>