import { Component, ElementRef } from '@angular/core';
import { SkeletonComponent } from 'src/app/base-class/skeleton-class';

@Component({
  selector: 'app-skeleton-rectangle',
  host: {
    'class': 'pulse'
  },
  templateUrl: './skeleton-rectangle.component.html',
  styleUrls: ['./skeleton-rectangle.component.scss']
})
export class SkeletonRectangleComponent extends SkeletonComponent {
  constructor(override host: ElementRef<HTMLElement>) {
    super(host);
  }
  ngOnInit() {
    this.setStylesAndClass();
  }
}
