import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ExerciseRoutingModule } from './exercise-routing.module';
import { ExerciseComponent } from './exercise.component';
import { CreateNewExerciseComponent } from './modals/create-new-exercise/create-new-exercise.component';
import { ExerciseListComponent } from './exercise-list/exercise-list.component';
import { ViewExerciseComponent } from './view-exercise/view-exercise.component';
import { ExerciseBuilderModule } from './exercise-builder/exercise-builder.module';
import { NgbModalModule } from '@ng-bootstrap/ng-bootstrap';
import { PreviewModalComponent } from './modals/preview-modal/preview-modal.component';
import { ExerciseService } from './exercise.service';
import { WrapperModule } from '../shared/modules/wrapper/wrapper.module';
import { MarksModalComponent } from './modals/marks-modal/marks-modal.component';
import { ProjectListCardModule } from '../shared/modules/project-list-card/project-list-card.module';
import { ExerciseListCardModule } from '../shared/modules/exercise-list-card/exercise-list-card.module';
import { DateFormatPipeModule } from '../pipes/date-format.pipe';

@NgModule({
  declarations: [
    ExerciseComponent,
    CreateNewExerciseComponent,
    ExerciseListComponent,
    ViewExerciseComponent,
    PreviewModalComponent,
    MarksModalComponent
  ],
  imports: [
    CommonModule,
    ExerciseRoutingModule,
    ExerciseBuilderModule,
    NgbModalModule,
    WrapperModule,
    ProjectListCardModule,
    ExerciseListCardModule,
    DateFormatPipeModule
  ],
  providers: [ExerciseService],
})
export class ExerciseModule { }
