import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { distinctUntilChanged, filter } from 'rxjs';
import { ProjectService } from './project.service';
import { ProjectPlayerModes } from 'src/app/enums/PlayerModes';
import { ProjectDetails } from 'src/app/models/projectInterface';
export enum TabModes {
  SETUP = 'setup',
  CONSOLE = 'console',
  RUBRICS = 'rubrics',
  TRY = 'try'
}
@Component({
  selector: 'app-project',
  templateUrl: './project.component.html',
  styleUrls: ['./project.component.scss']
})
export class ProjectComponent {
  concealRubric = false;
  TabModes = TabModes;
  activatedTab!: TabModes;
  createMode!: boolean;
  isLayoutReady = false;
  constructor(private activatedRoute: ActivatedRoute, private router: Router, public projectService: ProjectService,) { }
  ngOnInit() {
    //Check the current mode of operation. Create or Edit mode based on route parameters.
    this.detectCreateEditMode();
    //Setting the default tab to SETUP
    this.tabSwitch(TabModes.SETUP);
    this.setupQueryParamActiveTabListener();
    if (this.activatedRoute.snapshot.queryParams['contentRoot']) {
      this.projectService.isSaveAsDraftOrCancelEnable = false;
    }
  }
  maskRubric(data: boolean) {
    this.concealRubric = data;
  }

  /**
   * This will fetch the project id from route param and if it is create the
   * create mode would be enabled else the edit mode would be enabled.
   */
  detectCreateEditMode() {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const projectId: string = this.activatedRoute.snapshot.paramMap.get('projectId')!;
    this.createMode = projectId === 'create' ? (
      this.projectService.projectMode = ProjectPlayerModes.CREATE,
      this.isLayoutReady = true,
      true) : (
      this.projectService.setProjectId(projectId),
      this.projectService.projectMode = ProjectPlayerModes.EDIT,
      this.storeProjectDetails(),
      false);
  }
  async storeProjectDetails() {
    const exerciseId: string = this.activatedRoute.snapshot.queryParams['exerciseId'];
    if (exerciseId) {
      this.projectService.exerciseId = exerciseId;
    }
    if (!exerciseId) {
      this.projectService.exerciseId = undefined;
    }

    this.projectService.getProjectDetails().subscribe((data: ProjectDetails) => {
      this.projectService.projectDetails = data;
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      this.projectService.archetypes = data.archetypes!;
      if (data.evalBlackBoxTests?.length !== undefined) {
        if (data.evalBlackBoxTests?.length > 0) {
          this.projectService.isEvaluatorBlackBoxInputDisable = false;
        }
      }
      else {
        this.projectService.isEvaluatorBlackBoxInputDisable = true;
      }
      if (data.userBlackBoxTests?.length !== undefined) {
        if (data.userBlackBoxTests?.length > 0) {
          this.projectService.isUserBlackBoxInputDisable = false;
        }
      }
      else {
        this.projectService.isUserBlackBoxInputDisable = true;
      }
      this.projectService.publishProjectDetailsState();
      this.isLayoutReady = true;
    });
  }
  /**
   * This function will switch the active tab to the desired tab and update the query params as well. Output emitters from child components should invoke this function.
   * @param {TabModes}  activeTabMode the value of the tab to switch to. Use the TabMode enum for the input argument
   * @returns {void}
   */
  tabSwitch(activeTabMode: TabModes): void {
    if (this.activatedTab === activeTabMode) {
      return;
    }
    this.activatedTab = activeTabMode;
    this.router.navigate([], { relativeTo: this.activatedRoute, queryParams: { projectManipulationTab: this.activatedTab }, queryParamsHandling: 'merge' });
  }

  changeProjectPlayerMode(modeToSwitchTo: ProjectPlayerModes) {
    this.projectService.projectMode = modeToSwitchTo;
    if (modeToSwitchTo === ProjectPlayerModes.EDIT) {
      this.createMode = false;
    }
  }

  /**
   * This will setup a listener for the query param - 'projectManipulationTab' and switch the tab automatically to maintain the state.
   */
  private setupQueryParamActiveTabListener() {
    this.activatedRoute.queryParams.pipe(
      distinctUntilChanged(),
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      filter((param: any) => param.projectManipulationTab)
    ).subscribe((activeTabParam: { projectManipulationTab: TabModes; }) => {
      if (this.activatedTab !== activeTabParam.projectManipulationTab) {
        this.tabSwitch(activeTabParam.projectManipulationTab);
      }
    });
  }

  ngOnDestroy() {
    this.projectService.clearAll();
    this.router.navigate([], { queryParams: { projectManipulationTab: null }, queryParamsHandling: 'merge', replaceUrl: true, skipLocationChange: true });
  }
}
