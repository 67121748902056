export enum AttemptEventKey {
  START_RESUME_ATTEMPT = "start_resume_attempt",
  RESUME_ATTEMPT = "resume_attempt",
  VALIDATION_ERROR = "validation_error",
  ATTEMPT_METADATA_CREATED = "attempt_metadata_created",
  ATTEMPT_CREATED = "attempt_created",
  SUBMIT_PROJECT = "submit_project",
  PROJECT_SUBMITTED = "project_submitted",
  SUBMIT_EXERCISE = "submit_exercise",
  EXERCISE_SUBMITTED = "exericse_submitted",
  RUN = "run",
  IS_PROJECT_MARKED_FOR_REVIEW = "is_project_marked_for_review",
  IS_PROJECT_MARKED_FOR_REVIEW_SUCCESS = "is_project_marked_for_review_success",
  UPDATE_PROJECT_STATUS = "update_project_status",
  PROJECT_STATUS_UPDATED = "project_status_updated",
  AUTO_SAVE = "auto_save",
  AUTO_SAVE_OK = "auto_save_ok",
  GET_DB_CREDENTIALS = "get_db_credentials",
  DB_CREDENTIALS = "db_credentials",
  TIME_SPENT_UPDATED = "time_spent_updated"
}