import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ProjectListCardComponent } from '../../components/projectlistcard/projectlistcard.component';
import { RouterModule } from '@angular/router';
import { DateFormatPipeModule } from 'src/app/pipes/date-format.pipe';

@NgModule({
  declarations: [ProjectListCardComponent],
  imports: [
    CommonModule,
    RouterModule,
    DateFormatPipeModule
  ],
  exports: [ProjectListCardComponent]
})
export class ProjectListCardModule { }
