import { ElementRef } from "@angular/core";
import { SkeletonLoaderType } from "../enums/skeletonui";

export class SkeletonComponent {
  SkeletonLoaderType = SkeletonLoaderType;
  width!: string;
  height!: string;
  className!: string;
  styles!: Record<string, string>;
  constructor(public host: ElementRef<HTMLElement>) {
  }
  public setStylesAndClass() {
    const host = this.host.nativeElement;
    if (this.className) {
      host.classList.add(this.className);
    }
    host.style.setProperty('--skeleton-rect-width', this.width ?? '100%');
    host.style.setProperty('--skeleton-rect-height', this.height ?? '15px');
    if (this.styles) {
      Object.keys(this.styles).map((style: string) => {
        host.style.setProperty(style, this.styles[style]);
      });
    }
  }
}