/* eslint-disable @typescript-eslint/no-explicit-any */
import { ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, Output } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators, } from '@angular/forms';
import { NgSelectConfig } from '@ng-select/ng-select';
import { BlackBoxInterface, BlackBoxEditInterface, testCaseForm } from 'src/app/models/projectInterface';
import { ProjectPlayerService } from 'src/app/shared/modules/player/project-player.service';
import { TestCaseType } from 'src/app/enums/testCases';
import { Subscription } from 'rxjs';
@Component({
  selector: 'app-black-box',
  templateUrl: './black-box.component.html',
  styleUrls: ['./black-box.component.scss']
})

export class BlackBoxComponent {
  @Input() title!: string;
  @Input() testCaseData!: testCaseForm[];
  public count = 0;
  showTable = false;
  consoleIp = ['Console Input', 'Command Input', 'Command & Console Input'];
  selectedOption: string | undefined;
  selectedOnConsole = '';
  testCaseForm!: FormGroup;
  projectId = "";
  @Output() closedStatus = new EventEmitter();
  @Output() totalTest = new EventEmitter();
  public saveblackbox!: Subscription;
  // eslint-disable-next-line max-params
  constructor(private _fb: FormBuilder, public activeModal: NgbActiveModal, private config: NgSelectConfig, public projectPlayerService: ProjectPlayerService, public elementRef: ElementRef, private cdr: ChangeDetectorRef) { }

  ngOnInit() {
    this.blackBoxForm();
    if (this.testCaseData) {
      this.editForm(this.testCaseData);
      if (this.testCase.length > 0) {
        this.showTable = !this.showTable;
      }
    }

  }

  ngAfterViewInit(): void {
    const tableBody = this.elementRef.nativeElement.querySelector('#myTableBody');
    if (tableBody) {
      const rowCount = tableBody.rows?.length;
      this.count = rowCount;
      this.cdr.detectChanges();
    }
  }

  blackBoxForm() {
    this.testCaseForm = this._fb.group({
      testInput: new FormArray([this.initTest()]),
    });
  }

  get testCase() {
    return this.testCaseForm.get('testInput') as FormArray;
  }

  initTest() {
    return this._fb.group({
      title: new FormControl('', [Validators.required]),
      inputType: new FormControl(''),
      commandInput: new FormControl(''),
      consoleInput: new FormControl(''),
      output: new FormControl('', [Validators.required]),
      isConsoleDisable: new FormControl(true),
      isCommandDisable: new FormControl(true)
    });
  }

  editForm(patchData: testCaseForm[]) {
    this.bindTestCase(patchData);
  }

  bindTestCase(patchTestCases: testCaseForm[]) {
    this.testCase.clear();
    patchTestCases.forEach((val: any) => {
      this.addTest(val);
    });
  }

  addTest(testcaseValue: BlackBoxEditInterface) {
    this.testCase.push(this.editinitTest(testcaseValue));
  }

  editinitTest(data: BlackBoxEditInterface) {
    return this._fb.group({
      title: data.title,
      inputType: data.commandInput ? "Command Input" : (data.commandInput && data.consoleInput) ? "Command & Console Input" : "Console Input",
      commandInput: data.commandInput,
      consoleInput: data.consoleInput,
      output: data.output,
      isConsoleDisable: data.consoleInput ? false : true,
      isCommandDisable: data.commandInput ? false : true
    });
  }

  addNewTest() {
    this.testCase.push(this.initTest());
    this.count++;
  }

  deleteRow(index: number) {
    this.testCase.removeAt(index);
    this.count--;
  }

  removeControlByName(controlName: string) {
    const formArrayIndex = this.testCase.controls.findIndex(control => control.get(controlName));
    if (formArrayIndex !== -1) {
      const formGroup = this.testCase.at(formArrayIndex) as FormGroup; formGroup.removeControl(controlName);
    }
  }

  saveFrom() {
    this.removeControlByName("isConsoleDisable");
    this.removeControlByName("isCommandDisable");
    const type = this.title === 'User' ? TestCaseType.USER_BLACK_BOX : TestCaseType.EVAL_BLACK_BOX;
    const blackBoxObj: BlackBoxInterface = {
      testCaseType: type,
      testCases: this.testCaseForm.value.testInput,
      projectId: this.projectPlayerService.projectId || this.projectId,
      ...(this.projectPlayerService.exerciseIdTry && { exerciseId: this.projectPlayerService.exerciseIdTry }),
    };
    this.saveblackbox = this.projectPlayerService.saveBlackBoxTestCase(blackBoxObj).subscribe(() => {
      const blackBoxTest = this.title === "User" ? "userBlackBoxTests" : "evalBlackBoxTests";
      if (!this.projectPlayerService.blackBox.includes(blackBoxTest)) {
        this.projectPlayerService.blackBox.push(blackBoxTest);
      }
      this.storeBlackBoxToProjectDetails(blackBoxObj, blackBoxTest);
      this.closedStatus.emit();
      this.totalTest.emit(this.count);
    });
  }

  storeBlackBoxToProjectDetails(blackBoxObj: BlackBoxInterface, field: string) {
    if (field === "userBlackBoxTests") {
      this.projectPlayerService.projectDetails.userBlackBoxTests = blackBoxObj.testCases;
    }
    else {
      this.projectPlayerService.projectDetails.evalBlackBoxTests = blackBoxObj.testCases;
    }
  }

  saveBlackBoxTestCaseFailure() {
    this.projectPlayerService.saveBlackBoxTestCaseFailure().subscribe(() => {
      setTimeout(() => {
        this.saveFrom();
      }, 2000);
    });
  }
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  disableInput(test: any) {
    const inputType = test.get('inputType')?.value;
    if (inputType === 'Command Input') {
      test.controls['isCommandDisable'].setValue(false);
      test.controls['isConsoleDisable'].setValue(true);
    }

    else if (inputType === 'Console Input') {
      test.controls['isCommandDisable'].setValue(true);
      test.controls['isConsoleDisable'].setValue(false);

    }
    else {
      test.controls['isCommandDisable'].setValue(false);
      test.controls['isConsoleDisable'].setValue(false);
    }
    this.onInputTypeChange(test);
  }

  toggleShowTable(): void {
    this.showTable = !this.showTable;
    if (this.testCase.length === 0) {
      this.testCase.push(this.initTest());
    }
    this.count++;
  }

  onInputTypeChange(test: FormGroup): void {
    const inputTypeControl = test.get('inputType');
    const commandInputControl = test.get('commandInput');
    const consoleInputControl = test.get('consoleInput');
    if (inputTypeControl?.value !== 'Command Input') {
      commandInputControl?.setValue('');
    }
    if (inputTypeControl?.value !== 'Console Input') {
      consoleInputControl?.setValue('');
    }

  }

  ngOnDestroy() {
    if (this.saveblackbox) {
      this.saveblackbox.unsubscribe();
    }
  }
}
