import { NgModule, Pipe, PipeTransform } from '@angular/core';
import * as dayjs from 'dayjs';

@Pipe({
  name: 'dateFormat',
  pure: true,
})
export class DateFormatPipe implements PipeTransform {

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  transform(value: any, format?: string) {
    return value && dayjs(value).format(format ? format : "DD MMM'YY hh:mm A");
  }

}
@NgModule({ declarations: [DateFormatPipe], exports: [DateFormatPipe] }) export class DateFormatPipeModule { }
