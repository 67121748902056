<div class="date-time-wrapper">
  <div class="input-wrapper" [class.form-field-error]="highlightError">
    <input #dateDirectivePicker="dpDayPicker" autocomplete="off" [dpDayPicker]="config" [mode]="'daytime'"
      [theme]="'dp-material date-time-picker'" (open)="opened()" (onChange)="changeDate()"
      [placeholder]="placeholderFormat" [value]="displayDate?.format(dateFormat)" [disabled]="disabled">
  </div>
  <div class="date-time-widget">

  </div>
  <div #timeSelector class="time-selector-container d-flex justify-content-center flex-column">
    <div *ngIf="displayTimePicker" class="time-selector">
      <div class="quantity">
        <input type="number" max="12" min="1" name='hours' [(ngModel)]="timePicker.hours"
          (change)="changeTime($event, 1, 12)">
        <div class="quantity__btn quantity__btn--up "
          (click)="updateTime({name: 'hours', value: timePicker.hours + 1},  1, 12,$event)">
          <svg-icon color="#262626" src="../../../../assets/images/date-arrow-up.svg">
          </svg-icon>
        </div>
        <div class="quantity__btn quantity__btn--down"
          (click)="updateTime({name: 'hours', value: timePicker.hours - 1},  1, 12,$event)">
          <svg-icon color="#262626" src="../../../../assets/images/date-arrow-down.svg">
          </svg-icon>
        </div>
      </div>
      <span>:</span>
      <div class="quantity">
        <input type="number" name='minutes' min="0" max="59" [(ngModel)]="timePicker.minutes"
          (change)="changeTime($event, 0, 59)">
        <div class="quantity__btn quantity__btn--up"
          (click)="updateTime({name: 'minutes', value: timePicker.minutes + 1},  0, 59,$event)">
          <svg-icon color="#262626" src="../../../../assets/images/date-arrow-up.svg">
          </svg-icon>
        </div>
        <div class="quantity__btn quantity__btn--down "
          (click)="updateTime({name: 'minutes', value: timePicker.minutes - 1},  0, 59,$event)">
          <svg-icon color="#262626" src="../../../../assets/images/date-arrow-down.svg">
          </svg-icon>
        </div>
      </div>
      <div class="merdian-input">
        <label for="am">
          <span class="merdian-radio" [class.active]="timePicker.meridiem === 'am'"
            (click)="changeMeridiem($event,'am')"></span>
          <p>
            AM
          </p>
        </label>
        <label for="pm">
          <span class="merdian-radio" [class.active]="timePicker.meridiem === 'pm'"
            (click)="changeMeridiem($event,'pm')"></span>
          <p>PM</p>
        </label>
      </div>
    </div>
    <div *ngIf="enableSaveButton" class="date-time-display">
      <div class="date-time-display-content">
        <span class="selected-text">Selected: </span>
        <span class="selected-date">
          {{formattedDate}}
        </span>
      </div>
      <div class="operation-buttons">
        <div *ngIf="enableCancelButton" class="cancel-cta" (click)="cancelDate($event)">
          <p>Cancel</p>
        </div>
        <div class="save-cta" (click)="saveDate()">
          <p>Save</p>
        </div>
      </div>
    </div>
  </div>
</div>