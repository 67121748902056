import { ProjectEventsKey } from "../enums/projectEventsKey";
export enum ErrorLevels {
  ERROR = 'ERROR',
  WARNING = 'WARNING'
}
export interface ErrorConfig {
  title? : string,
  message: string,
  params?: string[],
  retry: number,
  delegateToGlobalHandler: boolean,
  errorLevel: ErrorLevels,
  interval?: number
}
type ErrorEventConfig = { [key in string]?: ErrorConfig }

export const errorEventConfig: ErrorEventConfig = {
  [ProjectEventsKey.METADATA_CREATION_FAILURE]: {
    message: 'Metadata creation failed',
    retry: 3,
    delegateToGlobalHandler: true,
    errorLevel: ErrorLevels.WARNING,
  },
  [ProjectEventsKey.ADD_FILE_TO_PROJECT_FAILURE]: {
    message: 'Project creation failed',
    retry: 0,
    delegateToGlobalHandler: false,
    errorLevel: ErrorLevels.WARNING
  },
  [ProjectEventsKey.PROJECT_CLONE_FAILURE]: {
    message: 'Project cloning failed',
    retry: 0,
    delegateToGlobalHandler: true,
    errorLevel: ErrorLevels.ERROR
  },
  [ProjectEventsKey.ADD_RUBRICS_FAILURE]: {
    message: 'Rubrics creation failed, please try again',
    retry: 5,
    delegateToGlobalHandler: false,
    errorLevel: ErrorLevels.WARNING,
    interval: 2000
  },
};

//export const errorsToBeHandledGlobally = [ProjectEventsKey.ADD_FILE_TO_PROJECT_FAILURE, ProjectEventsKey.PROJECT_CLONE_FAILURE];