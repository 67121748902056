<!-- HEADER -->
<div class="modal-header d-flex align-items-center justify-content-between px-24 py-24">
  <span class="heading-2">
    <ng-template skeletonUi [skeletonUiType]="SkeletonLoaderType.SkeletonRectangleComponent" [skeletonUiWidth]="'13vw'"
      [skeletonUiStyles]="{'margin-right': '1vw'}"></ng-template>
  </span>
  <img src="../../../../../assets/images/test_case_close.svg">
</div>
<!-- MAIN BOX -->
<div class="px-24">
  <div class="py-24">
    <ng-template skeletonUi [skeletonUiType]="SkeletonLoaderType.SkeletonRectangleComponent" [skeletonUiWidth]="'10vw'"
      [skeletonUiStyles]="{'margin-right': '1vw'}"></ng-template>
    <ng-template skeletonUi [skeletonUiType]="SkeletonLoaderType.SkeletonRectangleComponent" [skeletonUiWidth]="'9vw'"
      [skeletonUiStyles]="{'margin-right': '1vw'}"></ng-template>
    <ng-template skeletonUi [skeletonUiType]="SkeletonLoaderType.SkeletonRectangleComponent" [skeletonUiWidth]="'7vw'"
      [skeletonUiStyles]="{'float': 'right','position':'relative'}">
    </ng-template>
  </div>
  <!-- BORDER BOX -->
  <div class="preview-container">
    <div class="px-24 pt-24 pb-2">
      <p class="heading-3">Problem Statement</p>
    </div>
    <div class="px-24">
      <ng-template skeletonUi [skeletonUiType]="SkeletonLoaderType.SkeletonRectangleComponent"
        [skeletonUiWidth]="'10vw'" [skeletonUiStyles]="{'margin-right': '1vw'}"></ng-template>
      <ng-template skeletonUi [skeletonUiType]="SkeletonLoaderType.SkeletonRectangleComponent" [skeletonUiWidth]="'5vw'"
        [skeletonUiStyles]="{'margin-right': '1vw'}"></ng-template>
      <ng-template skeletonUi [skeletonUiType]="SkeletonLoaderType.SkeletonRectangleComponent" [skeletonUiWidth]="'7vw'"
        [skeletonUiStyles]="{'margin-right': '1vw'}"></ng-template>
      <ng-template skeletonUi [skeletonUiType]="SkeletonLoaderType.SkeletonRectangleComponent" [skeletonUiWidth]="'2vw'"
        [skeletonUiStyles]="{'margin-right': '1vw'}"></ng-template>
      <ng-template skeletonUi [skeletonUiType]="SkeletonLoaderType.SkeletonRectangleComponent"
        [skeletonUiWidth]="'10vw'" [skeletonUiStyles]="{'margin-right': '1vw'}"></ng-template>
      <ng-template skeletonUi [skeletonUiType]="SkeletonLoaderType.SkeletonRectangleComponent" [skeletonUiWidth]="'5vw'"
        [skeletonUiStyles]="{'margin-right': '1vw'}"></ng-template>
      <ng-template skeletonUi [skeletonUiType]="SkeletonLoaderType.SkeletonRectangleComponent"
        [skeletonUiWidth]="'2vw'"></ng-template>
    </div>
    <div class="px-24 pt-2">
      <ng-template skeletonUi [skeletonUiType]="SkeletonLoaderType.SkeletonRectangleComponent" [skeletonUiWidth]="'2vw'"
        [skeletonUiStyles]="{'margin-right': '1vw'}"></ng-template>
      <ng-template skeletonUi [skeletonUiType]="SkeletonLoaderType.SkeletonRectangleComponent" [skeletonUiWidth]="'5vw'"
        [skeletonUiStyles]="{'margin-right': '1vw'}"></ng-template>
      <ng-template skeletonUi [skeletonUiType]="SkeletonLoaderType.SkeletonRectangleComponent"
        [skeletonUiWidth]="'10vw'" [skeletonUiStyles]="{'margin-right': '1vw'}"></ng-template>
      <ng-template skeletonUi [skeletonUiType]="SkeletonLoaderType.SkeletonRectangleComponent" [skeletonUiWidth]="'2vw'"
        [skeletonUiStyles]="{'margin-right': '1vw'}"></ng-template>
      <ng-template skeletonUi [skeletonUiType]="SkeletonLoaderType.SkeletonRectangleComponent" [skeletonUiWidth]="'7vw'"
        [skeletonUiStyles]="{'margin-right': '1vw'}"></ng-template>
      <ng-template skeletonUi [skeletonUiType]="SkeletonLoaderType.SkeletonRectangleComponent" [skeletonUiWidth]="'5vw'"
        [skeletonUiStyles]="{'margin-right': '1vw'}"></ng-template>
      <ng-template skeletonUi [skeletonUiType]="SkeletonLoaderType.SkeletonRectangleComponent"
        [skeletonUiWidth]="'10vw'"></ng-template>
    </div>
    <div class="px-24 2 pb-2">
      <p class="heading-3">Instructions</p>
    </div>
    <div class="px-24">
      <ng-template skeletonUi [skeletonUiType]="SkeletonLoaderType.SkeletonRectangleComponent"
        [skeletonUiWidth]="'10vw'" [skeletonUiStyles]="{'margin-right': '1vw'}"></ng-template>
      <ng-template skeletonUi [skeletonUiType]="SkeletonLoaderType.SkeletonRectangleComponent" [skeletonUiWidth]="'5vw'"
        [skeletonUiStyles]="{'margin-right': '1vw'}"></ng-template>
      <ng-template skeletonUi [skeletonUiType]="SkeletonLoaderType.SkeletonRectangleComponent" [skeletonUiWidth]="'7vw'"
        [skeletonUiStyles]="{'margin-right': '1vw'}"></ng-template>
      <ng-template skeletonUi [skeletonUiType]="SkeletonLoaderType.SkeletonRectangleComponent" [skeletonUiWidth]="'2vw'"
        [skeletonUiStyles]="{'margin-right': '1vw'}"></ng-template>
      <ng-template skeletonUi [skeletonUiType]="SkeletonLoaderType.SkeletonRectangleComponent"
        [skeletonUiWidth]="'10vw'" [skeletonUiStyles]="{'margin-right': '1vw'}"></ng-template>
      <ng-template skeletonUi [skeletonUiType]="SkeletonLoaderType.SkeletonRectangleComponent" [skeletonUiWidth]="'5vw'"
        [skeletonUiStyles]="{'margin-right': '1vw'}"></ng-template>
      <ng-template skeletonUi [skeletonUiType]="SkeletonLoaderType.SkeletonRectangleComponent"
        [skeletonUiWidth]="'2vw'"></ng-template>
    </div>
    <div class="px-24 pt-2">
      <ng-template skeletonUi [skeletonUiType]="SkeletonLoaderType.SkeletonRectangleComponent" [skeletonUiWidth]="'2vw'"
        [skeletonUiStyles]="{'margin-right': '1vw'}"></ng-template>
      <ng-template skeletonUi [skeletonUiType]="SkeletonLoaderType.SkeletonRectangleComponent" [skeletonUiWidth]="'5vw'"
        [skeletonUiStyles]="{'margin-right': '1vw'}"></ng-template>
      <ng-template skeletonUi [skeletonUiType]="SkeletonLoaderType.SkeletonRectangleComponent"
        [skeletonUiWidth]="'10vw'" [skeletonUiStyles]="{'margin-right': '1vw'}"></ng-template>
      <ng-template skeletonUi [skeletonUiType]="SkeletonLoaderType.SkeletonRectangleComponent" [skeletonUiWidth]="'2vw'"
        [skeletonUiStyles]="{'margin-right': '1vw'}"></ng-template>
      <ng-template skeletonUi [skeletonUiType]="SkeletonLoaderType.SkeletonRectangleComponent" [skeletonUiWidth]="'7vw'"
        [skeletonUiStyles]="{'margin-right': '1vw'}"></ng-template>
      <ng-template skeletonUi [skeletonUiType]="SkeletonLoaderType.SkeletonRectangleComponent" [skeletonUiWidth]="'5vw'"
        [skeletonUiStyles]="{'margin-right': '1vw'}"></ng-template>
      <ng-template skeletonUi [skeletonUiType]="SkeletonLoaderType.SkeletonRectangleComponent"
        [skeletonUiWidth]="'10vw'"></ng-template>
    </div>
    <!-- ACCORDIANS -->
    <!-- EXAMPLE ACCORDIAN -->
    <div class="px-24 pt-24">
      <div class="accordion accordion-flush" id="accordionFlushExample1">
        <div class="accordion-item">
          <h2 class="accordion-header" id="flush-headingOne">
            <button class="accordion-button collapsed big-bold-assistive-text" type="button" data-bs-toggle="collapse"
              data-bs-target="#flush-collapseOne1" aria-expanded="false" aria-controls="flush-collapseOne1">
              Example
            </button>
          </h2>
        </div>
      </div>
    </div>
    <!-- RUBRIC ACCORDIAN -->
    <div class="px-24 pt-24">
      <div class="accordion accordion-flush" id="accordionFlushExample1">
        <div class="accordion-item">
          <h2 class="accordion-header" id="flush-headingOne">
            <button class="accordion-button collapsed big-bold-assistive-text" type="button" data-bs-toggle="collapse"
              data-bs-target="#flush-collapseOne1" aria-expanded="false" aria-controls="flush-collapseOne1">
              Rubric
            </button>
          </h2>
        </div>
      </div>
    </div>
    <div class="px-24 py-24">
      <ng-template skeletonUi [skeletonUiType]="SkeletonLoaderType.SkeletonRectangleComponent"
        [skeletonUiWidth]="'13vw'" [skeletonUiStyles]="{'margin-right': '1vw'}"></ng-template>
      <ng-template skeletonUi [skeletonUiType]="SkeletonLoaderType.SkeletonRectangleComponent"
        [skeletonUiWidth]="'13vw'" [skeletonUiStyles]="{'margin-right': '1vw'}"></ng-template>
    </div>

  </div>
  <!-- BUTTONS -->
  <div class="d-flex justify-content-end  py-24">
    <button class="btn btn-secondary-grey" disabled>Cancel</button>
    <button class="btn btn-primary ml-24" disabled>Select Project</button>
  </div>
</div>