import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ExerciseDetails } from 'src/app/models/exerciseInterface';
import { Archetype, ProjectListCardItem } from 'src/app/models/project-list-card';
import { ProjectListCardModes } from 'src/app/enums/projectListCardMode';
import { ExerciseService } from '../exercise.service';
import { CommonUtils, timeOutput } from 'src/app/utils/common-utils';
import { SkeletonLoaderType } from 'src/app/enums/skeletonui';
@Component({
  selector: 'app-view-exercise',
  templateUrl: './view-exercise.component.html',
  styleUrls: ['./view-exercise.component.scss']
})
export class ViewExerciseComponent {
  ProjectListCardModes = ProjectListCardModes;
  countProjects!: number;
  projectsFetched!: ProjectListCardItem[];
  descriptionFetched!: string;
  duration!: number;
  idealTime!: number;
  title!: string;
  endDate!: Date;
  startDate!: Date;
  formattedDate!: string;
  marks!: number;
  langArch!: Archetype[];
  projectCountText !: string;
  exerciseStatus!: string;
  formattedArchetypes!: string[];
  projectStatus!: string;
  viewFormattedTime!: string;
  formattedTime!: timeOutput;
  displayTime!: string;
  SkeletonLoaderType = SkeletonLoaderType;
  loadingSkeleton = true;

  constructor(private activatedRoute: ActivatedRoute, public exerciseService: ExerciseService) { }
  ngOnInit() {
    this.loadDependencies();
  }

  loadDependencies() {
    const exerciseIdFetch = this.activatedRoute.snapshot.paramMap.get('exerciseId') as string;
    const payload = {
      exerciseId: exerciseIdFetch
    };
    const commonUtils = new CommonUtils();
    this.exerciseService.getExerciseDetails(payload).subscribe((exerciseDetails: ExerciseDetails) => {
      this.projectsFetched = exerciseDetails.projects;
      this.descriptionFetched = exerciseDetails.description;
      if (exerciseDetails.duration) {
        this.duration = exerciseDetails.duration;
        this.formattedTime = commonUtils.getTimeInHourAndMinute(this.duration);
        this.displayTime = commonUtils.displayTimeFormat(this.formattedTime);
      }
      else {
        this.duration = exerciseDetails.idealTimeRequired;
        this.formattedTime = commonUtils.getTimeInHourAndMinute(this.duration);
        this.displayTime = commonUtils.displayTimeFormat(this.formattedTime);
      }
      this.idealTime = exerciseDetails.idealTimeRequired;
      this.title = exerciseDetails.title;
      this.startDate = new Date(exerciseDetails.startDate);
      this.endDate = new Date(exerciseDetails.endDate);
      this.marks = exerciseDetails.maxMarks;
      this.countProjects = this.projectsFetched.length;
      this.loadingSkeleton = this.countProjects ? false : true;
      this.projectCountText = this.projectText();
      this.projectStatus = exerciseDetails.status;
      this.exerciseStatus = commonUtils.getExerciseStatus(this.projectStatus, this.startDate, this.endDate, this.projectsFetched);
      this.projectsFetched = exerciseDetails.projects;
      this.formattedArchetypes = commonUtils.formatUniqueArchetypes(this.projectsFetched);
    });
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  removeProject(projectId: string) {
    this.loadDependencies();
  }

  projectText(): string {
    if (this.countProjects === 0) {
      return 'No Projects';
    } else if (this.countProjects === 1) {
      return '1 Project';
    }
    return this.countProjects + ' Projects';
  }
}