<div class="content-container">
  <div class="page-title pb-2">
    Project Bank
  </div>
  <div class="row mb-24">
    <div class="col-6">
      <div class="input-group search-field">
        <input type="text" class="form-control inputField" placeholder="Search..." aria-label="Search..."
          aria-describedby="search-btn" [(ngModel)]="searchString">
        <span class="clear-button" *ngIf="searchString!==''">
          <svg-icon (click)="clearSearch()" src="../../../assets/images/self-practice/cross.svg">
          </svg-icon>
        </span>

        <button class="btn search-btn" type="button" id="search-btn" (click)="searchProject()">
          <img src="../assets/images/search.svg">
        </button>
      </div>
    </div>
    <div class="col-6">
      <div class="action-container d-flex align-items-center justify-content-end">
        <button *ngIf="projectList.length > 0" class="btn btn-primary-md" [routerLink]="['./create/manipulate-project']"
          queryParamsHandling="preserve">
          Create New
        </button>

        <!-- <span *ngIf="selectSortOption">
          <svg-icon src="../../../assets/images/red_Dot.svg"></svg-icon>
        </span> -->
        <ng-select class="sort-ng-select mx-4" placeholder="Sort" [items]="sortOptions" bindLabel="name"
          bindValue="value" (change)="setSortOption()" [(ngModel)]="selectSortOption" (clear)="clearSort()"
          [multiple]="false" [searchable]="false">
          <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index">
            <input id="item-{{ index }}" type="checkbox" class="custom-checkbox" [ngModel]="item$.selected" />
            {{ item.name }}
          </ng-template>
        </ng-select>

        <div class="filter-dropdown mr-24">
          <div class="dropdown">
            <div class="dropdown-toggle dropdown-filter-toggle" id="dropdownMenuClickableInside"
              data-bs-toggle="dropdown" data-bs-auto-close="outside" aria-expanded="false">
              <span *ngIf="filterOptionSelected">
                <svg-icon src="../../../assets/images/red_Dot.svg"></svg-icon>
              </span>
              Filter
            </div>
            <ul class="dropdown-menu" aria-labelledby="dropdownMenuClickableInside">
              <li class="dropdown-submenu" *ngFor="let data of  allFilters"><a
                  class="dropdown-item dropdown-toggle body-sub-text">{{data.title | titlecase}}</a>
                <ul class="dropdown-menu first-level-menu">
                  <li>
                    <a class="dropdown-item body-sub-text">
                      <div class="form-check filter-select" *ngFor="let item of data.titleValue">
                        <input class="form-check-input" type="checkbox" [id]="item.name"
                          (click)="onSelectFilter($any($event.target).checked,item.value, data.title)">
                        <label class="form-check-label" [for]="item.name">
                          {{ item.name }}
                        </label>
                      </div>
                    </a>
                  </li>
                </ul>
              </li>
            </ul>
          </div>
        </div>

        <!-- <ul class="filter-dropdown mr-24">
          <li class="nav-item dropdown">

            <a class="nav-link dropdown-toggle dropdown-filter-toggle" id="navbarDropdownMenuLink"
              data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
              <span *ngIf="filterOptionSelected">
                <svg-icon src="../../../assets/images/red_Dot.svg"></svg-icon>
              </span>Filter
            </a>
            <ul class="dropdown-menu first-level-menu" aria-labelledby="navbarDropdownMenuLink">
              <li class="dropdown-submenu" *ngFor="let data of  allFilters"><a
                  class="dropdown-item dropdown-toggle body-sub-text">{{data.title | titlecase}}</a>
                <ul class="dropdown-menu">
                  <li><a class="dropdown-item body-sub-text">
                      <div class="form-check filter-select" *ngFor="let item of data.titleValue">
                        <input class="form-check-input" type="checkbox" [id]="item.name"
                          (click)="onSelectFilter($any($event.target).checked,item.value, data.title)">
                        <label class="form-check-label" [for]="item.name">
                          {{ item.name }}
                        </label>
                      </div>
                    </a></li>
                </ul>
              </li>
            </ul>
          </li>
        </ul> -->

        <div class="body-text">
          Total number of records: <span>{{totalProjects}}</span>
        </div>
      </div>
    </div>
  </div>

  <scrollbar class="scrollbar" appInfiniteScroll [scrollThreshold]="0.7" (scrolled)="onScrollDown()">
    <div class="add-scroll">
      <ng-container *ngIf="!loadingSkeleton; else loadSkeleton">
        <ng-container *ngIf="projectList.length > 0; else show">
          <div *ngFor="let projectListCardItem of projectList; index as index" appDragDropArray
            [draggableArray]="projectList" [dragIndex]="index">
            <app-projectlistcard [projectListCardItem]="projectListCardItem" (deletedProj)="removeProject($event)"
              (clonedProjectEmitter)="appendClonedProjectToList($event)" [displayMode]="ProjectListCardModes.LISTING">
            </app-projectlistcard>
          </div>
        </ng-container>
        <ng-template #show>
          <div class="no-project-img-container pt-5">
            <svg-icon src="../../../assets/images/noProj.svg"></svg-icon>
            <button class="btn btn-primary" [routerLink]="['./create/manipulate-project']"
              queryParamsHandling="preserve">
              Create New
            </button>
          </div>
        </ng-template>
      </ng-container>
      <ng-template #loadSkeleton>
        <ng-template skeletonUi [skeletonUiType]="SkeletonLoaderType.ProjectListCardSkeletonComponent"
          [skeletonUiRepeat]="5">
        </ng-template>
      </ng-template>
    </div>
  </scrollbar>
</div>