import { Component, ElementRef } from '@angular/core';
import { SkeletonComponent } from 'src/app/base-class/skeleton-class';

@Component({
  selector: 'app-console-skeleton',
  templateUrl: './console-skeleton.component.html',
  styleUrls: ['./console-skeleton.component.scss']
})
export class ConsoleSkeletonComponent extends SkeletonComponent {
  constructor(override host: ElementRef<HTMLElement>) {
    super(host);
  }
  ngOnInit() {
    this.setStylesAndClass();
  }

}