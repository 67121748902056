<div class="uploading-sql-file-modal">
  <div class="uploading-header heading-2 d-flex align-items-center p-24">
    Uploading SQL File
  </div>
  <div class="uploading-body d-flex flex-column align-items-start justify-content-center p-24 ">
    <div class="d-flex align-items-end mb-16">

      <div class="col-md-4 d-flex align-items-center">
        <svg-icon src="../../../../../assets/images/file-upload/file-upload-blue.svg"></svg-icon>
        <span class="big-body-text-2 ms-2">File Upload</span>
      </div>

      <div class="col-md-8 d-flex align-items-end ms-3">
        <span>
          <div class="big-body-text-3 mb-2" *ngIf="!!initFile">{{initFile.name}}</div>
          <div class="progress" role="progressbar" aria-label="Success" aria-valuenow="75" aria-valuemin="0"
            aria-valuemax="100">
            <div class="progress-bar bg-success" [style.width]="fileUploaderWidth"><span>
                {{fileUploaderWidth}}
              </span></div>
          </div>
        </span>
        <span class="delete-icon-wrapper">
          <svg-icon src="../../../../../assets/images/file-upload/delete-red.svg" (click)="deleteSqlFile()"></svg-icon>
        </span>
      </div>

    </div>

    <div class="d-flex align-items-end mb-16">

      <div class="col-md-4 d-flex align-items-center">
        <svg-icon src="../../../../assets/images/file-upload/data-insertion.svg"></svg-icon>
        <span class="big-body-text-2 ms-2">Data Insertion</span>
      </div>

      <div class="col-md-8 d-flex align-items-end ms-3">
        <div class="progress" role="progressbar" aria-label="Success" aria-valuenow="0" aria-valuemin="0"
          aria-valuemax="100">
          <div class="progress-bar bg-success" [style.width]="dataInsertedWidth"><span>
              {{dataInsertedWidth}}
            </span></div>
        </div>
      </div>
    </div>

    <!-- <div class="">
        <span class="d-flex align-items-center">
          <svg-icon src="../../../../assets/images/file-upload/data-insertion.svg"></svg-icon>
          <span class="big-body-text ms-2">Data Insertion</span>
        </span>
      </div> -->

  </div>

</div>