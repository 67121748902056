/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { ProjectEventsKey } from '../enums/projectEventsKey';
import { ErrorConfig, ErrorLevels, errorEventConfig } from '../configs/errorEventConfig';
import { DialogTypes } from '../enums/dialog';
import { Dialog } from '../models/dialog';
import { DialogService } from './dialog.service';
import { successEventConfig } from '../configs/successEventConfig';

@Injectable({
  providedIn: 'root'
})
export class EventHandlerService {
  retryInterval = 5000;
  errorEventConfig = errorEventConfig;
  successEventConfig = successEventConfig;
  constructor(private toastService: ToastrService, private dialogService: DialogService) { }
  init() {
    // this.toastService.error('Test Error', 'Description', { disableTimeOut: true });
    // this.toastService.success('Test Error', 'Description', { disableTimeOut: true });
    // eslint-disable-next-line no-console
    console.log('Toast service initialized');
  }

  // check if event is successEvent or errorEvent
  // @param eventName Name of the error Event, fetched from the ProjectEventKey enum.
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  handleEventGlobally(eventName: string, payload: any): void {
    if (this.errorEventConfig[eventName]) {
      this.handleErrorGlobally(eventName, payload);
    }
    if (this.successEventConfig[eventName]) {
      this.handleSuccessGlobally(eventName, payload);
    }
  }

  /**
   * Handle global errors. To be called only via the SocketService.
   * @param eventName Name of the error Event, fetched from the ProjectEventKey enum.
   */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  handleErrorGlobally(eventName: string, payload: any): void {

    //If the error is critical and delegated to global handler then show error and refresh
    if (this.errorEventConfig[eventName]?.errorLevel === ErrorLevels.ERROR && this.errorEventConfig[eventName]?.delegateToGlobalHandler) {
      if (this.errorEventConfig[eventName]!.params && this.errorEventConfig[eventName]!.params!.length) {
        const errorMessage = this.replacePlaceholders(this.errorEventConfig[eventName]!.message, this.errorEventConfig[eventName]!.params!, payload);
        this.throwGlobalErrorDialog(errorMessage);
      }
      else{
        this.throwGlobalErrorDialog(this.errorEventConfig[eventName]!.message);
      }
    }

    //If the error is not critical but delegated to the handler. Show the error
    if (this.errorEventConfig[eventName]?.delegateToGlobalHandler) {
      if (this.errorEventConfig[eventName]!.params && this.errorEventConfig[eventName]!.params!.length) {
        const errorMessage = this.replacePlaceholders(this.errorEventConfig[eventName]!.message, this.errorEventConfig[eventName]!.params!, payload);
        this.toastService.error(errorMessage);
      }
      else{
        this.toastService.error(this.errorEventConfig[eventName]!.message);
      }
    }

  }

  /**
   * Handle global successEvent. To be called only via the SocketService.
   * @param eventName Name of the success Event, fetched from the ProjectEventKey enum.
   */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  handleSuccessGlobally(eventName: string, payload: any): void {

    //If the error is delegated to the handler. Show the success message
    if (this.successEventConfig[eventName]?.delegateToGlobalHandler) {
      if (this.successEventConfig[eventName]!.params && this.successEventConfig[eventName]!.params!.length) {
        const successMessage = this.replacePlaceholders(this.successEventConfig[eventName]!.message, this.successEventConfig[eventName]!.params!, payload);
        this.toastService.success(successMessage,this.successEventConfig[eventName]!.title);
      }
      else {
        this.toastService.success(this.successEventConfig[eventName]!.message);
      }
    }
  }

  /**
 * Replaces placeholders in a message with values from a payload.
 *
 * @param message - The message with placeholders to be replaced.
 * @param params - An array of parameter names corresponding to placeholders.
 * @param payload - An object containing values to replace placeholders.
 * @returns The message with placeholders replaced by actual values.
 */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private replacePlaceholders(message: string, params: string[], payload: any): string {
    for (let i = 0; i < params.length; i++) {
      const placeholder = `{${i + 1}}`;
      // Get the value from the payload based on the parameter name.
      const value = payload[params[i]];

      // If the value is defined (not undefined), replace the placeholder with the value.
      if (value !== undefined) {
        message = message.replace(placeholder, value);
      }
    }

    // Return the message with placeholders replaced by actual values.
    return message;
  }

  // eslint-disable-next-line @typescript-eslint/ban-types
  // retryOnError(eventName: ProjectEventsKey, callback : Function, retryInterval = this.retryInterval){
  //   let retries = this.getErrorRetries(eventName);
  //   setTimeout(() => {
  //     retries--;
  //     callback();
  //   }, retryInterval);
  // }

  /**
   * Fetch the error config containing the message and retries to be attempted for the particular error scenario.
   * @param eventName Name of the error Event, fetched from the ProjectEventKey enum.
   * @returns ErrorConfig for the particular error.
   */
  getErrorConfig(eventName: ProjectEventsKey): ErrorConfig {
    return this.errorEventConfig[eventName]!;
  }

  /**
   * Fetch the number of retries to be attempted for the particular error scenario
   * @param eventName Name of the error Event, fetched from the ProjectEventKey enum.
   * @returns number of retires.
   */
  getErrorRetries(eventName: ProjectEventsKey): number {
    return this.errorEventConfig[eventName]!.retry;
  }

  /**
   * Fetch the error message to be displayed.
   * @param eventName Name of the error Event, fetched from the ProjectEventKey enum.
   * @returns error message.
   */
  getErrorMessage(eventName: ProjectEventsKey): string {
    return this.errorEventConfig[eventName]!.message;
  }

  /**
 * Fetch Error specific or Global retry interval duration.
 * @param eventName Name of the error Event, fetched from the ProjectEventKey enum.
 * @returns retry interval
 */
  getRetryInterval(eventName: (ProjectEventsKey | string) = ''): number {
    if (this.errorEventConfig[eventName] && this.errorEventConfig[eventName]!.interval) {
      return this.errorEventConfig[eventName]!.interval!;
    }
    return this.retryInterval;
  }

  /**
 * This will throw a global error alert box and refresh the application. Only to be used for critical errors.
 * @param message The Error message to be displayed
 */
  throwGlobalErrorDialog(message: string): void {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const dialogOption: Dialog = {
      type: DialogTypes.ERROR,
      title: {
        translationKey: message
      }
    };
    this.toastService.error(message);
    this.reloadWindow();
  }

  reloadWindow() {
    window.location.reload();
  }

}
