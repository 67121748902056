import { Injectable } from '@angular/core';
import { ProjectPlayerService } from './project-player.service';
import { SocketService } from 'src/app/services/socket.service';
import { Observable, Subject } from 'rxjs';
import { EvaluationEventsKey } from 'src/app/enums/EvaluationEventKeys';
import { FeedBackData } from 'src/app/models/evaluationInterface';
import { GetDirectory, GetProjectFileContent, compileAndRun } from 'src/app/models/projectInterface';
import { ProjectEventsKey } from 'src/app/enums/projectEventsKey';
import { IAttemptProjectDetails } from 'src/app/models/attemptInterface';
import { StorageService } from 'src/app/services/storage.service';
import { DialogService } from 'src/app/services/dialog.service';
import { CommonService } from 'src/app/services/common.service';

@Injectable()
export class EvaluationPlayerService extends ProjectPlayerService {
  private projectChange = new Subject<IAttemptProjectDetails>();
  public override evaluateData = new Map();
  override exerciseId!: string;
  override attemptId!: string;
  override userId!: string;
  public redirectUrl!: string;
  constructor(protected override socketService: SocketService, protected override storageService: StorageService, protected override dialogService: DialogService, protected override commonService: CommonService) {
    super(socketService, storageService, dialogService, commonService);
  }
  //c80c6de0-899d - 412b - bca2 - 272004e130e9
  //evaluation-flow - test - dont - use - this
  // eslint-disable-next-line @typescript-eslint/no-unused-vars, @typescript-eslint/no-explicit-any
  override fetchAttemptDetails(attemptId: string): Observable<any> {
    const payload = {
      attemptId: this.attemptId
    };
    return this.socketService.getDataFromSocket(EvaluationEventsKey.FETCH_ATTEMPT_DETAILS, EvaluationEventsKey.ATTEMPT_DETAILS, payload, true);

  }
  override getDirectoryDetails(obj: GetDirectory) {
    obj.attemptId = this.attemptId;
    obj.exerciseId = this.exerciseId;
    obj.userId = this.userId;
    return this.socketService.getDataFromSocket(ProjectEventsKey.GET_DIRECTORY_DETAILS, ProjectEventsKey.DIRECTORY_DETAILS_SUCCESS, obj, true);

  }
  override saveFeedback(data: FeedBackData) {
    return this.socketService.getDataFromSocket(EvaluationEventsKey.ADD_CUSTOM_MARKS, EvaluationEventsKey.CUSTOM_MARKS_ADDED, data, true);
  }

  override listenProjectChange() {
    return this.projectChange.asObservable();
  }

  override triggerProjectChange(obj: IAttemptProjectDetails) {
    this.projectChange.next(obj);
  }

  override requestProjectFileContent(obj: GetProjectFileContent) {
    obj.attemptId = this.attemptId;
    obj.exerciseId = this.exerciseId;
    obj.userId = this.userId;
    return this.socketService.getDataFromSocket(ProjectEventsKey.GET_FILE_CONTENT, ProjectEventsKey.FILE_CONTENT_SUCCESS, obj, true);
  }

  override compileAndRun(payload: compileAndRun) {
    payload.userId = this.userId;
    payload.attemptId = this.attemptId;
    payload.exerciseId = this.exerciseId;
    return this.socketService.getDataFromSocket(ProjectEventsKey.RUN, ProjectEventsKey.FETCHING_META_DATA, payload, true);
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  override setFormValues(projectId: string, formValues: any) {
    this.evaluateData.set(projectId, formValues);
  }

  override getFormValues(projectId: string) {
    return this.evaluateData.get(projectId);
  }

  override completeEvaluation(data: string) {
    const payload = {
      attemptId: this.attemptId,
      feedback: data
    };
    return this.socketService.getDataFromSocket(EvaluationEventsKey.SUBMIT_ATTEMPT_FEEDBACK, EvaluationEventsKey.ATTEMPT_FEEDBACK_SUBMITTED, payload, true);
  }

}
