import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ProjectPlayerModes } from 'src/app/enums/PlayerModes';
import { Archetype } from 'src/app/models/project-list-card';
import { ProjectDetails } from 'src/app/models/projectInterface';
import { ProjectBankService } from 'src/app/projectbank/project-bank.service';

@Component({
  selector: 'app-try-project',
  templateUrl: './try-project.component.html',
  styleUrls: ['./try-project.component.scss']
})
export class TryProjectComponent {
  projectId!: string;
  ProjectPlayerModes = ProjectPlayerModes;
  archeTypes!: Archetype[];
  public projectDetails!: ProjectDetails;
  constructor(private activatedRoute: ActivatedRoute, private projectBankService: ProjectBankService) { }
  ngOnInit() {
    this.projectId = this.activatedRoute.snapshot.params['projectId'];
    this.projectBankService.projectId = this.projectId;
    this.storeProjectDetails();
  }

  storeProjectDetails() {
    const exerciseId: string = this.activatedRoute.snapshot.queryParams['exerciseId'];
    if (exerciseId) {
      this.projectBankService.exerciseId = exerciseId;
    }
    this.projectBankService.getProjectDetails().subscribe((data: ProjectDetails) => {
      this.projectDetails = data;
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      this.archeTypes = data.archetypes!;
    });
  }
}
