import { Component } from '@angular/core';
import { ProjectBankService } from '../project-bank.service';
import { ProjectListCardItem, Configuration } from 'src/app/models/project-list-card';
import { Subscription, map } from 'rxjs';
import { SkeletonLoaderType } from 'src/app/enums/skeletonui';
import { ProjectListCardModes } from 'src/app/enums/projectListCardMode';
import { CommonUtils } from 'src/app/utils/common-utils';
export interface SortOptions {
  name: string,
  value: {
    sortBy: string,
    sortOrder: string,
  }
}
@Component({
  selector: 'app-projectbank',
  templateUrl: './projectbank.component.html',
  styleUrls: ['./projectbank.component.scss']
})
export class ProjectBankComponent {
  constructor(private projectBankService: ProjectBankService) { }
  SkeletonLoaderType = SkeletonLoaderType;
  ProjectListCardModes = ProjectListCardModes;
  selectSortOption!: SortOptions["value"];
  projectList$!: Subscription;
  configuration$!: Subscription;
  projectList: ProjectListCardItem[] = [];
  totalProjects!: number;
  loadingSkeleton = true;
  filterOptionSelected = false;
  searchString = '';
  versionConfig: { language: string, versions: { langId: string, label: string, archetypes: { targetFileName: string, sourceFileName: string, label: string, archetypeId: string }[] }[] }[] = [];
  allFilters: { title: string, titleValue: { name: string, value: string }[] }[] = [];

  filters: Record<string, string[]> = {
    // author: [],
    language: [],
    archetype: [],
    level: [],
    status: []
  };

  sortOptions = [
    {
      name: "Created A-Z",
      value: {
        sortBy: "createdAt",
        sortOrder: "ASC",
      }
    },
    {
      name: "Created Z-A",
      value: {
        sortBy: "createdAt",
        sortOrder: "DSC",
      }
    },
    {
      name: "Edited A-Z",
      value: {
        sortBy: "updatedAt",
        sortOrder: "ASC",
      }
    },
    {
      name: "Edited Z-A",
      value: {
        sortBy: "updatedAt",
        sortOrder: "DSC",
      }
    }

  ];

  ngOnInit() {
    this.getProjectList();
    this.getConfig();
  }

  async getConfig() {
    let levels: string[] = [];
    let state: string[] = [];
    //let authors: string[] = [];
    const configuration$ = this.projectBankService.getConfig().subscribe((configuration: Configuration) => {
      levels = configuration.level;
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      state = configuration.status!;
      //authors = configuration.author;
      this.versionConfig = configuration.languages;
      let languages: { name: string, value: string }[] = [];
      // let author: { name: string, value: string }[] = [];
      let level: { name: string, value: string }[] = [];
      let status: { name: string, value: string }[] = [];
      if (this.versionConfig) {
        languages = this.versionConfig.map((lang: { language: string, versions: { langId: string, label: string, archetypes: { targetFileName: string, sourceFileName: string, label: string, archetypeId: string }[] }[] }) => {
          return { name: lang.language, value: lang.language };
        });
      }
      this.allFilters.push({ title: "language", titleValue: languages });
      this.allFilters.push({ title: "archetype", titleValue: [] });
      // if (authors) {
      //   author = authors.map(author => ({ name: author, value: author }));
      //   this.allFilters.push({ title: "author", titleValue: author });
      // }
      if (levels) {
        level = levels.map(level => ({ name: level, value: level }));
        this.allFilters.push({ title: "level", titleValue: level });
      }
      if (state) {
        status = state.map(status => ({ name: status, value: status }));
        this.allFilters.push({ title: "status", titleValue: status });
      }
      configuration$.unsubscribe();
    });
  }

  private getProjectList() {
    this.projectList$ = this.projectBankService.getProjectList()
      .pipe(
        // take(1),
        map((projectListPayload: { projects: ProjectListCardItem[], lastProjectCursor: string, totalProjects: number }) => {
          this.projectBankService.setLastProjectCursor = projectListPayload.lastProjectCursor;
          this.totalProjects = projectListPayload.totalProjects;
          return projectListPayload;
        })
      ).subscribe((projectListPayload: { projects: ProjectListCardItem[] }) => {
        this.loadingSkeleton = false;
        this.projectList.push(...projectListPayload.projects);
      });
  }

  removeProject(projectId: string) {
    this.projectList = this.projectList.filter(projectList => projectList.projectId !== projectId);
    this.totalProjects -= 1;
  }
  appendClonedProjectToList(projectToBeAppended: ProjectListCardItem) {
    this.projectList.unshift(projectToBeAppended);
  }

  searchProject() {
    if (this.searchString !== '') {
      this.projectList = [];
      this.projectBankService.setSearchString(this.searchString);
      this.refreshProjectList();
    }
  }
  clearSearch() {
    this.searchString = '';
    this.projectBankService.setSearchString(this.searchString);
    this.refreshProjectList();
  }

  public refreshProjectList() {
    this.loadingSkeleton = true;
    this.projectBankService.setLastProjectCursor = "";
    this.projectList = [];
    this.projectBankService.getProjectList();
  }

  setSortOption() {
    this.projectBankService.setSortOption(this.selectSortOption);
    this.refreshProjectList();
  }

  clearSort() {
    this.projectBankService.setSortOption({
      sortBy: "",
      sortOrder: "",
    });
    this.refreshProjectList();
  }

  onScrollDown() {
    this.projectBankService.getProjectList();
  }

  onSelectFilter(checked: boolean, item: string, title: string) {

    if (checked) {
      this.filters[title].push(item);
      if (title === "language") {
        this.setArchTypesBasedOnLanguages(title);
      }
    } else {
      this.removeIndex(item, this.filters[title]);
      if (title === "language") {
        this.setArchTypesBasedOnLanguages(title);
      }
    }

    const commonUtils = new CommonUtils();
    //Check if any filter options are selected
    this.filterOptionSelected = commonUtils.isAnyFilterSelected(this.filters);

    if (this.filters) {
      this.projectBankService.setFilters(this.filters);
      this.refreshProjectList();
    }
  }

  setArchTypesBasedOnLanguages(title: string) {
    const filteredArchId: string[] = [];
    const archetypeNames: { name: string; value: string; }[] = [];
    const filteredVersionArray: { langId: string; label: string; archetypes: { targetFileName: string, sourceFileName: string, label: string; archetypeId: string; }[]; }[] = [];
    const index = this.allFilters.findIndex((item: { title: string; titleValue: { name: string, value: string }[] }) => item.title === 'archetype');
    if (this.filters[title].length > 0) {
      this.filters[title].forEach((language: string) => {
        this.versionConfig.map(
          (languagePayload: { language: string, versions: { langId: string, label: string, archetypes: { targetFileName: string, sourceFileName: string, label: string, archetypeId: string }[] }[] }) => {
            if (language === languagePayload.language) {
              filteredVersionArray.push(...languagePayload.versions);
              languagePayload.versions.forEach((version: { langId: string, label: string, archetypes: { targetFileName: string, sourceFileName: string, label: string, archetypeId: string }[] }) => {
                filteredArchId.push(...version.archetypes.flatMap(archetype => archetype.archetypeId));
              });
            }
          });
      });
      filteredVersionArray.map(item => {
        item.archetypes.map(archetype => {
          archetypeNames.push({
            name: archetype.label,
            value: archetype.archetypeId
          });
        });
      });
      this.allFilters[index].titleValue = [];
      if (index !== -1) {
        this.allFilters[index].titleValue.push(...archetypeNames);
      }
    }
    else {
      this.allFilters[index].titleValue = [];
    }
  }
  removeIndex(item: string, type: string[]) {
    const indexToRemove = type.indexOf(item);
    if (indexToRemove !== -1) {
      type.splice(indexToRemove, 1);
    }
  }

  ngOnDestroy() {
    this.projectList = [];
    this.projectBankService.setLastProjectCursor = "";
    this.projectList$.unsubscribe();
    this.filters = {};
  }
}
