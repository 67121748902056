<div class="modal-header d-flex align-items-center justify-content-between">
  <span class="heading-2">
    Create New Exercise
  </span>
  <svg-icon (click)="activeModal.dismiss()" class="cursor-pointer" src="../../../../assets/images/close.svg">
  </svg-icon>
</div>

<div class="modal-body">
  <p class="big-bold-assistive-text mb-24">
    Which type of Exercise would you like to create?
  </p>

  <label class="custom-radio mb-16">
    <input type="radio" name="radio" [(ngModel)]="selectedExerciseType" value="quiz"
      (change)="updateSelectedOption('quiz')" />
    <span>Quiz</span>
  </label>

  <label class="custom-radio mb-16">
    <input type="radio" name="radio" [(ngModel)]="selectedExerciseType" value="assignment"
      (change)="updateSelectedOption('assignment')" />
    <span>Assignment</span>
  </label>

  <label class="custom-radio">
    <input type="radio" name="radio" [(ngModel)]="selectedExerciseType" value="practice"
      (change)="updateSelectedOption('practice')" />
    <span>Practice</span>
  </label>
</div>

<div class="modal-footer">
  <button class="btn btn-secondary-grey-md" (click)="activeModal.dismiss()">Cancel</button>
  <button class="btn btn-primary-md ml-24" (click)="createExercise()">Create</button>
</div>