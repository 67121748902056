import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ExerciseComponent } from './exercise.component';
import { ExerciseListComponent } from './exercise-list/exercise-list.component';
import { RoutingParams } from '../enums/routingParameters';
import { ViewExerciseComponent } from './view-exercise/view-exercise.component';

const routes: Routes = [
  {
    path: '',
    component: ExerciseComponent,
    children: [
      {
        path: '',
        component: ExerciseListComponent
      }
    ]
  },
  {
    path: ':exerciseId/' + RoutingParams.VIEW_EXERCISE,
    component: ViewExerciseComponent
  },
  {
    path: ':exerciseId/' + RoutingParams.MANIPULATE_EXERCISE,
    loadChildren: () => import('./exercise-builder/exercise-builder.module').then((module) => module.ExerciseBuilderModule),

  },
  {
    path: ':projectId/' + RoutingParams.TRY_PROJECT,
    loadChildren: () => import('./../shared/modules/try-project/try-project.module').then((module) => module.TryProjectModule),
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ExerciseRoutingModule { }
