<div *ngIf="!projectDetails; else content">
  <!-- Skeleton Loading -->
  <ng-template skeletonUi [skeletonUiType]="SkeletonLoaderType.ModalSkeletonComponent"></ng-template>
</div>
<!-- Content Loading -->
<ng-template #content>
  <div class="modal-header d-flex align-items-center justify-content-between px-24 py-24">
    <span class="heading-2">
      {{projectDetails.title}}
    </span>
    <svg-icon (click)="activeModal.dismiss()" class="cursor-pointer"
      src="../../../../assets/images/test_case_close.svg">
    </svg-icon>
  </div>
  <div class="px-24">
    <div class="py-24 d-inline-flex justify-content-between w-100">
      <div>
        <span class="body-sub-text " translate="exercise.excerciseSetup.previewModal.level"><span
            [ngClass]="{'level-easy' :projectDetails.difficultyLevel == DifficultyLevel.EASY , 'level-difficult':projectDetails.difficultyLevel == DifficultyLevel.DIFFICULT,'level-medium':projectDetails.difficultyLevel == DifficultyLevel.MEDIUM }">{{projectDetails.difficultyLevel
            | titlecase}}</span></span>
        <span class="px-2 body-sub-text" translate="exercise.excerciseSetup.previewModal.languageAndArchtype"><span
            class="ff-semibold lh-16 ms-1" *ngFor="let archetype of projectDetails.archetypes">
            {{archetype.language | titlecase}}</span></span>
      </div>
      <div class="right-p">
        <p class="body-sub-text" translate="exercise.excerciseSetup.previewModal.authored">
          <span>{{projectDetails.userName}}</span>
        </p>
      </div>
    </div>
    <!-- BOX -->
    <div class="preview-container">
      <scrollbar class="scrollbar">
        <div class="add-scroll adding-scroll">
          <!-- Problem Statement -->
          <div class="px-24 pt-24 pb-2">
            <p class="heading-3" translate="exercise.excerciseSetup.previewModal.problemStatement"></p>
          </div>
          <div class="px-24">
            <p class="body-text" [innerHTML]="projectDetails.problemStatement">
            </p>
          </div>
          <!-- Instruction -->
          <div class="px-24 pt-24 pb-2">
            <p class="heading-3" translate="exercise.excerciseSetup.previewModal.instruction"></p>
          </div>
          <div class="px-24" [innerHTML]="projectDetails.instructions">
          </div>
          <!-- Accordians -->
          <!-- Example Accordian -->
          <div class="px-24 pt-24">
            <div class="accordion accordion-flush" id="accordionFlushExample1">
              <div class="accordion-item">
                <h2 class="accordion-header" id="flush-headingOne">
                  <button class="accordion-button collapsed big-bold-assistive-text" type="button"
                    data-bs-toggle="collapse" data-bs-target="#flush-collapseOne1" aria-expanded="false"
                    aria-controls="flush-collapseOne1" translate="exercise.excerciseSetup.previewModal.example">
                  </button>
                </h2>
                <div id="flush-collapseOne1" class="accordion-collapse collapse" aria-labelledby="flush-headingOne1"
                  data-bs-parent="#accordionFlushExample1">
                  <div class="accordion-body" [innerHTML]="projectDetails.example">
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- Rubric Accordian -->
          <div class="px-24 pt-24">
            <div class="accordion accordion-flush" id="accordionFlushExample2">
              <div class="accordion-item">
                <h2 class="accordion-header" id="flush-headingOne">
                  <button class="accordion-button collapsed big-bold-assistive-text" type="button"
                    data-bs-toggle="collapse" data-bs-target="#flush-collapseOne2" aria-expanded="false"
                    aria-controls="flush-collapseOne2" translate="exercise.excerciseSetup.previewModal.rubric">
                  </button>
                </h2>
                <div id="flush-collapseOne2" class="accordion-collapse collapse" aria-labelledby="flush-headingOne1"
                  data-bs-parent="#accordionFlushExample2">
                  <div class="accordion-body">
                    <p class="big-bold-assistive-text pb-1"
                      translate="exercise.excerciseSetup.previewModal.compilation"></p>
                    <p class="pb-2" translate="exercise.excerciseSetup.previewModal.compilationDefination"></p>
                    <div class="d-inline-flex pb-16">
                      <div class="rubric">
                        {{projectDetails.rubricWeightage?.compilation}}
                      </div>
                      <div class="body-sub-text pt-16 ms-2" translate="exercise.excerciseSetup.previewModal.percentage">
                      </div>
                    </div>
                    <p class="big-bold-assistive-text pb-1"
                      translate="exercise.excerciseSetup.previewModal.userBlackBox"></p>
                    <p class="pb-2" translate="exercise.excerciseSetup.previewModal.compilationDefination">
                    <p>
                    <div class="d-inline-flex pb-16">
                      <div class="rubric">
                        {{projectDetails.rubricWeightage?.userBlackBox}}
                      </div>
                      <div class="body-sub-text pt-16 ms-2" translate="exercise.excerciseSetup.previewModal.percentage">
                      </div>
                    </div>
                    <p class="big-bold-assistive-text pb-1"
                      translate="exercise.excerciseSetup.previewModal.evaluatorBlackBox"></p>
                    <p class="pb-2" translate="exercise.excerciseSetup.previewModal.compilationDefination">
                    <p>
                    <div class="d-inline-flex pb-16">
                      <div class="rubric">
                        {{projectDetails.rubricWeightage?.evalBlackBox}}
                      </div>
                      <div class="body-sub-text pt-16 ms-2" translate="exercise.excerciseSetup.previewModal.percentage">
                      </div>
                    </div>
                    <p class="big-bold-assistive-text pb-1"
                      translate="exercise.excerciseSetup.previewModal.userWhiteBox"></p>
                    <p class="pb-2" translate="exercise.excerciseSetup.previewModal.compilationDefination">
                    <p>
                    <div class="d-inline-flex pb-16">
                      <div class="rubric">
                        {{projectDetails.rubricWeightage?.userWhiteBox}}
                      </div>
                      <div class="body-sub-text pt-16 ms-2" translate="exercise.excerciseSetup.previewModal.percentage">
                      </div>
                    </div>
                    <p class="big-bold-assistive-text pb-1"
                      translate="exercise.excerciseSetup.previewModal.evaluatorWhiteBox"></p>
                    <p class="pb-2" translate="exercise.excerciseSetup.previewModal.compilationDefination"></p>
                    <div class="d-inline-flex pb-16">
                      <div class="rubric">
                        {{projectDetails.rubricWeightage?.evalWhiteBox}}
                      </div>
                      <div class="body-sub-text pt-16 ms-2" translate="exercise.excerciseSetup.previewModal.percentage">
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- Note -->
          <div class="d-inline-flex p-24">
            <p class="note fs-16" translate="exercise.excerciseSetup.previewModal.note"></p>
            <p translate="exercise.excerciseSetup.previewModal.noteMessage"></p>
          </div>
        </div>
      </scrollbar>
    </div>
  </div>
  <div class="d-flex justify-content-end px-24 py-24">
    <button class="btn btn-secondary-grey" translate="exercise.excerciseSetup.previewModal.cancel"
      (click)="activeModal.dismiss()"></button>
    <button class="btn btn-primary ml-24" translate="exercise.excerciseSetup.previewModal.selectProject"
      (click)="saveProject()"></button>
  </div>
</ng-template>