import { Injectable } from '@angular/core';
import { ProjectEventsKey } from 'src/app/enums/projectEventsKey';
import { SocketService } from 'src/app/services/socket.service';
import { IGetProjectDetails, deleteProj } from '../models/projectInterface';
import { SortOptions } from './project-bank/projectbank.component';
import { Archetype } from '../models/project-list-card';

@Injectable()
export class ProjectBankService {
  lastProjectCursor!: string;
  searchString!: string;
  selectedSortOption!: SortOptions["value"];
  public archTypes!: Archetype[];
  filters: Record<string, string[]> = {
    author: [],
    language: [],
    archetype: [],
    level: [],
    status: []
  };
  projectId!: string;
  public exerciseId!: string;
  constructor(private socketService: SocketService) { }

  getProjectList() {
    return this.socketService.getDataFromSocket(ProjectEventsKey.GET_PROJECT_LIST, ProjectEventsKey.PROJECT_LIST, {
      ...((this.lastProjectCursor && this.lastProjectCursor !== '') && { lastProjectCursor: this.lastProjectCursor }),
      ...(this.searchString && { search: this.searchString }),
      ...((this.selectedSortOption && this.selectedSortOption.sortBy !== "") && { sortby: this.selectedSortOption.sortBy, sortOrder: this.selectedSortOption.sortOrder }),
      ...(this.filters && { filters: this.filters }),
      limit: 10,
      //filters: [{}]
    }
    , true);
  }

  set setLastProjectCursor(lastProjectCursor: string) {
    this.lastProjectCursor = lastProjectCursor;
  }
  deleteProj(obj: deleteProj) {
    return this.socketService.getDataFromSocket(ProjectEventsKey.DELETE_PROJECT, ProjectEventsKey.PROJECT_DELETED, obj);
  }

  cloneProject(projectId: string) {
    const payload = {
      projectId: projectId,
    };
    return this.socketService.getDataFromSocket(ProjectEventsKey.CLONE_PROJECT, ProjectEventsKey.PROJECT_CLONED, payload, true);
  }

  clonedProjectMetadataCreated() {
    return this.socketService.listenToEventWithoutHistory(ProjectEventsKey.CLONED_PROJECT_METADATA_CREATED);
  }

  projectCloneFailure() {
    return this.socketService.listenToEvent(ProjectEventsKey.PROJECT_CLONE_FAILURE);
  }

  metadataCreationFailure() {
    return this.socketService.listenToEvent(ProjectEventsKey.METADATA_CREATION_FAILURE);
  }

  oldProjectPathNotFound() {
    return this.socketService.listenToEvent(ProjectEventsKey.OLD_PROJECT_PATH_NOT_FOUND);
  }

  setSearchString(searchString: string) {
    this.searchString = searchString;
  }
  
  setSortOption(selectedSortOption: SortOptions["value"]) {
    this.selectedSortOption = selectedSortOption;
  }

  setFilters(selectedFilters: Record<string, string[]>) {
    this.filters = selectedFilters;
  }

  getProjectDetails() {
    const payload: IGetProjectDetails = {
      projectId: this.projectId,
    };
    if (this.exerciseId) {
      payload.exerciseId = this.exerciseId;
    }
    return this.socketService.getDataFromSocket(ProjectEventsKey.GET_PROJECT_DETAILS, ProjectEventsKey.PROJECT_DETAILS, payload, true);
  }

  public getConfig() {
    const payload = {};
    return this.socketService.getDataFromSocket(ProjectEventsKey.GET_PROJECT_CONFIGURATION, ProjectEventsKey.PROJECT_CONFIGURATION, payload, true);
  }
}
