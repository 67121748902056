<div class="inner-container">

  <scrollbar class="scrollbar">
    <div class="add-scroll mt-24">

      <form [formGroup]="rubricForm" class="px-24">

        <div class="mt-24">
          <div>
            <div class="big-bold-assistive-text" translate="projectBank.manipulation.rubric.total">
              <span class="big-bold-assistive-text p-0" [ngClass]="{ 'error-message': !rubricForm.valid }">{{ sum
                }}%</span>
            </div>
            <div class="mt-2">
              <p class="body-pill-text" [ngClass]="{ 'error-message': !rubricForm.valid }"
                translate="projectBank.manipulation.rubric.note">
                <span class="body-sub-text" [ngClass]="{ 'error-message': !rubricForm.valid }"
                  translate="projectBank.manipulation.rubric.noteText"></span>
              </p>
            </div>
          </div>

          <div class="mt-24">
            <div class="big-bold-assistive-text" translate="projectBank.manipulation.rubric.compilation"></div>
            <p class="body-sub-text mt-1" translate="projectBank.manipulation.rubric.compilationDefinition"></p>
            <div class="d-flex flex-row mt-16">
              <input type="number" class="form-control input-no-sm" formControlName="compilation" />
              <div class="body-sub-text pt-16 ms-2">%</div>
            </div>
          </div>

          <div class="mt-24">
            <div class="big-bold-assistive-text" translate="projectBank.manipulation.rubric.userBlackBox"></div>
            <p class="body-sub-text mt-1" translate="projectBank.manipulation.rubric.userBlackBoxDefinition"></p>
            <div class="d-flex flex-row mt-16">
              <input type="number" class="form-control input-no-sm"
                [readOnly]="projectService.isUserBlackBoxInputDisable" formControlName="userBlackBox" />
              <div class="body-sub-text pt-16 ms-2">%</div>
            </div>
          </div>

          <div class="mt-24">
            <div class="big-bold-assistive-text" translate="projectBank.manipulation.rubric.evaluatorBlackBox"></div>
            <p class="body-sub-text mt-1" translate="projectBank.manipulation.rubric.evaluatorBlackBoxDefinition"></p>
            <div class="d-flex flex-row mt-16">
              <input type="number" class="form-control input-no-sm"
                [readOnly]="projectService.isEvaluatorBlackBoxInputDisable" formControlName="evaluatorBlackBox" />
              <div class="body-sub-text pt-16 ms-2">%</div>
            </div>
          </div>

          <div class="mt-24">
            <div class="big-bold-assistive-text" translate="projectBank.manipulation.rubric.userWhiteBox"></div>
            <p class="body-sub-text mt-2" translate="projectBank.manipulation.rubric.userWhiteBoxDefinition"></p>
            <p class="body-sub-text" *ngIf="isUserWhiteBoxInputDisable"
              translate="projectBank.manipulation.rubric.userWhiteBoxDisable"></p>
            <div class="d-flex flex-row mt-16">
              <input type="number" class="form-control input-no-sm" [ngClass]="" formControlName="userWhiteBox"
                [readOnly]="isUserWhiteBoxInputDisable">
              <div class="body-sub-text pt-16 ms-2">%</div>
            </div>
          </div>

          <div class="mt-24 pb-24">
            <div class="big-bold-assistive-text" translate="projectBank.manipulation.rubric.evaluatorWhiteBox"></div>
            <p class="body-sub-text mt-2 " translate="projectBank.manipulation.rubric.evaluatorWhiteBoxDefinition">
            </p>
            <p class="body-sub-text" *ngIf="isEvaluatorWhiteBoxInputDisable"
              translate="projectBank.manipulation.rubric.evaluatorWhiteBoxDisable">
            </p>
            <div class="d-flex flex-row mt-16">
              <input type="number" class="form-control input-no-sm" formControlName="evaluatorWhiteBox"
                [readOnly]="isEvaluatorWhiteBoxInputDisable">
              <div class="body-sub-text pt-16 ms-2">%</div>
            </div>
          </div>
        </div>
      </form>
    </div>
  </scrollbar>

  <div class="d-flex justify-content-end footerBtn">
    <button type="button" class="btn btn-secondary-grey mx-4" (click)="cancelForm()"
      translate="projectBank.manipulation.cancel" [disabled]="!projectService.isSaveAsDraftOrCancelEnable">
    </button>
    <button type="button" class="btn btn-primary" [disabled]="!rubricForm.valid"
      translate="projectBank.manipulation.saveProject" (click)="saveRubrics()">
    </button>
  </div>

</div>