export enum ProjectPlayerModes {
  CREATE = 'create',
  TRY = 'try',
  EDIT = 'edit',
  EXERCISE_TRY = 'exercise try',
  ATTEMPT = 'attempt',
  PRACTISE = 'practise',
  EVALUATION = 'evaluate'
}

export enum content {
  CREATE = 'Create',
  EDIT = 'Edit'
}

export enum Themes {
  LIGHT = 'vs-light',
  DARK = 'vs-dark'
}

export enum EContentType {
  FILE = 'file',
  FOLDER = 'directory'
}