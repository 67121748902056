import { Component, HostListener } from '@angular/core';
import { SocketService } from './services/socket.service';
import { HttpClient } from '@angular/common/http';
import { TranslateService } from '@ngx-translate/core';
import { DialogService } from './services/dialog.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  constructor(private socketService: SocketService, private http: HttpClient, private translateService: TranslateService, private dialogService: DialogService,) {
    // This will tell which are all the languages available for translation
    translateService.addLangs(['en', 'ar']);
    // This will set the default language
    translateService.setDefaultLang('en');
    // If there are some other language than the default,
    // Then add that using this line. So the new language will take into effect
    // translateService.use('en');
    translateService.use('en');
  }
  title = 'web';
  @HostListener('window:focus', ['$event'])
  onFocused() {
    // this.socketService.initSocketConnections(true, true);
  }
  ngOnInit() {
    // eslint-disable-next-line no-constant-condition
    document.documentElement.setAttribute('data-theme', false ? "dark" : "light");
  }
}
