<div class="col-9 pl-24">
  <div class="left-container pl-24">
    <div>
      <ng-template skeletonUi [skeletonUiType]="SkeletonLoaderType.SkeletonRectangleComponent"
        [skeletonUiWidth]="'17vw'" [skeletonUiStyles]="{'margin-right': '1vw','margin-top':'3vh'}"></ng-template>
      <ng-template skeletonUi [skeletonUiType]="SkeletonLoaderType.SkeletonRectangleComponent"
        [skeletonUiWidth]="'10vw'" [skeletonUiStyles]="{'margin-right': '1vw','margin-top':'3vh'}"></ng-template>
      <ng-template skeletonUi [skeletonUiType]="SkeletonLoaderType.SkeletonRectangleComponent" [skeletonUiWidth]="'4vw'"
        [skeletonUiStyles]="{'margin-right': '1vw','margin-top':'3vh'}"></ng-template>
    </div>
  </div>
  <div class="left-container pl-24">
    <div>
      <ng-template skeletonUi [skeletonUiType]="SkeletonLoaderType.SkeletonRectangleComponent"
        [skeletonUiWidth]="'10vw'" [skeletonUiStyles]="{'margin-right': '1vw','margin-top':'1vh'}"></ng-template>
      <ng-template skeletonUi [skeletonUiType]="SkeletonLoaderType.SkeletonRectangleComponent"
        [skeletonUiWidth]="'17vw'" [skeletonUiStyles]="{'margin-right': '1vw','margin-top':'1vh'}"></ng-template>
      <ng-template skeletonUi [skeletonUiType]="SkeletonLoaderType.SkeletonRectangleComponent" [skeletonUiWidth]="'4vw'"
        [skeletonUiStyles]="{'margin-right': '1vw','margin-top':'1vh'}"></ng-template>
    </div>
  </div>
  <div class="left-container pl-24">
    <div>
      <ng-template skeletonUi [skeletonUiType]="SkeletonLoaderType.SkeletonRectangleComponent"
        [skeletonUiWidth]="'17vw'" [skeletonUiStyles]="{'margin-right': '1vw','margin-top':'1vh'}"></ng-template>
      <ng-template skeletonUi [skeletonUiType]="SkeletonLoaderType.SkeletonRectangleComponent"
        [skeletonUiWidth]="'10vw'" [skeletonUiStyles]="{'margin-right': '1vw','margin-top':'1vh'}"></ng-template>
      <ng-template skeletonUi [skeletonUiType]="SkeletonLoaderType.SkeletonRectangleComponent" [skeletonUiWidth]="'4vw'"
        [skeletonUiStyles]="{'margin-right': '1vw','margin-top':'1vh'}"></ng-template>
    </div>
  </div>
  <div class="left-container pl-24">
    <div>
      <ng-template skeletonUi [skeletonUiType]="SkeletonLoaderType.SkeletonRectangleComponent"
        [skeletonUiWidth]="'10vw'" [skeletonUiStyles]="{'margin-right': '1vw','margin-top':'1vh'}"></ng-template>
      <ng-template skeletonUi [skeletonUiType]="SkeletonLoaderType.SkeletonRectangleComponent"
        [skeletonUiWidth]="'17vw'" [skeletonUiStyles]="{'margin-right': '1vw','margin-top':'1vh'}"></ng-template>
      <ng-template skeletonUi [skeletonUiType]="SkeletonLoaderType.SkeletonRectangleComponent" [skeletonUiWidth]="'4vw'"
        [skeletonUiStyles]="{'margin-right': '1vw','margin-top':'1vh'}"></ng-template>
    </div>
  </div>
  <div class="left-container pl-24">
    <div>
      <ng-template skeletonUi [skeletonUiType]="SkeletonLoaderType.SkeletonRectangleComponent"
        [skeletonUiWidth]="'17vw'" [skeletonUiStyles]="{'margin-right': '1vw','margin-top':'1vh'}"></ng-template>
      <ng-template skeletonUi [skeletonUiType]="SkeletonLoaderType.SkeletonRectangleComponent"
        [skeletonUiWidth]="'10vw'" [skeletonUiStyles]="{'margin-right': '1vw','margin-top':'1vh'}"></ng-template>
      <ng-template skeletonUi [skeletonUiType]="SkeletonLoaderType.SkeletonRectangleComponent" [skeletonUiWidth]="'4vw'"
        [skeletonUiStyles]="{'margin-right': '1vw','margin-top':'1vh'}"></ng-template>
    </div>
  </div>
  <div class="left-container pl-24">
    <div>
      <ng-template skeletonUi [skeletonUiType]="SkeletonLoaderType.SkeletonRectangleComponent"
        [skeletonUiWidth]="'10vw'" [skeletonUiStyles]="{'margin-right': '1vw','margin-top':'1vh'}"></ng-template>
      <ng-template skeletonUi [skeletonUiType]="SkeletonLoaderType.SkeletonRectangleComponent"
        [skeletonUiWidth]="'17vw'" [skeletonUiStyles]="{'margin-right': '1vw','margin-top':'1vh'}"></ng-template>
      <ng-template skeletonUi [skeletonUiType]="SkeletonLoaderType.SkeletonRectangleComponent" [skeletonUiWidth]="'4vw'"
        [skeletonUiStyles]="{'margin-right': '1vw','margin-top':'1vh'}"></ng-template>
    </div>
  </div>
  <div class="left-container pl-24">
    <div>
      <ng-template skeletonUi [skeletonUiType]="SkeletonLoaderType.SkeletonRectangleComponent"
        [skeletonUiWidth]="'10vw'" [skeletonUiStyles]="{'margin-right': '1vw','margin-top':'1vh'}"></ng-template>
      <ng-template skeletonUi [skeletonUiType]="SkeletonLoaderType.SkeletonRectangleComponent"
        [skeletonUiWidth]="'17vw'" [skeletonUiStyles]="{'margin-right': '1vw','margin-top':'1vh'}"></ng-template>
      <ng-template skeletonUi [skeletonUiType]="SkeletonLoaderType.SkeletonRectangleComponent" [skeletonUiWidth]="'4vw'"
        [skeletonUiStyles]="{'margin-right': '1vw','margin-top':'1vh'}"></ng-template>
    </div>
  </div>
  <div class="left-container pl-24">
    <div>
      <ng-template skeletonUi [skeletonUiType]="SkeletonLoaderType.SkeletonRectangleComponent"
        [skeletonUiWidth]="'17vw'" [skeletonUiStyles]="{'margin-right': '1vw','margin-top':'1vh'}"></ng-template>
      <ng-template skeletonUi [skeletonUiType]="SkeletonLoaderType.SkeletonRectangleComponent"
        [skeletonUiWidth]="'10vw'" [skeletonUiStyles]="{'margin-right': '1vw','margin-top':'1vh'}"></ng-template>
      <ng-template skeletonUi [skeletonUiType]="SkeletonLoaderType.SkeletonRectangleComponent" [skeletonUiWidth]="'4vw'"
        [skeletonUiStyles]="{'margin-right': '1vw','margin-top':'1vh'}"></ng-template>
    </div>
  </div>
  <div class="left-container pl-24">
    <div>
      <ng-template skeletonUi [skeletonUiType]="SkeletonLoaderType.SkeletonRectangleComponent"
        [skeletonUiWidth]="'10vw'" [skeletonUiStyles]="{'margin-right': '1vw','margin-top':'1vh'}"></ng-template>
      <ng-template skeletonUi [skeletonUiType]="SkeletonLoaderType.SkeletonRectangleComponent"
        [skeletonUiWidth]="'17vw'" [skeletonUiStyles]="{'margin-right': '1vw','margin-top':'1vh'}"></ng-template>
      <ng-template skeletonUi [skeletonUiType]="SkeletonLoaderType.SkeletonRectangleComponent" [skeletonUiWidth]="'4vw'"
        [skeletonUiStyles]="{'margin-right': '1vw','margin-top':'1vh'}"></ng-template>
    </div>
  </div>
  <div class="left-container pl-24">
    <div>
      <ng-template skeletonUi [skeletonUiType]="SkeletonLoaderType.SkeletonRectangleComponent"
        [skeletonUiWidth]="'17vw'" [skeletonUiStyles]="{'margin-right': '1vw','margin-top':'1vh'}"></ng-template>
      <ng-template skeletonUi [skeletonUiType]="SkeletonLoaderType.SkeletonRectangleComponent"
        [skeletonUiWidth]="'10vw'" [skeletonUiStyles]="{'margin-right': '1vw','margin-top':'1vh'}"></ng-template>
      <ng-template skeletonUi [skeletonUiType]="SkeletonLoaderType.SkeletonRectangleComponent" [skeletonUiWidth]="'4vw'"
        [skeletonUiStyles]="{'margin-right': '1vw','margin-top':'1vh'}"></ng-template>
    </div>
  </div>
  <div class="left-container pl-24">
    <div>
      <ng-template skeletonUi [skeletonUiType]="SkeletonLoaderType.SkeletonRectangleComponent"
        [skeletonUiWidth]="'10vw'" [skeletonUiStyles]="{'margin-right': '1vw','margin-top':'1vh'}"></ng-template>
      <ng-template skeletonUi [skeletonUiType]="SkeletonLoaderType.SkeletonRectangleComponent"
        [skeletonUiWidth]="'17vw'" [skeletonUiStyles]="{'margin-right': '1vw','margin-top':'1vh'}"></ng-template>
      <ng-template skeletonUi [skeletonUiType]="SkeletonLoaderType.SkeletonRectangleComponent" [skeletonUiWidth]="'4vw'"
        [skeletonUiStyles]="{'margin-right': '1vw','margin-top':'1vh'}"></ng-template>
    </div>
  </div>
  <div class="left-container pl-24">
    <div>
      <ng-template skeletonUi [skeletonUiType]="SkeletonLoaderType.SkeletonRectangleComponent"
        [skeletonUiWidth]="'17vw'" [skeletonUiStyles]="{'margin-right': '1vw','margin-top':'1vh'}"></ng-template>
      <ng-template skeletonUi [skeletonUiType]="SkeletonLoaderType.SkeletonRectangleComponent"
        [skeletonUiWidth]="'10vw'" [skeletonUiStyles]="{'margin-right': '1vw','margin-top':'1vh'}"></ng-template>
      <ng-template skeletonUi [skeletonUiType]="SkeletonLoaderType.SkeletonRectangleComponent" [skeletonUiWidth]="'4vw'"
        [skeletonUiStyles]="{'margin-right': '1vw','margin-top':'1vh'}"></ng-template>
    </div>
  </div>
  <div class="left-container pl-24">
    <div>
      <ng-template skeletonUi [skeletonUiType]="SkeletonLoaderType.SkeletonRectangleComponent"
        [skeletonUiWidth]="'10vw'" [skeletonUiStyles]="{'margin-right': '1vw','margin-top':'1vh'}"></ng-template>
      <ng-template skeletonUi [skeletonUiType]="SkeletonLoaderType.SkeletonRectangleComponent"
        [skeletonUiWidth]="'17vw'" [skeletonUiStyles]="{'margin-right': '1vw','margin-top':'1vh'}"></ng-template>
      <ng-template skeletonUi [skeletonUiType]="SkeletonLoaderType.SkeletonRectangleComponent" [skeletonUiWidth]="'4vw'"
        [skeletonUiStyles]="{'margin-right': '1vw','margin-top':'1vh'}"></ng-template>
    </div>
  </div>
  <div class="left-container pl-24">
    <div>
      <ng-template skeletonUi [skeletonUiType]="SkeletonLoaderType.SkeletonRectangleComponent"
        [skeletonUiWidth]="'17vw'" [skeletonUiStyles]="{'margin-right': '1vw','margin-top':'1vh'}"></ng-template>
      <ng-template skeletonUi [skeletonUiType]="SkeletonLoaderType.SkeletonRectangleComponent"
        [skeletonUiWidth]="'10vw'" [skeletonUiStyles]="{'margin-right': '1vw','margin-top':'1vh'}"></ng-template>
      <ng-template skeletonUi [skeletonUiType]="SkeletonLoaderType.SkeletonRectangleComponent" [skeletonUiWidth]="'4vw'"
        [skeletonUiStyles]="{'margin-right': '1vw','margin-top':'1vh'}"></ng-template>
    </div>
  </div>
  <div class="left-container pl-24">
    <div>
      <ng-template skeletonUi [skeletonUiType]="SkeletonLoaderType.SkeletonRectangleComponent"
        [skeletonUiWidth]="'10vw'" [skeletonUiStyles]="{'margin-right': '1vw','margin-top':'1vh'}"></ng-template>
      <ng-template skeletonUi [skeletonUiType]="SkeletonLoaderType.SkeletonRectangleComponent"
        [skeletonUiWidth]="'17vw'" [skeletonUiStyles]="{'margin-right': '1vw','margin-top':'1vh'}"></ng-template>
      <ng-template skeletonUi [skeletonUiType]="SkeletonLoaderType.SkeletonRectangleComponent" [skeletonUiWidth]="'4vw'"
        [skeletonUiStyles]="{'margin-right': '1vw','margin-top':'1vh'}"></ng-template>
    </div>
  </div>



</div>