<!-- Heading -->
<div class="modal-header d-flex align-items-center justify-content-between px-24 py-24">
  <span class="heading-2">
    <p>{{projectDetails.title}}</p>
  </span>
  <svg-icon (click)="activeModal.dismiss()" class="cursor-pointer" src="../../../../assets/images/test_case_close.svg">
  </svg-icon>
</div>
<!-- Lower Body -->
<div *ngIf="detailsPage" class="modal-body p-24">
  <scrollbar class="scrollbar">
    <!-- Problem Statement -->
    <div class="mb-24">
      <div class="mb-2">
        <p class="body-bold-assistive-text">
          Problem Statement
        </p>
      </div>
      <div>
        <p class="body-text" [innerHTML]="projectDetails.problemStatement">
        </p>
      </div>
    </div>
    <!-- Instructions -->
    <div class="mb-24" *ngIf="projectDetails.instructions">
      <div class="mb-2">
        <p class="body-bold-assistive-text">
          Instructions
        </p>
      </div>
      <div>
        <p class="body-text" [innerHTML]="projectDetails.instructions">
        </p>
      </div>
    </div>
    <!-- Example -->
    <div class="mb-24" *ngIf="projectDetails.example">
      <div class="mb-2">
        <p class="body-bold-assistive-text">
          Example
        </p>
      </div>
      <div class="test">
        <div class="p-16">
          <p class="body-text" [innerHTML]="projectDetails.example"></p>
        </div>
      </div>
    </div>

    <!-- Warning -->
    <!-- <div class="mb-2">
    <p class="body-bold-assistive-text">
      Warning
    </p>
  </div>
  <div>
    <p [innerHTML]="projectDetails.example"></p>
  </div> -->
  </scrollbar>

</div>

<div *ngIf="!detailsPage">
  <app-image-zoom [image]="sourceAttribute" [header]="false" (detailsScreen)="listenToDetailsEvent()"></app-image-zoom>
</div>