
import { Component, ElementRef, Input, ViewChild } from '@angular/core';
import { ProjectPlayerService } from '../../modules/player/project-player.service';
import { EvaluationPlayerService } from '../../modules/player/evaluation-player.service';
import { LayoutModes } from 'src/app/enums/layoutModes';
import { AbstractControl, FormArray, FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Evaluation, Project } from 'src/app/models/evaluationInterface';
import { GetProjectFileContent } from 'src/app/models/projectInterface';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { ProjectPlayerModes } from 'src/app/enums/PlayerModes';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-evaluation-player',
  templateUrl: './evaluation-player.component.html',
  styleUrls: ['./evaluation-player.component.scss'],
  providers: [{
    provide: ProjectPlayerService,
    useClass: EvaluationPlayerService
  }
  ],
})
export class EvaluationPlayerComponent {

  isTotalMarksExceeded = false;
  sum = 0;
  @ViewChild('projectsAccordion') projectsAccordion!: ElementRef;
  @ViewChild('langFileAccordion') langFileAccordion!: ElementRef;
  lastScrollTop = 0;
  currentIndex = 0;
  layout = LayoutModes;
  feedbackForm!: FormGroup;
  // attemptId!: string | null;
  selectedProject!: Project;
  projects!: Project[];
  projectTitleLengthExceded = false;
  @Input() mode!: ProjectPlayerModes;
  @Input() attemptDataDetails !: Evaluation;
  @Input() exerciseId!: string;
  @Input() attemptId!: string;
  @Input() userId!: string;
  @Input() redirectUrl!: string;
  viewlayout = LayoutModes;
  compilation!: number;
  userBlackBox!: number;
  evaluatorBlackBox!: number;
  userWhiteBox!: number;
  evaluatorWhiteBox!: number;
  editorOptions = { theme: 'vs-light', language: 'javascript', readOnly: true };
  // eslint-disable-next-line @typescript-eslint/no-inferrable-types
  solutionCode: string = 'function x() {\nconsole.log("Hello world!");\n}';

  // eslint-disable-next-line max-params
  constructor(public projectPlayerService: ProjectPlayerService, private _fb: FormBuilder, private activatedRoute: ActivatedRoute, private modal: NgbModal, public toastrService: ToastrService, private router: Router, private route: ActivatedRoute) { }
  modalRef!: NgbModalRef;

  ngOnInit() {
    this.projectPlayerService.exerciseId = this.exerciseId;
    this.projectPlayerService.attemptId = this.attemptId;
    this.projectPlayerService.userId = this.userId;
    this.projectPlayerService.layout = LayoutModes.NONE;
    this.feedBackForm();
    this.projects = this.attemptDataDetails.attempt.projects;
    this.selectProject(this.attemptDataDetails.attempt.projects[0], 0);
    this.processEvaluationData(this.selectedProject);
  }

  ngAfterViewInit() {
    this.calculateTotalMarks();
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any, max-lines-per-function
  selectProject(project: any, i: number) {
    this.currentIndex = i;
    this.projectPlayerService.projectDetails = project;
    this.selectedProject = project;
    this.projectTitleLengthExceded = project.title?.length > 18 ? true : false;
    this.compilation = this.selectedProject.rubricWeightage?.compilation;
    this.userBlackBox = this.selectedProject.rubricWeightage?.userBlackBox;
    this.evaluatorWhiteBox = this.selectedProject.rubricWeightage?.evalWhiteBox;
    this.userWhiteBox = this.selectedProject.rubricWeightage?.userWhiteBox;
    this.evaluatorBlackBox = this.selectedProject.rubricWeightage?.evalBlackBox;
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const projectId: string = this.activatedRoute.snapshot.paramMap.get('projectId')!;
    this.projectPlayerService.projectId = project.projectId || projectId;
    this.projectPlayerService.archetypes = project.archetypes;
    this.projectPlayerService.triggerProjectChange(project);
    this.projectPlayerService.viewSolutionLayout = this.viewlayout.NONE;
    const evaluationData = this.projectPlayerService.getFormValues(this.selectedProject.projectId);
    if (evaluationData !== undefined) {
      const data = evaluationData['customMarks'];
      const evaluationData_copy = Object.assign({}, data);
      const plagiarismValue = -data['plagiarism'];
      delete evaluationData_copy['plagiarism'];
      const feedback = evaluationData['feedback'];
      this.feedbackDetails(evaluationData_copy);
      this.feedbackForm.patchValue({
        plagiarism: plagiarismValue,
        feedback: feedback
      });
    }
    else if (this.selectedProject.customMarks) {
      this.processEvaluationData(this.selectedProject);
    }

    else {
      this.feedbackForm.reset();
      while (this.testCase.length > 1) {
        this.testCase.removeAt(1);
      }
    }
    this.calculateTotalMarks();
  }

  outputLayout(layout: LayoutModes) {
    this.projectPlayerService.layout = layout;
  }

  feedBackForm() {
    this.feedbackForm = this._fb.group({
      plagiarism: new FormControl(''),
      feedBackInput: new FormArray([this.initTest()], this.uniqueTitleValidator),
      feedback: new FormControl('')
    });
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  uniqueTitleValidator(formArray: AbstractControl): { [key: string]: any } | null {
    const titlesSet = new Set();
    for (const item of formArray.value) {
      if (titlesSet.has(item.title)) {
        return { duplicateTitles: true };
      }
      titlesSet.add(item.title);
    }
    return null;
  }

  get testCase() {
    return this.feedbackForm.get('feedBackInput') as FormArray;
  }

  initTest() {
    return this._fb.group({
      title: new FormControl(''),
      marks: new FormControl(''),
    });
  }

  addNewTest() {
    this.testCase.push(this.initTest());
  }

  deleteRow(index: number) {
    this.testCase.removeAt(index);
    this.calculateTotalMarks();
  }

  scrollToElement(elementId: string): void {
    const element = document.getElementById(elementId);
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
    }
  }

  saveForm() {
    const plagiarismValue = -(this.feedbackForm.get('plagiarism')?.value);
    const customMarks: Record<string, number> = {};
    const feedbackInput = this.feedbackForm.get('feedBackInput')?.value as { title: string | null, marks: number | null }[];
    if (feedbackInput.some(item => item.title !== null && item.title !== '')) {
      feedbackInput.forEach((i) => {
        if (i.title !== null && i.title !== '' && i.marks !== null) {
          customMarks[i.title] = i.marks;
        }
      });
    } else {
      customMarks['plagiarism'] = 0;
    }
    customMarks['plagiarism'] = plagiarismValue;
    const feedbackData = {
      attemptId: this.projectPlayerService.attemptId,
      projectId: this.selectedProject.projectId,
      customMarks,
      feedback: this.feedbackForm.get('feedback')?.value,
    };
    const saveFeedbackSubscription = this.projectPlayerService.saveFeedback(feedbackData).subscribe(() => {
      this.projectPlayerService.setFormValues(feedbackData.projectId, feedbackData);
      this.selectedProject.marks = this.sum;
      this.attemptDataDetails.attempt.projects[this.currentIndex].marks = this.sum;
      this.projects = this.attemptDataDetails.attempt.projects;
      this.navigateToNextProject(this.currentIndex);
      saveFeedbackSubscription.unsubscribe();
    });

  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  customMarksPatching(patchData: any) {
    this.feedbackDetails(patchData);
  }

  feedbackDetails(evaluationData: Record<string, number> = {}) {
    this.testCase.clear();
    Object.entries(evaluationData).forEach(([key, value]) => {
      this.addOption(key, value);
    });
  }

  addOption(key: string, value: number) {
    this.testCase.push(this.appendFeedbackDetails(key, value));
  }

  appendFeedbackDetails(key: string, value: number) {
    return this._fb.group({
      title: key,
      marks: value
    });
  }

  openViewSolution() {
    this.projectPlayerService.viewSolutionLayout = this.viewlayout.PICTURE;
    this.solutionContent();
  }

  closeViewSolution() {
    this.projectPlayerService.viewSolutionLayout = this.viewlayout.NONE;
  }

  listenToSolution(data: GetProjectFileContent) {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const unsubscribeFileContent = this.projectPlayerService.requestProjectFileContent(data).subscribe((res: any) => {
      this.solutionCode = res.fileContent;
      unsubscribeFileContent.unsubscribe();
    });
  }

  solutionContent() {
    let path: string | undefined;
    if (this.projectPlayerService.treeDataSolution && this.projectPlayerService.treeDataSolution.files && this.projectPlayerService.treeDataSolution.files[0] && this.projectPlayerService.treeDataSolution.files[0].files) {
      if (this.projectPlayerService.treeDataSolution.files[0].files[0].kind === 'file') {
        path = this.projectPlayerService.treeDataSolution.files[0].files[0].path;
        const data: GetProjectFileContent = {
          archetypeId: this.projectPlayerService.selectedArchetype,
          projectId: this.projectPlayerService.projectId,
          path: path as unknown as string
        };
        this.listenToSolution(data);
      }
    }

  }

  calculateTotalMarks() {
    let totalMarks = 0;
    const lengthofMarks = [];
    const lengthOfTitle = [];
    const plagiarismValue = -(this.feedbackForm.get('plagiarism')?.value);
    this.testCase.controls.forEach((control: AbstractControl) => {
      const marks = control.get('marks')?.value;
      const title = control.get('title')?.value;
      // eslint-disable-next-line no-extra-boolean-cast
      if (!!title) {
        lengthOfTitle.push(title);
      }
      // eslint-disable-next-line no-extra-boolean-cast
      if (!!marks) {
        lengthofMarks.push(marks);
      }
      if (marks) {
        totalMarks += +marks;
      }
    });
    this.sum = totalMarks + this.selectedProject.systemMarks.totalMarks + plagiarismValue;
    const projectMaxMarks = this.selectedProject.maxMarks;
    if (lengthofMarks.length === lengthOfTitle.length) {
      if (this.sum > projectMaxMarks) {
        this.isTotalMarksExceeded = true;
        this.showErrorToast("Total custom marks is greater than max marks");
      }
      if (this.sum <= projectMaxMarks) {
        this.isTotalMarksExceeded = false;
      }
    }
    else if (lengthofMarks.length !== lengthOfTitle.length) {
      this.showErrorToast("Please add respective input for the marks added");
    }

  }

  navigateToNextProject(index: number) {
    if (index < this.projects.length - 1) {
      this.selectProject(this.projects[index + 1], index + 1);
    } else {
      this.completeExerciseEvaluation();
    }
  }
  completeExerciseEvaluation() {
    const exerciseId = this.projectPlayerService.exerciseId;
    const attemptId = this.projectPlayerService.attemptId;
    this.router.navigate([`/evaluation/${exerciseId}/${attemptId}/evaluate-summary`], { queryParamsHandling: 'merge' });
  }

  showErrorToast(message: string): void {
    this.toastrService.error(message, '', {
      positionClass: 'toast-top-center',
      closeButton: true,
      timeOut: 5000,
      extendedTimeOut: 5000,
      tapToDismiss: false
    });
  }

  onScroll(event: Event) {
    const currentScrollTop = (event.target as HTMLElement).scrollTop;
    if (currentScrollTop > this.lastScrollTop) {
      this.closeAccordion(this.projectsAccordion);
      this.closeAccordion(this.langFileAccordion);
    }
    this.lastScrollTop = currentScrollTop;
  }

  private closeAccordion(accordionElement: ElementRef) {
    const accordion = accordionElement.nativeElement as HTMLElement;
    const collapseElement = accordion.querySelector('.accordion-collapse');

    if (collapseElement?.classList.contains('show')) {
      setTimeout(() => {
        collapseElement?.classList.remove('show');
      }, 300);
    }
  }

  processEvaluationData(selectedProject: Project) {
    const evaluation_data = selectedProject['customMarks'];
    let plag = 0;

    if (evaluation_data.plagiarism !== undefined) {
      plag = -evaluation_data.plagiarism;
    }

    const feedback = selectedProject.feedback;
    const data_copy = Object.assign({}, evaluation_data);
    delete data_copy['plagiarism'];

    this.customMarksPatching(data_copy);

    this.feedbackForm.patchValue({
      plagiarism: plag,
      feedback: feedback
    });
  }

  exitEvaluation() {
    const backtoLumen = this.redirectUrl;
    if (backtoLumen) {
      const url = `${backtoLumen}`;
      location.replace(url);
    }
  }

  ngOnDestroy() {
    this.currentIndex = 0;
  }

}
