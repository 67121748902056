<div class="exercise-detail d-flex align-items-center p-24 mb-24">
  <div class="exercise-details pr-24">
    <h3 class="heading-3 mb-1 cursor-pointer text-break"
      [routerLink]="[exerciseListCardItem.exerciseId, 'view-exercise']" queryParamsHandling="preserve">
      {{exerciseListCardItem.title}}
    </h3>
    <p class="body-sub-text mb-16 text-break" [innerHTML]="exerciseListCardItem.description">
      {{exerciseListCardItem.description}}
    </p>

    <div class="d-flex align-items-center exercise-card-footer">

      <div class="d-flex align-items-center">
        <span class="body-sub-text">Status:</span>
        <span class="d-inline-flex align-items-center justify-content-center level ms-1" [ngClass]="{
            'status-draft': exerciseStatus === 'Draft',
            'status-yet-to-start': exerciseStatus === 'Yet to start',
            'status-ongoing': exerciseStatus === 'Ongoing',
            'status-expired': exerciseStatus === 'Expired'
          }">
          {{ exerciseStatus }}
        </span>
      </div>

      <div class="body-sub-text text-nowrap" *ngIf="exerciseListCardItem.kind !== 'practice'">
        Validity:<span class="ff-semibold lh-16 ms-1">
          {{exerciseListCardItem.startDate| dateFormat}} - {{exerciseListCardItem.endDate| dateFormat}}</span>
      </div>


      <div class="body-sub-text text-nowrap">
        Time Limit:<span class="ff-semibold lh-16 ms-1">
          {{(displayTime)}}</span>
      </div>

      <div class="body-sub-text text-nowrap" *ngIf="exerciseListCardItem.kind !== 'practice'">
        Marks:<span class="ff-semibold lh-16 ms-1">
          {{exerciseListCardItem.maxMarks}}</span>
      </div>

      <div class="body-sub-text lang-arch d-flex align-items-center">
        <span class="text-nowrap">Language & Archetype:</span>
        <span class="ff-semibold lh-16 ms-1" *ngFor="let language of filteredValues">
          {{language | titlecase}}</span>
        <span class="remaining-count ms-1" *ngIf="remainingValuesCount">+{{remainingValuesCount}}</span>
      </div>

    </div>
  </div>
  <div class="try-project">
    <div class="dropdown text-end mb-16">
      <img class="dropdown-toggle cursor-pointer" data-bs-toggle="dropdown" aria-expanded="false"
        src="../../assets/images/ellipsis-menu.svg">
      <ul class="dropdown-menu">
        <li *ngIf="displayMode !== ExerciseListCardModes.SELECTION">
          <a [routerLink]="['./'+exerciseListCardItem.exerciseId+'/manipulate-exercise']" class="dropdown-item"
            queryParamsHandling="preserve"><img src="../../../../../assets/images/ellipsis-edit.svg">
            Edit
          </a>
        </li>
        <li>
          <a class="dropdown-item cursor-pointer" (click)="cloneExercise()"><img
              src="../../../../../assets/images/ellipsis-clone.svg">
            Clone
          </a>
        </li>
        <li *ngIf="displayMode !== ExerciseListCardModes.SELECTION"
          [ngClass]="{ 'disabled' : exerciseListCardItem.status === 'attached'}">
          <a class="dropdown-item cursor-pointer" (click)="deleteModal()"><img
              src="../../../../../assets/images/ellipsis-delete.svg">
            Delete
          </a>
        </li>
      </ul>
    </div>
    <p class="primary-text cursor-pointer" [routerLink]="[exerciseListCardItem.exerciseId, 'view-exercise']"
      queryParamsHandling="preserve">Preview
    </p>
  </div>
</div>