import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { distinctUntilChanged, filter } from 'rxjs';
import { UserRoles } from 'src/app/enums/userRoles';
import { SocketService } from 'src/app/services/socket.service';

@Component({
  selector: 'app-leftnavigation',
  templateUrl: './leftnavigation.component.html',
  styleUrls: ['./leftnavigation.component.scss']
})
export class LeftNavigationComponent {
  leftMenuExpanded = false;
  UserRoles = UserRoles;
  constructor(private activatedRoute: ActivatedRoute, private router: Router, protected socketService: SocketService) { }
  ngOnInit() {
    this.leftMenuExpandCollapseListener();
    this.setDefaultLeftMenuState();
  }

  toggleSideBar() {
    this.router.navigate([], { queryParams: { leftMenu: !this.leftMenuExpanded }, queryParamsHandling: 'merge', replaceUrl: true });
  }

  /**
   * This will check the query param for the state of the left menu and set it else set it to default.
   * @returns {void}
   */
  setDefaultLeftMenuState(): void {
    const leftMenuQueryParam = this.activatedRoute.snapshot.queryParams['leftMenu'];
    if (leftMenuQueryParam !== undefined) {
      // this.leftMenuExpanded = leftMenuQueryParam === 'true';
      return;
    }
    this.router.navigate([], { queryParams: { leftMenu: true }, queryParamsHandling: 'merge', replaceUrl: true });
  }

  /**
 * This will setup a listener for the query param - 'leftMenu' and open and close the left menu.
 */
  private leftMenuExpandCollapseListener() {
    this.activatedRoute.queryParams.pipe(
      distinctUntilChanged(),
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      filter((param: any) => param.leftMenu)
    ).subscribe(() => {
      // this.leftMenuExpanded = activeTabParam.leftMenu === 'true';
    });
  }
}
