<div class="project-selection-container">
  <div class="row mb-24 px-24 g-0">
    <div class="col-6">
      <div class="input-group search-field">
        <input type="text" class="form-control" placeholder="Search..." aria-label="Search..."
          aria-describedby="search-btn" #searchString>
        <button class="btn search-btn" type="button" id="search-btn" (click)="searchProject(searchString.value)">
          <img src="../assets/images/search.svg">
        </button>
      </div>
    </div>
    <div class="col-6">
      <div class="action-container d-flex align-items-center justify-content-end">

        <div class="d-inline-flex align-items-center justify-content-center level ms-1 projects-selected ">
          {{totalSelected}} Projects Selected
        </div>


        <ng-select class="sort-ng-select mx-24" placeholder="Sort" [items]="sortOptions" bindLabel="name"
          bindValue="value" (change)="setSortOption()" [(ngModel)]="selectSortOption" (clear)="clearSort()"
          [multiple]="false" [searchable]="false">
          <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index">
            <input id="item-{{ index }}" type="checkbox" class="custom-checkbox" [ngModel]="item$.selected" />
            {{ item.name }}
          </ng-template>
        </ng-select>

        <div class="filter-dropdown me-0">
          <div class="dropdown">
            <div class="dropdown-toggle dropdown-filter-toggle" id="dropdownMenuClickableInside"
              data-bs-toggle="dropdown" data-bs-auto-close="outside" aria-expanded="false">
              <span *ngIf="filterOptionSelected">
                <svg-icon src="../../../assets/images/red_Dot.svg"></svg-icon>
              </span>
              Filter
            </div>
            <ul class="dropdown-menu" aria-labelledby="dropdownMenuClickableInside">
              <li class="dropdown-submenu" *ngFor="let data of  allFilters"><a
                  class="dropdown-item dropdown-toggle body-sub-text">{{data.title | titlecase}}</a>
                <ul class="dropdown-menu first-level-menu">
                  <li>
                    <a class="dropdown-item body-sub-text">
                      <div class="form-check filter-select" *ngFor="let item of data.titleValue">
                        <input class="form-check-input" type="checkbox" [id]="item.name"
                          (click)="onSelectFilter($any($event.target).checked,item.value, data.title)">
                        <label class="form-check-label" [for]="item.name">
                          {{ item.name }}
                        </label>
                      </div>
                    </a>
                  </li>
                </ul>
              </li>
            </ul>
          </div>
        </div>

        <!-- <ul class="filter-dropdown me-0">
          <li class="nav-item dropdown">
            <a class="nav-link dropdown-toggle dropdown-filter-toggle" id="navbarDropdownMenuLink"
              data-toggle="dropdown" data-bs-toggle="dropdown" aria-expanded="false">
              <span *ngIf="filterOptionSelected"><svg-icon src="../../../../assets/images/red_Dot.svg"></svg-icon></span>
              Filter
            </a>
            <ul class="dropdown-menu first-level-menu m-0" aria-labelledby="navbarDropdownMenuLink">
              <li class="dropdown-submenu" *ngFor="let data of  allFilters"><a
                  class="dropdown-item dropdown-toggle body-sub-text">{{data.title | titlecase}}</a>
                <ul class="dropdown-menu">
                  <li><a class="dropdown-item body-sub-text">
                      <div class="form-check filter-select" *ngFor="let item of data.titleValue">
                        <input class="form-check-input" type="checkbox" [id]="item.name"
                          (click)="onSelectFilter($any($event.target).checked,item.value, data.title)">
                        <label class="form-check-label" [for]="item.name">
                          {{ item.name }}
                        </label>
                      </div>
                    </a></li>
                </ul>
              </li>
            </ul>
          </li>
        </ul> -->

      </div>
    </div>
  </div>

  <div class="px-24 content-container ">
    <scrollbar class="scrollbar" appInfiniteScroll (scrolled)="onScrollDown()">
      <div class="add-scroll">
        <div class="project-bank-list pb-16">
          <ng-container *ngIf="projectList.length; else loadSkeleton">
            <div class="projectlist-check" *ngFor="let projectListCardItem of projectList; index as index">
              <div class="form-check form-checkbox-orange projectlist-checkbox">
                <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault"
                  [(ngModel)]="projectListCardItem.selected" (change)="onProjectSelectionToggle(projectListCardItem)">
              </div>
              <div>
                <app-projectlistcard (selected)="previewSelected($event)" [projectListCardItem]="projectListCardItem"
                  [displayMode]="ProjectListCardModes.SELECTION">
                </app-projectlistcard>
              </div>

            </div>
          </ng-container>
          <!-- Load the skeleton until data loads -->
          <ng-template #loadSkeleton skeletonUi [skeletonUiType]="SkeletonLoaderType.ProjectListCardSkeletonComponent"
            [skeletonUiRepeat]="5">
          </ng-template>
        </div>
      </div>
    </scrollbar>
  </div>


  <div class="d-flex justify-content-end footerBtn">
    <button type="button" class="btn btn-secondary-grey" (click)="cancelOperation()">Cancel</button>
    <button type="button" class="btn btn-secondary-orange mx-24" (click)="clearSelection()"
      [disabled]="selectedProjects.length === 0">Clear Selection</button>
    <button type="button" class="btn btn-primary" (click)="addproject()" [disabled]="selectedProjects.length === 0">Add
      Project</button>
  </div>
</div>