import { Component, ElementRef, Input, Renderer2 } from '@angular/core';
import { ProjectPlayerModes } from 'src/app/enums/PlayerModes';
import { ProjectDetails } from 'src/app/models/projectInterface';
import { ProjectPlayerService } from 'src/app/shared/modules/player/project-player.service';
import { ExercisePlayerModalComponent } from '../../exercise-player-modal/exercise-player-modal.component';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { ImageZoomComponent } from '../image-zoom/image-zoom.component';
import { Subject, takeUntil } from 'rxjs';

@Component({
  selector: 'app-project-details',
  templateUrl: './project-details.component.html',
  styleUrls: ['./project-details.component.scss']
})
export class ProjectDetailsComponent {
  @Input() mode!: ProjectPlayerModes;
  ProjectPlayerModes = ProjectPlayerModes;
  projectDetails!: ProjectDetails;
  modalRef!: NgbModalRef;
  @Input() projectTitle = '';
  unSubscribe = new Subject<void>();
  constructor(public projectPlayerService: ProjectPlayerService, private modal: NgbModal, private renderer: Renderer2, private elementRef: ElementRef) { }

  ngOnInit(): void {
    this.projectDetails = this.projectPlayerService.projectDetails;
    if (this.mode === ProjectPlayerModes.ATTEMPT || this.mode === ProjectPlayerModes.EVALUATION) {
      this.projectPlayerService.listenProjectChange().pipe(takeUntil(this.unSubscribe)).subscribe(() => {
        this.projectDetails = this.projectPlayerService.projectDetails;
        setTimeout(() => {
          this.addMethodInImageTags();
        }, 1000);
        this.openDefaultModal();
      });
      this.openDefaultModal();
    }
  }

  ngAfterViewInit() {
    this.addMethodInImageTags();
  }

  openDefaultModal() {
    if (this.mode === ProjectPlayerModes.ATTEMPT) {
      this.openModal();
    }
  }

  addMethodInImageTags() {
    const figureElements = this.elementRef.nativeElement.querySelectorAll('figure.image');
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    figureElements.forEach((figure: any) => {
      const img = figure.querySelector('img');
      const src = img.getAttribute('src');
      this.renderer.listen(img, 'click', () => this.clickonimage(src));
      this.renderer.addClass(figure, 'on');
    });
  }

  clickonimage(src: string) {
    this.modalRef = this.modal.open(ImageZoomComponent, { backdrop: 'static', centered: true, animation: true, windowClass: "image-zoom-modal" });
    this.modalRef.componentInstance.image = src;
    this.modalRef.componentInstance.title = this.projectDetails.title;
  }

  openModal() {
    this.modalRef = this.modal.open(ExercisePlayerModalComponent, { backdrop: 'static', centered: true, animation: true, windowClass: "image-zoom-modal" });
    this.modalRef.componentInstance.projectDetails = this.projectDetails;
  }

  show(value: HTMLElement) {
    value.scrollIntoView();
  }

  ngOnDestroy() {
    this.unSubscribe.next();
    this.unSubscribe.unsubscribe();
  }

}
