//import { v4 as uuid } from 'uuid';

import { Archetype, ProjectListCardItem } from "../models/project-list-card";

export interface timeOutput {
  hour: number;
  minute: number;
  second: number;
}

export class CommonUtils {

  static formatNumber(num: number | undefined): string {
    if (!num) {
      return '0';
    }
    return `${Math.round(num * 100) / 100}`;
  }
  static delay = (timeToWait: number) => new Promise<void>((resolve) => {
    setTimeout(() => {
      resolve();
    }, timeToWait);
  });

  getTimeInMinutes(hour = 0, minute = 0): number {
    let minutes = 0;
    if (hour > 0) {
      minutes = hour * 60;
    }

    return +minutes + +minute;
  }

  getTimeInSeconds(hour = 0, minute = 0, second = 0): number {
    let minutes = 0;
    let seconds = 0;
    if (hour > 0) {
      minutes = hour * 60;
      minute = +minutes + +minute;
    }
    if (minute > 0) {
      seconds = minute * 60;
    }
    return +seconds + +second;
  }

  getTimeInHourAndMinute(time: number): timeOutput {
    const hours = Math.floor(time / 60 / 60);
    const minutes = Math.floor(time / 60) - hours * 60;
    const second = time % 60;
    return {
      hour: hours,
      minute: minutes,
      second: second,
    };
  }
  displayTimeFormat(timeObject: { hour: number; minute: number; second: number }): string {
    const { hour, minute, second } = timeObject;
    const parts: string[] = [];

    if (hour > 0) {
      parts.push(`${hour} hours`);
    }

    if (minute > 0 || (hour === 0 && minute === 0 && second > 0)) {
      // Include "mins" when there are minutes, or when there are only seconds
      parts.push(`${minute} mins`);
    }

    if (second > 0 && (hour > 0 || minute > 0)) {
      // Include "secs" when there are seconds and at least one of hour or minute is present
      parts.push(`${second} secs`);
    }

    return parts.join(' ');
  }

  getExerciseStatus(projectStatus: string, startDate: Date, endDate: Date, projects: ProjectListCardItem[]): string {
    const currentDate = new Date();
    if (projectStatus === 'draft' || projects.length === 0) {
      return 'Draft';
    } else if (currentDate < startDate) {
      return 'Yet to start';
    } else if (currentDate >= startDate && currentDate <= endDate) {
      return 'Ongoing';
    }
    return 'Expired';
  }

  formatUniqueArchetypes(projects: ProjectListCardItem[]): string[] {
    if (projects.length === 0) {
      return ["No archetypes"];
    }
    const uniqueArchetypes = new Set<string>();
    projects.forEach((project) => {
      project.archetypes.forEach((archetype: Archetype) => {
        const formattedArchetype = `${archetype.language} > ${archetype.archetypeId}`;
        uniqueArchetypes.add(formattedArchetype);
      });
    });
    return [Array.from(uniqueArchetypes).join('  ,  ')];
  }

  /**
   * converts hh:mm:ss => s
   * also handles mm:ss => s
   * ss => s
   * eg:
   * "00:20" => 20
   * "1:20" => 80
   */
  hmsToSecondsOnly(str: string) {
    const segment = str.split(':');
    let seconds = 0;
    let minutes = 1;

    while (segment.length > 0) {
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      seconds += minutes * parseInt(segment.pop()!, 10);
      minutes *= 60;
    }

    return seconds;
  }

  removeHTML(str: string): string {
    const tmp = document.createElement('DIV');
    tmp.innerHTML = str;
    return tmp.textContent || tmp.innerText || '';
  }

  timestampToDate(timestamp: string): string {
    const newDate = new Date(timestamp);
    const stringNewDate = newDate.toString();
    const year = stringNewDate.slice(11, 15);
    const month = stringNewDate.slice(4, 7);
    const date = stringNewDate.slice(8, 10);
    return `${date} ${month} ${year}`;
  }

  getDomainFromUrl(): string {
    const { hostname } = new URL(window.location.href); // "localhost";//write code here to detect the domain based on the address bar url
    return hostname;
  }

  isAnyFilterSelected(filters: { [key: string]: string[] }): boolean {
    return Object.values(filters).some(filter => filter.length > 0);
  }

  static isLocal() {
    const { hostname } = new URL(window.location.href); // "localhost";//write code here to detect the domain based on the address bar url
    if (hostname === "localhost") {
      return true;
    }
    return false;
  }

}

