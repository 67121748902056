<!-- Heading Of The Dialog Box -->
<div class="modal-header">
  <div class="heading-2" translate="projectBank.projectPlayer.customModal.customHeading">
  </div>
  <span class="close-icon cursor-pointer" (click)="activeModal.dismiss()">
    <svg-icon src="../../../../../assets/images/test_case_close.svg"></svg-icon>
  </span>
</div>
<div class="modal-body pb-0">
  <scrollbar class="scrollbar">
    <form [formGroup]="customInputForm">
      <!-- Console Input -->
      <div class="form-group">
        <label class="form-label d-flex align-items-center p-0"
          translate="projectBank.projectPlayer.customModal.consoleInput"> <span class="d-flex align-items-center ms-1">
            <img src="../../../../../assets/images/info.svg">
          </span></label>
        <textarea class="form-control input-box" formControlName="consoleInput" placeholder="Add Console Input"
          tabindex="-1"></textarea>
      </div>
      <!-- Command Input -->
      <div class="form-group mb-0">
        <label class="form-label p-0" translate="projectBank.projectPlayer.customModal.commandInput"></label>
        <textarea class="form-control input-box" formControlName="commandInput" placeholder="Add Command Input"
          tabindex="-1"></textarea>
      </div>

    </form>
  </scrollbar>

</div>

<div class="modal-footer">
  <button class="btn btn-secondary-grey" translate="projectBank.projectPlayer.customModal.cancel"
    (click)="cancel()"></button>
  <button class="btn btn-primary ml-24" translate="projectBank.projectPlayer.customModal.save"
    [disabled]="isSaveDisabled()" (click)="submit()"></button>
</div>