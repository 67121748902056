/* eslint-disable max-lines-per-function */
import { Component } from '@angular/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { BlackBoxComponent } from '../black-box/black-box.component';
import { ProjectPlayerService } from 'src/app/shared/modules/player/project-player.service';
import { ProjectPlayerModes } from 'src/app/enums/PlayerModes';
import { EnableEvalWhiteBoxTestCases, EnableUserWhiteBoxTestCases } from 'src/app/models/projectInterface';
import { ProjectService } from 'src/app/projectbank/project/project.service';
import { Subscription } from 'rxjs';
@Component({
  selector: 'app-test-cases',
  templateUrl: './test-cases.component.html',
  styleUrls: ['./test-cases.component.scss']
})
export class TestCasesComponent {
  isUserWhiteBoxTestCaseEnabled !: boolean;
  isEvaluatorWhiteBoxTestCaseEnabled !: boolean;
  eventChangeEvalWhiteBox!: Subscription;
  eventChangeUserWhiteBox!: Subscription;
  usercount = 0;
  evalcount = 0;

  constructor(private modal: NgbModal, public projectPlayerService: ProjectPlayerService, public projectService: ProjectService) { }
  modalRef!: NgbModalRef;

  ngOnInit() {
    if (this.projectPlayerService.mode === ProjectPlayerModes.EDIT) {
      this.whiteBoxEnabled();
      if (this.projectPlayerService.projectDetails.userBlackBoxTests?.length !== undefined) {
        this.usercount = this.projectPlayerService.projectDetails.userBlackBoxTests?.length;
        this.projectService.isUserBlackBoxInputDisable = this.usercount > 0 ? false : true;
      }
      if (this.projectPlayerService.projectDetails.evalBlackBoxTests?.length !== undefined) {
        this.evalcount = this.projectPlayerService.projectDetails.evalBlackBoxTests?.length;
        this.projectService.isEvaluatorBlackBoxInputDisable = this.evalcount > 0 ? false : true;
      }
    }
  }

  whiteBoxEnabled() {
    if (this.projectPlayerService.projectDetails.userWhiteBoxEnabled !== undefined) {
      this.isUserWhiteBoxTestCaseEnabled = this.projectPlayerService.projectDetails.userWhiteBoxEnabled;
    }
    if (this.projectPlayerService.projectDetails.evalWhiteBoxEnabled !== undefined) {
      this.isEvaluatorWhiteBoxTestCaseEnabled = this.projectPlayerService.projectDetails.evalWhiteBoxEnabled;
    }
  }

  blackBox(testCase: string) {
    this.modalRef = this.modal.open(BlackBoxComponent, { backdrop: 'static', centered: true, animation: true, windowClass: "test-case-modal" });
    this.modalRef.componentInstance.projectPlayerService = this.projectPlayerService;
    this.modalRef.componentInstance.title = testCase;
    this.modalRef.componentInstance.projectId = this.projectPlayerService.projectId;
    const tests = testCase === 'User' ? this.projectPlayerService.projectDetails.userBlackBoxTests : this.projectPlayerService.projectDetails.evalBlackBoxTests;
    if (tests) {
      this.modalRef.componentInstance.testCaseData = tests;
    }
    this.modalRef.componentInstance.totalTest.subscribe((res: number) => {
      if (testCase === "User") {
        this.usercount = res;
        this.projectService.isUserBlackBoxInputDisable = res > 0 ? false : true;
        if (this.projectService.isUserBlackBoxInputDisable && this.projectService.projectDetails.rubricWeightage) {
          this.projectService.projectDetails.rubricWeightage.userBlackBox = 0;
        }
      }
      else {
        this.evalcount = res;
        this.projectService.isEvaluatorBlackBoxInputDisable = res > 0 ? false : true;
        if (this.projectService.isEvaluatorBlackBoxInputDisable && this.projectService.projectDetails.rubricWeightage) {
          this.projectService.projectDetails.rubricWeightage.evalBlackBox = 0;
        }
      }
    });
    this.modalRef.componentInstance.closedStatus.subscribe(() => {
      this.modalRef.close();
    });
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onEventChangeUserWhiteBox(event: any) {
    const checked = event.target.checked;
    this.isUserWhiteBoxTestCaseEnabled = checked;
    const payload: EnableUserWhiteBoxTestCases = {
      projectId: this.projectPlayerService.projectId,
      userWhiteBoxEnabled: this.isUserWhiteBoxTestCaseEnabled,
      ...(this.projectPlayerService.exerciseIdTry && { exerciseId: this.projectPlayerService.exerciseIdTry }),
    };
    this.eventChangeUserWhiteBox = this.projectPlayerService.enableWhiteBoxTest(payload).subscribe(() => {
      this.projectPlayerService.projectDetails.userWhiteBoxEnabled = this.isUserWhiteBoxTestCaseEnabled;
      if (!this.isUserWhiteBoxTestCaseEnabled && this.projectService.projectDetails.rubricWeightage) {
        this.projectService.projectDetails.rubricWeightage.userWhiteBox = 0;
      }
    });
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onEventChangeEvalWhiteBox(event: any) {
    const checked = event.target.checked;
    this.isEvaluatorWhiteBoxTestCaseEnabled = checked;
    const payload: EnableEvalWhiteBoxTestCases = {
      projectId: this.projectPlayerService.projectId,
      evalWhiteBoxEnabled: this.isEvaluatorWhiteBoxTestCaseEnabled,
      ...(this.projectPlayerService.exerciseIdTry && { exerciseId: this.projectPlayerService.exerciseIdTry }),
    };
    this.eventChangeEvalWhiteBox = this.projectPlayerService.enableWhiteBoxTest(payload).subscribe(() => {
      this.projectPlayerService.projectDetails.evalWhiteBoxEnabled = this.isEvaluatorWhiteBoxTestCaseEnabled;
      if (!this.isEvaluatorWhiteBoxTestCaseEnabled && this.projectService.projectDetails.rubricWeightage) {
        this.projectService.projectDetails.rubricWeightage.evalWhiteBox = 0;
      }
    });
  }

  ngOnDestroy() {
    if (this.eventChangeUserWhiteBox) {
      this.eventChangeUserWhiteBox.unsubscribe();
    }
    if (this.eventChangeEvalWhiteBox) {
      this.eventChangeEvalWhiteBox.unsubscribe();
    }
  }
}
