import { Component, ElementRef } from '@angular/core';
import { SkeletonComponent } from 'src/app/base-class/skeleton-class';

@Component({
  selector: 'app-evaluation-skeleton',
  templateUrl: './evaluation-skeleton.component.html',
  styleUrls: ['./evaluation-skeleton.component.scss']
})
export class EvaluationSkeletonComponent extends SkeletonComponent {
  constructor(override host: ElementRef<HTMLElement>) {
    super(host);
  }
  ngOnInit() {
    this.setStylesAndClass();
  }
}
