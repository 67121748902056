<ng-container *ngIf="projectListInsideExercise">
  <div *ngIf="projectListInsideExercise.length" class="exercise-added-projects">
    <div class="px-24 exercise-project-content">
      <div class="row py-24 g-0">
        <div class="col-6">
          <div class="input-group search-field">
            <input type="text" class="form-control" placeholder="Search..." aria-label="Search..."
              aria-describedby="search-btn" [(ngModel)]="searchPattern">
            <button class="btn search-btn" type="button" id="search-btn" (click)="handleSearch()">
              <img src="../assets/images/search.svg">
            </button>
          </div>
        </div>
        <div class="col-6">
          <div class="action-container d-flex align-items-center justify-content-end">

            <div class="d-inline-flex align-items-center justify-content-center level total-edit-marks-card">
              Total Marks: <b class="ps-1">{{totalMarks}}</b> <span class="d-flex cursor-pointer pl-24"
                (click)="editMarks()">Edit
                Marks <img class="ms-1" src="../../../../assets/images/edit-marks.svg"> </span>
            </div>


            <div class="dropdown btn btn-primary ml-16 output-dropdown addBtn">
              <span class="dropdown-toggle cursor-pointer d-flex" data-bs-toggle="dropdown" aria-expanded="false">
                <p>Add Project <svg-icon src="../../../../assets/images/exerciseBuilder/dropdown_downarrow.svg"
                    class="ms-2"></svg-icon>
                </p>
              </span>
              <ul class="dropdown-menu">
                <li (click)="createNewProject()">
                  <a class="dropdown-item">
                    Create New
                  </a>
                </li>

                <li (click)="addFromExistingProjects()">
                  <a class="dropdown-item">
                    Add From Existing
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>

      <div class="project-selection-body">
        <scrollbar class="scrollbar">
          <div class="add-scroll">
            <div class="project-bank-list">
              <div *ngFor="let projectListCardItem of projectListInsideExercise; index as index">
                <div *ngIf="projectListCardItem.visible" class="projectlist-check mb-24">
                  <svg-icon src="../../../../assets/images/exerciseBuilder/drag-drop_icon.svg" class="dragDrop"
                    appDragDropArray [draggableArray]="projectListInsideExercise" [dragIndex]="index"></svg-icon>
                  <app-projectlistcard [projectListCardItem]="projectListCardItem"
                    [displayMode]="ProjectListCardModes.DRAG_DROP" (deletedProj)="removeProject($event)">
                  </app-projectlistcard>
                </div>
              </div>
            </div>
          </div>
        </scrollbar>
      </div>
    </div>
    <div class="d-flex justify-content-end footerBtn">
      <button type="button" class="btn btn-secondary-grey cancelBtn" (click)="saveAsDraft()">Cancel</button>
      <button type="button" *ngIf="status !== exerciseStatus.ATTACHED" class="btn btn-secondary-orange mx-4"
        (click)="saveAsDraft()">Save as Draft</button>
      <button type="button" class="btn btn-primary save-btn" (click)="saveReorder()">Save &
        Create Exercise</button>
    </div>
  </div>

  <ng-container *ngIf="!projectListInsideExercise.length">
    <div class="d-flex flex-column align-items-center justify-content-center empty-img-container">
      <div class="empty-img">
        <svg-icon src="../../../../assets/images/exerciseBuilder/emptyProject_image.svg"></svg-icon>
      </div>
      <div class="d-flex align-items-center justify-content-center">
        <button type="button" class="btn btn-primary" (click)="createNewProject()">Create New</button>
        <button type="button" class="btn btn-secondary-orange mx-4" (click)="addFromExistingProjects()">Add from
          Existing</button>
      </div>
    </div>

    <div class="d-flex justify-content-end footerBtn">
      <button type="button" class="btn btn-secondary-grey cancelBtn" (click)="saveAsDraft()">Cancel</button>
      <button type="button" class="btn btn-secondary-orange mx-4" *ngIf="status !== exerciseStatus.ATTACHED"
        (click)="saveAsDraft()">Save as Draft</button>
      <button type="button" class="btn btn-primary save-btn" disabled>Save &
        Create Exercise</button>
    </div>
  </ng-container>
</ng-container>

<div class="px-24 pt-24" *ngIf="!projectListInsideExercise">
  <ng-template skeletonUi [skeletonUiType]="SkeletonLoaderType.ProjectListCardSkeletonComponent" [skeletonUiRepeat]="5">
  </ng-template>
</div>