import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { WrapperModule } from 'src/app/shared/modules/wrapper/wrapper.module';
import { TryProjectComponent } from '../../components/try-project/try-project.component';
import { RouterModule } from '@angular/router';
import { TryProjectRoutingModule } from './try-project-routing.module';
import { PlayerModule } from '../player/player.module';

@NgModule({
  declarations: [TryProjectComponent],
  imports: [
    CommonModule,
    WrapperModule,
    PlayerModule,
    RouterModule,
    TryProjectRoutingModule
  ],
  exports: [TryProjectComponent]
})
export class TryProjectModule { }
