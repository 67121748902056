import { Directive, ElementRef, HostBinding, HostListener, Input } from '@angular/core';

@Directive({
  selector: '[appDragDropArray]'
})
export class DragDropArrayDirective {
  @HostBinding('attr.draggable') draggable = true;
  @Input('draggableArray') appDraggable!: Array<unknown>;
  @Input('dragIndex') elementIndex!: number;
  private dragStartIndex!: number;
  constructor(private el: ElementRef) { }

  @HostListener('dragstart', ['$event'])
  onDragStart(event: DragEvent): void {
    this.dragStartIndex = this.elementIndex;
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    event.dataTransfer!.setData('text', this.dragStartIndex.toString());
    this.el.nativeElement.classList.add('dragging');
  }
  @HostListener('dragover', ['$event'])
  onDragOver(event: DragEvent): void {
    event.preventDefault();
    this.el.nativeElement.style.setProperty('border-bottom', '1px solid grey');
  }
  @HostListener('dragleave', ['$event'])
  onDragLeave(event: DragEvent): void {
    event.preventDefault();
    this.el.nativeElement.style.removeProperty('border-bottom');
  }

  @HostListener('drop', ['$event'])
  onDrop(event: DragEvent): void {
    event.preventDefault();
    const dropIndex = this.elementIndex;
    if (!isNaN(dropIndex) && dropIndex !== this.dragStartIndex) {
      const movedElement = this.appDraggable.splice(this.dragStartIndex, 1)[0];
      this.appDraggable.splice(dropIndex, 0, movedElement);

    }
    this.el.nativeElement.style.removeProperty('border-bottom');
    this.el.nativeElement.classList.remove('dragging');
  }

  @HostListener('dragend')
  onDragEnd(): void {
    this.el.nativeElement.classList.remove('dragging');
  }

}
