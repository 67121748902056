import { IImageContent, IfilesContent } from "src/app/models/projectInterface";
import { CkEditorService } from "src/app/services/ck-editor.service";
import { CommonService, ErrorType } from "src/app/services/common.service";
import { CommonUtils } from "src/app/utils/common-utils";
import { environment } from "src/environments/environment";

/* eslint-disable @typescript-eslint/no-explicit-any */
export class MyUploadAdapter {
  public loader: any;
  constructor(loader: any, private ckEditorService: CkEditorService, private commonService: CommonService,) {
    this.loader = loader;
  }

  upload() {
    return new Promise((resolve, reject) => {
      this.loader.file.then((file: File) => {
        const payload: IImageContent = {
          originalFileName: crypto.randomUUID().replaceAll('-', ''),
          contentType: file.type
        };
        const contentPayload: IfilesContent = {
          files: [payload]
        };
        const imageUnsubscribe = this.ckEditorService.UploadContent(contentPayload).subscribe(async (response: any) => {
          if (response.message === 'success') {
            const s3Response = await this.postData(response[0].url, file);
            if (s3Response === 200) {
              resolve({
                default: environment.s3Bucket + "editor-assets/" + response[0].fileName
              });
            } else {
              reject();
              this.commonService.showToast(' unable to upload please try again', ErrorType.ERROR);
            }
          } else {
            reject();
            this.commonService.showToast(' unable to upload please try again', ErrorType.ERROR);
          }
          imageUnsubscribe.unsubscribe();
        });
      });
    });
  }

  async postData(url = "", data: File) {
    if (CommonUtils.isLocal()) {
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      url = url.split("ckeditor-lumen-nonprod.s3.ap-south-1.amazonaws.com").pop()!;
    }
    return fetch(url, {
      method: "PUT",
      body: data,
    }).then((response) => {
      return response.status;
    });
  }

}
