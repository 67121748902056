import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ExerciseListCardComponent } from '../../components/exercise-list-card/exercise-list-card.component';
import { RouterModule } from '@angular/router';
import { DateFormatPipeModule } from 'src/app/pipes/date-format.pipe';

@NgModule({
  declarations: [ExerciseListCardComponent],
  imports: [
    CommonModule,
    RouterModule,
    DateFormatPipeModule
  ],
  exports: [ExerciseListCardComponent]
})
export class ExerciseListCardModule { }
