import { Injectable } from '@angular/core';
import { SocketService } from '../services/socket.service';
import { ExerciseEventsKey } from '../enums/exerciseEventsKey';
import { SortOptions } from '../projectbank/project-bank/projectbank.component';
import { ExerciseType } from '../enums/getExerciseList';
import { deleteExercise } from '../models/exerciseInterface';
import { ProjectEventsKey } from '../enums/projectEventsKey';

@Injectable()
export class ExerciseService {
  searchString!: string;
  lastExerciseCursor!: string;
  selectedSortOption!: SortOptions["value"];
  type!: string;
  filters: Record<string, string[]> = {
    author: [],
    language: [],
    archetype: [],
    level: [],
    status: []
  };

  constructor(private socketService: SocketService) { }
  getExerciseList() {
    return this.socketService.getDataFromSocket(ExerciseEventsKey.GET_EXERCISE_LIST, ExerciseEventsKey.EXERCISE_LIST,
      {
        kind: this.type,
        ...((this.lastExerciseCursor && this.lastExerciseCursor !== '') && { lastExerciseCursor: this.lastExerciseCursor }),
        ...(this.searchString && { search: this.searchString }),
        ...((this.selectedSortOption && this.selectedSortOption.sortBy !== "") && { sortby: this.selectedSortOption.sortBy, sortOrder: this.selectedSortOption.sortOrder }),
        ...(this.filters && { filters: this.filters }),

      }
      , true);
  }
  getExerciseDetails(payload: { exerciseId: string }) {
    return this.socketService.getDataFromSocket(ExerciseEventsKey.GET_EXERCISE_DETAILS, ExerciseEventsKey.EXERCISE_DEATILS, payload, true);
  }

  set setLastExerciseCursor(lastExerciseCursor: string) {
    this.lastExerciseCursor = lastExerciseCursor;
  }

  setExerciseType(exrcisetype: ExerciseType) {
    this.type = exrcisetype;
  }

  setSearchString(searchString: string) {
    this.searchString = searchString;
  }

  setSortOption(selectedSortOption: SortOptions["value"]) {
    this.selectedSortOption = selectedSortOption;
  }

  deleteExercise(obj: deleteExercise) {
    return this.socketService.getDataFromSocket(ExerciseEventsKey.DELETE_EXERCISE, ExerciseEventsKey.EXERCISE_DELETED, obj, true);
  }

  setFilters(selectedFilters: Record<string, string[]>) {
    this.filters = selectedFilters;
  }

  public getConfig() {
    const payload = {};
    return this.socketService.getDataFromSocket(ProjectEventsKey.GET_PROJECT_CONFIGURATION, ProjectEventsKey.PROJECT_CONFIGURATION, payload, true);
  }
  cloneExercise(obj: deleteExercise) {
    return this.socketService.getDataFromSocket(ExerciseEventsKey.CLONE_EXERCISE, ExerciseEventsKey.EXERCISE_CLONED, obj, true);
  }
}
