import { Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-image-zoom',
  templateUrl: './image-zoom.component.html',
  styleUrls: ['./image-zoom.component.scss']
})
export class ImageZoomComponent {
  @Input() image!: string;
  @ViewChild('imageHolder') imageHolder!: ElementRef;
  @Output() detailsScreen = new EventEmitter();
  @Input() header = true;
  @Input() title = '';
  widthIframe = 35;
  zoomValue = 100;
  constructor(public activeModal: NgbActiveModal) { }
  ngAfterViewInit() {
    const el = this.imageHolder.nativeElement;
    el.style.width = `${this.widthIframe}vw`;
  }
  zoom(zoomCase: string): void {
    const el = this.imageHolder.nativeElement;
    switch (zoomCase) {
      case 'positive':
        el.style.width = `${this.widthIframe += 1}vw`;
        this.zoomValue = this.zoomValue === 195 ? this.zoomValue + 5 : this.zoomValue + 5;
        break;
      case 'negative':
        el.style.width = `${this.widthIframe -= 1}vw`;
        this.zoomValue = this.zoomValue === 30 ? this.zoomValue - 5 : this.zoomValue - 5;
        break;
    }
  }

  backToDetails() {
    this.detailsScreen.emit();
  }
}
