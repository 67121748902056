import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DateTimePickerComponent } from '../../components/date-time-picker/date-time-picker.component';
import { WrapperModule } from '../wrapper/wrapper.module';
import { ScrollService } from 'src/app/services/scroll.service';
import { DpDatePickerModule } from 'ng2-date-picker';

@NgModule({
  declarations: [DateTimePickerComponent],
  imports: [
    CommonModule,
    WrapperModule,
    DpDatePickerModule
  ],
  providers: [ScrollService],
  exports: [DateTimePickerComponent]
})
export class DateTimePickerModule { }
