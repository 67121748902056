<div class="form-group p-16 m-0">
  <ng-select [items]="archetypes" [clearable]="false" bindLabel="name" bindValue="value" placeholder="Select Archetype"
    [closeOnSelect]="true" [searchable]="false" [(ngModel)]="selectedArchType"
    (change)="storeSelectedArchetypeAndLangIdInService()">
  </ng-select>
</div>
<ng-template #treeTemplate let-nodes>
  <ul class="tree-ul mb-16">
    <li *ngFor="let item of nodes" [ngClass]="{'selected': item === selectedItem}">
      <ng-container>
        <div class="tree-toggle d-flex align-items-center pt-2 pr-16 pb-2 pl-24"
          [ngClass]="{'folder-selected': item === selectedDirectory}">
          <!-- <span class="expand-collapse me-2" *ngIf="item.files">
          </span> -->
          <span class="d-flex align-items-center element-properties ">
            <span class="isSystemGenerated " (click)="selectItem(item)">
              <span class="me-2" [ngClass]="item.kind === 'directory'? 'folder-icon':'file-icon'"></span>
              <span class="file-name text-break">{{ item.name }}</span>
            </span>
            <!-- <span class="dropdown text-end mb-16"
              *ngIf="!item.isSystemGenerated && (mode !== ProjectPlayerModes.TRY && mode!== ProjectPlayerModes.EVALUATION)">
              <img class="dropdown-toggle cursor-pointer" data-bs-toggle="dropdown" aria-expanded="false"
                src="../../assets/images/ellipsis-menu.svg">
              <ul class="dropdown-menu file-menu">
                <li class="content-crud" (click)="editDirectoryOrFile(item)">
                  <a class="dropdown-item"><img src="../../../../../assets/images/ellipsis-edit.svg">
                    Edit {{item.kind === 'directory'? 'Folder':'File'}}
                  </a>
                </li>
                <li class="content-crud" (click)="deleteDirectoryOrFile(item)">
                  <a class="dropdown-item cursor-pointer"><img src="../../../../../assets/images/ellipsis-delete.svg">
                    Delete {{item.kind === 'directory'? 'Folder':'File'}}
                  </a>
                </li>
              </ul>
            </span> -->
          </span>
        </div>
      </ng-container>
      <ng-container *ngIf="item.files && item.files.length > 0">
        <div [ngClass]="{'childrenPadding' : 'item.files > 0'}">
          <ng-container *ngTemplateOutlet="treeTemplate;  context: { $implicit: item.files }"></ng-container>
        </div>
      </ng-container>
    </li>
  </ul>
</ng-template>

<scrollbar class="scrollbar">
  <div class="scroll-container">
    <ng-container *ngIf="treeData">
      <ng-container *ngTemplateOutlet="treeTemplate; context: { $implicit: treeData.files }"></ng-container>
    </ng-container>
  </div>
</scrollbar>

<div *ngIf="archetypesWithDbCredentials.includes(selectedArchType)" class="credentials-box p-3 m-3">
  <p class="body-bold-assistive-text mb-3">DB Credentials</p>
  <div class="d-flex justify-content-between align-items-center">
    <div class="credentialField">
      <span class="body-text">Username:</span>
      <span class="body-text dbWordBox "> {{userName}}</span>
    </div>
    <span *ngIf="selectedLang !=='DBMS'" [copy-clipboard]="getCopyText('Username')" (copied)="notify('Username')">
      <svg-icon src="../../../../../assets/images/copy.svg"></svg-icon>
    </span>
  </div>
  <div class="d-flex justify-content-between align-items-center">
    <div class="credentialField">
      <span class="body-text">Password:</span>
      <span class="body-text dbWordBox">{{password}}</span>
    </div>
    <span *ngIf="selectedLang !=='DBMS'" [copy-clipboard]="getCopyText('Password')" (copied)="notify('Password')">
      <svg-icon src="../../../../../assets/images/copy.svg"></svg-icon>
    </span>
  </div>
  <div class="d-flex justify-content-between align-items-center">
    <div class="credentialField">
      <span class="body-text">DB Name:</span>
      <span class="body-text dbWordBox"> {{dbName}}</span>
    </div>
    <span *ngIf="selectedLang !=='DBMS'" [copy-clipboard]="getCopyText('DB Name')" (copied)="notify('DB Name')">
      <svg-icon src="../../../../../assets/images/copy.svg"></svg-icon>
    </span>
  </div>
</div>

<!-- <div *ngIf="mode !== ProjectPlayerModes.TRY && mode!== ProjectPlayerModes.EVALUATION">
  <div class="separator"></div>
  <div class="add-file-folder">
    <svg-icon class="mr-16" src="../../../../../assets/images/add-Folder.svg" (click)="addFileOrDirectory('directory')">
    </svg-icon>
    <svg-icon src="../../../../../assets/images/add-File.svg" (click)="addFileOrDirectory('file');"></svg-icon>
  </div>
</div> -->

<ng-content></ng-content>