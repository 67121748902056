/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-console */
import { Injectable, NgZone } from '@angular/core';
import { Observable, ReplaySubject, Subject, filter, map } from 'rxjs';
import { environment } from 'src/environments/environment';
import { EventHandlerService } from './event-handler.service';
import { UserRoles } from '../enums/userRoles';
import { CommonService, ErrorType } from './common.service';

interface socketConfig {
  header: {
    Authorization: string
  },
  body: {
    eventName: string,
    channelId: string,
    payload: Record<string, unknown>
  },
  reconnectDuration: {
    gateWay: number,
    pubSub: number
  }
}

@Injectable({
  providedIn: 'root',
})
export class SocketService {
  public socketConfig: socketConfig = {
    header: {
      //Student
      //Authorization: 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJyb2xlIjoic3R1ZGVudCIsInVzZXJJZCI6IjkyNjJkMzdmLWE3MjgtNDBlMS1iZDJkLTNjNzhmY2E5YzQwOSIsImNsaWVudElkIjoiZTczMTBhMzctNGU3Ny00MmE2LTg5NmUtYWUzZmZjYjA2NWQ5IiwiY2xpZW50TmFtZSI6Imx1bWVuIiwib3JnSWQiOiJlNzMxMGEzNy00ZTc3LTQyYTYtODk2ZS1hZTNmZmNiMDY1ZDkiLCJuYW1lIjoiZG9udCAgRGVhY3RpdmF0ZSIsImVtYWlsIjoiZG9udGRlYWN0aXZhdGVwY0B5b3BtYWlsLmNvbSIsImlhdCI6MTY5MjU1NjA2MiwiZXhwIjoxNzAwMzMyMTAwfQ.wB6y50vFfyw6FFdqgtnE0ix-Yjyrdag7ejibxNhlNwc'
      //Faculty
      Authorization: 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJyb2xlIjoiZmFjdWx0eSIsInVzZXJJZCI6IjkyNjJkMzdmLWE3MjgtNDBlMS1iZDJkLTNjNzhmY2E5YzQwOSIsImNsaWVudElkIjoiZTczMTBhMzctNGU3Ny00MmE2LTg5NmUtYWUzZmZjYjA2NWQ5IiwiY2xpZW50TmFtZSI6Imx1bWVuIiwib3JnSWQiOiJlNzMxMGEzNy00ZTc3LTQyYTYtODk2ZS1hZTNmZmNiMDY1ZDkiLCJuYW1lIjoiZG9udCAgRGVhY3RpdmF0ZSIsImVtYWlsIjoiZG9udGRlYWN0aXZhdGVwY0B5b3BtYWlsLmNvbSIsImlhdCI6MTY5MjU1NjA2MiwiZXhwIjoxNzAwMzMyMTAwfQ.rwLDvG4sUOSyTGbZcn0p5S7gO0Ey1dCTHKSDWvKFLEM'
    },
    body: {
      eventName: 'abc',
      channelId: crypto.randomUUID().replaceAll('-', ''),
      payload: {}
    },
    reconnectDuration: {
      gateWay: 2000,
      pubSub: 3000
    }
  };
  private gatewaySocket!: WebSocket;
  private pubSubSocket!: EventSource;
  private gatewayServerUrl!: string;
  private pubSubServerUrl!: string;
  messageBroadcasterOnce$ = new Subject();
  messageBroadcaster$ = new ReplaySubject();
  gateWayHeartBeatAlive = false;
  public currentMode = 'FACULTY';
  public role = UserRoles.FACULTY;
  constructor(private zone: NgZone, private eventHandlerService: EventHandlerService, private commonService: CommonService) {

  }
  initEndPoints() {
    this.eventHandlerService.init();
    this.gatewayServerUrl = environment.gatewaySocketUrl;
    this.pubSubServerUrl = environment.nchanEventSourceUrl;
    this.initSocketConnections(true, true);
  }
  initSocketConnections(connectGatewaySocket: boolean, connectPubSubSocket: boolean) {
    console.log('Init sockets');
    connectGatewaySocket ? this.gatewaySocket = new WebSocket(`${this.gatewayServerUrl}?authorization=${this.socketConfig.header.Authorization}`) : '';
    connectPubSubSocket ? this.pubSubSocket = new EventSource(`${this.pubSubServerUrl}${this.socketConfig.body.channelId}`) : '';
    this.setupListeners();
  }

  setupListeners() {
    this.onConnect();
    this.onMessage();
    this.onClose();
    this.onError();
  }

  private onConnect() {
    this.gatewaySocket.onopen = (event) => {
      console.log('GATEWAY WebSocket connection established for', event);
      this.gateWayHeartBeatAlive = true;
      this.gateWaySocketHeartBeatInitialize();
    };
    this.pubSubSocket.onopen = (event) => {
      console.log('PUBSUB connection established for', event, this.pubSubSocket);
    };
  }

  private onMessage() {
    this.gatewaySocket.onmessage = (event) => {
      if (event.data === 'pong') {
        this.gateWayHeartBeatAlive = true;
      }
    };
    console.log('message listener from pubsub');
    this.pubSubSocket.onmessage = (event) => {
      console.log('Received message:', JSON.parse(event.data));
      this.zone.run(() => {
        this.eventHandlerService.handleEventGlobally(JSON.parse(event.data).eventName, JSON.parse(event.data).payload);
        this.messageBroadcaster$.next(JSON.parse(event.data));
        this.messageBroadcasterOnce$.next(JSON.parse(event.data));
      });
    };
  }

  private onClose() {
    this.gatewaySocket.onclose = (event) => {
      console.log('GATEWAY WebSocket connection closed for', event);
      setTimeout(() => {
        console.log(`Retrying gateway after seconds : `, this.socketConfig.reconnectDuration.gateWay / 1000);
        this.socketConfig.reconnectDuration.gateWay *= 2;
        this.initSocketConnections(true, false);
      }, this.socketConfig.reconnectDuration.gateWay);
    };

    // this.pubSubSocket.onclose = (event) => {
    //   console.log('PUBSUB WebSocket connection closed for', event);
    //   setTimeout(() => {
    //     console.log(`Retrying pubSub after seconds : `, this.socketConfig.reconnectDuration.pubSub / 1000);
    //     this.socketConfig.reconnectDuration.pubSub *= 2;
    //     this.initSocketConnections(false, true);
    //   }, this.socketConfig.reconnectDuration.pubSub);
    // };
  }

  private onError() {
    this.gatewaySocket.onerror = (event) => {
      console.log('GATEWAY WebSocket connection error for', event);
    };
    this.pubSubSocket.onerror = (event) => {
      console.log('PUBSUB connection error for', event, this.pubSubSocket);
    };
  }

  private async gateWaySocketHeartBeatInitialize() {
    const intervalTime = 50000;
    this.gateWayHeartBeatAlive = false;
    this.gatewaySocket.send('ping');
    setTimeout(async () => {
      this.gateWaySocketHeartBeatStatus();
    }, intervalTime);
  }

  private gateWaySocketHeartBeatStatus() {
    if (!this.gateWayHeartBeatAlive) {
      console.log('Gateway Socket heartbeat is not alive - Initializing Gateway again');
      this.initSocketConnections(true, false);
    } else {
      this.gateWaySocketHeartBeatInitialize();
    }
  }

  /**
   * Listen to a specific event from the pubSub server
   * @param {string=} eventName - Event name to listen to fetch data from pubsub socket
   * @returns {Observable} - An observable to listen to the event from pubSub. Needs to be subscribed to get data.
   */
  public listenToEventWithoutHistory(eventName: string): Observable<any> {
    const obs = this.messageBroadcasterOnce$.asObservable()
      .pipe(
        filter((data: any) => data.eventName === eventName),
        map((data: { eventName: string, payload: any }) => data.payload)
      );
    return obs;
  }

  public listenToEvent(eventName: string): Observable<any> {
    const obs = this.messageBroadcaster$.asObservable()
      .pipe(
        filter((data: any) => data.eventName === eventName),
        map((data: { eventName: string, payload: any }) => data.payload)
      );
    return obs;
  }

  /**
   * Send a request to the Gateway server.
   * @param {string}  eventName - Event name to publish to through gateway socket.
   * @param {any} payload - Payload to post to the gateway socket, is optional.
   */
  public async sendMessage(eventName: string, payload: any) {
    if (this.pubSubSocket.readyState === EventSource.OPEN) {
      this.checkGatewaySocketAndPublish(eventName, payload);
    }
    else {
      console.error('Pubsub webSocket connection is not open, waiting for it to connect before sending messages');
      try {
        console.log('Waiting for Pubsub socket to connect.');
        await this.waitForOpenConnection(this.pubSubSocket);
        this.checkGatewaySocketAndPublish(eventName, payload);
      } catch (error) {
        console.log('Pubsub failed to connect', error);
      }
    }
  }

  private async checkGatewaySocketAndPublish(eventName: string, payload: Record<string, unknown>) {
    const channelId = this.socketConfig.body.channelId;
    if (this.gatewaySocket.readyState === WebSocket.OPEN) {
      console.log('publishing message', { eventName, channelId, payload });
      this.gatewaySocket.send(JSON.stringify({ eventName, channelId, payload }));
    } else {
      try {
        console.log('Waiting for Gateway socket to connect.');
        await this.waitForOpenConnection(this.gatewaySocket);
        console.log('publishing message', { eventName, channelId, payload });
        this.gatewaySocket.send(JSON.stringify({ eventName, channelId, payload }));
      } catch (error) {
        console.log('Gateway failed to connect', error);
      }

    }
  }

  private async waitForOpenConnection(socket: WebSocket | EventSource) {
    return new Promise<void>((resolve, reject) => {
      const maxNumberOfAttempts = 5;
      const intervalTime = 1000; //ms
      let currentAttempt = 0;
      const interval = setInterval(() => {
        if (currentAttempt > maxNumberOfAttempts - 1) {
          clearInterval(interval);
          const message = this.getTranslateMessage();
          this.commonService.showToast(message, ErrorType.ERROR);
          reject(new Error('Maximum number of attempts exceeded'));
        } else if (socket.readyState === socket.OPEN) {
          clearInterval(interval);
          resolve();
        }
        currentAttempt++;
      }, intervalTime);
    });
  }

  getTranslateMessage() {
    if (this.gateWayHeartBeatAlive) {
      return this.commonService.translateText('connectionMessages.pubsub');
    }
    return this.commonService.translateText('connectionMessages.gateWay');
  }

  /**
 * Utility to request data from the Gateway server and listen to the corresponding event from the pubSub server.
 * @param {string}  eventNameToPublish - Event name to publish to the gateway socket.
 * @param {string=} eventNameToListen - Event name to listen to fetch data from pubsub socket
 * @param {any} payload - Payload to post to the gateway socket, is optional.
 * @param {any} withoutHistory - Do we need historic data, this will define if the listener subscribes to a ReplaySubject(for old data) or Subject(only new data).
 * @returns {Observable} - An observable to listen to the event from pubSub. Needs to be subscribed to get data.
 */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public getDataFromSocket(eventNameToPublish: string, eventNameToListen: string, payload: any = {}, withoutHistory = false) {
    try {
      if (withoutHistory) {
        return this.listenToEventWithoutHistory(eventNameToListen);
      }
      return this.listenToEvent(eventNameToListen);
    } finally {
      this.sendMessage(eventNameToPublish, payload);
    }
  }
  //Temporary Function to switch mode
  switchMode() {
    if (this.currentMode === 'FACULTY') {
      this.socketConfig.header.Authorization = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJyb2xlIjoic3R1ZGVudCIsInVzZXJJZCI6IjkyNjJkMzdmLWE3MjgtNDBlMS1iZDJkLTNjNzhmY2E5YzQwOSIsImNsaWVudElkIjoiZTczMTBhMzctNGU3Ny00MmE2LTg5NmUtYWUzZmZjYjA2NWQ5IiwiY2xpZW50TmFtZSI6Imx1bWVuIiwib3JnSWQiOiJlNzMxMGEzNy00ZTc3LTQyYTYtODk2ZS1hZTNmZmNiMDY1ZDkiLCJuYW1lIjoiZG9udCAgRGVhY3RpdmF0ZSIsImVtYWlsIjoiZG9udGRlYWN0aXZhdGVwY0B5b3BtYWlsLmNvbSIsImlhdCI6MTY5MjU1NjA2MiwiZXhwIjoxNzAwMzMyMTAwfQ.wB6y50vFfyw6FFdqgtnE0ix-Yjyrdag7ejibxNhlNwc';
      this.gatewaySocket.close();
      this.initSocketConnections(true, false);
      this.currentMode = 'reload';
      setTimeout(() => {
        this.currentMode = 'STUDENT';
        this.role = UserRoles.STUDENT;
      }, 1);
      return;
    }
    this.socketConfig.header.Authorization = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJyb2xlIjoiZmFjdWx0eSIsInVzZXJJZCI6IjkyNjJkMzdmLWE3MjgtNDBlMS1iZDJkLTNjNzhmY2E5YzQwOSIsImNsaWVudElkIjoiZTczMTBhMzctNGU3Ny00MmE2LTg5NmUtYWUzZmZjYjA2NWQ5IiwiY2xpZW50TmFtZSI6Imx1bWVuIiwib3JnSWQiOiJlNzMxMGEzNy00ZTc3LTQyYTYtODk2ZS1hZTNmZmNiMDY1ZDkiLCJuYW1lIjoiZG9udCAgRGVhY3RpdmF0ZSIsImVtYWlsIjoiZG9udGRlYWN0aXZhdGVwY0B5b3BtYWlsLmNvbSIsImlhdCI6MTY5MjU1NjA2MiwiZXhwIjoxNzAwMzMyMTAwfQ.rwLDvG4sUOSyTGbZcn0p5S7gO0Ey1dCTHKSDWvKFLEM';
    this.gatewaySocket.close();
    this.initSocketConnections(true, false);
    this.currentMode = 'reload';
    setTimeout(() => {
      this.currentMode = 'FACULTY';
      this.role = UserRoles.FACULTY;
    }, 1);
  }
}

