import { Component } from '@angular/core';
import { Subscription, map } from 'rxjs';
import { ProjectListCardModes } from 'src/app/enums/projectListCardMode';
import { ProjectListCardItem } from 'src/app/models/project-list-card';
import { ExerciseBuilderService } from '../exercise-builder.service';
import { SkeletonLoaderType } from 'src/app/enums/skeletonui';
import { ExerciseProjectConfiguration } from 'src/app/models/exerciseInterface';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { MarksModalComponent, MarksMode } from '../../modals/marks-modal/marks-modal.component';
import { ActivatedRoute, Router } from '@angular/router';
import { take } from 'rxjs/operators';
import { CommonUtils } from 'src/app/utils/common-utils';

export interface SortOptions {
  name: string,
  value: {
    sortBy: string,
    sortOrder: string,
  }
}
@Component({
  selector: 'app-project-selection',
  templateUrl: './project-selection.component.html',
  styleUrls: ['./project-selection.component.scss']
})
export class ProjectSelectionComponent {
  ProjectListCardModes = ProjectListCardModes;
  projectList$!: Subscription;
  projectList: ProjectListCardItem[] = [];
  selectedProjects: ProjectListCardItem[] = [];
  totalProjects!: string;
  filterOptionSelected = false;
  exerciseId!: string;
  getExercisePaylod!: {
    exerciseId: string
  };
  SkeletonLoaderType = SkeletonLoaderType;
  selectSortOption!: SortOptions["value"];
  sortOptions = [
    {
      name: "Created A-Z",
      value: {
        sortBy: "createdAt",
        sortOrder: "ASC",
      }
    },
    {
      name: "Created Z-A",
      value: {
        sortBy: "createdAt",
        sortOrder: "DSC",
      }
    },
    {
      name: "Edited A-Z",
      value: {
        sortBy: "updatedAt",
        sortOrder: "ASC",
      }
    },
    {
      name: "Edited Z-A",
      value: {
        sortBy: "updatedAt",
        sortOrder: "DSC",
      }
    }

  ];
  versionConfig: { language: string, versions: { langId: string, label: string, archetypes: { targetFileName: string, sourceFileName: string, label: string, archetypeId: string }[] }[] }[] = [];
  allFilters: { title: string, titleValue: { name: string, value: string }[] }[] = [];
  filters: Record<string, string[]> = {
    // author: [],
    language: [],
    archetype: [],
    level: [],
  };
  totalSelected = 0;
  defaultMarks = 20;
  constructor(private exerciseBuilderService: ExerciseBuilderService, private NgbModal: NgbModal, private activatedRoute: ActivatedRoute, private router: Router) { }
  modalRef!: NgbModalRef;

  ngOnInit() {
    this.switchProjectSelectionMode(true);
    this.setExerciseId();
    this.getProjectSelectionList();
    this.getConfig();
  }

  async getConfig() {
    let levels: string[] = [];

    // let authors: string[] = [];
    this.exerciseBuilderService.getConfig().pipe(take(1)).subscribe((configuration: ExerciseProjectConfiguration) => {
      levels = configuration.level;
      // authors = configuration.author;
      this.versionConfig = configuration.languages;
      const languages = this.versionConfig.map((lang: { language: string, versions: { langId: string, label: string, archetypes: { targetFileName: string, sourceFileName: string, label: string, archetypeId: string }[] }[] }) => {
        return { name: lang.language, value: lang.language };
      });
      // const author = authors.map(author => ({ name: author, value: author }));
      // this.allFilters.push({ title: "author", titleValue: author });
      this.allFilters.push({ title: "language", titleValue: languages });
      this.allFilters.push({ title: "archetype", titleValue: [] });
      const level = levels.map(level => ({ name: level, value: level }));
      this.allFilters.push({ title: "level", titleValue: level });
    });
  }

  private switchProjectSelectionMode(mode: boolean) {
    this.exerciseBuilderService.selectionMode = mode;
  }
  setExerciseId() {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    this.exerciseId = this.activatedRoute.snapshot.paramMap.get('exerciseId')!;
    this.getExercisePaylod = {
      exerciseId: this.exerciseId
    };
  }

  private getProjectSelectionList() {
    this.projectList$ = this.exerciseBuilderService.getProjectSelectionList(this.getExercisePaylod)
      .pipe(
        // take(1),
        map((projectListPayload: { projects: ProjectListCardItem[], lastProjectCursor: string, totalProjects: string }) => {
          this.exerciseBuilderService.setLastProjectCursor = projectListPayload.lastProjectCursor;
          this.totalProjects = projectListPayload.totalProjects;
          return projectListPayload;
        })
      ).subscribe((projectListPayload: { projects: ProjectListCardItem[] }) => {
        this.projectList.push(...projectListPayload.projects);
      });
  }
  searchProject(searchString: string) {
    this.exerciseBuilderService.setSearchString(searchString);
    this.refreshProjectList();
  }
  public refreshProjectList() {
    this.exerciseBuilderService.setLastProjectCursor = "";
    this.projectList = [];
    this.exerciseBuilderService.getProjectSelectionList(this.getExercisePaylod);
  }

  setSortOption() {
    this.exerciseBuilderService.setSortOption(this.selectSortOption);
    this.refreshProjectList();
  }

  clearSort() {
    this.exerciseBuilderService.setSortOption({
      sortBy: "",
      sortOrder: "",
    });
    this.refreshProjectList();
  }
  onScrollDown() {
    this.exerciseBuilderService.getProjectSelectionList(this.getExercisePaylod);
  }

  onSelectFilter(checked: boolean, item: string, title: string) {
    if (checked) {
      this.filters[title].push(item);
      if (title === "language") {
        this.setArchTypesBasedOnLanguages(title);
      }
    } else {
      this.removeIndex(item, this.filters[title]);
      if (title === "language") {
        this.setArchTypesBasedOnLanguages(title);
      }
    }

    const commonUtils = new CommonUtils();
    //Check if any filter options are selected
    this.filterOptionSelected = commonUtils.isAnyFilterSelected(this.filters);

    if (this.filters) {
      this.exerciseBuilderService.setFilters(this.filters);
      this.refreshProjectList();
    }
  }

  setArchTypesBasedOnLanguages(title: string) {
    const filteredArchId: string[] = [];
    const archetypeNames: { name: string; value: string; }[] = [];
    const filteredVersionArray: { langId: string; label: string; archetypes: { targetFileName: string, sourceFileName: string, label: string; archetypeId: string; }[]; }[] = [];
    const index = this.allFilters.findIndex((item: { title: string; titleValue: { name: string, value: string }[] }) => item.title === 'archetype');
    if (this.filters[title].length > 0) {
      this.filters[title].forEach((language: string) => {
        this.versionConfig.map(
          (languagePayload: { language: string, versions: { langId: string, label: string, archetypes: { targetFileName: string, sourceFileName: string, label: string, archetypeId: string }[] }[] }) => {
            if (language === languagePayload.language) {
              filteredVersionArray.push(...languagePayload.versions);
              languagePayload.versions.forEach((version: { langId: string, label: string, archetypes: { targetFileName: string, sourceFileName: string, label: string, archetypeId: string }[] }) => {
                filteredArchId.push(...version.archetypes.flatMap(archetype => archetype.archetypeId));
              });
            }
          });
      });
      filteredVersionArray.map(item => {
        item.archetypes.map(archetype => {
          archetypeNames.push({
            name: archetype.label,
            value: archetype.archetypeId
          });
        });
      });
      this.allFilters[index].titleValue = [];
      if (index !== -1) {
        this.allFilters[index].titleValue.push(...archetypeNames);
      }
    }
    else {
      this.allFilters[index].titleValue = [];
    }
  }
  removeIndex(item: string, type: string[]) {
    const indexToRemove = type.indexOf(item);
    if (indexToRemove !== -1) {
      type.splice(indexToRemove, 1);
    }
  }

  onProjectSelectionToggle(project: ProjectListCardItem): void {
    if (project.selected) {
      project.maxMarks = this.defaultMarks;
      this.selectedProjects.push(project);
      this.totalSelected = this.selectedProjects.length;
    } else {
      const index = this.selectedProjects.indexOf(project);
      if (index !== -1) {
        this.selectedProjects.splice(index, 1);
        this.totalSelected = this.selectedProjects.length;
      }
    }
  }

  clearSelection() {
    this.projectList.forEach(project => {
      project.selected = false;
    });
    this.selectedProjects = [];
    this.totalSelected = this.selectedProjects.length;
  }

  cancelOperation() {
    //route back to project tab
    this.router.navigate([], { relativeTo: this.activatedRoute, queryParams: { exerciseManipulationTab: 'exercise-projects' }, queryParamsHandling: 'merge' });
  }

  marksModel() {
    this.modalRef = this.NgbModal.open(MarksModalComponent, { windowClass: 'createNewExercise-modal' });
    this.modalRef.componentInstance.selectedProjects = this.selectedProjects;
    this.modalRef.componentInstance.exerciseId = this.exerciseId;
    this.modalRef.componentInstance.marksMode = MarksMode.CONFIRM_MARKS;
    this.modalRef.componentInstance.closedStatus.subscribe(() => {
      this.modalRef.close();
      this.router.navigate([], { relativeTo: this.activatedRoute, queryParams: { exerciseManipulationTab: 'exercise-projects' }, queryParamsHandling: 'merge' });
    });
  }

  addproject() {
    //open modal
    this.marksModel();
  }

  previewSelected(projectID: string) {
    const project = this.projectList.find(project => project.projectId === projectID);
    if (project) {
      if (project.selected === true) {
        this.totalSelected = this.selectedProjects.length;
      }
      else {
        project.selected = true;
        project.maxMarks = this.defaultMarks;
        this.selectedProjects.push(project);
        this.totalSelected = this.selectedProjects.length;
      }
    }
  }

  ngOnDestroy() {
    this.switchProjectSelectionMode(false);
    this.projectList = [];
    this.exerciseBuilderService.setLastProjectCursor = "";
    this.projectList$.unsubscribe();
    this.filters = {};
  }

}
