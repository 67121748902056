<!-- Main Container -->
<div class=" container-fluid delete-modal-style py-32">
  <!-- Image -->
  <div class="pb-24">
    <svg-icon *ngIf ="!modalConfig.isRejectRequestModal" src="../../../../assets/images/icon-warning-orange.svg"></svg-icon>
    <svg-icon *ngIf ="modalConfig.isRejectRequestModal" src="../../../../assets/images/failed.svg"></svg-icon>
  </div>
  <!-- First Warning Line -->
  <p class="heading-1 pb-24" [innerHtml]="modalConfig.message"></p>
  <!-- Second Warning Line -->
  <div class="pb-16">
    <span class="big-bold-assistive-text">Note:</span> <span class="body-text ps-1"
      [innerHtml]="modalConfig.note"></span>
  </div>
  <!-- Buttons -->
  <div class="row pt-16 justify-content-center">
    <button class=" btn btn-secondary-grey mr-24" [translate]="modalConfig.cancelBtn"
      (click)="sendConfirmStatus(false)"></button>
    <button class="btn btn-warning" [translate]="modalConfig.confirmBtn" (click)="sendConfirmStatus(true)"></button>
  </div>
</div>