<div class='image-zoom-wrapper'>
  <div *ngIf="header" class="modal-header d-flex align-items-center justify-content-between">
    <span class="heading-2">
      {{title}}
    </span>
    <span class="close-icon cursor-pointer" (click)="activeModal.dismiss()">
      <svg-icon src="assets/images/close.svg"></svg-icon>
    </span>
  </div>
  <div class="p-24">
    <div class="zoom-wrapper d-flex align-items-center justify-content-between mb-24">
      <div class="back-to-details">
        <span *ngIf="!header" class="d-flex align-items-center">
          <svg-icon class="cursor-pointer me-2" src="assets/images/back-arrow.svg" (click)="backToDetails()">
          </svg-icon>
          <span>Image</span>
        </span>
      </div>
      <div class="d-flex align-items-center justify-content-center">
        <button (click)="zoom('negative')" id="image-zoom-negative" [disabled]="zoomValue === 25">
          <svg-icon src="assets/images/icon-zoom-out.svg">
          </svg-icon>
        </button>
        <span class="zoom-percentage">{{zoomValue}}%</span>
        <button (click)="zoom('positive')" id="image-zoom-positive" [disabled]="zoomValue === 200">
          <svg-icon src="assets/images/icon-zoom-in.svg">
          </svg-icon>
        </button>
      </div>

    </div>
  </div>
  <div class="imageHolder-holder" id="image-imageHolder">
    <img #imageHolder src={{image}}>
  </div>

</div>