import { RoutingParams } from "src/app/enums/routingParameters";

export interface LeftMenuItem {
  id: string,
  authorization: boolean,
  title: string,
  route: string,
  iconUrl: string,
  iconUrlActive: string;
  active: boolean,
  visible: boolean
}

export const leftMenuItems: LeftMenuItem[] = [
  // {
  //   id: 'dashboard',
  //   authorization: false,
  //   title: "Dashboard",
  //   route: RoutingParams.DASHBOARD,
  //   iconUrl: '../../assets/images/sidebar/dashboard.svg',
  //   iconUrlActive: '../../assets/images/sidebar/dashboard-active.svg',
  //   active: false,
  //   visible: true
  // },
  {
    id: 'exercise',
    authorization: false,
    title: "Exercise",
    route: RoutingParams.EXERCISE,
    iconUrl: '../../assets/images/sidebar/exercise.svg',
    iconUrlActive: '../../assets/images/sidebar/exercise-active.svg',
    active: false,
    visible: true
  },
  {
    id: 'project-bank',
    authorization: false,
    title: "Project Bank",
    route: RoutingParams.PROJECT_BANK,
    iconUrl: '../../assets/images/sidebar/project-bank.svg',
    iconUrlActive: '../../assets/images/sidebar/projectbank-active.svg',
    active: true,
    visible: true
  },
  {
    id: 'learner',
    authorization: false,
    title: "Learner",
    route: RoutingParams.LEARNER,
    iconUrl: '../../assets/images/sidebar/project-bank.svg',
    iconUrlActive: '../../assets/images/sidebar/projectbank-active.svg',
    active: false,
    visible: false
  },
  {
    id: 'evaluation',
    authorization: false,
    title: "Evaluation",
    route: RoutingParams.EVALUATE_ATTEMPT,
    iconUrl: '../../assets/images/sidebar/project-bank.svg',
    iconUrlActive: '../../assets/images/sidebar/projectbank-active.svg',
    active: false,
    visible: false
  },
  {
    id: 'evaluation',
    authorization: false,
    title: "Evaluation",
    route: RoutingParams.EVALUATE_SUMMARY,
    iconUrl: '../../assets/images/sidebar/project-bank.svg',
    iconUrlActive: '../../assets/images/sidebar/projectbank-active.svg',
    active: false,
    visible: false
  },
  {
    id: 'selfpractise',
    authorization: false,
    title: "Selfpractice",
    route: RoutingParams.SELFPRACTISE,
    iconUrl: '../../assets/images/sidebar/project-bank.svg',
    iconUrlActive: '../../assets/images/sidebar/projectbank-active.svg',
    active: false,
    visible: false
  },
  {
    id: 'lumen-integration',
    authorization: false,
    title: "lumen-integration",
    route: RoutingParams.LUMEN_INTEGRATION,
    iconUrl: '../../assets/images/sidebar/project-bank.svg',
    iconUrlActive: '../../assets/images/sidebar/projectbank-active.svg',
    active: false,
    visible: false
  },
  {
    id: 'lumen',
    authorization: false,
    title: "lumen",
    route: RoutingParams.LUMEN,
    iconUrl: '../../assets/images/sidebar/lumen.svg',
    iconUrlActive: '../../assets/images/sidebar/lumen.svg',
    active: false,
    visible: true
  },
];