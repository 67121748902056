<!-- Main Container -->
<div class="h-100">
  <!-- Header -->
  <div class="row px-24 py-24 g-0 modal-header">
    <div class="col-6 p-0">
      <p class="heading-2"> {{title}} <span translate="projectBank.projectPlayer.blackBox.blackBoxHeading"> </span></p>
    </div>
    <div class="col-6 d-flex justify-content-end p-0">
      <button class="p-0 close-button" (click)="activeModal.dismiss()">
        <svg-icon src="../../../../../assets/images/test_case_close.svg"></svg-icon>
      </button>
    </div>
  </div>
  <!-- Total Test Count & Add -->
  <div class="row px-24 py-24 g-0">
    <div class="col-6 p-0">
      <p class="mb-0 heading-2" translate="projectBank.projectPlayer.blackBox.totalTest"><span> {{count}}</span></p>
    </div>
    <div class="col-6 p-0 d-flex justify-content-end" (click)="addNewTest()" *ngIf='showTable'>
      <button class="p-0 d-inline-flex new-test-case-btn">
        <svg-icon src="../../../../../assets/images/plus.svg"></svg-icon>
        <span>
          <p class="ps-2 mb-0 .fs-16 add-new-text" translate="projectBank.projectPlayer.blackBox.addNewTest"></p>
        </span>
      </button>
    </div>

  </div>
  <!-- Input Table   -->
  <form [formGroup]="testCaseForm">
    <scrollbar class="scrollbar">
      <div class="row px-24 table-container g-0">
        <div class="d-flex flex-column align-items-center justify-content-center" *ngIf="!showTable">
          <div class="text-center img-container">
            <svg-icon src="../../../../../assets/images/projectPlayer/blackBoxFrame.svg"></svg-icon>
          </div>
          <button (click)="toggleShowTable()" class="btn btn-primary mt-24"
            translate="projectBank.projectPlayer.blackBox.addNew"></button>
        </div>

        <div class="test-case-box p-0 " *ngIf='showTable'>

          <table class="table-box">
            <thead class="table-headers">
              <tr>
                <th class="py-24 right-border required" translate="projectBank.projectPlayer.blackBox.caseTitle"></th>
                <th class="py-24" translate="projectBank.projectPlayer.blackBox.inputType">
                  <svg-icon class="ps-1" src="../../../../../assets/images/info.svg"
                    ngbPopover="This is a popover content" placement="top" triggers="hover"></svg-icon>
                </th>
                <th class="py-24" translate="projectBank.projectPlayer.blackBox.commandInput"></th>
                <th class="py-24 right-border" translate="projectBank.projectPlayer.blackBox.consoleInput"></th>
                <th class="py-24 right-border required" translate="projectBank.projectPlayer.blackBox.output"></th>
                <th></th>
              </tr>
            </thead>
            <tbody formArrayName="testInput" id="myTableBody">
              <ng-container *ngFor="let test of testCase?.controls; let i = index" [formGroupName]="i">
                <tr class="test-case-inputs ">
                  <td class="test-case-tile-column p-24 right-border bottom-border"><textarea formControlName="title"
                      class="form-control pb-0 pt-16 inputs"></textarea></td>
                  <td class="input-type-column p-24 bottom-border">
                    <ng-select ngClass="custom-ng-select" [items]="consoleIp" [clearable]="false" bindLabel="name"
                      bindValue="name" placeholder="Select" [closeOnSelect]="true" [searchable]="false"
                      formControlName="inputType" (change)="disableInput(test)">
                    </ng-select>
                  </td>
                  <td class="bottom-border pr-24 py-24"><input formControlName="commandInput"
                      [readOnly]="test.value.isCommandDisable" class="inputs form-control command-input" type="text" />
                    <div
                      *ngIf="(test.get('inputType')?.value === 'Command Input' || test.get('inputType')?.value === 'Command & Console Input') && !test.get('commandInput')?.value"
                      class="text-danger">
                      Command Input is required
                    </div>
                  </td>
                  <td class="right-border bottom-border pr-24 py-24"><textarea formControlName="consoleInput"
                      [readOnly]="test.value.isConsoleDisable" class="inputs  form-control console-input"></textarea>
                    <div
                      *ngIf="(test.get('inputType')?.value === 'Console Input' || test.get('inputType')?.value === 'Command & Console Input') && !test.get('consoleInput')?.value "
                      class="text-danger">
                      Console Input is required
                    </div>
                  </td>
                  <td class="right-border bottom-border p-24"><textarea formControlName="output"
                      class="inputs form-control"></textarea></td>
                  <td class="bottom-border">
                    <button class="delete-row" (click)="deleteRow(i)">
                      <svg-icon src="../../../../../assets/images/close.svg"></svg-icon>
                    </button>
                  </td>
                </tr>
              </ng-container>
            </tbody>
          </table>

        </div>

      </div>
    </scrollbar>
    <!-- Buttons -->
    <div class="row">
      <div class="col d-flex justify-content-end p-24" *ngIf='showTable'>
        <button class="btn btn-secondary-grey mr-24" (click)="activeModal.dismiss()">Cancel</button>
        <button id="saveButton" class="btn btn-primary" (click)="saveFrom()"
          [disabled]="testCaseForm.invalid">Save</button>
      </div>
    </div>
  </form>
</div>