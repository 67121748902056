import { EvaluationEventsKey } from "../enums/EvaluationEventKeys";
import { AttemptEventKey } from "../enums/attemptEventKeys";
import { ExerciseEventsKey } from "../enums/exerciseEventsKey";
import { ProjectEventsKey } from "../enums/projectEventsKey";

export interface SuccessConfig {
  title?: string,
  message: string,
  params?: string[],
  delegateToGlobalHandler: boolean,
}
type SuccessEventConfig = { [key in string]?: SuccessConfig }

export const successEventConfig: SuccessEventConfig = {
  [ProjectEventsKey.PROJECT_DELETED]: {
    title: "Project Deleted!",
    message: 'Your project {1} has been deleted successfully.',
    delegateToGlobalHandler: true,
    params: ['title']
  },
  [ProjectEventsKey.PROJECT_CLONED]: {
    title: "Project Cloned!",
    message: 'Project has been cloned successfully.',
    delegateToGlobalHandler: true
  },
  [ExerciseEventsKey.EXERCISE_DELETED]: {
    title: "Exercise Deleted!",
    message: 'Your exercise {1} has been deleted successfully.',
    delegateToGlobalHandler: true,
    params: ['title']
  },
  [ExerciseEventsKey.EXERCISE_CLONED]: {
    title: "Exercise Cloned!",
    message: 'Exercise has been cloned successfully.',
    delegateToGlobalHandler: true
  },
  [AttemptEventKey.PROJECT_SUBMITTED]: {
    title: "Project Submitted!",
    message: 'Your project {1} has been submitted successfully.',
    delegateToGlobalHandler: true,
    params: ['projectTitle']
  },
  [AttemptEventKey.EXERCISE_SUBMITTED]: {
    title: "Exercise Submitted!",
    message: 'Your exercise has been submitted successfully.',
    delegateToGlobalHandler: true
  }
  ,
  [EvaluationEventsKey.CUSTOM_MARKS_ADDED]: {
    title: "Custom Marks Added",
    message: 'Your project has been submitted successfully.',
    delegateToGlobalHandler: true,

  }
};