import { Component, ElementRef, Input, Renderer2 } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { IAttemptProjectDetails } from 'src/app/models/attemptInterface';

@Component({
  selector: 'app-exercise-player-modal',
  templateUrl: './exercise-player-modal.component.html',
  styleUrls: ['./exercise-player-modal.component.scss']
})
export class ExercisePlayerModalComponent {
  constructor(public activeModal: NgbActiveModal, private renderer: Renderer2, private elementRef: ElementRef) { }
  @Input() projectDetails!: IAttemptProjectDetails;
  sourceAttribute!: string;
  @Input() detailsPage = true;
  ngAfterViewInit() {
    this.addclassOnFigureTags();
  }

  addclassOnFigureTags() {
    const figureElements = this.elementRef.nativeElement.querySelectorAll('figure.image');
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    figureElements.forEach((figure: any) => {
      const img = figure.querySelector('img');
      const src = img.getAttribute('src');
      this.renderer.listen(img, 'click', () => this.clickonimage(src));
      this.renderer.addClass(figure, 'on');
    });
  }

  clickonimage(src: string) {
    this.sourceAttribute = src;
    this.detailsPage = false;
  }

  listenToDetailsEvent() {
    this.detailsPage = true;
    setTimeout(() => {
      this.addclassOnFigureTags();
    }, 100);
  }
}
