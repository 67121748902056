export enum ExerciseType {
  ASSIGNMENT = "assignment",
  QUIZ = "quiz",
  PRACTICE = "practice"
}

export enum ExerciseStatus {
  DRAFT = "draft",
  ATTACHED = "attached",
  NOT_ATTACHED = "not_attached"
}

export enum ContentStatus {
  MANDATORY = "mandatory",
  OPTIONAL = "optional"
}
export enum OverallGradeCalculation {
  MAXIMUM_OF_ALL_ATTEMPTS = "MaxOfAllAttempts",
  MINIMUM_OF_ALL_ATTEMPTS = "MinOfAllAttempts",
  AVERAGE_OF_ALL_ATTEMPTS = "AvgOfAllAttempts",
  FIRST_ATTEMPT = "FirstAttempt",
  LAST_ATTEMPT = "LastAttempt"
}

export enum GradingType {
  GRADABLE = "gradable",
  NON_GRADABLE_WITH_MARKS = "non_gradable_with_marks",
  NON_GRADABLE_WITHOUT_MARKS = "non_gradable_without_marks"
}