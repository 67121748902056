import { Component } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CreateNewExerciseComponent } from '../modals/create-new-exercise/create-new-exercise.component';
import { Subscription, map } from 'rxjs';
import { ExerciseService } from '../exercise.service';
import { SkeletonLoaderType } from 'src/app/enums/skeletonui';
import { SortOptions } from 'src/app/projectbank/project-bank/projectbank.component';
import { ExerciseType } from 'src/app/enums/getExerciseList';
import { ExerciseDetails } from 'src/app/models/exerciseInterface';
import { Configuration } from 'src/app/models/project-list-card';
import { ExerciseListCardModes } from 'src/app/enums/exerciseListCardModes';
import { CommonUtils } from 'src/app/utils/common-utils';
import { StorageService } from 'src/app/services/storage.service';
import { StorageKey } from 'src/app/enums/storageKey';
import { UserRoles } from 'src/app/enums/userRoles';
@Component({
  selector: 'app-exercise-list',
  templateUrl: './exercise-list.component.html',
  styleUrls: ['./exercise-list.component.scss']
})
export class ExerciseListComponent {
  exerciseList$!: Subscription;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  exerciseList: any = [];
  totalExercise!: number;
  SkeletonLoaderType = SkeletonLoaderType;
  ExerciseListCardModes = ExerciseListCardModes;
  selectSortOption!: SortOptions["value"];
  ExerciseType = ExerciseType;
  type = ExerciseType.ASSIGNMENT;
  loadingSkeleton = true;
  filterOptionSelected = false;
  searchString = '';
  userRole = UserRoles.FACULTY;
  userRoles = UserRoles;
  versionConfig: { language: string, versions: { langId: string, label: string, archetypes: { targetFileName: string, sourceFileName: string, label: string, archetypeId: string }[] }[] }[] = [];

  allFilters: { title: string, titleValue: { name: string, value: string }[] }[] = [];

  filters: Record<string, string[]> = {
    // author: [],
    language: [],
    archetype: [],
    level: [],
    status: []
  };

  constructor(private NgbModal: NgbModal, private exerciseService: ExerciseService, private storageService: StorageService) { }

  ngOnInit() {
    this.exerciseService.setExerciseType(this.type);
    this.getExerciseList();
    this.getConfig();
    this.userRole = this.storageService.get(StorageKey.role);
  }
  sortOptions = [
    {
      name: "Created A-Z",
      value: {
        sortBy: "createdAt",
        sortOrder: "ASC",
      }
    },
    {
      name: "Created Z-A",
      value: {
        sortBy: "createdAt",
        sortOrder: "DSC",
      }
    },
    {
      name: "Edited A-Z",
      value: {
        sortBy: "updatedAt",
        sortOrder: "ASC",
      }
    },
    {
      name: "Edited Z-A",
      value: {
        sortBy: "updatedAt",
        sortOrder: "DSC",
      }
    }

  ];

  createNewExerciseModal() {
    this.NgbModal.open(CreateNewExerciseComponent, {
      windowClass: 'createNewExercise-modal'
    });
  }

  public getExerciseList() {
    this.exerciseList$ = this.exerciseService.getExerciseList().pipe(
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      map((exerciseListPayload: { exercise: ExerciseDetails[], lastExerciseCursor: string, totalExercise: number, totalProjects: string }) => {
        this.exerciseService.setLastExerciseCursor = exerciseListPayload.lastExerciseCursor;
        this.totalExercise = exerciseListPayload.totalExercise;
        return exerciseListPayload;
      })
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    ).subscribe((exerciseListPayload: { exercise: ExerciseDetails[] }) => {
      this.loadingSkeleton = false;
      this.exerciseList.push(...exerciseListPayload.exercise);
    });
  }

  removeExercise(exerciseId: string) {
    this.exerciseList = this.exerciseList.filter((exerciseList: { exerciseId: string; }) => exerciseList.exerciseId !== exerciseId);
    this.totalExercise -= 1;
  }

  onScrollDown() {
    this.getExerciseList();
  }

  clearSearch() {
    this.searchString = '';
    this.exerciseService.setSearchString(this.searchString);
    this.refreshExerciseList();
  }

  searchExercise() {
    if (this.searchString !== '') {
      this.exerciseList = [];
      this.exerciseService.setSearchString(this.searchString);
      this.refreshExerciseList();
    }
  }

  public refreshExerciseList() {
    this.loadingSkeleton = true;
    this.exerciseService.setLastExerciseCursor = "";
    this.exerciseList = [];
    this.exerciseService.getExerciseList();
  }

  getExerciseTypeList(exerciseType: ExerciseType) {
    this.type = exerciseType;
    this.exerciseService.setExerciseType(this.type);
    this.refreshExerciseList();
  }

  setSortOption() {
    this.exerciseService.setSortOption(this.selectSortOption);
    this.refreshExerciseList();
  }

  appendClonedExerciseToList(cloneExercisedata: ExerciseDetails) {
    this.exerciseList.unshift(cloneExercisedata);
  }

  clearSort() {
    this.exerciseService.setSortOption({
      sortBy: "",
      sortOrder: "",
    });
    this.refreshExerciseList();
  }

  onSelectFilter(checked: boolean, item: string, title: string) {

    if (checked) {
      this.filters[title].push(item);
      if (title === "language") {
        this.setArchTypesBasedOnLanguages(title);
      }
    } else {
      this.removeIndex(item, this.filters[title]);
      if (title === "language") {
        this.setArchTypesBasedOnLanguages(title);
      }
    }

    const commonUtils = new CommonUtils();
    //Check if any filter options are selected
    this.filterOptionSelected = commonUtils.isAnyFilterSelected(this.filters);

    if (this.filters) {
      this.exerciseService.setFilters(this.filters);
      this.refreshExerciseList();
    }
  }

  async getConfig() {
    let levels: string[] = [];
    let state: string[] = [];
    //let authors: string[] = [];
    const configuration$ = this.exerciseService.getConfig().subscribe((configuration: Configuration) => {
      levels = configuration.level;
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      state = configuration.exerciseStatus!;
      // authors = configuration.author;
      this.versionConfig = configuration.languages;
      let languages: { name: string, value: string }[] = [];
      // let author: { name: string, value: string }[] = [];
      let level: { name: string, value: string }[] = [];
      let status: { name: string, value: string }[] = [];
      if (this.versionConfig) {
        languages = this.versionConfig.map((lang: { language: string, versions: { langId: string, label: string, archetypes: { targetFileName: string, sourceFileName: string, label: string, archetypeId: string }[] }[] }) => {
          return { name: lang.language, value: lang.language };
        });
      }
      this.allFilters.push({ title: "language", titleValue: languages });
      this.allFilters.push({ title: "archetype", titleValue: [] });
      // if (authors) {
      //   author = authors.map(author => ({ name: author, value: author }));
      //   this.allFilters.push({ title: "author", titleValue: author });
      // }
      if (levels) {
        level = levels.map(level => ({ name: level, value: level }));
        this.allFilters.push({ title: "level", titleValue: level });
      }
      if (state) {
        status = state.map(status => ({
          name: status.replace('_', ' ').toUpperCase(),
          value: status
        }));
        this.allFilters.push({ title: "status", titleValue: status });
      }
      configuration$.unsubscribe();
    });
  }

  setArchTypesBasedOnLanguages(title: string) {
    const filteredArchId: string[] = [];
    const archetypeNames: { name: string; value: string; }[] = [];
    const filteredVersionArray: { langId: string; label: string; archetypes: { targetFileName: string, sourceFileName: string, label: string; archetypeId: string; }[]; }[] = [];
    const index = this.allFilters.findIndex((item: { title: string; titleValue: { name: string, value: string }[] }) => item.title === 'archetype');
    if (this.filters[title].length > 0) {
      this.filters[title].forEach((language: string) => {
        this.versionConfig.map(
          (languagePayload: { language: string, versions: { langId: string, label: string, archetypes: { targetFileName: string, sourceFileName: string, label: string, archetypeId: string }[] }[] }) => {
            if (language === languagePayload.language) {
              filteredVersionArray.push(...languagePayload.versions);
              languagePayload.versions.forEach((version: { langId: string, label: string, archetypes: { targetFileName: string, sourceFileName: string, label: string, archetypeId: string }[] }) => {
                filteredArchId.push(...version.archetypes.flatMap(archetype => archetype.archetypeId));
              });
            }
          });
      });
      filteredVersionArray.map(item => {
        item.archetypes.map(archetype => {
          archetypeNames.push({
            name: archetype.label,
            value: archetype.archetypeId
          });
        });
      });
      this.allFilters[index].titleValue = [];
      if (index !== -1) {
        this.allFilters[index].titleValue.push(...archetypeNames);
      }
    }
    else {
      this.allFilters[index].titleValue = [];
    }
  }

  removeIndex(item: string, type: string[]) {
    const indexToRemove = type.indexOf(item);
    if (indexToRemove !== -1) {
      type.splice(indexToRemove, 1);
    }
  }

  ngOnDestroy() {
    this.exerciseList = [];
    this.exerciseService.lastExerciseCursor = "";
    this.exerciseList$.unsubscribe();
    this.filters = {};
  }

}
