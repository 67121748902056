/* eslint-disable @typescript-eslint/no-explicit-any */
import { Injectable } from '@angular/core';
import { ExerciseBuilderModes } from 'src/app/enums/exerciseBuilderModes';
import { ExerciseEventsKey } from 'src/app/enums/exerciseEventsKey';
import { ProjectEventsKey } from 'src/app/enums/projectEventsKey';
import { AddProjects, RemoveProject, ReorderProjects, exerciseData, saveAsDraft } from 'src/app/models/exerciseInterface';
import { ExerciseDetails } from 'src/app/models/exerciseInterface';
import { SocketService } from 'src/app/services/socket.service';
import { SortOptions } from './project-selection/project-selection.component';
import { Subject } from 'rxjs';

@Injectable()
export class ExerciseBuilderService {
  lastProjectCursor!: string;
  searchString!: string;
  public exerciseId!: string;
  public selectionMode = false;
  selectedSortOption!: SortOptions["value"];
  filters: Record<string, string[]> = {
    author: [],
    language: [],
    archetype: [],
    level: [],
  };
  public exerciseMode = ExerciseBuilderModes.CREATE;
  constructor(private socketService: SocketService) { }
  public exerciseBuilderMode = ExerciseBuilderModes.CREATE;
  public exerciseDetails!: ExerciseDetails;
  private exerciseDetailsStateBroadcaster = new Subject;
  public redirectBack!: string;

  getProjectSelectionList(payload: { exerciseId: string }) {
    return this.socketService.getDataFromSocket(ExerciseEventsKey.GET_PROJECT_SELECTION_LIST, ExerciseEventsKey.PROJECT_SELECTION_LIST, {
      ...((this.lastProjectCursor && this.lastProjectCursor !== '') && { lastProjectCursor: this.lastProjectCursor }),
      ...(this.searchString && { search: this.searchString }),
      ...((this.selectedSortOption && this.selectedSortOption.sortBy !== "") && { sortby: this.selectedSortOption.sortBy, sortOrder: this.selectedSortOption.sortOrder }),
      ...(this.filters && { filters: this.filters }),
      limit: 10,
      ...payload
    }, true);
  }

  set setLastProjectCursor(lastProjectCursor: string) {
    this.lastProjectCursor = lastProjectCursor;
  }
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  saveExerciseSetup(obj: exerciseData) {
    return this.socketService.getDataFromSocket(ExerciseEventsKey.CREATE_EXERCISE, ExerciseEventsKey.EXERCISE_CREATED, obj, true);
  }

  setSearchString(searchString: string) {
    this.searchString = searchString;
  }
  setSortOption(selectedSortOption: SortOptions["value"]) {
    this.selectedSortOption = selectedSortOption;
  }
  setFilters(selectedFilters: Record<string, string[]>) {
    this.filters = selectedFilters;
  }

  public getConfig() {
    const payload = {};
    return this.socketService.getDataFromSocket(ProjectEventsKey.GET_PROJECT_CONFIGURATION, ProjectEventsKey.PROJECT_CONFIGURATION, payload);
  }

  addProjects(obj: AddProjects) {
    return this.socketService.getDataFromSocket(ExerciseEventsKey.ADD_PROJECTS, ExerciseEventsKey.PROJECTS_ADDED, obj, true);
  }
  setExerciseId(exerciseId: string) {
    this.exerciseId = exerciseId;
  }

  getExerciseId() {
    return this.exerciseId;
  }
  publishExerciseDetailsState() {
    this.exerciseDetailsStateBroadcaster.next(true);
  }
  listenToExerciseDetailsState() {
    return this.exerciseDetailsStateBroadcaster.asObservable();
  }

  updateExerciseForm(obj: exerciseData) {
    return this.socketService.getDataFromSocket(ExerciseEventsKey.UPDATE_EXERCISE, ExerciseEventsKey.EXERCISE_METADATA_UPDATED, obj, true);
  }

  getExerciseDetails(payload: { exerciseId: string }) {
    return this.socketService.getDataFromSocket(ExerciseEventsKey.GET_EXERCISE_DETAILS, ExerciseEventsKey.EXERCISE_DEATILS, payload, true);
  }

  destroyInstance() {
    this.exerciseId = '';
  }

  getSubmissionCount(payload: { exerciseId: string }) {
    return this.socketService.getDataFromSocket(ExerciseEventsKey.GET_NO_OF_SUBMISSIONS, ExerciseEventsKey.NO_OF_SUBMISSIONS, payload, true);
  }

  removeProject(obj: RemoveProject) {
    return this.socketService.getDataFromSocket(ExerciseEventsKey.REMOVE_PROJECTS, ExerciseEventsKey.PROJECTS_REMOVED, obj, true);
  }

  editMarks(obj: AddProjects) {
    return this.socketService.getDataFromSocket(ExerciseEventsKey.EDIT_MARKS, ExerciseEventsKey.MARKS_EDITED, obj, true);
  }

  reorderProjects(obj: ReorderProjects) {
    return this.socketService.getDataFromSocket(ExerciseEventsKey.REORDER_PROJECTS, ExerciseEventsKey.PROJECTS_REORDERED, obj, true);
  }

  saveExerciseAsDraft(obj: saveAsDraft) {
    return this.socketService.getDataFromSocket(ExerciseEventsKey.UPDATE_EXERCISE, ExerciseEventsKey.EXERCISE_METADATA_UPDATED, obj, true);
  }
}
