import { Component, ElementRef } from '@angular/core';
import { SkeletonComponent } from 'src/app/base-class/skeleton-class';

@Component({
  selector: 'app-view-exercise-skeleton',
  templateUrl: './view-exercise-skeleton.component.html',
  styleUrls: ['./view-exercise-skeleton.component.scss']
})

export class ViewExerciseSkeletonComponent extends SkeletonComponent {
  constructor(override host: ElementRef<HTMLElement>) {
    super(host);
  }
  ngOnInit() {
    this.setStylesAndClass();
  }
}

