import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CKEditorModule } from '@ckeditor/ckeditor5-angular';
import { ReactiveFormsModule } from '@angular/forms';
import { CommonUtils } from 'src/app/utils/common-utils';
import { CkEditorComponent } from '../../components/ck-editor/ck-editor.component';
import { CkEditorService } from 'src/app/services/ck-editor.service';

@NgModule({
  declarations: [CkEditorComponent],
  imports: [
    CommonModule,
    CKEditorModule,
    ReactiveFormsModule
  ],
  providers: [CommonUtils, CkEditorService],
  exports: [CkEditorComponent]
})
export class CkEditorSharedModule { }
