import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';

interface IAlertCustomConfiguration {
  positionClass?: string,
  closeButton?: boolean,
  timeOut?: number,
  extendedTimeOut?: number,
  tapToDismiss?: boolean
}

export enum ErrorType {
  SUCESS = 'success',
  ERROR = 'error',
}

@Injectable({
  providedIn: 'root'
})
export class CommonService {

  constructor(private toastService: ToastrService, private translate: TranslateService,) { }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  translateText(translationKey: string, params?: { [key: string]: any }) {
    let result = '';
    this.translate.get(translationKey, params).subscribe((res: string) => {
      result = res;
    }).unsubscribe();
    return result;
  }

  showToast(message: string, type: ErrorType, configuration?: IAlertCustomConfiguration, title = '') {
    const defaultConfiguration: IAlertCustomConfiguration = {
      positionClass: 'toast-top-center',
      closeButton: true,
      timeOut: 3000,
      extendedTimeOut: 3000,
      tapToDismiss: false,
    };
    const mergedConfiguration: IAlertCustomConfiguration = {
      ...defaultConfiguration,
      ...configuration,
    };
    switch (type) {
      case ErrorType.SUCESS:
        this.toastService.success(message, title, mergedConfiguration);
        break;
      case ErrorType.ERROR:
        this.toastService.error(message, title, mergedConfiguration);
        break;
      default:
        break;
    }

  }
}
