import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ExerciseBuilderComponent } from './exercise-builder.component';
import { RoutingParams } from 'src/app/enums/routingParameters';

const routes: Routes = [
  {
    path: '',
    component: ExerciseBuilderComponent
  },
  {
    path: ':projectId/' + RoutingParams.TRY_PROJECT,
    loadChildren: () => import('./../../shared/modules/try-project/try-project.module').then((module) => module.TryProjectModule),
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ExerciseBuilderRoutingModule { }
