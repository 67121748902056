import { Component, EventEmitter, Input, Output } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ProjectDetails } from 'src/app/models/projectInterface';
import { ProjectBankService } from 'src/app/projectbank/project-bank.service';
import { ExerciseBuilderService } from '../../exercise-builder/exercise-builder.service';
import { DifficultyLevel } from 'src/app/models/project-list-card';
import { SkeletonLoaderType } from 'src/app/enums/skeletonui';

@Component({
  selector: 'app-preview-modal',
  templateUrl: './preview-modal.component.html',
  styleUrls: ['./preview-modal.component.scss']
})
export class PreviewModalComponent {
  constructor(public activeModal: NgbActiveModal, public exerciseBuilder: ExerciseBuilderService) { }
  @Input() projectId!: string;
  DifficultyLevel = DifficultyLevel;
  SkeletonLoaderType = SkeletonLoaderType;
  @Input() projectBankService!: ProjectBankService;
  @Output() closedStatus = new EventEmitter();
  projectDetails!: ProjectDetails;
  expanded = false;

  ngOnInit() {
    if (this.projectId) {
      this.projectBankService.projectId = this.projectId;
    }
    //fallbackMechanism
    if (!this.projectDetails) {
      this.projectBankService?.getProjectDetails().subscribe((res: ProjectDetails) => {
        this.projectDetails = res;
      });
    }
  }
  
  saveProject() {
    this.closedStatus.emit(this.projectId);
  }
}
