import { inject } from "@angular/core";
import { SocketService } from "../services/socket.service";
import { JwtHelperService } from "@auth0/angular-jwt";
import { StorageService } from "../services/storage.service";
import { StorageKey } from "../enums/storageKey";
import { UserRoles } from "../enums/userRoles";

export interface ITokenInterface {
  userId: string,
  role: string,
  name: string,
  orgId: string
}
export function initApp() {
  const socketService = inject(SocketService);
  const storageService = inject(StorageService);
  return async (): Promise<void> => {
    const jwt = getCookie('jwt_unextLabs');
    if (jwt) {
      const decodedToken: ITokenInterface = decodeJWTToken(jwt);
      const { userId, name, role, orgId } = decodedToken;
      storageService.set(StorageKey.UserId, userId);
      storageService.set(StorageKey.OrganizationId, orgId);
      storageService.set(StorageKey.UserName, name);
      storageService.set(StorageKey.role, role);
      role === 'student' ? socketService.role = UserRoles.STUDENT : socketService.role = UserRoles.FACULTY;
      socketService.socketConfig.header.Authorization = jwt;
    }
    socketService.initEndPoints();
  };
}

function getCookie(cookieName: string) {
  const cookie: Record<string, string> = {};
  document.cookie.split(';').forEach(function (el) {
    const [key, value] = el.split('=');
    cookie[key.trim()] = value;
  });
  return cookie[cookieName];
}

export function decodeJWTToken(token: string): ITokenInterface {
  const helper = new JwtHelperService();
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  return helper.decodeToken(token)!;
}