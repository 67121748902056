import { Component, EventEmitter, Input, Output } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { AddProjects } from 'src/app/models/exerciseInterface';
import { ProjectListCardItem } from 'src/app/models/project-list-card';
import { ExerciseBuilderService } from '../../exercise-builder/exercise-builder.service';
import { ActivatedRoute, Router } from '@angular/router';
import * as _ from 'lodash';

export enum MarksMode {
  CONFIRM_MARKS = 'confirm_marks',
  EDIT_MARKS = 'edit_marks',
}
@Component({
  selector: 'app-marks-modal',
  templateUrl: './marks-modal.component.html',
  styleUrls: ['./marks-modal.component.scss']
})
export class MarksModalComponent {
  @Input() selectedProjects!: ProjectListCardItem[];
  @Output() closedStatus = new EventEmitter();
  @Input() projectListInsideExercise!: ProjectListCardItem[];
  MarksMode = MarksMode;
  totalMarks = 0;
  exerciseId!: string;
  marksMode!: MarksMode;
  changesMade = false;

  localSelectedProjects: ProjectListCardItem[] = [];
  constructor(public activeModal: NgbActiveModal, private exerciseBuilderService: ExerciseBuilderService, private activatedRoute: ActivatedRoute, private router: Router) { }
  ngOnInit() {
    if (this.marksMode === MarksMode.CONFIRM_MARKS) {
      this.localSelectedProjects = _.cloneDeep(this.selectedProjects);
    }
    else {
      this.localSelectedProjects = _.cloneDeep(this.projectListInsideExercise);
    }
    this.calculateSumOfMarks();
  }

  calculateSumOfMarks(): void {
    if (this.localSelectedProjects) {
      this.totalMarks = this.localSelectedProjects.reduce((total: number, project: ProjectListCardItem) => total + (project.maxMarks || 0), 0);
    } else {
      this.totalMarks = 0;
    }
  }
  saveExercise() {
    const payload: AddProjects = {
      exerciseId: this.exerciseId,
      projects: this.localSelectedProjects.map((project) => ({
        projectId: project.projectId,
        maxMarks: project.maxMarks || 0,
      })),
    };
    this.exerciseBuilderService.addProjects(payload).subscribe(() => {
      this.closedStatus.emit();
    });
  }

  updateMarks() {
    const payload: AddProjects = {
      exerciseId: this.exerciseId,
      projects: this.localSelectedProjects.map((project) => ({
        projectId: project.projectId,
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        maxMarks: project.maxMarks!,
      })),
    };
    this.exerciseBuilderService.editMarks(payload).subscribe(() => {
      this.projectListInsideExercise = this.localSelectedProjects;
      this.closedStatus.emit();
    });
  }

  onInputChange() {
    this.changesMade = true;
  }

  closeModal() {
    this.activeModal.close();
  }

}
