import { Component, ElementRef } from '@angular/core';
import { SkeletonComponent } from 'src/app/base-class/skeleton-class';

@Component({
  selector: 'app-project-list-card-skeleton',
  templateUrl: './project-list-card-skeleton.component.html',
  styleUrls: ['./project-list-card-skeleton.component.scss']
})
export class ProjectListCardSkeletonComponent extends SkeletonComponent {
  constructor(override host: ElementRef<HTMLElement>) {
    super(host);
  }
  ngOnInit() {
    this.setStylesAndClass();
  }
}
