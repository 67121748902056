<scrollbar class="scrollbar" containerHeight='auto'>
  <div class="programming-editor-container d-flex"
    [ngClass]="{'layout-side' :projectPlayerService.layout === layout.SIDE,
  'layout-down':projectPlayerService.layout === layout.DOWN,'layout-picture':projectPlayerService.layout ===
  layout.PICTURE, 'layout-none':projectPlayerService.layout ===
  layout.NONE,'attempt-width':mode === ProjectPlayerModes.ATTEMPT, 'evaluation-width':mode === ProjectPlayerModes.EVALUATION, 'try-player':mode === ProjectPlayerModes.TRY , 'practice-player':mode === ProjectPlayerModes.PRACTISE}">

    <div class="inputside-container" #inputsidecontainer>
      <div class="editor-side">
        <div class="editor-header">
          <div class="tab-scroll-container">
            <scrollbar class="scrollbar">
              <div class="tab-scroll d-flex h-100">
                <div class="tabs">
                  <div class="tab heading-3" *ngFor="let tab of tabs; let i = index" [class.active]="activeFile === tab"
                    (click)="activateTab(tab)">
                    {{tab.name}}
                    <svg-icon *ngIf="tabs.length>1" (click)="close($event, tab.path)" class="ml-48"
                      src="../../../../../assets/images/projectPlayer/cross_icon.svg"></svg-icon>
                  </div>
                </div>
              </div>
            </scrollbar>
          </div>

          <div class="d-flex align-items-center justify-content-end editor-btn-container ml-24">
            <div class="d-flex align-items-center" *ngIf="mode == ProjectPlayerModes.ATTEMPT">
              <button class="customInputBtn"
                (click)="$event.stopPropagation();markForReview(projectPlayerService.projectId)">
                <svg-icon class="pe-2" *ngIf="!projectPlayerService.getMarkedForReview(projectPlayerService.projectId)"
                  src="../../../../../assets/images/file-editor/bookmark.svg"></svg-icon>
                <svg-icon class="pe-2" *ngIf="projectPlayerService.getMarkedForReview(projectPlayerService.projectId)"
                  src="../../../../assets/images/projectPlayer/BookmarkActive.svg"></svg-icon>
                <span class="body-bold-assistive-text-orange text-nowrap">Mark for Review</span>
              </button>

            </div>

            <div class="d-flex ml-24">
              <button class="body-bold-assistive-text-orange customInputBtn p-0"
                translate="projectBank.projectPlayer.customInput" (click)="customInput()">
                <span>
                  <svg-icon class="ps-2" src="../../../../../assets/images/projectPlayer/popup_icon.svg"></svg-icon>
                </span>

              </button>
            </div>
            <div class="ml-24 body-bold-assistive-text-orange customInputBtn "
              *ngIf="mode == ProjectPlayerModes.EDIT || mode == ProjectPlayerModes.TRY || mode==ProjectPlayerModes.EVALUATION"
              translate="projectBank.projectPlayer.sanitize">
              <div class="form-check form-switch form-switch-green ms-2">
                <input class="form-check-input cursor-pointer" type="checkbox" role="switch" id="flexSwitchCheckDefault"
                  [checked]="isSanitized" (change)="sanitizeFile($event)">
              </div>
            </div>
            <div class="ml-24"><button class="btn btn-secondary-orange-md" [disabled]="iscompileDisabled"
                (click)="compileAndRun()" [textContent]="getButtonLabel()"></button></div>
          </div>
        </div>
        <div class="tab-content py-24" *ngIf="tabs.length > 0 else skeleton">
          <ng-container *ngIf="activeFile !== null">
            <scrollbar class="scrollbar">
              <div class="editor-scroll">
                <div style="height: 100%">
                  <ngx-monaco-editor style="height: 100%;  width: 100%" [options]="editorOptions"
                    [(ngModel)]="activeFile.code" (onInit)="onEditorInit($event)">
                  </ngx-monaco-editor>
                </div>
              </div>
            </scrollbar>
          </ng-container>
        </div>
        <ng-template #skeleton>
          <ng-template skeletonUi [skeletonUiType]="SkeletonLoaderType.FileEditorSkeletonComponent"
            [skeletonUiWidth]="'10vw'" [skeletonUiStyles]="{'margin-right': '1vw'}">
          </ng-template>
        </ng-template>
      </div>
    </div>



    <ng-container *ngIf="projectPlayerService.layout === layout.SIDE">
      <ng-container *ngTemplateOutlet="output">
      </ng-container>
    </ng-container>

    <ng-container *ngIf="projectPlayerService.layout === layout.DOWN">
      <ng-container *ngTemplateOutlet="output">

      </ng-container>
    </ng-container>

    <ng-container *ngIf="projectPlayerService.layout === layout.PICTURE">
      <div appDraggable>
        <ng-container *ngTemplateOutlet="output">
        </ng-container>
      </div>
    </ng-container>

    <ng-container *ngIf="isResultDisplayed">
      <div class="uploading-sql-file-draggable" appDraggable>
        <ng-container *ngTemplateOutlet="result">
        </ng-container>
      </div>
    </ng-container>

    <ng-container *ngIf="displayUploader">
      <div class="uploading-sql-file-draggable" appDraggable>
        <ng-container *ngTemplateOutlet="uploader">
        </ng-container>
      </div>
    </ng-container>

  </div>
</scrollbar>

<ng-template #output>
  <div class="outputside-container">
    <div class="output-side">
      <div class="heading-3 output-heading d-flex align-items-center justify-content-between">
        <div class="d-flex align-items-center">
          <span translate="projectBank.projectPlayer.outputHeading"></span>
          <span (click)="navigateToViewPage()" class="cursor-pointer ms-1"
            *ngIf="['PYTHON_BASIC', 'R_BASIC', 'WEB_BASIC'].includes(projectPlayerService.selectedArchetype)">
            <svg-icon class="me-1" src="../../../../../assets/images/file-editor/icon-view.svg">
            </svg-icon>
            <span class="primary-text">View</span>
          </span>
        </div>
        <div class="close-icon" (click)="closeOutput()" *ngIf="mode !== ProjectPlayerModes.EDIT">
          <svg-icon class="cursor-pointer ms-1" src="assets/images/close.svg"></svg-icon>
        </div>
      </div>
      <div class="output-body">
        <scrollbar class="scrollbar">
          <div class="output-scroll" *ngIf="(compilationEvents.length >0)">
            <div *ngFor="let compile of compilationEvents">
              <div *ngIf="(compile | isElementArray)">
                <div class="dbms-query-table">
                  <table class="table table-bordered">
                    <thead>
                      <tr>
                        <th *ngFor="let key of (compile[0] | getkeys)">{{ key }}</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr *ngFor="let row of compile">
                        <td *ngFor="let key of (compile[0] | getkeys)">{{ row[key] }}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              <div *ngIf="!(compile | isElementArray)">
                <pre class="body-text">{{compile}}</pre><br>
              </div>
            </div>
          </div>
        </scrollbar>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #result>
  <div class="outputside-container">
    <div class="output-side">
      <div class="heading-3 output-heading d-flex align-items-center justify-content-between">
        <div class="close-icon" (click)="closeResult()">
          <svg-icon class="cursor-pointer ms-1" src="assets/images/close.svg"></svg-icon>
        </div>
      </div>
      <div class="output-body">
        <img [src]="base64String">
      </div>
    </div>
  </div>
</ng-template>

<ng-template #uploader>
  <app-sql-file-uploader [initFile]="initFile" [fileUploaderWidth]="fileUploaderWidth"
    (delete)="trigeredFromSql($event)"></app-sql-file-uploader>
</ng-template>