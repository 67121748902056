import { NgModule, Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'isElementArray'
})
export class IsElementArrayPipe implements PipeTransform {

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  transform(value: any): boolean {
    return Array.isArray(value) ? true : false;
  }

}
@NgModule({ declarations: [IsElementArrayPipe], exports: [IsElementArrayPipe] }) export class IsElementArrayPipeModule { }
