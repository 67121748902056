import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ProjectListCardSkeletonComponent } from '../../components/skeleton-ui/project-list-card-skeleton/project-list-card-skeleton.component';
import { SkeletonUiLoaderDirective } from 'src/app/directives/skeleton-ui-loader.directive';
import { SkeletonRectangleComponent } from '../../components/skeleton-ui/skeleton-rectangle/skeleton-rectangle.component';
import { ConsoleSkeletonComponent } from '../../components/skeleton-ui/console-skeleton/console-skeleton.component';
import { ModalSkeletonComponent } from '../../components/skeleton-ui/modal-skeleton/modal-skeleton.component';
import { EvaluationSkeletonComponent } from '../../components/skeleton-ui/evaluation-skeleton/evaluation-skeleton.component';
import { OverlaySkeletonComponent } from '../../components/skeleton-ui/overlay-skeleton/overlay-skeleton.component';
import { FileEditorSkeletonComponent } from '../../components/skeleton-ui/file-editor-skeleton/file-editor-skeleton.component';
import { ViewExerciseSkeletonComponent } from '../../components/skeleton-ui/view-exercise-skeleton/view-exercise-skeleton.component';

@NgModule({
  declarations: [SkeletonUiLoaderDirective, ProjectListCardSkeletonComponent, SkeletonRectangleComponent, ConsoleSkeletonComponent, ModalSkeletonComponent, EvaluationSkeletonComponent, OverlaySkeletonComponent, FileEditorSkeletonComponent, ViewExerciseSkeletonComponent],
  imports: [
    CommonModule
  ],
  exports: [
    SkeletonUiLoaderDirective
  ]
})
export class SkeletonUiModule { }
