<div class="add-scroll d-flex px-24 try-project-container">

  <div class="left-content">
    <scrollbar class="scrollbar" (scroll)="onScroll($event)">
      <div class="left-content-scroll">
        <div class="mb-16">
          <p class="body-bold-assistive-text">{{attemptDataDetails.attempt.userName}}</p>
        </div>
        <div class="mb-16">
          <p class="body-bold-assistive-text">
            Attempt {{attemptDataDetails.attempt.attemptNumber}} - {{attemptDataDetails.attempt.exerciseTitle}}
          </p>
        </div>
        <div class="d-flex align-items-center mb-16">
          <span class="d-flex align-items-center body-sub-text mr-16">
            Exercise Total:
            <span class="exercise-total body-pill-text ms-1">
              {{attemptDataDetails.attempt.maxMarks.toFixed(2)}}
            </span>
          </span>

          <span class="d-flex align-items-center body-sub-text">
            Pass Mark:
            <span class="pass-mark-card body-pill-text ms-1">
              {{attemptDataDetails.attempt.attemptsAndGradeConfig.passingCriteria}}
            </span>
          </span>

        </div>
        <!-- Accordian Having Projects -->
        <div class="accordion project-player-accordian projects-accordion mb-16" id="accordionPanelsStayOpenExample"
          #projectsAccordion>
          <div class="accordion-item project-player-accordian">
            <h2 class="accordion-header" id="panelsStayOpen-heading">
              <button
                class="accordion-button d-flex align-items-center justify-content-between heading-2 project-player-accordian-button"
                type="button">
                {{attemptDataDetails.attempt.projects.length}} Projects
                <span class="accordion-toggle-icon cursor-pointer ms-1" data-bs-toggle="collapse"
                  data-bs-target="#panelsStayOpen-collapse" aria-expanded="true"
                  aria-controls="panelsStayOpen-collapse"></span>
              </button>
            </h2>
            <div id="panelsStayOpen-collapse" class="accordion-collapse collapse show"
              aria-labelledby="panelsStayOpen-heading">
              <scrollbar class="scrollbar">
                <div class="accordion-body p-16">
                  <div class="project-list pb-16">
                    <div *ngFor="let project of projects; let i = index"
                      class="single-project d-flex align-items-center row-container" (click)="selectProject(project,i)"
                      [ngClass]="{'selected1': project.title === selectedProject.title}">
                      <div class="d-flex align-items-center p-2 col-content">
                        <div class="d-flex">
                          <div class="project-score" [ngClass]="{'selected': project.title === selectedProject.title}">
                            <p class="score body-sub-text"
                              [ngClass]="{'selected-score': project.title === selectedProject.title}">
                              <b>{{project.marks.toFixed(2)}}</b>/{{ project?.maxMarks }}
                            </p>
                          </div>
                        </div>
                      </div>
                      <div class="project-name text-break p-2  col-content d-inline-flex cursor-pointer">
                        {{ project?.title }}
                      </div>
                    </div>
                  </div>
                </div>
              </scrollbar>
            </div>
          </div>
        </div>
        <!-- Get Direcxtory Details      -->
        <div class="accordion project-player-accordian lang-file" id="accordionPanelsStayOpenExample1"
          #langFileAccordion>
          <div class="accordion-item project-player-accordian">
            <h2 class="accordion-header" id="panelsStayOpen-headingOne">
              <button
                class="accordion-button d-flex align-items-center justify-content-between heading-2  project-player-accordian-button"
                type="button" translate="projectBank.projectPlayer.languageFileHeading">
                <span class="accordion-toggle-icon cursor-pointer ms-1 collapsed" data-bs-toggle="collapse"
                  data-bs-target="#panelsStayOpen-collapseOne" aria-expanded="false"
                  aria-controls="panelsStayOpen-collapseOne">
                </span>
              </button>
            </h2>
            <div id="panelsStayOpen-collapseOne" class="accordion-collapse collapse "
              aria-labelledby="panelsStayOpen-headingOne">
              <div class="accordion-body p-0">
                <app-file-explorer [mode]="mode">
                </app-file-explorer>
              </div>
            </div>
          </div>
        </div>
        <!-- instructions -->
        <div class="project-clear-instructions">
          <scrollbar class="scrollbar">
            <app-project-details [mode]="mode"></app-project-details>
          </scrollbar>
        </div>
      </div>
    </scrollbar>

  </div>

  <div class="d-flex right-content-container">
    <div class="right-content abc border-right-0">
      <div class="row">
        <div class="heading-1 d-flex align-items-center col-6">
          <p *ngIf="!projectTitleLengthExceded">{{selectedProject.title}}</p>
          <P *ngIf="projectTitleLengthExceded" class="elipse" data-toggle="tooltip" [title]="selectedProject.title">
            {{selectedProject.title}}</P><span
            class="project-marks body-text ml-16 px-2 py-2"><b>{{selectedProject.maxMarks}}</b>
            <span>Mark</span></span>
        </div>
        <div class="col-6 d-flex justify-content-end ">
          <div class="d-flex cursor-pointer" (click)="openViewSolution()">
            <svg-icon class="pt-2 pe-2" src="../../../../assets/images/viewSolution.svg"></svg-icon>
            <p class="pt-2 body-bold-assistive-text-orange">View Solution</p>
          </div>
          <ng-container *ngIf="projectPlayerService.viewSolutionLayout === viewlayout.PICTURE">
            <div appDraggable>
              <ng-container *ngTemplateOutlet="output">
              </ng-container>
            </div>
          </ng-container>
          <div class="dropdown flat-btn ml-16 output-dropdown">
            <span class="dropdown-toggle cursor-pointer d-flex" data-bs-toggle="dropdown" aria-expanded="false">
              <img class="me-1" src="../../../../assets/images/output.svg">
              <p translate="projectBank.projectPlayer.tryProject.output"></p>
            </span>
            <ul class="dropdown-menu">
              <li>
                <a class="dropdown-item" (click)="outputLayout(layout.DOWN)"
                  [ngClass]="{'selected-layout': projectPlayerService.layout == layout.DOWN}">
                  <svg-icon class="selected-layout-icon pe-2" src="../../../../assets/images/tick.svg"></svg-icon>
                  <svg-icon class="me-2" src="../../../../assets/images/projectPlayer/down_panel_icon.svg"></svg-icon>
                  <p translate="projectBank.projectPlayer.tryProject.downPanel"></p>
                </a>
              </li>
              <li>
                <a class="dropdown-item" (click)="outputLayout(layout.PICTURE)"
                  [ngClass]="{'selected-layout': projectPlayerService.layout == layout.PICTURE}">
                  <svg-icon class="selected-layout-icon pe-2" src="../../../../assets/images/tick.svg"></svg-icon>
                  <svg-icon class="me-2" src="../../../../assets/images/projectPlayer/picture_in_picture_icon.svg">
                  </svg-icon>
                  <p translate="projectBank.projectPlayer.tryProject.pictureInPicture"></p>
                </a>
              </li>
              <li>
                <a class="dropdown-item" (click)="outputLayout(layout.NONE)"
                  [ngClass]="{'selected-layout': projectPlayerService.layout == layout.NONE}">
                  <svg-icon class="selected-layout-icon pe-2" src="../../../../assets/images/tick.svg"></svg-icon>
                  <svg-icon class="me-2" src="../../../../assets/images/projectPlayer/none_icon.svg"></svg-icon>
                  <p translate="projectBank.projectPlayer.tryProject.none"></p>
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <app-file-editor [mode]="mode"></app-file-editor>
    </div>

    <div class="third-component">
      <scrollbar class="third-component-scroll">
        <!-- Test Case Accordian -->
        <div class="mb-16">
          <div class="accordion" id="uniqueAccordion1">
            <div class="accordion-item project-player-accordian">
              <h2 class="accordion-header" id="uniqueAccordionHeading1">
                <button
                  class="accordion-button d-flex align-items-center justify-content-between heading-2  project-player-accordian-button"
                  type="button">Test Cases
                  <span class="accordion-toggle-icon cursor-pointer ms-1" data-bs-toggle="collapse"
                    data-bs-target="#uniqueAccordionCollapse1" aria-expanded="true"
                    aria-controls="uniqueAccordionCollapse1">
                  </span>
                </button>
              </h2>
              <div id="uniqueAccordionCollapse1" class="accordion-collapse collapse"
                aria-labelledby="uniqueAccordionHeading1">
                <scrollbar>
                  <div class="accordion-body test-case-accordian p-0">
                    <div class="p-16">
                      <!-- Test Case Box -->
                      <div class="testcase-box ">
                        <div class="px-16 mt-16 cursor-pointer" (click)="scrollToElement('userWhiteBox')">
                          <p>User White Box</p>
                        </div>
                        <div class="px-16 my-16 cursor-pointer" (click)="scrollToElement('evaluatorWhiteBox')">
                          <p>Evaluator White Box</p>
                        </div>
                        <div class="px-16 mb-16 cursor-pointer" (click)="scrollToElement('userBlackBox')">
                          <p>User Black Box</p>
                        </div>
                        <div class="px-16 pb-16 cursor-pointer" (click)="scrollToElement('evaluatorBlackBox')">
                          <p>Evaluator Black Box</p>
                        </div>
                      </div>
                      <!-- Test Case Value -->
                      <!-- User White Box -->
                      <div class="mt-24 mb-2" id="userWhiteBox">
                        <p class="ps-1 body-bold-assistive-text">User White Box</p>
                      </div>
                      <div *ngFor="let test of this.selectedProject.userWhiteBoxTests; let i = index">
                        <div>
                          <div class="mb-2">
                            <p class="ps-1 body-text">Test Case {{ i + 1 }}</p>
                          </div>
                          <div class="d-flex mb-2">
                            <div class="pr-16 me-2">
                              <img src="../../../../assets/images/exerciseBuilder/range-vector.svg" alt="">
                            </div>
                            <div class="body-text">
                              <p>Title:</p>
                            </div>
                            <div class="ps-1">
                              <p class="body-text">{{ test.title }}</p>
                            </div>
                          </div>
                          <div class="d-flex mb-2" *ngIf="test.consoleInput">
                            <div class="pr-16 me-2">
                              <img src="../../../../assets/images/exerciseBuilder/range-vector.svg" alt="">
                            </div>
                            <div class="body-text">
                              <p>Console Input:</p>
                            </div>
                            <div class="ps-1">
                              <pre class="body-text">{{test.consoleInput}}</pre>
                            </div>
                          </div>
                          <div class="d-flex mb-2" *ngIf="test.commandInput">
                            <div class="pr-16 me-2">
                              <img src="../../../../assets/images/exerciseBuilder/range-vector.svg" alt="">
                            </div>
                            <div class="body-text">
                              <p>Command Input:</p>
                            </div>
                            <div class="ps-1">
                              <pre class="body-text">{{test.commandInput}}</pre>
                            </div>
                          </div>
                          <div class="d-flex mb-2">
                            <div class="pr-16 me-2">
                              <img src="../../../../assets/images/exerciseBuilder/range-vector.svg" alt="">
                            </div>
                            <div class="body-text">
                              <p>Output:</p>
                            </div>
                            <div class="ps-1">
                              <pre class="body-text">{{test.output}}</pre>
                            </div>
                          </div>
                        </div>
                      </div>

                      <!-- Evaluator White Box -->
                      <div class="mt-24 mb-2" id="evaluatorWhiteBox">
                        <p class="ps-1 body-bold-assistive-text">Evaluator White Box</p>
                      </div>
                      <div *ngFor="let test of this.selectedProject.evalWhiteBoxTests;let i = index">
                        <div>
                          <div class="mb-2">
                            <p class="ps-1 body-text">Test Case {{ i + 1 }}</p>
                          </div>
                          <div class="d-flex mb-2">
                            <div class="pr-16 me-2">
                              <img src="../../../../assets/images/exerciseBuilder/range-vector.svg" alt="">
                            </div>
                            <div class="body-text">
                              <p>Title:</p>
                            </div>
                            <div class="ps-1">
                              <p class="body-text">{{ test.title }}</p>
                            </div>
                          </div>
                          <div class="d-flex mb-2" *ngIf="test.consoleInput">
                            <div class="pr-16 me-2">
                              <img src="../../../../assets/images/exerciseBuilder/range-vector.svg" alt="">
                            </div>
                            <div class="body-text">
                              <p>Console Input:</p>
                            </div>
                            <div class="ps-1">
                              <pre class="body-text">{{test.consoleInput}}</pre>
                            </div>
                          </div>
                          <div class="d-flex mb-2" *ngIf="test.commandInput">
                            <div class="pr-16 me-2">
                              <img src="../../../../assets/images/exerciseBuilder/range-vector.svg" alt="">
                            </div>
                            <div class="body-text">
                              <p>Command Input:</p>
                            </div>
                            <div class="ps-1">
                              <pre class="body-text">{{test.commandInput}}</pre>
                            </div>
                          </div>
                          <div class="d-flex mb-2">
                            <div class="pr-16 me-2">
                              <img src="../../../../assets/images/exerciseBuilder/range-vector.svg" alt="">
                            </div>
                            <div class="body-text">
                              <p>Output:</p>
                            </div>
                            <div class="ps-1">
                              <pre class="body-text">{{test.output}}</pre>
                            </div>
                          </div>
                        </div>
                      </div>

                      <!-- User Black Box -->
                      <div class="mt-24 mb-2" id="userBlackBox">
                        <p class="ps-1 body-bold-assistive-text">User Black Box</p>
                      </div>
                      <div *ngFor="let test of this.selectedProject.userBlackBoxTests; let i = index">
                        <div>
                          <div class="mb-2">
                            <p class="ps-1 body-text">Test Case {{ i + 1 }}</p>
                          </div>
                          <div class="d-flex mb-2">
                            <div class="pr-16 me-2">
                              <img src="../../../../assets/images/exerciseBuilder/range-vector.svg" alt="">
                            </div>
                            <div class="body-text">
                              <p>Title:</p>
                            </div>
                            <div class="ps-1">
                              <p class="body-text">{{ test.title }}</p>
                            </div>
                          </div>
                          <div class="d-flex mb-2" *ngIf="test.consoleInput">
                            <div class="pr-16 me-2">
                              <img src="../../../../assets/images/exerciseBuilder/range-vector.svg" alt="">
                            </div>
                            <div class="body-text">
                              <p>Console Input:</p>
                            </div>
                            <div class="ps-1">
                              <pre class="body-text">{{test.consoleInput}}</pre>
                            </div>
                          </div>
                          <div class="d-flex mb-2" *ngIf="test.commandInput">
                            <div class="pr-16 me-2">
                              <img src="../../../../assets/images/exerciseBuilder/range-vector.svg" alt="">
                            </div>
                            <div class="body-text">
                              <p>Command Input:</p>
                            </div>
                            <div class="ps-1">
                              <pre class="body-text">{{test.commandInput}}</pre>
                            </div>
                          </div>
                          <div class="d-flex mb-2">
                            <div class="pr-16 me-2">
                              <img src="../../../../assets/images/exerciseBuilder/range-vector.svg" alt="">
                            </div>
                            <div class="body-text">
                              <p>Output:</p>
                            </div>
                            <div class="ps-1">
                              <pre class="body-text">{{test.output}}</pre>
                            </div>
                          </div>
                        </div>
                      </div>

                      <!-- Evaluator Black Box -->
                      <div class="mt-24 mb-2" id="evaluatorBlackBox">
                        <p class="ps-1 body-bold-assistive-text">Evaluator Black Box</p>
                      </div>
                      <div *ngFor="let test of this.selectedProject.evalBlackBoxTests; let i=index">
                        <div>
                          <div class="mb-2">
                            <p class="ps-1 body-text">Test Case {{ i+1 }}</p>
                          </div>
                          <div class="d-flex mb-2">
                            <div class="pr-16 me-2">
                              <img src="../../../../assets/images/exerciseBuilder/range-vector.svg" alt="">
                            </div>
                            <div class="body-text">
                              <p>Title:</p>
                            </div>
                            <div class="ps-1">
                              <p class="body-text">{{ test.title }}</p>
                            </div>
                          </div>
                          <div class="d-flex mb-2" *ngIf="test.consoleInput">
                            <div class="pr-16 me-2">
                              <img src="../../../../assets/images/exerciseBuilder/range-vector.svg" alt="">
                            </div>
                            <div class="body-text">
                              <p>Console Input:</p>
                            </div>
                            <div class="ps-1">
                              <pre class="body-text">{{test.consoleInput}}</pre>
                            </div>
                          </div>
                          <div class="d-flex mb-2" *ngIf="test.commandInput">
                            <div class="pr-16 me-2">
                              <img src="../../../../assets/images/exerciseBuilder/range-vector.svg" alt="">
                            </div>
                            <div class="body-text">
                              <p>Command Input:</p>
                            </div>
                            <div class="ps-1">
                              <pre class="body-text">{{test.commandInput}}</pre>
                            </div>
                          </div>
                          <div class="d-flex mb-2">
                            <div class="pr-16 me-2">
                              <img src="../../../../assets/images/exerciseBuilder/range-vector.svg" alt="">
                            </div>
                            <div class="body-text">
                              <p>Output:</p>
                            </div>
                            <div class="ps-1">
                              <pre class="body-text">{{test.output}}</pre>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </scrollbar>
              </div>
            </div>
          </div>
        </div>
        <!-- Rubric Accordian -->
        <div class="mb-16">
          <div class="accordion project-player-accordian lang-file" id="uniqueAccordion2">
            <div class="accordion-item project-player-accordian">
              <h2 class="accordion-header" id="uniqueAccordionHeading2">
                <button
                  class="accordion-button d-flex align-items-center justify-content-between heading-2  project-player-accordian-button"
                  type="button">Rubric
                  <span class="accordion-toggle-icon cursor-pointer ms-1" data-bs-toggle="collapse"
                    data-bs-target="#uniqueAccordionCollapse2" aria-expanded="true"
                    aria-controls="uniqueAccordionCollapse2">
                  </span>
                </button>
              </h2>
              <div id="uniqueAccordionCollapse2" class="accordion-collapse collapse"
                aria-labelledby="uniqueAccordionHeading2">
                <div class="accordion-body p-0">
                  <div>
                    <div class="row mt-16 px-16">
                      <div class="col-7 d-flex">
                        <p class="body-bold-assistive-text me-2">Compilation</p>
                        <div class="rubric-percentage">
                          <p class="rubric-percentage-marks">{{ compilation }}%</p>
                        </div>
                      </div>
                      <div class="col-5 d-flex justify-content-center align-items-center">
                        <p class="text-right body-text"> {{selectedProject.systemMarks.compilation}} </p>
                      </div>
                    </div>
                    <div class="row mt-24 px-16">
                      <div class="col-7 d-flex">
                        <p class="body-bold-assistive-text me-2">User Black Box</p>
                        <div class="rubric-percentage">
                          <p class="rubric-percentage-marks">{{ userBlackBox }}%</p>
                        </div>
                      </div>
                      <div class="col-5 d-flex justify-content-center align-items-center">
                        <p class="text-right body-text">{{selectedProject.systemMarks.userBlackBox}}</p>
                      </div>
                    </div>
                    <div class="row mt-24 px-16">
                      <div class="col-7 d-flex">
                        <p class="body-bold-assistive-text me-2">Evaluator Black Box</p>
                        <div class="rubric-percentage">
                          <p class="rubric-percentage-marks">{{ evaluatorBlackBox }}%</p>
                        </div>
                      </div>
                      <div class="col-5 d-flex justify-content-center align-items-center">
                        <p class="text-right body-text">{{selectedProject.systemMarks.evalBlackBox}}</p>
                      </div>
                    </div>
                    <div class="row mt-24 px-16">
                      <div class="col-7 d-flex">
                        <p class="body-bold-assistive-text me-2">User White Box</p>
                        <div class="rubric-percentage">
                          <p class="rubric-percentage-marks">{{ userWhiteBox }}%</p>
                        </div>
                      </div>
                      <div class="col-5 d-flex justify-content-center align-items-center">
                        <p class="text-right body-text">{{selectedProject.systemMarks.userWhiteBox}}</p>
                      </div>
                    </div>
                    <div class="row mt-24 px-16">
                      <div class="col-7 d-flex">
                        <p class="body-bold-assistive-text me-2">Evaluator White Box</p>
                        <div class="rubric-percentage">
                          <p class="rubric-percentage-marks">{{ evaluatorWhiteBox }}%</p>
                        </div>
                      </div>
                      <div class="col-5 d-flex justify-content-center align-items-center">
                        <p class="text-right body-text">{{selectedProject.systemMarks.evalWhiteBox}}</p>
                      </div>
                    </div>
                    <hr>
                    <div class="row mt-24 px-16 pb-16">
                      <div class="col-7 d-flex">
                        <p class="body-bold-assistive-text">System Marks</p>
                      </div>
                      <div class="col-5 d-flex justify-content-center align-items-center">
                        <p class="text-right body-text">{{ selectedProject.systemMarks.totalMarks.toFixed(2)}}</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- Total Marks & Feedback -->
        <div class="accordion" id="uniqueAccordion3">
          <div class="accordion-item project-player-accordian">
            <h2 class="accordion-header" id="uniqueAccordionHeading3">
              <button
                class="accordion-button d-flex align-items-center justify-content-between heading-2  project-player-accordian-button"
                type="button">Total Marks & Feedback
                <span class="accordion-toggle-icon cursor-pointer ms-1" data-bs-toggle="collapse"
                  data-bs-target="#uniqueAccordionCollapse3" aria-expanded="true"
                  aria-controls="uniqueAccordionCollapse3">
                </span>
              </button>
            </h2>
            <div id="uniqueAccordionCollapse3" class="accordion-collapse collapse show"
              aria-labelledby="uniqueAccordionHeading3">
              <scrollbar style="height: 60%;">
                <div class="accordion-body total-mark-accordian p-0">
                  <form [formGroup]="feedbackForm" class="scrollable-form">
                    <div class="py-16">
                      <div class="px-16">
                        <div class="add-marks">
                          <div class="row pb-16">
                            <div class="col-7 d-flex">
                              <p class="body-bold-assistive-text">System Marks</p>
                            </div>
                            <div class="col-4 d-flex justify-content-center align-items-center">
                              <p class="text-right body-text">
                                {{selectedProject.systemMarks.totalMarks}}/{{selectedProject.maxMarks}}</p>
                            </div>
                          </div>
                          <div class="mt-2">
                            <div class="d-flex align-items-center" *ngIf="selectedProject.plagiarismScore ">
                              <div class="d-flex align-items-center plagiarism-input-container flex-wrap">
                                <span class="body-text pe-2">Plagiarism</span>
                                <span class="similarity-card">
                                  {{selectedProject.plagiarismScore}}% similarity
                                </span>
                              </div>
                              <div class="input-container">
                                <div class="left-section">
                                  <img src="../../../../assets/images/evaluation-player/minus.svg">
                                </div>
                                <input type="number" class="input-box marks-input" style="appearance: textfield;"
                                  formControlName="plagiarism" (input)="calculateTotalMarks()">
                              </div>
                            </div>

                            <div formArrayName="feedBackInput">
                              <div *ngFor="let control of testCase.controls; let i = index">
                                <div [formGroupName]="i">
                                  <div class="d-flex align-items-center mt-2">

                                    <div class="code-quality-field" [ngClass]="{'disabled': isTotalMarksExceeded}">
                                      <input type="text" class="title-input required" id="myInput"
                                        placeholder="Code Quality" formControlName="title">
                                    </div>

                                    <div class="d-flex justify-content-center align-items-center ml-16">
                                      <div class="input-container" [ngClass]="{'disabled': isTotalMarksExceeded}">
                                        <div class="left-section">
                                          <img src="../../../../assets/images/evaluation-player/plus.svg">
                                        </div>
                                        <input type="number" class="input-box marks-input"
                                          style="appearance: textfield;" formControlName="marks"
                                          (input)="calculateTotalMarks()">
                                      </div>
                                      <svg-icon class="pl-16 cursor-pointer"
                                        src="../../.././../assets/images/evalCross.svg" (click)="deleteRow(i)">
                                      </svg-icon>
                                    </div>

                                  </div>
                                </div>
                              </div>
                            </div>
                            <label *ngIf="feedbackForm.invalid" class="error-message form-label">
                              Titles must be unique.</label>

                            <div class="mt-2" [ngClass]="{'disabled': isTotalMarksExceeded}">
                              <button class="p-0 d-inline-flex new-test-case-btn" (click)="addNewTest()">
                                <svg-icon src="../../../../../assets/images/plus.svg"></svg-icon>
                                <span>
                                  <p class="ps-2 mb-0 fs-16 add-new-text">Add Extra Marks</p>
                                </span>
                              </button>
                            </div>
                          </div>

                        </div>
                        <div class="final-Marks my-16" [ngClass]="{'error': isTotalMarksExceeded}">
                          <div class="d-flex">
                            <p class="px-16 py-24 final-Marks-text" [ngClass]="{'error-text': isTotalMarksExceeded}">
                              Final Marks Received</p>
                            <p class="final-Marks-text px-16 py-24" [ngClass]="{'error-text': isTotalMarksExceeded}">
                              {{sum.toFixed(2)}}/{{selectedProject.maxMarks}}</p>
                          </div>
                        </div>
                        <div class="pb-16">
                          <p class="body-bold-assistive-text mb-16">Feedback</p>
                          <textarea formControlName="feedback" class="form-control"
                            placeholder="Share Your Feedback About The Project" rows="5"></textarea>
                        </div>
                        <div class="px-16 pt-16 d-flex justify-content-center align-items-center">
                          <button type="button" class="btn btn-primary"
                            [disabled]="isTotalMarksExceeded || !feedbackForm.valid" (click)="saveForm()">Submit Marks &
                            Feedback</button>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </scrollbar>
            </div>
          </div>
        </div>
      </scrollbar>
    </div>
  </div>

</div>

<div class="d-flex justify-content-end footerBtn">
  <button type="button" class="btn btn-secondary-grey mx-4 px-5" (click)="exitEvaluation()">Exit Evaluation</button>
  <button type="button" class="btn btn-primary" [disabled]="isTotalMarksExceeded"
    (click)="completeExerciseEvaluation()">Complete Exercise Evaluation</button>
</div>
<ng-template #output>
  <div class="container-fluid h-100">
    <div class="row px-24 py-24 view-solution-pip g-0">
      <div class=" col-6 p-0">
        <p class="heading-2"> Solution</p>
      </div>
      <div class="col-6 d-flex justify-content-end p-0">
        <button class="p-0 close-button" (click)="closeViewSolution()" style="border-style: none; background: #ffff;">
          <svg-icon src="../../../../assets/images/test_case_close.svg"></svg-icon>
        </button>
      </div>
    </div>
    <div class="px-4 py-24 view-solution">
      <div class="lower-box ">
        <div class="editor-scroll">
          <div>
            <ngx-monaco-editor [options]="editorOptions" [(ngModel)]="solutionCode" style="height: 40vh;">
            </ngx-monaco-editor>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-template>