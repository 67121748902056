import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LayoutComponent } from './layout.component';
import { RoutingParams } from '../enums/routingParameters';

const routes: Routes = [
  {
    path: '',
    canActivate: [],
    component: LayoutComponent,
    children: [
      {
        path: RoutingParams.DASHBOARD,
        loadChildren: () => import('../dashboard/dashboard.module').then((module) => module.DashboardModule),
      },
      {
        path: RoutingParams.EXERCISE,
        loadChildren: () => import('../exercise/exercise.module').then((module) => module.ExerciseModule),
      },
      {
        path: RoutingParams.PROJECT_BANK,
        loadChildren: () => import('../projectbank/projectbank.module').then((module) => module.ProjectBankModule),
      },
      {
        path: RoutingParams.LEARNER,
        loadChildren: () => import('../learner/learner.module').then((module) => module.LearnerModule),
      },
      {
        path: RoutingParams.EVALUATION,
        loadChildren: () => import('../evaluation/evaluation.module').then((module) => module.EvaluationModule),
      },
      {
        path: RoutingParams.SELFPRACTISE,
        loadChildren: () => import('../self-practice/self-practice.module').then((module) => module.SelfPracticeModule)
      },
      {
        path: RoutingParams.LUMEN_INTEGRATION,
        loadChildren: () => import('../lumen-integration/lumen-integration.module').then((module) => module.LumenIntegrationModule),

      },
      {
        path: 'identify',
        redirectTo: RoutingParams.LUMEN_INTEGRATION + '/identify'
      }

      // {
      //   path: '',
      //   pathMatch: 'full',
      //   redirectTo: './' + RoutingParams.PROJECT_BANK
      // },
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class LayoutRoutingModule { }
