export enum ExerciseType {
  QUIZ = 'quiz',
  ASSIGNMENT = 'assignment',
  PRACTICE = 'practice',
  
}

export enum ExerciseStatus {
  DRAFT = "draft",
  ATTACHED = "attached",
  NOT_ATTACHED = "not_attached"
}