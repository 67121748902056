<!-- Main Container -->
<div class=" container-fluid delete-modal-style">
  <!-- Image -->
  <div class="py-24">
    <div class="alert-img-container">
      <svg-icon src="../../../../assets/images/icon-warning-orange.svg"></svg-icon>
    </div>

  </div>
  <!-- First Warning Line -->
  <p class=" heading-2 pb-24" [innerHtml]="modalConfig.message"></p>
  <!-- Second Warning Line -->
  <p class="body-sub-text" [innerHtml]="modalConfig.note"></p>
  <!-- option -->

  <div class="mt-32">
    <div *ngFor="let option of modalConfig.archetypeOptions">
      <div class="form-check form-check-inline mb-16">
        <label class="form-check-label custom-radio" (click)="selectOptions(option)">
          <input class="form-check-input" type="radio" name="radio"
            [checked]="modalConfig.selectedArchetype === option.archetypeId">
          <span class="checkmark"></span>
          {{option.label}}
        </label>
      </div>

    </div>

  </div>

  <!-- Buttons -->
  <div class="row py-24 justify-content-center">
    <button class="btn btn-secondary-grey mr-24" [translate]="modalConfig.cancelBtn"
      (click)="sendCancelStatus()"></button>
    <button class="btn btn-primary" [translate]="modalConfig.confirmBtn" (click)="sendConfirmStatus()"></button>
  </div>
</div>