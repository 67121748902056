import { Directive, ElementRef, Output, EventEmitter, HostListener, Input, HostBinding } from '@angular/core';

@Directive({
  selector: '[appInfiniteScroll]'
})
export class InfiniteScrollDirective {
  @Output() scrolled = new EventEmitter<void>();
  @Input() scrollThreshold = 0.7;

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private scrollTimeout!: any;
  previousScrollPosition = 0;
  @HostBinding('class') elementClass = 'scrollable-list';

  constructor(private el: ElementRef) { }

  @HostListener('scroll')
  onScroll(): void {
    const element = this.el.nativeElement;
    const scrollPosition = element.scrollTop;
    const totalHeight = element.scrollHeight - element.clientHeight;

    if (scrollPosition > this.previousScrollPosition && scrollPosition >= totalHeight * this.scrollThreshold) {
      this.previousScrollPosition = scrollPosition;
      clearTimeout(this.scrollTimeout); // Clear previous timeout
      this.scrollTimeout = setTimeout(() => {
        this.scrolled.emit();
      }, 200); // Set a timeout (adjust as needed)
    }
  }
}
