import { Injectable } from '@angular/core';
import { SocketService } from './socket.service';
import { IfilesContent } from '../models/projectInterface';
import { ProjectEventsKey } from '../enums/projectEventsKey';
import { filter } from 'rxjs';

@Injectable()
export class CkEditorService {
  constructor(private socketService: SocketService) { }

  UploadContent(obj: IfilesContent) {
    // we are wrapping a observal to spectific file name , incase we are uploading same image
    const obs = this.socketService.getDataFromSocket(ProjectEventsKey.UPLOAD_IMAGE, ProjectEventsKey.IMAGE_UPLOADED, obj, true)
      .pipe(
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        filter((data: any) => data[0].originalFileName === obj.files[0].originalFileName)); // added filter to retrun images based on file name
    return obs;
  }

}
